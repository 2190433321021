import React from "react";
import {Box, Center, HStack, Icon, ScrollView, Text, VStack} from "native-base";
import {CompanyInformation} from "../state";
import {Feather} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";

interface CompanyScreenProps {
  companyInformation: CompanyInformation;
}

interface InformationComponent {
  iconName?: string;
  label: string;
  description: string;
}

const LabelComponent = ({ ...props }) => (
  <Text
    fontSize="md"
    bold
    color="darkText"
    numberOfLines={1}
  >
    {props.label}
  </Text>
);

const InformationComponent = (props: InformationComponent) => {
  return (
    <VStack
      w="full"
      borderRadius={8}
      borderWidth={1}
      borderColor="coolGray.400"
      py={3}
      px={5}
      space="sm"
    >
      <HStack
        space="sm"
        alignItems="center"
      >
        {
          props.iconName &&
          <Icon
            as={Feather}
            name={props.iconName}
            color="darkText"
            size={5}
          />
        }
        <LabelComponent
          label={props.label}
        />
      </HStack>
      <Box>
        <Text
          fontSize="sm"
          color="darkText"
        >
          {props.description}
        </Text>
      </Box>
    </VStack>
  );
};

export const CompanyScreen = (props: CompanyScreenProps) => {
  const {t}: { t: any } = useTranslation();
  return (
    <Center
      flex={1}
      bg="secondary.500"
    >
      <ScrollView
        w="full"
        h="full"
        contentContainerStyle={{
          paddingVertical: 10,
        }}
      >
        <VStack
          w="full"
          space="md"
        >
          <VStack
            px={4}
            space="md"
          >
            <LabelComponent
              label={t('companyScreen.generalInformation')}
            />
            <InformationComponent
              label={t('companyScreen.address')}
              description={props.companyInformation.address}
              iconName="map-pin"
            />
            <InformationComponent
              label={t('companyScreen.phone')}
              description={props.companyInformation.phone}
              iconName="phone"
            />
            <InformationComponent
              label={t('companyScreen.email')}
              description={props.companyInformation.email}
              iconName="mail"
            />
          </VStack>
        </VStack>
      </ScrollView>
    </Center>
  );
};
