import {ModalProps, State} from "../state";
import React from "react";
import {Button, Modal} from "native-base";
import {connect, useDispatch} from "react-redux";
import {updateErrorModalStatus} from "../actions/actions";
import { useTranslation } from "react-i18next";

interface ErrorModalContainerProps {
  errorModalProps: ModalProps;
}

const mapStateToProps = (state: State) => {
  return {
    errorModalProps: state.control.errorModal
  }
}

const ErrorModalContainer = (props: ErrorModalContainerProps) => {
  const errorModalProps = props.errorModalProps;
  const dispatch = useDispatch();
  const {t}: { t: any } = useTranslation();

  const handleErrorModalCloseButton = () => {
    dispatch(updateErrorModalStatus({
      title: "",
      content: "",
      isOpen: false
    }));
  }

  return (
    <Modal isOpen={errorModalProps.isOpen}
           overlayVisible={true}
           closeOnOverlayClick={true}
           size={"xl"}
           onClose={handleErrorModalCloseButton}
    >
      <Modal.Content bgColor={"secondary.500"}>
        <Modal.CloseButton/>
        <Modal.Header>{props.errorModalProps.title}</Modal.Header>
        <Modal.Body>{errorModalProps.content}</Modal.Body>
        <Modal.Footer bgColor={"secondary.500"}>
          <Button colorScheme={"danger"}
                  size={"md"}
                  onPress={handleErrorModalCloseButton}
          >
            {String(t('button.closeButton'))}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps)(ErrorModalContainer);