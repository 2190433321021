import {CompanyScreen} from "../screens/CompanyScreen";
import React, {useEffect, useState} from "react";
import {CompanyData, CompanyInformation, State} from "../state";
import {connect, useDispatch} from "react-redux";
import {fetchCompanyInformation} from "../actions/actionsCompany";

interface CompanyScreenContainerProps {
  selectedCompany?: CompanyData;
  companyInformation?: CompanyInformation;
}

const mapStateToProps = (state: State) => {
  return {
    selectedCompany: state.control.selectedCompany,
    companyInformation: state.control.companyInformation,
  };
};

const CompanyScreenContainer = (props: CompanyScreenContainerProps) => {

  const initialCompanyInformation: CompanyInformation = {
    companyName: '',
    companyDescription: '',
    companyLogo: '',
    email: '',
    phone: '',
    address: '',
    codeOfConduct: '',
    visionMission: '',
    whatsAppNumber: '',
  };

  const dispatch = useDispatch();
  const [companyInformation, setCompanyInformation] = useState<CompanyInformation>(initialCompanyInformation);

  useEffect(() => {
    if (props.selectedCompany) {
      dispatch(fetchCompanyInformation(props.selectedCompany.companyName))
    }
  }, [props.selectedCompany]);

  useEffect(() => {
    if (props.companyInformation) {
      setCompanyInformation(props.companyInformation);
    }
  }, [props.companyInformation]);

  return (
    <CompanyScreen
      companyInformation={companyInformation}
    />
  );
};

export default connect(mapStateToProps)(CompanyScreenContainer);
