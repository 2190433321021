import {Box, Center, Heading, HStack, Image, ScrollView, Text, VStack} from "native-base";
import React from "react";
import {ProjectProgressBarComponent} from "../Components/ProjectProgressBarComponent";
import {ProjectDetail, TimesheetDetail} from "../state";
import {DropdownComponent} from "../Components/DropdownComponent";
import {DEVICE_WIDTH} from "../master-data/constants";
import {BarChart} from "react-native-chart-kit";
import {ChartData} from "react-native-chart-kit/dist/HelperTypes";
import {AbstractChartConfig} from "react-native-chart-kit/dist/AbstractChart";
import {useTranslation} from "react-i18next";

interface MonthlyPerformanceScreenProps {
  timesheetDetail?: TimesheetDetail;
  selectedProject?: ProjectDetail;
  selectedDropdown: string;
  projects: string[];
  handleDropdownOnChange: (value: string) => void;
}

interface ProjectDetailInformationProps {
  projectDetail: ProjectDetail;
}

export const MonthlyPerformanceScreen = (props: MonthlyPerformanceScreenProps) => {

  const {t}: { t: any } = useTranslation();

  const ProjectContributionBanner = () => {
    return (
      <HStack
        space="sm"
      >
        <Box
          flex={1}
          p={3}
          borderRadius={10}
          bg="success.500"
        >
          <VStack>
            <Center mb={2}>
              <Image
                key="most-project-illustration"
                alt="most-project"
                size="md"
                source={require('../../assets/performance-illustrations/most-project-illustration.png')}
                resizeMode="contain"
              />
            </Center>
            <Text
              fontSize="sm"
              color="secondary.500"
            >
              {t('monthlyPerformanceScreen.yourMostProject')}
            </Text>
            <Text
              fontSize="md"
              fontWeight="bold"
              color="secondary.500"
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {props.timesheetDetail?.mostActiveProject || ""}
            </Text>
          </VStack>
        </Box>
        <Box
          flex={1}
          p={3}
          borderRadius={10}
          bg="blue.400"
        >
          <VStack>
            <Center mb={2}>
              <Image
                key="most-activity-illustration"
                alt="most-activity"
                size="md"
                source={require('../../assets/performance-illustrations/most-activity-illustration.png')}
                resizeMode="contain"
              />
            </Center>
            <Text
              fontSize="sm"
              color="secondary.500"
            >
              {t('monthlyPerformanceScreen.yourMostActivity')}
            </Text>
            <Text
              fontSize="md"
              fontWeight="bold"
              color="secondary.500"
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {props.timesheetDetail?.mostActivity || ""}
            </Text>
          </VStack>
        </Box>
      </HStack>
    );
  };

  const ProjectDetailInformation = (item: ProjectDetailInformationProps) => {
    const projectDetail = item.projectDetail;
    const chartConfig: AbstractChartConfig = {
      backgroundGradientFrom: "#FBF5E7",
      backgroundGradientTo: "#FBF5E7",
      fillShadowGradient: projectDetail.colorCode,
      fillShadowGradientOpacity: 1,
      color: (opacity = 1) => `rgba(90, 90, 90, ${opacity})`,
      labelColor: () => `rgba(90, 90, 90, 1)`,
      barPercentage: 1,
      propsForVerticalLabels: {rotation: -15},
      style: {
        marginRight: 20
      },
      propsForDots: {
        r: "6",
        strokeWidth: "30",
        stroke: "#4080DA"
      },
    };

    const formatData = () => {
      const activitiesLength = Object.keys(projectDetail.activities).length;
      const datasets: number[] = Array(activitiesLength).fill(0);
      const activities = Object.keys(projectDetail.activities);
      let chartData: ChartData = {
        labels: activities,
        datasets: [{ data: datasets }],
      };
      Object.entries(projectDetail.activities)
        .forEach(([key, value], index) => {
          datasets[index] = value;
        });
      return chartData;
    };

    return (
      <VStack
        flex={1}
        space="lg"
        alignItems="center"
      >
        <Box
          w="full"
          bg="primary.500"
          borderRadius={5}
        >
          <Center
            p={3}
            borderWidth={1}
            borderRadius={5}
            borderColor="primary.500"
            bg="secondary.500"
          >
            <Heading>
              {projectDetail.totalProjectHours}
            </Heading>
          </Center>
          <Center
            p={2}
            borderRadius={5}
            bg="primary.500"
            justifyContent="center"
          >
            <Text
              color="secondary.500"
              textAlign="center"
            >
              {t('monthlyPerformanceScreen.workHoursProject')}
            </Text>
          </Center>
        </Box>
        <ScrollView
          pb={5}
          mb={5}
          horizontal
        >
          <BarChart
            data={formatData()}
            width={DEVICE_WIDTH * 1.5}
            chartConfig={chartConfig}
            style={{
              flex: 1,
              right: 15,
              marginRight: 10,
            }}
            height={220}
            fromZero
            yAxisLabel=""
            yAxisSuffix=""
            showValuesOnTopOfBars
          />
        </ScrollView>
      </VStack>
    );
  };

  return (
    <ScrollView
      flex={1}
      p={4}
      bg="secondary.500"
    >
      <VStack
        flex={1}
        space="lg"
        w="full"
      >
        <ProjectContributionBanner />
        <Text
          fontSize="lg"
          color="darkText"
        >
          {t('monthlyPerformanceScreen.performanceInsight')}
        </Text>
        {
          props.timesheetDetail &&
          <VStack space="lg">
            <ProjectProgressBarComponent
              timesheetDetail={props.timesheetDetail}
            />
            <VStack space="sm">
              <Text
                fontSize="sm"
                color="darkText"
              >
                {t('monthlyPerformanceScreen.performanceDetails')}:
              </Text>
              <DropdownComponent
                data={props.projects}
                selectedDropdown={props.selectedDropdown}
                placeholder={t('monthlyPerformanceScreen.chooseProject')}
                handleDropdownOnChange={props.handleDropdownOnChange}
              />
            </VStack>
          </VStack>
        }
        {
          props.selectedProject &&
          <ProjectDetailInformation
            projectDetail={props.selectedProject}
          />
        }
      </VStack>
    </ScrollView>
  );
};
