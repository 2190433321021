import {ModalProps, Project, State, Timelog, TimeLogAction, TimesheetDetail, TimeSheetTemplate} from "../state";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {captureErrorSentry, constructComplexFilters, constructFields} from "./httpUtils";
import axios from "axios";
import {
  extractActivity,
  extractProject,
  extractTimelog,
  extractTimelogAfterAddingTimelog,
  extractTimesheetDetails, formatTimeLogsSubmission
} from "./frappeResponse";
import {updateErrorModalStatus, updateSuccessModalStatus, updateTimesheetFormModalOpen} from "./actions";
import {handleHttpError, handleNoBaseUrlError} from "./actionsAuth";
import {formatDateYYYYMMDD} from "../lib/dateFormat";
import i18n from "i18next";
import {Moment} from "moment";

export const UPDATE_PROJECTS_NAME_LIST = "UPDATE_PROJECT_NAME_LIST";
export const UPDATE_ACTIVITIES_NAME_LIST = "UPDATE_ACTIVITIES_NAME_LIST";
export const UPDATE_TODAY_TIMELOG_LIST = "UPDATE_TODAY_TIMELOG_LIST";
export const UPDATE_TIMELOG_LIST = "UPDATE_TIMELOG_LIST";
export const UPDATE_TIMESHEET_DETAIL = "UPDATE_TIMESHEET_DETAIL";
export const UPDATE_TIMESHEET_TEMPLATES = "UPDATE_TIMESHEET_TEMPLATES";
export const UPDATE_FETCHING_TIMELOGS_ATTRIBUTE = "UPDATE_FETCHING_TIMELOGS_ATTRIBUTE";
export const UPDATE_ADDING_TIMELOG_ATTRIBUTE = "UPDATE_ADDING_TIMELOG_ATTRIBUTE";
export const UPDATE_FETCHING_TIMESHEET_DETAIL_ATTRIBUTE = "UPDATE_FETCHING_TIMESHEET_DETAIL_ATTRIBUTE";


export const updateProjectsNameList = (projects: Project[]) => {
  return {type: UPDATE_PROJECTS_NAME_LIST, payload: projects};
};

export const updateActivitiesNameList = (projectsActivitiesList: string[]) => {
  return {type: UPDATE_ACTIVITIES_NAME_LIST, payload: projectsActivitiesList};
};

export const updateTodayTimelogList = (timelogList?: Timelog[]) => {
  return {type: UPDATE_TODAY_TIMELOG_LIST, payload: timelogList};
};

export const updateTimelogList = (timelogList?: Timelog[]) => {
  return {type: UPDATE_TIMELOG_LIST, payload: timelogList};
};

export const updateTimesheetDetail = (timesheetDetail: TimesheetDetail) => {
  return {type: UPDATE_TIMESHEET_DETAIL, payload: timesheetDetail};
};

export const updateTimesheetTemplates = (timesheetTemplates: TimeSheetTemplate[]) => {
  return {type: UPDATE_TIMESHEET_TEMPLATES, payload: timesheetTemplates};
}

export const updateFetchingTimelogsAttribute = (status: boolean) => {
  return {
    type: UPDATE_FETCHING_TIMELOGS_ATTRIBUTE,
    payload: {
      isFetching: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const updateAddingTimelogAttribute = (status: boolean) => {
  return {
    type: UPDATE_ADDING_TIMELOG_ATTRIBUTE,
    payload: {
      isStoring: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const updateFetchingTimesheetDetailAttribute = (status: boolean) => {
  return {
    type: UPDATE_FETCHING_TIMESHEET_DETAIL_ATTRIBUTE,
    payload: {
      isFetching: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const fetchProjects = () => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchProjectsErrorTitle')
    };

    const fields = constructFields([
      'name',
      'project_name',
    ]);

    const filters = constructComplexFilters([
      {
        key: "status",
        operator: '=',
        value: "Open"
      },
      {
        key: "is_active",
        operator: '=',
        value: "Yes"
      }
    ])

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/resource/Project?"
      .concat(fields)
      .concat("&").concat(filters)
      .concat("&").concat("order_by=name")
      .concat("&").concat("limit_page_length=50");

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateProjectsNameList(extractProject(response.data.data)));
        } else {
          captureErrorSentry("Error when fetching projects", response);
          handleHttpError(response.request, errorModalStatus, dispatch);
        }
      })
      .catch(error => {
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const fetchActivityTypes = () => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchActivityTypesErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/resource/Activity%20Type?"
      .concat("&").concat("order_by=name")
      .concat("&").concat("limit_page_length=50");

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateActivitiesNameList(extractActivity(response.data.data)));
        } else {
          captureErrorSentry("Error when fetching activity types", response);
          handleHttpError(response.request, errorModalStatus, dispatch);
        }
      })
  };
};

export const fetchTimelogs = (employeeName: string, receivedDate: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    const isToday = receivedDate === formatDateYYYYMMDD(new Date());
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchTimelogsErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const timelogFilters = ("data={\"employee\":\"").concat(employeeName).concat("\", \"date\":\"").concat(receivedDate).concat("\"}");
    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_time_logs?".concat(timelogFilters);

    dispatch(updateFetchingTimelogsAttribute(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          if (isToday) {
            dispatch(updateTodayTimelogList(extractTimelog(response.data.message)));
          }
          dispatch(updateTimelogList(extractTimelog(response.data.message)));
          dispatch(updateFetchingTimelogsAttribute(false));
        } else {
          captureErrorSentry("Error when fetching time logs", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingTimelogsAttribute);
        }
      })
      .catch(error => {
        dispatch(updateFetchingTimelogsAttribute(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const addNewTimelog = (
  employee: string,
  date: string,
  project: string,
  activity: string,
  duration: number,
  isFromTemplate: boolean,
  successfulAction?: () => void
) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State): Promise<any> => {
    const isToday = date === formatDateYYYYMMDD(new Date());
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.addNewTimelogErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.add_time_logs";
    const createTimesheetData = new URLSearchParams();
    createTimesheetData.append("data", encodeURIComponent(JSON.stringify({
      employee_name: employee,
      date: date,
      project: project,
      activity: activity,
      duration: duration
    })));

    dispatch(updateAddingTimelogAttribute(true));

    return axios({
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      url: url,
      data: createTimesheetData,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateAddingTimelogAttribute(false));
          if (!isFromTemplate) {
            dispatch(updateSuccessModalStatus({
              isOpen: true,
              title: i18n.t('modal.successModal.title'),
              content: i18n.t('modal.successModal.submitTimeLogSuccess')
            }));
          }
          dispatch(updateTimesheetFormModalOpen(false));
          const timelogsResponse = response.data.message.time_logs;
          if (timelogsResponse && timelogsResponse.length > 0) {
            if (isToday) {
              dispatch(updateTodayTimelogList(extractTimelogAfterAddingTimelog(timelogsResponse)));
            }
            dispatch(updateTimelogList(extractTimelogAfterAddingTimelog(timelogsResponse)));
          }
          if (successfulAction) {
            successfulAction();
          }
          return Promise.resolve();
        } else {
          captureErrorSentry("Error when submitting time log", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateAddingTimelogAttribute);
          return Promise.reject();
        }
      })
      .catch(error => {
        dispatch(updateAddingTimelogAttribute(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const editTimeLog = (
  timeLogs: Timelog[],
  timeLogAction: TimeLogAction,
  successfulAction?: () => void,
) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.editTimelogErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.update_timesheet";
    const createTimesheetData = new URLSearchParams();
    createTimesheetData.append("data", encodeURIComponent(JSON.stringify({
      timesheet_name: timeLogs[0].timesheetName,
      time_logs: formatTimeLogsSubmission(timeLogs),
    })));

    dispatch(updateAddingTimelogAttribute(true));

    axios({
      method: 'put',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      url: url,
      data: createTimesheetData,
      validateStatus: () => true
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateTimelogList(timeLogs));
          dispatch(updateAddingTimelogAttribute(false));
          if (timeLogAction === "Delete") {
            dispatch(updateSuccessModalStatus({
              isOpen: true,
              title: i18n.t('modal.successModal.title'),
              content: i18n.t('modal.successModal.deleteTimeLogSuccess')
            }));
          } else {
            dispatch(updateSuccessModalStatus({
              isOpen: true,
              title: i18n.t('modal.successModal.title'),
              content: i18n.t('modal.successModal.submitTimeLogSuccess')
            }));
          }
          dispatch(updateTodayTimelogList(timeLogs));
          dispatch(updateTimelogList(timeLogs));
          dispatch(updateTimesheetFormModalOpen(false));
          if (successfulAction) {
            successfulAction();
          }
        } else {
          captureErrorSentry("Error when updating time log", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateAddingTimelogAttribute);
        }
      });
  };
};

export const fetchTimesheetDetail = (employeeName: string, currentDate: Moment) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchTimesheetDetails')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const data = `data={"employee": "${employeeName}", "month": ${currentDate.format("M")}, "year":${currentDate.get('year')}}`;
    const url = `${baseUrl}/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_timesheet_info?${data}`;

    dispatch(updateFetchingTimesheetDetailAttribute(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateTimesheetDetail(extractTimesheetDetails(response.data.message)));
          dispatch(updateFetchingTimesheetDetailAttribute(false));
        } else {
          captureErrorSentry("Error when fetching timesheet detail", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingTimesheetDetailAttribute);
        }
      })
      .catch(error => {
        dispatch(updateFetchingTimesheetDetailAttribute(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};
