import React from "react";
import {Center, HStack, Pressable, Text, useTheme} from "native-base";
import {SceneMap, TabView} from 'react-native-tab-view';
import LeaderboardScreenContainer from "../Containers/LeaderboardScreenContainer";
import AchievementScreenContainer from "../Containers/AchievementScreenContainer";
import {NavigationState, SceneRendererProps} from "react-native-tab-view/lib/typescript/types";
import {useSelector} from "react-redux";
import {State} from "../state";

interface ActivityScreenProps {
  currentActiveActivityIndex: number;
  handlePageChange: (index: number) => void;
  shouldLoadComponent: (index: number) => boolean;
}

const Leaderboard = () => <LeaderboardScreenContainer/>;

const Achievements = () => <AchievementScreenContainer/>;

const renderScene = SceneMap({
  leaderboard: Leaderboard,
  achievements: Achievements
});

const ActivityScreen: React.FC<ActivityScreenProps> = (props: ActivityScreenProps) => {
  const gamificationFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.gamification);
  const achievementFeatureStatus = gamificationFeatureStatus ? gamificationFeatureStatus.achievementEnabled : null;

  const [routes] = achievementFeatureStatus ? React.useState([
    {key: 'leaderboard', title: 'LEADERBOARD'},
    {key: 'achievements', title: 'ACHIEVEMENTS'}
  ]) : React.useState([
    {key: 'leaderboard', title: 'LEADERBOARD'},
  ]);
  const theme = useTheme();
  const handlePageChange = props.handlePageChange;

  const currentActiveActivityIndex = props.currentActiveActivityIndex;

  const renderTabBar = (props: SceneRendererProps & {
    navigationState: NavigationState<any>;
  }) => {
    return (
      <HStack>
        {props.navigationState.routes.map((route, i) => {
          return (
            <Center key={i} flex={1} py={2} bg={"secondary.500"} opacity={1}
                    borderBottomWidth={currentActiveActivityIndex === i ? 3 : 3}
                    borderColor={currentActiveActivityIndex === i ? "primary.500" : "#BFB8AC"}
            >
              <Pressable onPress={() => handlePageChange(i)}>
                <Text fontSize={"md"} 
                      color={currentActiveActivityIndex === i ? "primary.500" : "#BFB8AC"}
                      fontWeight={600}
                >
                  {route.title}
                </Text>
              </Pressable>
            </Center>
          );
        })}
      </HStack>
    );
  };

  return (
    <TabView style={{backgroundColor: theme.colors.secondary[500]}}
             renderScene={renderScene}
             renderTabBar={renderTabBar}
             navigationState={{index: currentActiveActivityIndex, routes: routes}}
             onIndexChange={props.handlePageChange}
    />
  );
};

export default ActivityScreen;
