import {SceneMap, TabView} from "react-native-tab-view";
import React, {useState} from "react";
import {DEVICE_WIDTH} from "../master-data/constants";
import LeaveApplicationScreenContainer from "../Containers/LeaveApplicationScreenContainer";
import LeaveBalanceScreenContainer from "../Containers/LeaveBalanceScreenContainer";
import {TabBarComponent} from "../Components/TabBarComponent";

export const LeaveApplicationTopTabNavigator = () => {
  const initialLayout = { width: DEVICE_WIDTH };

  const renderScene = SceneMap({
    first: LeaveApplicationScreenContainer,
    second: LeaveBalanceScreenContainer,
  });

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'first', title: 'Leave List' },
    { key: 'second', title: 'Leave Balance' },
  ]);

  return (
    <TabView
      onIndexChange={setIndex}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      renderTabBar={props => <TabBarComponent {...props} /> }
      initialLayout={initialLayout}
    />
  );
};
