import {CompanyRulesDetailScreen} from "../screens/CompanyRulesDetailScreen";
import React, {useEffect, useState} from "react";
import {useRoute} from "@react-navigation/native";

export const CompanyRulesDetailScreenContainer = () => {
  const route = useRoute();
  const params: any = route.params;

  const [rulesDetail, setRulesDetail] = useState("");
  const [sectionName, setSectionName] = useState("");

  useEffect(() => {
    if (params) {
      setRulesDetail(params.rulesDetail);
      setSectionName(params.sectionName);
    }
    return () => {
      setRulesDetail('');
      setSectionName('');
    }
  }, [params]);

  return (
    <CompanyRulesDetailScreen
      rulesDetail={rulesDetail}
      sectionName={sectionName}
    />
  );
};
