import React from "react";
import {Box, Button, Center, HStack, Icon, Pressable, ScrollView, Text, VStack} from "native-base";
import {Feather, FontAwesome} from "@expo/vector-icons";
import * as Application from "expo-application";
import {useTranslation} from "react-i18next";

interface SettingsScreenProps {
  language: string;
  isLoggingOut: boolean;
  handleSettingOnPress: (screenName: string) => void;
  handleSettingOnLongPress: () => void;
  handleLogoutOnPress: () => void;
}

interface SettingsComponent {
  label?: string;
  iconName?: string;
  additionalInformation?: JSX.Element;
  handleSettingOnPress?: () => void | undefined;
  handleSettingOnLongPress?: () => void | undefined;
}

const HeaderLabelComponent = (props: SettingsComponent) => (
  <Box>
    <Text
      fontSize="md"
      color="darkText"
    >
      {props.label}
    </Text>
  </Box>
);

const SettingsComponent = (props: SettingsComponent) => (
  <Pressable
    py={2}
    px={4}
    borderRadius={10}
    borderWidth={1}
    borderColor="coolGray.500"
    onPress={props.handleSettingOnPress}
    onLongPress={props.handleSettingOnLongPress}
    _pressed={{
      opacity: 0.5,
    }}
  >
    <HStack
      space="sm"
      alignItems="center"
    >
      <Icon
        as={FontAwesome}
        name={props.iconName}
        size="sm"
      />
      <HStack
        flex={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          fontSize="md"
          color="darkText"
        >
          {props.label}
        </Text>
        {props.additionalInformation}
      </HStack>
    </HStack>
  </Pressable>
);

const SelectedLanguage = ({ ...props }) => (
  <HStack
    space="xs"
    alignItems="center"
  >
    <Text
      fontSize="sm"
      color="coolGray.400"
    >
      {props.language}
    </Text>
    <Icon
      as={Feather}
      size={5}
      name="chevron-right"
      color="coolGray.400"
    />
  </HStack>
);

const AppVersion = () => (
  <Text
    fontSize="sm"
    color="coolGray.400"
    textAlign="right"
  >
    {
      Application.nativeApplicationVersion ?
        Application.nativeBuildVersion ?
          "version ".concat(Application.nativeApplicationVersion).concat(" build ").concat(Application.nativeBuildVersion)
          :
          "version ".concat(Application.nativeApplicationVersion)
        : ""
    }
  </Text>
);

const LogoutButtonComponent = ({ ...props }) => {
  const {t}: { t: any } = useTranslation();
  return (
    <Button
      colorScheme="danger"
      onPress={props.handleLogoutOnPress}
      isLoading={props.isLoggingOut}
      size="md"
      w="full"
    >
      {String(t('button.logoutButton'))}
    </Button>
  );
};

export const SettingsScreen = (props: SettingsScreenProps) => {
  const {t}: { t: any } = useTranslation();
  return (
    <Center
      flex={1}
      px={4}
      h="full"
      bg="secondary.500"
    >
      <ScrollView
        w="full"
        h="full"
        contentContainerStyle={{
          paddingVertical: 10,
        }}
      >
        <VStack
          space="2xl"
        >
          <VStack space="md">
            <HeaderLabelComponent
              label={t('settingsScreen.accountSettings')}
            />
            <SettingsComponent
              label={t('settingsScreen.language')}
              iconName="globe"
              additionalInformation={<SelectedLanguage language={props.language} />}
              handleSettingOnPress={() => props.handleSettingOnPress("LanguageSelectionProfile")}
            />
          </VStack>
          <VStack space="md">
            <HeaderLabelComponent
              label={t('settingsScreen.aboutApp')}
            />
            <SettingsComponent
              label={t('settingsScreen.version')}
              iconName="rocket"
              additionalInformation={<AppVersion />}
              handleSettingOnLongPress={props.handleSettingOnLongPress}
            />
          </VStack>
          <LogoutButtonComponent
            isLoggingOut={props.isLoggingOut}
            handleLogoutOnPress={props.handleLogoutOnPress}
          />
        </VStack>
      </ScrollView>
    </Center>
  );
};
