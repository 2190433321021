import {ImageBackground, RefreshControl} from "react-native";
import React from "react";
import {
  Avatar,
  Center,
  Box,
  Heading,
  HStack,
  Pressable,
  ScrollView,
  Text,
  useToast,
  VStack,
  Divider,
  Image,
  Icon,
  Circle
} from "native-base";
import {Camera} from "expo-camera";
import {useNavigation} from "@react-navigation/native";
import {MaterialCommunityIcons} from '@expo/vector-icons';

import {TimesheetDetail, User} from "../state";
import {useTranslation} from "react-i18next";
import {ProjectProgressBarComponent} from "../Components/ProjectProgressBarComponent";

interface ProfileScreenProps {
  currentUser?: User;
  timesheetDetail?: TimesheetDetail;
  hasTimesheetFeature: boolean;
  isRefreshing: boolean;
  totalWorkHour: number;
  totalEarlyEntry: number;
  totalLateLeave: number;
  totalFullDay: number;
  handleScreenRefresh: () => void;
  handleChangeScreen: (screenName: string) => void;
}

interface PerformanceSectionProps {
  title: string;
  component: JSX.Element;
}

export const ProfileScreen = (props: ProfileScreenProps) => {
  const navigation: any = useNavigation();
  const toast = useToast();
  const {t}: { t: any } = useTranslation();

  const isRefreshing = props.isRefreshing;
  const employeeFullName = props.currentUser ? props.currentUser.employeeFullName : "";
  const employeeDesignation = props.currentUser ? props.currentUser.designation : "";
  const profileImageUrl = props.currentUser ? props.currentUser.profileImageUrl : "";
  const designationIconUrl = props.currentUser ? props.currentUser.designationIconUrl : "";

  const ornamentImage = require("../../assets/ornament.png");

  const renderSingleStatisticItem = (statisticValue: number | string, label: string) => {
    return (
      <VStack flex={1}>
        <Box borderWidth={"1"} borderColor={"primary.500"} borderRadius={"sm"}>
          <Heading color={"primary.500"} fontSize={"xl"} alignSelf={"center"} p={"3"}>{statisticValue}</Heading>
          <Center bgColor={"primary.500"}>
            <Text fontSize={"xs"} textAlign={"center"} color={"secondary.500"} fontWeight={500} lineHeight={"xs"}
                  py={"2"}>
              {label}
            </Text>
          </Center>
        </Box>
      </VStack>
    )
  }

  const handleLaunchCamera = () => {
    Camera.requestCameraPermissionsAsync().then((status) => {
      if (status.granted) {
        navigation.navigate('ProfileCamera', {
          isAllowUploadFromGallery: true,
          uploadType: "Profile"
        });

        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    })
      .catch(() => {
        toast.show({
          title: String(t('modal.errorModal.cameraPermissionError'))
        })
      });
  };

  const PerformanceSection = (performanceSection: PerformanceSectionProps) => {
    return (
      <VStack
        bg="secondary.500"
        space="md"
        px={4}
      >
        <HStack
          justifyContent="space-between"
          alignItems="center"
          mt={4}
        >
          <Text
            fontSize="lg"
            color="black"
          >
            {performanceSection.title}
          </Text>
          <Pressable
            onPress={() => props.handleChangeScreen('MonthlyPerformanceScreen')}
          >
            <Text
              fontSize="md"
              color="primary.500"
            >
              Detail
            </Text>
          </Pressable>
        </HStack>
        <Box>
          {performanceSection.component}
        </Box>
      </VStack>
    );
  };

  return (
    <ScrollView
      contentContainerStyle={{
        paddingVertical: 10,
      }}
      refreshControl={
        <RefreshControl
          refreshing={isRefreshing}
          onRefresh={props.handleScreenRefresh}
        />
      }
      bg="secondary.500"
    >
      <VStack
        px={4}
        pt={5}
        bg="secondary.500"
        h="full"
        flex={1}
      >
        <Box
          backgroundColor="primary.500"
          borderRadius="md"
          overflow="hidden"
        >
          <ImageBackground source={ornamentImage} style={{width: "100%"}}>
            <HStack p={"2"}>
              <Pressable onPress={handleLaunchCamera}>
                {
                  props.currentUser ?
                    <Avatar
                      key={profileImageUrl}
                      source={profileImageUrl !== "" ? {uri: profileImageUrl} : require('../../assets/logo-gram-03.png')}
                      bg={"primary.500"}
                      size="lg"
                    >
                      <Avatar.Badge borderColor={"transparent"} bgColor={"transparent"} right={3} bottom={3}>
                        <Circle size={5} bg={"orange.300"} alignContent="center">
                          <Icon as={MaterialCommunityIcons} name='pencil' color={"primary.500"} size={"xs"}/>
                        </Circle>
                      </Avatar.Badge>

                    </Avatar>
                    :
                    null
                }
              </Pressable>
              <VStack ml={6} justifyContent={"center"}>
                <Heading color={"secondary.500"} fontSize={"xl"}>{employeeFullName}</Heading>
                <HStack  mt={1}>
                  <Image source={{uri: designationIconUrl}}
                         size='2xs'
                         alt='designation-logo'
                  />
                  <Text color={"secondary.500"}
                        fontFamily={"body"}
                        fontSize={"sm"}
                        fontWeight={500}
                        noOfLines={1}
                        isTruncated={true}
                        maxW={{base: "90%"}}
                        ml={2}
                  >
                    {employeeDesignation}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
          </ImageBackground>
        </Box>
        <Text fontSize={"lg"} mt={4} fontWeight={500} color={"black"}>
          {t('profileScreen.totalPerformanceAttribute')}
        </Text>
        <HStack
          flex={1}
          space={3}
          mt={3}
          alignSelf="center"
        >
          {renderSingleStatisticItem(props.totalWorkHour.toFixed(0), String(t('profileScreen.workHours')))}
          {renderSingleStatisticItem(props.totalFullDay, String(t('profileScreen.workDays')))}
          {renderSingleStatisticItem(props.totalEarlyEntry, String(t('profileScreen.earlyEntries')))}
          {renderSingleStatisticItem(props.totalLateLeave, String(t('profileScreen.lateLeaves')))}
        </HStack>
      </VStack>
      <HStack bg={"secondary.500"}>
        <Divider mt={"5"} borderWidth={"2"} borderColor={"secondary.1000"}/>
      </HStack>
      {
        (props.timesheetDetail && props.hasTimesheetFeature) &&
        <PerformanceSection
          title={t('profileScreen.monthlyPerformance')}
          component={<ProjectProgressBarComponent
            timesheetDetail={props.timesheetDetail}
          />}
        />
      }
    </ScrollView>
  );
};
