import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import axios from "axios";
import moment from 'moment';

import {ModalProps, State} from "../state";
import {handleHttpError, handleNoBaseUrlError} from "./actionsAuth";
import { updateErrorModalStatus } from "./actions";
import i18n from "i18next";
import {captureErrorSentry} from "./httpUtils";

export const UPDATE_UPLOADING_IMAGE_STATUS = "UPDATE_UPLOADING_IMAGE_STATUS";

export const updateUploadingUserProfileImageStatus = (isUploading: boolean) => {
  return {type: UPDATE_UPLOADING_IMAGE_STATUS, payload: isUploading};
};

export const uploadUserProfileImage = (username: string, base64: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State): Promise<any> => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.ImageUploadErrorTitle'),
      content: "",
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }
    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.upload_and_edit_profile_picture";

    const fileName = username.concat("-").concat("profpic").concat(moment().toISOString()).concat(".jpg");

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("is_private", encodeURIComponent("0"));
    urlSearchParams.append("doctype", encodeURIComponent("User"));
    urlSearchParams.append("docname", encodeURIComponent(username));
    urlSearchParams.append("filedata", encodeURIComponent(base64));
    urlSearchParams.append("from_form", encodeURIComponent("0"));
    urlSearchParams.append("filename", encodeURIComponent(fileName));

    dispatch(updateUploadingUserProfileImageStatus(true));

    return axios({
      method: "post",
      url: encodeURI(url),
      headers: {
        Accept: "application/json",
      },
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
      data: urlSearchParams,
      validateStatus: () => true,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(updateUploadingUserProfileImageStatus(false));
        return Promise.resolve();
      } else {
        captureErrorSentry("Error when uploading profile picture", response);
        handleHttpError(response.request, errorModalStatus, dispatch, updateUploadingUserProfileImageStatus);
      }
    })
    .catch ((error: any) => {
      dispatch(updateUploadingUserProfileImageStatus(false))
      errorModalStatus.content = error.message;
      dispatch(updateErrorModalStatus(errorModalStatus));
    });
  }
}
