import React from "react";
import {State} from "../state";
import {connect, useDispatch} from "react-redux";
import {updateSuccessModalStatus} from "../actions/actions";
import {Button, Modal} from "native-base";
import { useTranslation } from "react-i18next";

interface SuccessModalProps {
  isOpen: boolean;
  title?: string;
  content?: string;
}

const mapStateToProps = (state: State) => {
  return {
    isOpen: state.control.successModal.isOpen,
    title: state.control.successModal.title,
    content: state.control.successModal.content
  };
};

const SuccessModalContainer: React.FC<SuccessModalProps> = (props: SuccessModalProps) => {
  const dispatch = useDispatch();
  const {t}: {t: any} = useTranslation();

  const handleCloseSuccessModal = () => {
    dispatch(updateSuccessModalStatus({
      title: "",
      content: "",
      isOpen: false
    }));
  };

  return (
    <Modal isOpen={props.isOpen}
           overlayVisible={true}
           size={"lg"}
           closeOnOverlayClick={true}
    >
      <Modal.Content bgColor={"secondary.500"}>
        <Modal.Header>{props.title}</Modal.Header>
        <Modal.Body>{props.content}</Modal.Body>
        <Modal.Footer bgColor={"secondary.500"}>
          <Button colorScheme={"danger"}
                  size={"md"}
                  onPress={handleCloseSuccessModal}
          >
            {String(t('button.closeButton'))}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default connect(mapStateToProps)(SuccessModalContainer);
