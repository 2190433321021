export const defaultTheme = {
  primary: {
    100: "#DDF8F0",
    200: "#BDF2E6",
    300: "#91D9CF",
    400: "#6AB4B0",
    500: "#3B8183",
    600: "#2B6870",
    700: "#1D515E",
    800: "#123B4B",
    900: "#0B2B3E",
    1000: "#EE7D29"
  },
  primary_transparent: {
    100: "rgba(238, 125, 41, 0.16)"
  },
  secondary_transparent: {
    100: "rgba(59, 129, 131, 0.2)"
  },
  secondary: {
    100: "#FEFEFA",
    200: "#FEFCF6",
    300: "#FDFAF1",
    400: "#FCF8ED",
    500: "#FBF5E7",
    600: "#D7C8A8",
    700: "#B49D74",
    800: "#917549",
    900: "#78582C",
    1000: "#E8DFCC"
  },
  success: {
    100: "#FEF5DE",
    200: "#FEE9BE",
    300: "#FEDA9D",
    400: "#FDCB85",
    500: "#FCB25D",
    600: "#D88C43",
    700: "#B56A2E",
    800: "#924B1D",
    900: "#783511",
    1000: "#FEEABF"
  },
  warning: {
    100: "#FDE5E6",
    200: "#FCCBD3",
    300: "#F8B0C3",
    400: "#F199B9",
    500: "#E878AC",
    600: "#C75796",
    700: "#A73C83",
    800: "#86266F",
    900: "#6F1763"
  },
  danger: {
    100: "#FDE9DD",
    200: "#FCCFBD",
    300: "#F6AD9A",
    400: "#ED8C7E",
    500: "#E25B55",
    600: "#C23E43",
    700: "#A22A39",
    800: "#831B30",
    900: "#6C102B"
  },
  info: {
    100: "#D1EFFB",
    200: "#A5DBF7",
    300: "#75BBE8",
    400: "#4F98D2",
    500: "#1F6BB5",
    600: "#16539B",
    700: "#0F3E82",
    800: "#092B68",
    900: "#051E56"
  },
  basic:{
    100: "#FFFFFF",
    500: "#CBCACA"
  }
}