import React from "react";
import {Center, HStack, Icon, Pressable, ScrollView, Text, VStack} from "native-base";
import {Feather} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";

interface CompanyRulesProps {
  handleSectionOnPress: (sectionName: string) => void;
}

interface SectionComponentProps {
  label: string;
  handleSectionOnPress: () => void;
}

const SectionComponent = (props: SectionComponentProps) => {
  return (
    <Pressable
      borderRadius={10}
      borderWidth={1}
      borderColor="coolGray.500"
      py={3}
      px={4}
      _pressed={{
        bg: 'coolGray.300',
      }}
      onPress={props.handleSectionOnPress}
    >
      <HStack
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          fontSize="md"
          color="darkText"
        >
          {props.label}
        </Text>
        <Icon
          as={Feather}
          name="chevron-right"
          size="sm"
          color="coolGray.500"
        />
      </HStack>
    </Pressable>
  )
}

export const CompanyRulesScreen = (props: CompanyRulesProps) => {
  const {t}: { t: any } = useTranslation();
  return (
    <Center
      flex={1}
      bg="secondary.500"
    >
      <ScrollView
        w="full"
        h="full"
        contentContainerStyle={{
          paddingVertical: 20,
        }}
      >
        <VStack
          px={4}
          space="md"
        >
          <SectionComponent
            label={t('companyScreen.visionAndMission')}
            handleSectionOnPress={() => props.handleSectionOnPress('Vision And Mission')}
          />
          <SectionComponent
            label={t('companyScreen.codeOfConduct')}
            handleSectionOnPress={() => props.handleSectionOnPress('Code Of Conduct')}
          />
        </VStack>
      </ScrollView>
    </Center>
  );
};
