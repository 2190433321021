import {MonthlyPerformanceScreen} from "../screens/MonthlyPerformanceScreen";
import {connect} from "react-redux";
import {ProjectDetail, State, TimesheetDetail} from "../state";
import React, {useEffect, useState} from "react";

interface MonthlyPerformanceScreenContainerProps {
  timesheetDetail?: TimesheetDetail
}

const mapStateToProps = (state: State) => {
  return {
    timesheetDetail: state.control.timesheetDetail,
  };
};

const MonthlyPerformanceScreenContainer = (props: MonthlyPerformanceScreenContainerProps) => {

  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [selectedProject, setSelectedProject] =
    useState<ProjectDetail | undefined>();
  const [projects, setProjects] = useState<string[]>([]);

  useEffect(() => {
    if (props.timesheetDetail) {
      let newProjects: string[] = [];
      Object.keys(props.timesheetDetail.projects).forEach((key) => {
        newProjects.push(key);
      });
      setProjects(newProjects);
    }
  }, [props.timesheetDetail]);

  const handleDropdownOnChange = (value: string) => {
    if (props.timesheetDetail) {
      const newSelectedProject = props.timesheetDetail.projects[value];
      setSelectedDropdown(value);
      setSelectedProject(newSelectedProject);
    }
  };

  return (
    <MonthlyPerformanceScreen
      timesheetDetail={props.timesheetDetail}
      selectedDropdown={selectedDropdown}
      selectedProject={selectedProject}
      projects={projects}
      handleDropdownOnChange={handleDropdownOnChange}
    />
  );
};

export default connect(mapStateToProps)(MonthlyPerformanceScreenContainer);
