import {CommunicationState, CompanyData, ControlState, DataState, SessionState, State} from "./state";
import {combineReducers} from "redux";
import {initialCommunicationState, initialControlState, initialDataState, initialSessionState} from "./initialStates";
import {
  OPEN_DAILY_REWARD_MODAL,
  UPDATE_CLOSING_TIME,
  UPDATE_DAILY_REWARD_MODAL,
  UPDATE_ERROR_MODAL, UPDATE_EXPO_PUSH_TOKEN, UPDATE_IS_INITIAL_ON_BOARDING,
  UPDATE_LANGUAGE,
  UPDATE_LEAVE_FORM_MODAL_OPEN,
  UPDATE_OPENING_TIME,
  UPDATE_SUCCESS_MODAL,
  UPDATE_TIMESHEET_FORM_MODAL_OPEN,
  UPDATE_TODAY_DATE
} from "./actions/actions";
import {UPDATE_UPLOADING_IMAGE_STATUS} from "./actions/actionProfile";
import {
  ADD_API_KEY,
  ADD_NEW_COMPANY_DATA,
  DELETE_COMPANY_DATA,
  REMOVE_API_KEY,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USERNAME,
  UPDATE_GETTING_API_KEY_FROM_SECURE_STORE_STATUS,
  UPDATE_GETTING_LOGGED_USER_STATUS,
  UPDATE_GETTING_USER_INFO_STATUS,
  UPDATE_LOGGING_IN_STATUS,
  UPDATE_LOGGING_OUT_STATUS,
  UPDATE_SEARCHING_COMPANY_SERVER_STATUS,
  UPDATE_SELECTED_COMPANY, UPDATE_SUBMITTING_EXPO_PUSH_TOKEN
} from "./actions/actionsAuth";
import {
  ADD_NEW_ATTENDANCE,
  UPDATE_ATTENDANCE_BREAK_LIST,
  UPDATE_ATTENDANCE_BREAKIN_STATUS,
  UPDATE_ATTENDANCE_CHECKEDIN_STATUS,
  UPDATE_ATTENDANCE_CHECKEDOUT_STATUS,
  UPDATE_ATTENDANCE_LIST,
  UPDATE_CURRENT_ATTENDANCE,
  UPDATE_CURRENT_ATTENDANCE_BREAK,
  UPDATE_FETCHING_ATTENDANCE_BREAK_ATTRIBUTE,
  UPDATE_FETCHING_ATTENDANCES_ATTRIBUTE,
  UPDATE_IS_BREAKING_IN_STATUS,
  UPDATE_IS_BREAKING_OUT_STATUS,
  UPDATE_IS_CHECKING_IN_STATUS,
  UPDATE_IS_CHECKING_OUT_STATUS,
  UPDATE_IS_SAVING_OTHER_DATE_STATUS
} from "./actions/actionsAttendance";
import {
  UPDATE_ACTIVITIES_NAME_LIST,
  UPDATE_ADDING_TIMELOG_ATTRIBUTE,
  UPDATE_FETCHING_TIMELOGS_ATTRIBUTE, UPDATE_FETCHING_TIMESHEET_DETAIL_ATTRIBUTE,
  UPDATE_PROJECTS_NAME_LIST,
  UPDATE_TIMELOG_LIST, UPDATE_TIMESHEET_DETAIL, UPDATE_TIMESHEET_TEMPLATES,
  UPDATE_TODAY_TIMELOG_LIST
} from "./actions/actionsTimesheet";
import {
  UPDATE_CURRENT_ACHIEVEMENT_LIST,
  UPDATE_CURRENT_PERFORMANCE_ATTRIBUTE,
  UPDATE_FETCHING_ACHIEVEMENT_LIST_ATTRIBUTE,
  UPDATE_FETCHING_PERFORMANCE_ATTRIBUTE_STATUS
} from "./actions/actionGamification";
import {
  ADD_LEAVE_APP_IMAGE_URL,
  UPDATE_CREATING_LEAVE_APPLICATION_ATTRIBUTE,
  UPDATE_FETCHING_LEAVE_APPLICATIONS_ATTRIBUTE,
  UPDATE_FETCHING_LEAVE_COUNT_STATUS,
  UPDATE_LEAVE_APPLICATION_LIST, UPDATE_LEAVE_BALANCE,
  UPDATE_LEAVE_COUNT,
  UPDATE_LEAVE_IMAGE_DATA,
  UPDATE_UPLOADING_LEAVE_IMAGE_STATUS
} from "./actions/actionLeaveApplication";
import {UPDATE_FETCHING_MONTHLY_INFOS_ATTRIBUTE, UPDATE_MONTHLY_INFO_LIST} from "./actions/actionsMonthlyInfo";
import {UPDATE_CURRENT_SERVER_TIME, UPDATE_GETTING_SERVER_TIME_ATTRIBUTE} from "./actions/actionsDateTime";
import {UPDATE_COMPANY_INFORMATION, UPDATE_FETCHING_COMPANY_INFORMATION_STATUS} from "./actions/actionsCompany";

export const dataReducer = (state: DataState = initialDataState, action: any) => {
  switch (action.type) {
    case UPDATE_ATTENDANCE_LIST: {
      return Object.assign({}, state, {
        attendances: action.payload
      });
    }
    case UPDATE_ATTENDANCE_BREAK_LIST: {
      return Object.assign({}, state, {
        attendanceBreaks: action.payload
      });
    }
    case ADD_NEW_ATTENDANCE: {
      return Object.assign({}, state, {
        attendances: [...state.attendances, action.payload]
      });
    }
    case UPDATE_TODAY_TIMELOG_LIST: {
      return Object.assign({}, state, {
        todayTimelogs: action.payload
      });
    }
    case UPDATE_TIMELOG_LIST: {
      return Object.assign({}, state, {
        timelogs: action.payload
      });
    }
    case UPDATE_PROJECTS_NAME_LIST: {
      return Object.assign({}, state, {
        projects: action.payload
      });
    }
    case UPDATE_ACTIVITIES_NAME_LIST: {
      return Object.assign({}, state, {
        activityTypes: action.payload
      });
    }
    case UPDATE_MONTHLY_INFO_LIST: {
      return Object.assign({}, state, {
        monthlyInfos: action.payload
      });
    }
    case UPDATE_LEAVE_COUNT: {
      return Object.assign({}, state, {
        leaveCount: action.payload
      });
    }
    case UPDATE_LEAVE_BALANCE: {
      return Object.assign({}, state, {
        leaveBalance: action.payload
      });
    }
    case UPDATE_LEAVE_APPLICATION_LIST: {
      return Object.assign({}, state, {
        leaveApplications: action.payload
      });
    }
    default:
      return state;
  }
};

export const controlReducer = (state: ControlState = initialControlState, action: any) => {
  switch (action.type) {
    case UPDATE_CURRENT_USERNAME:
      return Object.assign({}, state, {
        currentUserName: action.payload
      });
    case UPDATE_CURRENT_USER:
      return Object.assign({}, state, {
        currentUser: action.payload
      });
    case UPDATE_ERROR_MODAL:
      return Object.assign({}, state, {
        errorModal: action.payload
      });
    case UPDATE_SUCCESS_MODAL:
      return Object.assign({}, state, {
        successModal: action.payload
      });
    case UPDATE_ATTENDANCE_CHECKEDIN_STATUS:
      return Object.assign({}, state, {
        isAttendanceCheckedIn: action.payload
      });
    case UPDATE_ATTENDANCE_CHECKEDOUT_STATUS:
      return Object.assign({}, state, {
        isAttendanceCheckedOut: action.payload
      });
    case UPDATE_CURRENT_ATTENDANCE:
      return Object.assign({}, state, {
        currentAttendance: action.payload
      });
    case UPDATE_CURRENT_PERFORMANCE_ATTRIBUTE:
      return Object.assign({}, state, {
        currentPerformanceAttribute: action.payload
      });
    case UPDATE_TIMESHEET_FORM_MODAL_OPEN:
      return Object.assign({}, state, {
        isNewTimesheetModalOpen: action.payload
      });
    case UPDATE_LEAVE_FORM_MODAL_OPEN:
      return Object.assign({}, state, {
        isNewLeaveModalOpen: action.payload
      });
    case UPDATE_CURRENT_ATTENDANCE_BREAK:
      return Object.assign({}, state, {
        currentAttendanceBreak: action.payload
      });
    case OPEN_DAILY_REWARD_MODAL:
      return Object.assign({}, state, {
        dailyRewardModal: {
          ...state.dailyRewardModal,
          isOpen: action.payload
        }
      });
    case UPDATE_DAILY_REWARD_MODAL:
      return Object.assign({}, state, {
        dailyRewardModal: action.payload
      });
    case UPDATE_ATTENDANCE_BREAKIN_STATUS:
      return Object.assign({}, state, {
        isAttendanceBreakIn: action.payload
      });
    case UPDATE_CURRENT_ACHIEVEMENT_LIST: {
      return Object.assign({}, state, {
        currentAchievements: action.payload
      });
    }
    case ADD_NEW_COMPANY_DATA: {
      return Object.assign({}, state, {
        companyData: [
          ...state.companyData,
          action.payload
        ]
      })
    }
    case UPDATE_SELECTED_COMPANY: {
      return Object.assign({}, state, {
        selectedCompany: action.payload
      });
    }
    case DELETE_COMPANY_DATA: {
      const companyDataIndex = state.companyData.findIndex((companyData: CompanyData) => companyData.companyId === action.payload);
      return Object.assign({}, state, {
        companyData: [
          ...state.companyData.slice(0, companyDataIndex),
          ...state.companyData.slice(companyDataIndex + 1)
        ]
      });
    }
    case UPDATE_LEAVE_IMAGE_DATA: {
      return Object.assign({}, state, {
        currentLeaveAppImage: action.payload
      });
    }
    case ADD_LEAVE_APP_IMAGE_URL:
      return Object.assign({}, state, {
        currentLeaveAppImage: {
          url: action.payload.imageUrl,
          base64: action.payload.imageBase64
        }
      });

    case UPDATE_UPLOADING_LEAVE_IMAGE_STATUS: {
      return Object.assign({}, state, {
        currentLeaveAppImage: {
          isUploading: true
        }
      });
    }
    case UPDATE_CURRENT_SERVER_TIME:
      return Object.assign({}, state, {
        currentServerTime: action.payload
      });
    case UPDATE_LANGUAGE:
      return Object.assign({}, state, {
        language: action.payload
      });
    case UPDATE_OPENING_TIME:
      return Object.assign({}, state, {
        openingTime: action.payload
      });
    case UPDATE_CLOSING_TIME:
      return Object.assign({}, state, {
        closingTime: action.payload
      });
    case UPDATE_TODAY_DATE:
      return Object.assign({}, state, {
        todayDate: action.payload
      });
    case UPDATE_TIMESHEET_DETAIL: {
      return Object.assign({}, state, {
        timesheetDetail: action.payload
      });
    }
    case UPDATE_TIMESHEET_TEMPLATES: {
      return Object.assign({}, state, {
        timesheetTemplates: action.payload,
      });
    }
    case UPDATE_COMPANY_INFORMATION:
      return Object.assign({}, state, {
        companyInformation: action.payload,
    });
    case UPDATE_IS_INITIAL_ON_BOARDING:
      return Object.assign({}, state, {
        isInitialOnBoarding: action.payload,
    });
    default:
      return state;
  }
};

export const sessionReducer = (state: SessionState = initialSessionState, action: any) => {
  switch (action.type) {
    case UPDATE_UPLOADING_IMAGE_STATUS: {
      return Object.assign({}, state, {
        isUploadingImage: action.payload
      });
    }
    case UPDATE_SEARCHING_COMPANY_SERVER_STATUS:
      return Object.assign({}, state, {
        isSearchingCompanyServer: action.payload
      })
    case ADD_API_KEY:
      return Object.assign({}, state, {
        apiKey: action.payload
      });
    case REMOVE_API_KEY:
      return Object.assign({}, state, {
        apiKey: ''
      });
    case UPDATE_GETTING_API_KEY_FROM_SECURE_STORE_STATUS:
      return Object.assign({}, state, {
        isGettingApiKeyFromSecureStore: action.payload
      });
    case UPDATE_LOGGING_IN_STATUS:
      return Object.assign({}, state, {
        isLoggingIn: action.payload
      });
    case UPDATE_LOGGING_OUT_STATUS:
      return Object.assign({}, state, {
        isLoggingOut: action.payload
      });
    case UPDATE_GETTING_LOGGED_USER_STATUS:
      return Object.assign({}, state, {
        isGettingLoggedUser: action.payload
      });
    case UPDATE_GETTING_USER_INFO_STATUS:
      return Object.assign({}, state, {
        isGettingUserInfo: action.payload
      });
    case UPDATE_IS_CHECKING_IN_STATUS:
      return Object.assign({}, state, {
        isCheckingIn: action.payload
      });
    case UPDATE_IS_CHECKING_OUT_STATUS:
      return Object.assign({}, state, {
        isCheckingOut: action.payload
      });
    case UPDATE_FETCHING_LEAVE_COUNT_STATUS:
      return Object.assign({}, state, {
        isFetchingLeaveCount: action.payload
      });
    case UPDATE_GETTING_SERVER_TIME_ATTRIBUTE: {
      return Object.assign({}, state, {
        serverTime: {
          ...state.serverTime,
          isFetching: action.payload.isFetching,
          lastUpdated: action.payload.lastUpdated
        }
      });
    }
    case UPDATE_FETCHING_ATTENDANCES_ATTRIBUTE: {
      return Object.assign({}, state, {
        attendances: {
          ...state.attendances,
          isFetching: action.payload.isFetching,
          lastUpdated: action.payload.lastUpdated
        }
      });
    }
    case UPDATE_FETCHING_ATTENDANCE_BREAK_ATTRIBUTE: {
      return Object.assign({}, state, {
        attendanceBreaks: {
          ...state.attendanceBreaks,
          isFetching: action.payload.isFetching,
          lastUpdated: action.payload.lastUpdated
        }
      });
    }
    case UPDATE_ADDING_TIMELOG_ATTRIBUTE:
      return Object.assign({}, state, {
        timelogs: {
          ...state.timelogs,
          isStoring: action.payload.isStoring,
          lastUpdated: action.payload.lastUpdated
        }
      });
    case UPDATE_FETCHING_TIMESHEET_DETAIL_ATTRIBUTE:
      return Object.assign({}, state, {
        timesheetDetail: {
          ...state.timelogs,
          isFetching: action.payload.isStoring,
          lastUpdated: action.payload.lastUpdated
        }
      });
    case UPDATE_FETCHING_TIMELOGS_ATTRIBUTE: {
      return Object.assign({}, state, {
        timelogs: {
          ...state.timelogs,
          isFetching: action.payload.isFetching,
          lastUpdated: action.payload.lastUpdated
        }
      });
    }
    case UPDATE_FETCHING_PERFORMANCE_ATTRIBUTE_STATUS: {
      return Object.assign({}, state, {
        performanceAttribute: {
          ...state.performanceAttribute,
          isFetching: action.payload.isFetching,
          lastUpdated: action.payload.lastUpdated
        }
      });
    }
    case UPDATE_FETCHING_ACHIEVEMENT_LIST_ATTRIBUTE: {
      return Object.assign({}, state, {
        achievements: {
          ...state.achievements,
          isFetching: action.payload.isFetching,
          lastUpdated: action.payload.lastUpdated
        }
      });
    }
    case UPDATE_FETCHING_LEAVE_APPLICATIONS_ATTRIBUTE: {
      return Object.assign({}, state, {
        leaveApplications: {
          ...state.leaveApplications,
          isFetching: action.payload.isFetching,
          lastUpdated: action.payload.lastUpdated
        }
      });
    }
    case UPDATE_CREATING_LEAVE_APPLICATION_ATTRIBUTE: {
      return Object.assign({}, state, {
        leaveApplications: {
          ...state.leaveApplications,
          isStoring: action.payload.isStoring,
          lastUpdated: action.payload.lastUpdated
        }
      });
    }
    case UPDATE_IS_SAVING_OTHER_DATE_STATUS:
      return Object.assign({}, state, {
        isSavingOtherDate: action.payload
      });
    case UPDATE_FETCHING_MONTHLY_INFOS_ATTRIBUTE: {
      return Object.assign({}, state, {
        monthlyInfos: {
          ...state.monthlyInfos,
          isFetching: action.payload.isFetching,
          lastUpdated: action.payload.lastUpdated
        }
      });
    }
    case UPDATE_IS_BREAKING_IN_STATUS:
      return Object.assign({}, state, {
        isBreakingIn: action.payload
      });
    case UPDATE_IS_BREAKING_OUT_STATUS:
      return Object.assign({}, state, {
        isBreakingOut: action.payload
      });
    case UPDATE_EXPO_PUSH_TOKEN:
      return Object.assign({}, state, {
        expoPushToken: action.payload,
      });
    case UPDATE_SUBMITTING_EXPO_PUSH_TOKEN:
      return Object.assign({}, state, {
        isSubmittingExpoPushToken: action.payload,
      });
    case UPDATE_FETCHING_COMPANY_INFORMATION_STATUS:
      return Object.assign({}, state, {
        isFetchingCompanyInformation: action.payload,
      });
    default:
      return state;
  }
};

export const communicationReducer = (state: CommunicationState = initialCommunicationState, action: any) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

export const reducers = combineReducers<State>({
  data: dataReducer,
  control: controlReducer,
  session: sessionReducer,
  communication: communicationReducer
});
