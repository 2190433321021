import {Actionsheet, Icon} from "native-base";
import {Feather} from "@expo/vector-icons";
import React from "react";

interface BottomSheetMenuComponentProps {
  isMenuOpen: boolean;
  isButtonDisabled: boolean;
  handleEditTimesheetOnPress: () => void;
  handleDeleteTimesheetOnPress: () => void;
  handleCloseMenu?: () => void;
}

export const BottomSheetMenuComponent = (props: BottomSheetMenuComponentProps) => {
  return (
    <Actionsheet
      isOpen={props.isMenuOpen}
      onClose={props.handleCloseMenu}
    >
      <Actionsheet.Content>
        <Actionsheet.Item
          leftIcon={
            <Icon
              as={Feather}
              name="edit-2"
              size={5}
            />
          }
          onPress={props.handleEditTimesheetOnPress}
        >
          Edit
        </Actionsheet.Item>
        <Actionsheet.Item
          _text={{
            color: props.isButtonDisabled ? "muted.300" : "danger.500",
          }}
          leftIcon={
            <Icon
              as={Feather}
              name="trash-2"
              color={props.isButtonDisabled ? "muted.300" : "danger.500"}
              size={5}
            />
          }
          disabled={props.isButtonDisabled}
          onPress={props.handleDeleteTimesheetOnPress}
        >
          Delete
        </Actionsheet.Item>
      </Actionsheet.Content>
    </Actionsheet>
  );
};
