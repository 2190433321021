import React from "react";
import {Box, Text, VStack} from "native-base";
import {RenderHTML} from "react-native-render-html";
import {useWindowDimensions} from "react-native";

interface CompanyRulesDetailScreenProps {
  rulesDetail: string;
  sectionName: string;
}

export const CompanyRulesDetailScreen = (props: CompanyRulesDetailScreenProps) => {
  const { width } = useWindowDimensions();
  return (
    <VStack
      flex={1}
      p={4}
      space="md"
      bg="secondary.500"
    >
      <Box>
        <Text
          fontSize="lg"
          bold
          color="darkText"
        >
          {props.sectionName}
        </Text>
      </Box>
      <RenderHTML
        contentWidth={width}
        source={{
          html: props.rulesDetail
        }}
      />
    </VStack>
  );
};
