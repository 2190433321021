import React, {useEffect, useRef, useState} from "react";
import {Animated, ImageBackground, Platform, View} from "react-native";
import {Button, Center, Flex, HStack, Icon, VStack, useToast, useTheme} from "native-base";
import {connect, useDispatch} from "react-redux";
import {useNavigation, useRoute} from "@react-navigation/native";
import {heightPercentageToDP as hp} from 'react-native-responsive-screen';
import {FontAwesome, MaterialIcons} from '@expo/vector-icons';

import {Camera, CameraCapturedPicture} from "expo-camera";
import * as MediaLibrary from "expo-media-library";
import * as ImageManipulator from "expo-image-manipulator";
import {ImageResult} from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import {CameraPermissionResponse, ImagePickerResult} from "expo-image-picker";
import {CameraPictureOptions} from "expo-camera/src/Camera.types";
import { useTranslation } from "react-i18next";

import {tapCameraStyles} from "../styles/tapCameraStyles";

// Actions
import {addLeaveAppImageUrl} from "../actions/actionLeaveApplication";
import {uploadUserProfileImage} from "../actions/actionProfile";
import { getUserInfo } from "../actions/actionsAuth";

// Constants
import {DEFAULT_CAMERA_RATIO, DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH} from "../master-data/constants";

// Containers
import {SelectRatioModal} from "../Containers/SelectRatioModal";

// State
import { User, State } from "../state";


interface TapCameraProps {
  currentUser?: User;
  currentUserName: string;
}

const mapStateToProps = (state: State) => {
  return {
    currentUser: state.control.currentUser,
    currentUserName: state.control.currentUserName
  };
};

const TapCamera = (props: TapCameraProps) => {
  let camera: Camera | null;

  const fadeAnim = useRef(new Animated.Value(0)).current;
  const dispatch = useDispatch();
  const navigation: any = useNavigation();
  const route = useRoute();
  const toast = useToast();
  const {t}: { t: any } = useTranslation();

  const params: any = route.params;
  const isAllowUploadFromGallery = params.isAllowUploadFromGallery;
  const uploadType = params.uploadType;

  const [selectedRatio, setSelectedRatio] = useState(DEFAULT_CAMERA_RATIO);
  const [isAvailableRatioModalOpen, setIsAvailableRatioModalOpen] = useState(false);
  const [isTakingPicture, setIsTakingPicture] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [type, setType] = useState(Camera.Constants.Type.back);
  const [flashType, setFlashType] = useState(Camera.Constants.FlashMode.off);
  const [flash, setFlash] = useState("flash-off");
  const [capturedImage, setCapturedImage] = useState<any>(null);

  const borderWidth = new Animated.Value(0);
  const bgAlpha = new Animated.Value(0);
  const opacity = new Animated.Value(0);
  const borderWidthRef = useRef(borderWidth);
  const bgAlphaRef = useRef(bgAlpha);
  const theme = useTheme();

  useEffect(() => {
    navigation.getParent()?.setOptions({
      tabBarStyle: { display: 'none' },
    });
    return () => {
      navigation.getParent()?.setOptions({
        tabBarStyle: {
          display: 'flex',
          height: Platform.OS === "ios" ? hp(12) : hp(8),
          backgroundColor: theme.colors.secondary[500],
        },
      });
    }
  }, [navigation]);

  const executeCaptureAnimation = () => {
    Animated.timing(borderWidthRef.current, {
      useNativeDriver: false,
      toValue: hp(1),
      duration: 100
    }).start(() => {
      borderWidthRef.current.setValue(0);
    });
  };

  const executeDimAfterCapture = () => {
    Animated.timing(bgAlphaRef.current, {
      useNativeDriver: false,
      toValue: 1,
      duration: 1000
    }).start();
  };

  const fadeInPreview = () => {
    Animated.timing(opacity, {
      useNativeDriver: false,
      toValue: 1,
      duration: 1500
    }).start();
  }

  const bgAlphaInterpolation = bgAlphaRef.current.interpolate({
    inputRange: [0, 1],
    outputRange: ["rgba(0,0,0,0.0)", "rgba(0,0,0,0.5)"]
  });

  const opacityInterpolation = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0.0, 1.0]
  });

  const handleFlipCamera = () => {
    if (type === Camera.Constants.Type.back) {
      setType(Camera.Constants.Type.front);
      setFlashType(Camera.Constants.FlashMode.off);
      setFlash("flash-off");
    } else {
      setType(Camera.Constants.Type.back);
    }
  };

  const handleFlashCamera = () => {
    if (flashType === Camera.Constants.FlashMode.off) {
      setFlashType(Camera.Constants.FlashMode.on);
      setFlash("flash-on");
    } else if (flashType === Camera.Constants.FlashMode.on) {
      setFlashType(Camera.Constants.FlashMode.auto);
      setFlash("flash-auto");
    } else {
      setFlashType(Camera.Constants.FlashMode.off);
      setFlash("flash-off");
    }
  };

  const retakePicture = () => {
    fadeAnim.setValue(0);
    setCapturedImage(null);
    setIsTakingPicture(false);
    setIsSaving(false);
  };

  const getResizeOption = (currentWidth: number, currentHeight: number) => {
    let resizeOption: any = {
      resize: {
        width: DEFAULT_IMAGE_WIDTH,
        height: DEFAULT_IMAGE_HEIGHT
      }
    };

    if (currentWidth <= DEFAULT_IMAGE_WIDTH) {
      resizeOption = {
        resize: {
          width: currentWidth,
          height: currentHeight
        }
      };
    }
    return resizeOption;
  }

  const resizeImage = (result: ImagePickerResult): Promise<any> => {
    if (!result.cancelled && result.base64 && result.uri) {
      const resizeOption = getResizeOption(result.width, result.height);
      return ImageManipulator.manipulateAsync(result.uri, [resizeOption], {
        compress: 0.5,
        format: ImageManipulator.SaveFormat.JPEG,
        base64: true
      });
    } else {
      return Promise.reject("Unable to get the image");
    }
  };

  const getWidthAndHeight = (width: number, height: number): [number, number] => {
    let correctWidth = width;
    let correctHeight = height;

    if (width > height) {
      correctWidth = height;
      correctHeight = width;
    }

    return [correctWidth, correctHeight];
  }

  const takePicture = () => {
    const option: CameraPictureOptions = {
      quality: 0.5,
      base64: true,
      exif: false,
      skipProcessing: true
    };
    if (camera) {
      executeCaptureAnimation();
      executeDimAfterCapture();
      camera.takePictureAsync(option)
        .then((image: CameraCapturedPicture) => {
          camera?.pausePreview();
          const [width, height] = getWidthAndHeight(image.width, image.height);
          return ImageManipulator.manipulateAsync(image.uri, [{
            crop: {
              originX: 0,
              originY: 0.15 * height,
              width: width,
              height: width
            }
          }], {
            format: ImageManipulator.SaveFormat.JPEG,
            base64: true
          })
        })
        .then((result: ImageResult) => {
          const resizeOption = getResizeOption(result.width, result.height);
          return ImageManipulator.manipulateAsync(result.uri, [resizeOption], {
            compress: 0.5,
            format: ImageManipulator.SaveFormat.JPEG,
            base64: true
          });
        })
        .then((result: ImageResult) => {
          camera?.resumePreview();
          setIsTakingPicture(false);
          setCapturedImage(result);
          bgAlphaRef.current.setValue(0);
        })
        .catch((message: string) => {
          toast.show({
            title: "Error while taking picture",
            status: "error",
            description: message,
          })

          bgAlphaRef.current.setValue(0);
          retakePicture();
        });
    } else {
      console.log("NO CAMERA");
    }
  };

  const savePicture = (capturedImage: CameraCapturedPicture) => {
    setIsSaving(true);
    MediaLibrary.requestPermissionsAsync()
      .then((response) => {
        if (response.status === "granted") {
          return Promise.resolve();
        } else {
          return Promise.reject("Media is not granted");
        }
      })
      .then(() => {
        return resizeImage({
          cancelled: false,
          uri: capturedImage.uri,
          base64: capturedImage.base64,
          width: capturedImage.width,
          height: capturedImage.height,
          exif: capturedImage.exif
        })
      })
      .then(() => {
        if (capturedImage.base64) {
          if (uploadType == "Leave Application"){
            dispatch(addLeaveAppImageUrl(capturedImage.uri, capturedImage.base64));
          }
          else if (uploadType == "Profile"){
            if(props.currentUser && capturedImage.base64) {
              dispatch(uploadUserProfileImage(props.currentUser.username, capturedImage.base64));
            }
          }
          return MediaLibrary.saveToLibraryAsync(capturedImage.uri);
        } else {
          return Promise.reject("Unable to get the image");
        }
      })
      .then(() => {
        if(props.currentUser){
          dispatch(getUserInfo(props.currentUser.username));
        }
      })
      .then(() => {
        toast.show({
          title: "Image has been saved to your gallery"
        })
        navigation.goBack();
      })
      .catch((reason: string) => {
        toast.show({
          title: "Error while saving picture",
          status: "error",
          description: reason,
        })
        retakePicture();
      });
  };

  const openGallery = () => {
    ImagePicker.requestMediaLibraryPermissionsAsync()
      .then((response: CameraPermissionResponse) => {
        if (response.status === "granted") {
          return ImagePicker.launchImageLibraryAsync({
            aspect: [1, 1],
            allowsEditing: true,
            base64: true,
            quality: 0.5,
            mediaTypes: ImagePicker.MediaTypeOptions.Images
          });
        } else {
          return Promise.reject("Image library Permission is not granted");
        }
      })
      .then((result: ImagePickerResult) => {
        return resizeImage(result);
      })
      .then(async(result: ImageResult) => {
        if (result.base64 && result.uri) {
          if (uploadType == "Leave Application"){
            return Promise.resolve(dispatch(addLeaveAppImageUrl(result.uri, result.base64)));
          }
          else if (uploadType == "Profile"){
            if(props.currentUser){
              let upload_promise = dispatch(uploadUserProfileImage(props.currentUser.username, result.base64));
              navigation.navigate('Profile');
              return upload_promise;
            }
          }
        } else {
          return Promise.reject("Unable to get the image");
        }
      })
      .then(() => {
        if(props.currentUser){
          dispatch(getUserInfo(props.currentUser.username))
        }
      })
      .then(() => {
        toast.show({
          title: "Image has been successfully uploaded",
          duration: 2000
        })
      })
      .catch((reason) => {
        toast.show({
          title: "Unable to get the image",
          status: "error",
          description: reason,
        })
      });
  };

  const renderImagePreview = (capturedImage: CameraCapturedPicture) => {
    fadeInPreview();
    return (
      <Animated.View style={[tapCameraStyles.imagePreviewContainer, {opacity: opacityInterpolation}]}>
        <ImageBackground source={{uri: capturedImage && capturedImage.uri}}
                         style={tapCameraStyles.imageContainer}/>
        <View style={tapCameraStyles.imagePreviewButtonSection}>
          <Button leftIcon={<Icon as={MaterialIcons} name="do-not-disturb" size="md"/>}
                  onPress={retakePicture}
                  isDisabled={isSaving}
                  variant="ghost"
                  _text={{color:"secondary.100"}}
                  _pressed={{bg: "transparent"}}
          >
            {String(t('button.cancelButton'))}
          </Button>
          <Button leftIcon={<Icon as={MaterialIcons} name="check" size="md"/>}
                  onPress={() => savePicture(capturedImage)}
                  isDisabled={isSaving}
                  variant="ghost"
                  isLoading={isSaving}
                  _text={{color:"secondary.100"}}
                  _pressed={{bg: "transparent"}}
          >
            {String(t('button.doneButton'))}
          </Button>
        </View>
      </Animated.View>
    );
  };

  const handleOpenRatioModal = () => {
    setIsAvailableRatioModalOpen(true);
  }

  const handleCloseRatioModal = () => {
    setIsAvailableRatioModalOpen(false);
  }

  const handleSelectRatio = (selectedRatio: string) => {
    setSelectedRatio(selectedRatio)
  }

  return (
    <View style={{
      height: "100%",
      width: "100%",
      backgroundColor: 'black'
    }}>
      {
        capturedImage ?
          renderImagePreview(capturedImage)
          :
          <Flex flex={1}>
            <Camera style={tapCameraStyles.camera}
                    type={type}
                    flashMode={flashType}
                    autoFocus={"on"}
                    ref={ref => camera = ref}
                    ratio={selectedRatio}
            />
            <View style={tapCameraStyles.cropOverlayTop}/>
            <Animated.View style={[tapCameraStyles.cropOverlayMiddle, {
              borderWidth: borderWidthRef.current
            }]}/>
            <View style={tapCameraStyles.cropOverlayBottom}/>
            <Animated.View style={[tapCameraStyles.cropOverlayFull, {backgroundColor: bgAlphaInterpolation}]}/>
            <VStack style={tapCameraStyles.cameraButtonContainer}>
              <Center>
                <HStack>
                  <Icon as={MaterialIcons}
                        name={"camera"}
                        disabled={isTakingPicture}
                        size={"2xl"}
                        ml={isAllowUploadFromGallery ? 12 : "auto"}
                        color={"secondary.100"}
                        onPress={takePicture}
                  />
                  {
                    isAllowUploadFromGallery &&
                    <Icon as={MaterialIcons}
                          name={"photo-library"}
                          mt={3}
                          ml={3}
                          disabled={isTakingPicture}
                          size={"lg"}
                          color={"secondary.100"}
                          onPress={openGallery}
                    />
                  }
                </HStack>
              </Center>
              <HStack w={"full"} flex={1} mt={10} justifyContent={"space-between"} px={24}>
                <Icon as={MaterialIcons}
                      name={flash}
                      disabled={isTakingPicture || type === Camera.Constants.Type.front}
                      size={"sm"}
                      color={"secondary.100"}
                      onPress={handleFlashCamera}
                />
                <Icon as={FontAwesome}
                      name={"refresh"}
                      disabled={isTakingPicture}
                      size={"sm"}
                      color={"secondary.100"}
                      onPress={handleFlipCamera}
                />
                <Icon as={MaterialIcons}
                      name={"aspect-ratio"}
                      disabled={isTakingPicture}
                      size={"sm"}
                      color={"secondary.100"}
                      onPress={handleOpenRatioModal}
                />
              </HStack>
            </VStack>
          </Flex>
      }
      <SelectRatioModal isOpen={isAvailableRatioModalOpen}
                        selectedRatio={selectedRatio}
                        handleSelectRatio={handleSelectRatio}
                        handleCloseModal={handleCloseRatioModal}
      />
    </View>
  );
};

export default connect(mapStateToProps)(TapCamera);
