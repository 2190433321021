import {Flex, Heading, HStack, Icon, Pressable, Text, VStack} from "native-base";
import {FontAwesome} from "@expo/vector-icons";
import React from "react";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import moment from "moment";
import {TIME_DISPLAY_FORMAT} from "../lib/dateFormat";
import {useColorScheme} from 'react-native';

interface TapInputElement {
  mode: "date" | "time";
  labelName: string;
  isError: boolean;
  selectedValue: string;
  isDateTimePickerOpen: boolean;
  readOnly?: boolean;
  iconName?: any;
  errorText?: string;
  openDateTimePicker: () => void;
  closeDateTimePicker: () => void;
  handleValueChange: (date: Date) => void;
}

export const TapInputElement = (props: TapInputElement) => {
  const initialValue: Date = props.selectedValue ? moment(props.selectedValue, TIME_DISPLAY_FORMAT).toDate() : new Date();

  return (
    <VStack flex={1}>
      <Heading
        fontSize="lg"
        fontWeight="bold"
        color={props.labelName === "TAP OUT" ? "danger.500" : "primary.500"}
      >
        {props.labelName}
      </Heading>
      <HStack
        mt={2}
        space="xs"
        alignItems="center"
      >
        <Icon
          as={FontAwesome}
          name={props.iconName}
          size="xs"
          color={props.labelName === "TAP OUT" ? "danger.500" : "primary.500"}
        />
        <Pressable
          flex={1}
          p={1}
          borderRadius={6}
          borderWidth={1}
          borderColor={
            props.isError ?
              'danger.500'
              :
              !props.readOnly ?
                'success.500'
                :
                'transparent'
          }
          bg={
            props.isError ?
              'danger.200'
              :
              !props.readOnly ?
                'success.200'
                :
                'transparent'
          }
          disabled={props.readOnly}
          onPress={props.openDateTimePicker}
        >
          <Text color="muted.500">
            {props.selectedValue}
          </Text>
        </Pressable>
      </HStack>
      <Flex>
        {
          props.isError &&
          <Text
            fontSize="xs"
            numberOfLines={1}
            color="danger.500"
          >
            {props.errorText ?
              props.errorText
              :
              "Specify ".concat(props.labelName)
            }
          </Text>
        }
      </Flex>
      <DateTimePickerModal
        isVisible={props.isDateTimePickerOpen}
        mode={props.mode}
        date={initialValue}
        isDarkModeEnabled={useColorScheme() === 'dark'}
        onConfirm={props.handleValueChange}
        onCancel={props.closeDateTimePicker}
      />
    </VStack>
  );
};
