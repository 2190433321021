import {ModalProps, State} from "../state";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import moment from "moment";
import axios from "axios";
import {handleHttpError, handleNoBaseUrlError} from "./actionsAuth";
import i18n from "i18next";
import {updateErrorModalStatus} from "./actions";
import {captureErrorSentry} from "./httpUtils";

export const UPDATE_GETTING_SERVER_TIME_ATTRIBUTE = "UPDATE_GETTING_SERVER_TIME_ATTRIBUTE";

export const UPDATE_CURRENT_SERVER_TIME = "UPDATE_CURRENT_SERVER_TIME";

export const updateGettingServerTimeStatus = (gettingServerTimeStatus: boolean) => {
  return {
    type: UPDATE_GETTING_SERVER_TIME_ATTRIBUTE,
    payload: {
      isFetching: gettingServerTimeStatus,
      lastUpdated: new Date().getTime()
    }
  };
};

export const updateCurrentServerTime = (currentServerTime: string) => {
  return {type: UPDATE_CURRENT_SERVER_TIME, payload: currentServerTime};
};

const formatUtcTime = (unformattedDateTime: string) => {
  if (!unformattedDateTime.endsWith('Z')) {
    return unformattedDateTime.concat('Z');
  }
  return unformattedDateTime;
};

export const getCurrentServerTime = () => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.getCurrentServerTimeErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_centralized_time";

    dispatch(updateGettingServerTimeStatus(true));

    axios({
      method: 'get',
      url: url,
      responseType: 'json',
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateCurrentServerTime(formatUtcTime(response.data.message)));
          dispatch(updateGettingServerTimeStatus(false));
        } else {
          captureErrorSentry("Error when fetching server time", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateGettingServerTimeStatus);
          dispatch(updateCurrentServerTime(moment().toISOString()));
        }
      })
      .catch(error => {
        dispatch(updateGettingServerTimeStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};
