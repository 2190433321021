import React  from "react";
import {Pressable, Text, Icon, Box, HStack, Divider} from "native-base";
import { Feather } from "@expo/vector-icons";
import {
  ENGLISH_LANGUAGE_DISPLAYED,
  ENGLISH_LANGUAGE_SELECTION, INDONESIAN_LANGUAGE_DISPLAYED,
  INDONESIAN_LANGUAGE_SELECTION
} from "../master-data/constants";

interface LanguageSelectionScreenProps {
  language: string;
  handleChangeLanguage: (language: string) => void;
}

interface LanguageOptionsProps {
  label: string;
  languageId: string;
  language: string;
  onPress: (language: string) => void;
}

const CheckIconComponent = () => (
  <Icon
    as={Feather}
    name="check"
    color="success.500"
    size="sm"
  />
);

const LanguageOptions = (props: LanguageOptionsProps) => (
  <Pressable
    p={4}
    onPress={() => props.onPress(props.languageId)}
  >
    <HStack
      space="md"
      alignItems="center"
    >
      <Box flex={1}>
        {
          props.languageId === props.language &&
          <CheckIconComponent />
        }
      </Box>
      <Box flex={9}>
        <Text fontSize="md">
          {props.label}
        </Text>
      </Box>
    </HStack>
  </Pressable>
);

export const LanguageSelectionScreen = (props: LanguageSelectionScreenProps) => {
  return (
    <Box
      bgColor="secondary.500"
      h="full"
    >
      <LanguageOptions
        label={INDONESIAN_LANGUAGE_SELECTION}
        languageId={INDONESIAN_LANGUAGE_DISPLAYED.toLowerCase()}
        language={props.language}
        onPress={props.handleChangeLanguage}
      />
      <Divider
        borderColor="coolGray.500"
        borderWidth={0.5}
      />
      <LanguageOptions
        label={ENGLISH_LANGUAGE_SELECTION}
        languageId={ENGLISH_LANGUAGE_DISPLAYED.toLowerCase()}
        language={props.language}
        onPress={props.handleChangeLanguage}
      />
      <Divider
        borderColor="coolGray.500"
        borderWidth={0.5}
      />
    </Box>
  );
};
