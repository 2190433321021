import React from "react";
import {Timelog} from "../state";
import {Flex, HStack, Icon, Pressable, Text, VStack} from "native-base";
import {ScrollView} from "react-native";
import {SingleTimesheetCard} from "./SingleTimesheetCard";
import {FontAwesome} from '@expo/vector-icons';
import { useTranslation } from "react-i18next";

export interface TimelogListProps {
  isVertical: boolean;
  isToday: boolean;
  currentTimelogs: Timelog[];
  handleCreateNewTimelog: () => void;
  handleSingleTimesheetOnPress: (timeLog: Timelog) => void;
}

export const TimelogList: React.FC<TimelogListProps> = (props: TimelogListProps) => {
  const {t}: { t: any } = useTranslation();

  const renderTimelogList = (isVertical: boolean) => {
    if (props.currentTimelogs) {
      let timelogRows: JSX.Element[] = [];
      if (props.currentTimelogs.length > 0) {
        props.currentTimelogs.forEach((timelog: Timelog) => {
          timelogRows.push(
            <SingleTimesheetCard
              key={timelog.name}
              timeLog={timelog}
              isVertical={isVertical}
              isToday={props.isToday}
              handleSingleTimesheetOnPress={props.handleSingleTimesheetOnPress}
            />
          )
        })
      }

      {
        !isVertical ?
          timelogRows.push(
            <Pressable key={"no-timelogs"}
                       onPress={props.handleCreateNewTimelog}>
              <VStack flex={1}
                      justifyContent={"space-between"}
                      space={"sm"}
                      p={2}
                      borderRadius={8} borderWidth={4} borderColor={"primary.500"}
              >
                <Text fontSize={"md"} color={"primary.500"} bold>{String(t('homeScreen.addTimeLogText'))}</Text>
                <Icon as={FontAwesome} name='plus-circle' size={"md"} ml={"auto"} color={"primary.500"}/>
              </VStack>
            </Pressable>
          )
          :
          null
      }
      return (
        props.isVertical ?
          <VStack space={2}>
            {timelogRows}
          </VStack>
          :
          <HStack space={2}>
            {timelogRows}
          </HStack>
      );
    }
  }

  return (
    <ScrollView
      horizontal={!props.isVertical}
      contentContainerStyle={{
        padding: 10,
      }}
    >
      <Flex direction={props.isVertical ? "column" : "row"}>
        {renderTimelogList(props.isVertical)}
      </Flex>
    </ScrollView>
  )
};
