import * as Sentry from "sentry-expo";
import {AxiosResponse} from "axios";

export interface FrappeQueryParam {
  key: string;
  operator: '=' | '>' | '>=' | '<' | '<=' | '!=';
  value: string | number | boolean;
}

export const constructFilters = (paramMap: { [key: string]: string | number | boolean }, filterStringReceived?: string): string => {
  if (Object.keys(paramMap).length === 0) {
    return "";
  }
  let filtersString = "filters=[";
  if (filterStringReceived) {
    filtersString = filterStringReceived;
  }
  Object.entries(paramMap).forEach(([key, value]) => {
    filtersString = filtersString.concat("[\"").concat(key).concat("\",\"=\",\"").concat(String(value)).concat("\"],");
  })
  if (filterStringReceived) {
    return filtersString.substr(0, filtersString.length - 1);
  }
  return filtersString.substr(0, filtersString.length - 1).concat("]");
}

export const constructComplexFilters = (paramList: FrappeQueryParam[]): string => {
  if (paramList.length === 0) {
    return "";
  }
  let filtersString = "filters=[";
  paramList.forEach((param: FrappeQueryParam) => {
    filtersString = filtersString.concat("[\"").concat(param.key).concat("\",\"").concat(param.operator).concat("\",\"").concat(String(param.value)).concat("\"],");
  })
  return filtersString.substr(0, filtersString.length - 1).concat("]");
}

export const constructFields = ((fieldList: string[]) => {
  if (fieldList.length === 0) {
    return "";
  }
  let fieldsString = "fields=[";
  fieldList.forEach((fieldName: string) => {
    fieldsString = fieldsString.concat("\"").concat(fieldName).concat("\",")
  })
  return fieldsString.substr(0, fieldsString.length - 1).concat("]");
})

export const captureErrorSentry = (title: string, response: AxiosResponse<any>) => {
  Sentry.Native.captureMessage(title, {
    contexts: {
      apiErrorInfo: {
        httpErrorCode: response.status,
        httpErrorException: response.data.exception,
        httpErrorMessage: response.data.message,
      }
    }
  });
};
