import {Feather, FontAwesome} from "@expo/vector-icons";
import React from "react";
import moment from "moment";
import {LeaveStatus, LeaveType} from "../state";
import {ATTENDANCE_DATE_FORMAT, DATE_DISPLAY_FORMAT} from "../lib/dateFormat";
import {Box, Center, HStack, Icon, Text, VStack} from "native-base";
import { useTranslation } from "react-i18next";
import {ImageBackground} from "react-native";

interface SingleLeaveApplicationCardProps {
  status: LeaveStatus;
  leaveType: LeaveType;
  leaveFrom: string;
  leaveTo: string;
}

export const SingleLeaveApplicationCard = (props: SingleLeaveApplicationCardProps) => {
  const durationLeave = moment(props.leaveTo).diff(moment(props.leaveFrom), 'days') + 1;
  const leaveFromFormatted = moment(props.leaveFrom, ATTENDANCE_DATE_FORMAT).format(DATE_DISPLAY_FORMAT);

  const {t}: { t: any } = useTranslation();

  let dayLabel = String(t('modal.leaveModal.dayLabel'))

  let cardColor: string;
  let iconName: any;
  let ornamentImage: any;

  switch (props.status) {
    case "Open": {
      cardColor = "amber.500";
      iconName = "hourglass-start";
      ornamentImage = require("../../assets/leave-illustrations/ornament_dark_yellow.png");
      break;
    }
    case "Rejected": {
      cardColor = "red.500";
      iconName = "times";
      ornamentImage = require('../../assets/leave-illustrations/ornament_dark_red.png');
      break;
    }
    case "Approved": {
      cardColor = "primary.500";
      iconName = "check";
      ornamentImage = require('../../assets/leave-illustrations/ornament_dark_green.png');
      break;
    }
    default:
      cardColor = "muted.500";
      iconName = "times";
      ornamentImage = require("../../assets/leave-illustrations/ornament_dark_grey.png");
  }

  return (
    <Box
      flex={1}
      borderRadius={5}
      bg={cardColor}
      overflow="hidden"
    >
      <ImageBackground
        key={iconName}
        source={ornamentImage}
        style={{
          flex: 1,
          justifyContent: 'center',
        }}
        resizeMode="cover"
      >
        <HStack
          px={3}
          py={2}
          justifyContent="space-between"
        >
          <VStack justifyContent="space-between">
            <Text
              fontSize="lg"
              color="secondary.500"
              bold
            >
              {props.leaveType}
            </Text>
            <Text
              fontSize="xs"
              color="secondary.500"
              italic
            >
              {props.status}
            </Text>
            <HStack
              flex={1}
              space={2}
              mt={2}
              alignItems="center"
            >
              <Icon
                as={Feather}
                name="calendar"
                color="secondary.500"
                size={4}
              />
              <Text
                fontSize="sm"
                color="secondary.500"
                bold
              >
                {leaveFromFormatted + " (" + durationLeave + " " + dayLabel + ")"}
              </Text>
            </HStack>
          </VStack>
          <Center>
            <Icon
              as={FontAwesome}
              name={iconName}
              color="secondary.500"
              size="sm"
            />
          </Center>
        </HStack>
      </ImageBackground>
    </Box>
  );
};
