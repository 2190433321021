import React from "react";
import {Achievement} from "../state";
import {Button, Center, Heading, Image, Modal, Progress, Text, View, VStack} from "native-base";
import { useTranslation } from "react-i18next";
// ProgressView from https://github.com/react-native-community/progress-view produces an error when used in iOS,
// so for now stick with ProgressViewIOS

interface AchievementModalProps {
  isOpen: boolean;
  selectedAchievement?: Achievement;
  baseUrl: string;
  handleCloseAchievementModal: () => void;
}

const AchievementModal: React.FC<AchievementModalProps> = (props: AchievementModalProps) => {
  const selectedAchievement = props.selectedAchievement;
  const baseUrl = props.baseUrl;
  let currentProgress = props.selectedAchievement ? props.selectedAchievement.currentProgress : 0;
  let threshold = props.selectedAchievement ? props.selectedAchievement.threshold : 0;
  const isAchievementLocked = (currentProgress < threshold);

  const {t}: { t: any } = useTranslation();
  
  return (
    <React.Fragment>
      {
        selectedAchievement ?
          <Modal isOpen={props.isOpen}
                 overlayVisible={true}
                 closeOnOverlayClick={true}
                 onClose={props.handleCloseAchievementModal}
                 size={"xl"}
          >
            <Modal.Content bgColor={"secondary.400"}>
              <Modal.Body>
                <VStack pt={6}>
                  <View>
                    {
                      (!isAchievementLocked) ?
                        <Center>
                          <Image source={{uri: baseUrl.concat(selectedAchievement.icon)}}
                                 alt={"achievement-icon"}
                                 size={"xl"}
                          />
                        </Center>
                        :
                        <Center>
                          <Image source={{uri: baseUrl.concat(selectedAchievement.icon)}}
                                 alt={"achievement-icon"}
                                 size={"xl"}
                                 style={{
                                   alignSelf: "center",
                                   tintColor: 'gray'
                                 }}
                          />
                          <Image source={{uri: baseUrl.concat(selectedAchievement.icon)}}
                                 alt={"achievement-icon-locked"}
                                 size={"xl"}
                                 style={{
                                   alignSelf: "center",
                                   opacity: 0.2,
                                   position: "absolute"
                                 }}/>
                        </Center>
                    }
                  </View>
                  <VStack>
                    <Heading mt={4} textAlign={"center"}>
                      {selectedAchievement.achievementName}
                    </Heading>
                    <Text fontSize={"xs"} fontWeight={500} italic textAlign={"center"}>
                      {selectedAchievement.description}
                    </Text>
                    <Progress mt={6} colorScheme={"success"}
                              value={threshold > 0 ? 100 * Math.round(currentProgress) / threshold : 0}
                    />
                    <Text fontWeight={600} fontSize={"xl"} textAlign={"center"}>
                      {Math.round(currentProgress)} / {threshold}
                    </Text>
                  </VStack>
                </VStack>
              </Modal.Body>
              <Modal.Footer bgColor={"secondary.500"}>
                <Button colorScheme="danger"
                        size={"md"}
                        flex={1}
                        onPress={props.handleCloseAchievementModal}>
                  {String(t('button.closeButton'))}
                </Button>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
          :
          null
      }
    </React.Fragment>
  );
};

export default AchievementModal;
