import {CompanyRulesScreen} from "../screens/CompanyRulesScreen";
import {CompanyInformation, State} from "../state";
import {connect} from "react-redux";
import React from "react";
import {CommonActions, useNavigation} from "@react-navigation/native";

interface CompanyRulesScreenContainerProps {
  companyInformation?: CompanyInformation;
}

const mapStateToProps = (state: State) => {
  return {
    companyInformation: state.control.companyInformation,
  };
};

const CompanyRulesScreenContainer = (props: CompanyRulesScreenContainerProps) => {

  const navigation = useNavigation();

  const handleSectionOnPress = (sectionName: string) => {
    if (props.companyInformation) {
      const rulesDetail = sectionName === 'Vision And Mission' ?
        props.companyInformation.visionMission
        : props.companyInformation.codeOfConduct;
      navigation.dispatch(
        CommonActions.navigate('CompanyRulesDetailScreen', {
          rulesDetail: rulesDetail,
          sectionName: sectionName,
        })
      );
    }
  };

  return (
    <CompanyRulesScreen
      handleSectionOnPress={handleSectionOnPress}
    />
  );
};

export default connect(mapStateToProps)(CompanyRulesScreenContainer);
