import * as Notifications from "expo-notifications";
import {
  TAP_IN_NOTIFICATION_CHANNEL,
  TAP_NOTIFICATION_CHANNEL,
  TAP_OUT_NOTIFICATION_CHANNEL
} from "../master-data/constants";
import {Platform} from "react-native";

export const registerForPushNotificationsAsync = async () => {
  let token;
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;
  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== 'granted') {
    return Promise.reject("Notification is not granted");
  }
  token = (await Notifications.getExpoPushTokenAsync()).data;

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync(TAP_NOTIFICATION_CHANNEL, {
      name: TAP_NOTIFICATION_CHANNEL,
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#3B8183',
    });

    await Notifications.setNotificationChannelAsync(TAP_IN_NOTIFICATION_CHANNEL, {
      name: TAP_IN_NOTIFICATION_CHANNEL,
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#3B8183',
    });

    await Notifications.setNotificationChannelAsync(TAP_OUT_NOTIFICATION_CHANNEL, {
      name: TAP_OUT_NOTIFICATION_CHANNEL,
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#3B8183',
    });
  }

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    })
  });

  // TODO: (ti-20220621) delete this function when deeplink feature is ready
  await Notifications.getNotificationCategoriesAsync()
    .then(response => {
      if (response.length <= 0) return;
      response.forEach(async notificationCategory => {
        await Notifications.deleteNotificationCategoryAsync(notificationCategory.identifier);
      });
    });

  // TODO: (ti-20220621) will be enabled when deeplink feature is ready
  // await Notifications.setNotificationCategoryAsync(TAP_IN_NOTIFICATION_CHANNEL, [
  //   {
  //     buttonTitle: `Tap In`,
  //     identifier: TAP_IN_BUTTON,
  //     options: {
  //       opensAppToForeground: true,
  //     },
  //   },
  // ]);
  //
  // await Notifications.setNotificationCategoryAsync(TAP_OUT_NOTIFICATION_CHANNEL, [
  //   {
  //     buttonTitle: 'Tap Out',
  //     identifier: TAP_OUT_BUTTON,
  //     options: {
  //       opensAppToForeground: true,
  //     },
  //   },
  // ]);

  return token;
};
