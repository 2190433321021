import {useTranslation} from "react-i18next";
import {Button, Modal} from "native-base";
import React from "react";

interface DeleteConfirmationModalProps {
  title: string;
  description: string;
  isDeleteConfirmationModalOpen: boolean;
  handleCloseMenu: () => void;
  handleDeleteOnPress: () => void;
}

export const DeleteConfirmationModalComponent = (props: DeleteConfirmationModalProps) => {
  const {t}: { t: any } = useTranslation();
  return (
    <Modal
      isOpen={props.isDeleteConfirmationModalOpen}
      onClose={props.handleCloseMenu}
    >
      <Modal.Content
        bg="secondary.400"
      >
        <Modal.CloseButton />
        <Modal.Header>
          {t('modal.timesheetModal.deleteTimesheetHeader')}
        </Modal.Header>
        <Modal.Body>
          {t('modal.timesheetModal.deleteTimesheetConfirmation')}
        </Modal.Body>
        <Modal.Footer
          bg="secondary.400"
        >
          <Button
            mr={3}
            colorScheme="success"
            onPress={props.handleCloseMenu}
          >
            {t('button.cancelButton')}
          </Button>
          <Button
            variant="ghost"
            colorScheme="danger"
            onPress={props.handleDeleteOnPress}
          >
            {t('button.deleteButton')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
