import React, {useEffect, useState} from "react";
import {Platform} from "react-native";
import {connect} from "react-redux";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import moment from "moment";
import {DateData} from "react-native-calendars";
import * as Analytics from "expo-firebase-analytics";

// Actions
import {createOtherDateAttendance, fetchAttendances} from "../actions/actionsAttendance";

// Constants
import {
  DATE_DISPLAY_FORMAT_SHORT,
  formatDateYYYYMMDD,
  TIME_DISPLAY_FORMAT,
  TIME_FRAPPE_FORMAT
} from "../lib/dateFormat";

// Screens
import OtherDateScreen from "../screens/OtherDateScreen";

// States
import {Attendance, State, User} from "../state";

export interface OtherDateScreenContainerProps {
  dispatch: ThunkDispatch<State, {}, AnyAction>;
  attendances: Attendance[];
  currentUser?: User;
  isSavingOtherDate: boolean;
}

const mapStateToProps = (state: State) => {
  return {
    attendances: state.data.attendances,
    currentUser: state.control.currentUser,
    isSavingOtherDate: state.session.isSavingOtherDate,
  };
};

const OtherDateScreenContainer: React.FC<OtherDateScreenContainerProps> = (
  props: OtherDateScreenContainerProps
) => {
  const [selectedDate, setSelectedDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    day: new Date().getDate(),
    timestamp: new Date().getTimezoneOffset(),
    dateString: formatDateYYYYMMDD(new Date()),
  });
  const [selectedMonth, setSelectedMonth] = useState({
    firstDay: moment().startOf('month').format(DATE_DISPLAY_FORMAT_SHORT),
    lastDay: moment().endOf('month').format(DATE_DISPLAY_FORMAT_SHORT),
  });

  const initialState = {
    isStartTimeFromServerExist: false,
    isEndTimeFromServerExist: false,
    isStartTimePickerVisible: false,
    isEndTimePickerVisible: false,
    selectedStartTime: "",
    selectedEndTime: "",
    dateWithAttendances: [],
    isStartTimeError: false,
    isEndTimeError: false,
  };

  const [isStartTimeFromServerExist, setIsStartTimeFromServerExist] = useState(
    initialState.isStartTimeFromServerExist
  );
  const [isEndTimeFromServerExist, setIsEndTimeFromServerExist] = useState(
    initialState.isEndTimeFromServerExist
  );
  const [isStartTimePickerVisible, setStartTimePickerVisibility] = useState(
    initialState.isStartTimePickerVisible
  );
  const [isEndTimePickerVisible, setEndTimePickerVisibility] = useState(
    initialState.isEndTimePickerVisible
  );
  const [selectedStartTime, setSelectedStartTime] = useState(
    initialState.selectedStartTime
  );
  const [selectedEndTime, setSelectedEndTime] = useState(
    initialState.selectedEndTime
  );
  const [dateWithAttendances, setDateWithAttendances] = useState<string[]>(
    initialState.dateWithAttendances
  );
  const [isStartTimeError, setIsStartTimeError] = useState(
    initialState.isStartTimeError
  );
  const [isEndTimeError, setIsEndTimeError] = useState(
    initialState.isEndTimeError
  );

  const attendances = props.attendances;
  const dispatch = props.dispatch;
  const currentUser = props.currentUser;
  const isFeatureAvailable = !!props.currentUser?.featureList.attendance?.otherDate;

  useEffect(() => {
    Analytics.setCurrentScreen("Other Date Screen", "Other Date Screen");
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchAttendances(
        currentUser.employeeName,
        selectedMonth,
      ));
    }
  }, [dispatch, currentUser, selectedMonth]);

  useEffect(() => {
    if (attendances.length > 0) {
      let dateStrings: string[] = [];
      attendances.forEach((attendance: Attendance) => {
        dateStrings.push(attendance.attendanceDate);
      });
      setDateWithAttendances(dateStrings);
    }
  }, [attendances]);

  useEffect(() => {
    getAttendanceOnSelectedDate(selectedDate.dateString);
  }, [attendances]);

  useEffect(() => {
    const selectedAttendance = getAttendanceOnSelectedDate(selectedDate.dateString);
    if (!selectedAttendance) {
      setSelectedStartTime(initialState.selectedStartTime);
      setSelectedEndTime(initialState.selectedEndTime);
      setIsStartTimeFromServerExist(initialState.isStartTimeFromServerExist);
      setIsEndTimeFromServerExist(initialState.isEndTimeFromServerExist);
    }
  }, [selectedDate]);

  const getAttendanceOnSelectedDate = (date: string) => {
    const selectedAttendance =  attendances.find(
      (attendance: Attendance) =>
        attendance.attendanceDate === date
    );
    if (selectedAttendance) {
      if (selectedAttendance.startTime) {
        setSelectedStartTime(
          moment(selectedAttendance.startTime, TIME_FRAPPE_FORMAT).format(
            TIME_DISPLAY_FORMAT
          )
        );
        setIsStartTimeFromServerExist(true);
      }
      if (selectedAttendance.endTime) {
        setSelectedEndTime(
          moment(selectedAttendance.endTime, TIME_FRAPPE_FORMAT).format(
            TIME_DISPLAY_FORMAT
          )
        );
        setIsEndTimeFromServerExist(true);
      }
    }
    return selectedAttendance;
  };

  const handleSelectedDateChange = (date: DateData) => {
    setSelectedDate(date);
    setIsStartTimeError(initialState.isStartTimeError);
    setIsEndTimeError(initialState.isEndTimeError);
  };

  const handleOnMonthChange = (date: DateData) => {
    setSelectedMonth({
      firstDay: moment(date.dateString).startOf('month').format(DATE_DISPLAY_FORMAT_SHORT),
      lastDay: moment(date.dateString).endOf('month').format(DATE_DISPLAY_FORMAT_SHORT),
    });
    setIsStartTimeError(initialState.isStartTimeError);
    setIsEndTimeError(initialState.isEndTimeError);
  };

  const showStartTimePicker = () => {
    setStartTimePickerVisibility(true);
  };

  const hideStartTimePicker = () => {
    setStartTimePickerVisibility(false);
  };

  const handleStartTimeConfirm = (time: Date) => {
    // this seems to be a bug from datetimepicker lib.
    // without this line, the datepicker will be displayed twice
    setStartTimePickerVisibility(Platform.OS === "ios");
    setSelectedStartTime(moment(time).format(TIME_DISPLAY_FORMAT));
    setIsStartTimeError(initialState.isStartTimeError);
    setIsEndTimeError(initialState.isEndTimeError);
    setStartTimePickerVisibility(false);
  };

  const showEndTimePicker = () => {
    setEndTimePickerVisibility(true);
  };

  const hideEndTimePicker = () => {
    setEndTimePickerVisibility(false);
  };

  const handleEndTimeConfirm = (time: Date) => {
    // this seems to be a bug from datetimepicker lib.
    // without this line, the datepicker will be displayed twice
    setEndTimePickerVisibility(Platform.OS === "ios");
    setSelectedEndTime(moment(time).format(TIME_DISPLAY_FORMAT));
    setIsStartTimeError(initialState.isStartTimeError);
    setIsEndTimeError(initialState.isEndTimeError);
    setEndTimePickerVisibility(false);
  };

  const validateFormData = (): boolean => {
    return !!selectedStartTime && !!selectedEndTime;
  };

  const displayWarningLabels = () => {
    setIsStartTimeError(!selectedStartTime);
    setIsEndTimeError(!selectedEndTime);
  };

  const handleSaveButtonClick = () => {
    const isValid = validateFormData();
    if (isValid) {
      if (currentUser) {
        let startTime = moment(selectedStartTime, TIME_DISPLAY_FORMAT);
        let endTime = moment(selectedEndTime, TIME_DISPLAY_FORMAT);
        let workingHours = endTime.diff(startTime, "hours", true);
        let attendanceStatus: string;
        if (workingHours > 6) {
          attendanceStatus = "Present";
        } else if (workingHours > 3) {
          attendanceStatus = "Half Day";
        } else {
          attendanceStatus = "Absent";
        }
        Analytics.logEvent("add_other_date_attendance");
        props.dispatch(
          createOtherDateAttendance(
            currentUser.employeeName,
            selectedDate.dateString,
            selectedStartTime,
            selectedEndTime,
            attendanceStatus
          )
        );
      }
    } else {
      displayWarningLabels();
    }
  };

  return (
    <OtherDateScreen
      selectedDate={selectedDate}
      selectedStartTime={selectedStartTime}
      selectedEndTime={selectedEndTime}
      isStartTimeFromServerExist={isStartTimeFromServerExist}
      isEndTimeFromServerExist={isEndTimeFromServerExist}
      isFeatureAvailable={isFeatureAvailable}
      hasAttendanceDates={dateWithAttendances}
      handleSelectedDateChange={handleSelectedDateChange}
      handleOnMonthChange={handleOnMonthChange}
      showStartTimePicker={showStartTimePicker}
      showEndTimePicker={showEndTimePicker}
      hideStartTimePicker={hideStartTimePicker}
      hideEndTimePicker={hideEndTimePicker}
      handleConfirmStartTimePicker={handleStartTimeConfirm}
      handleConfirmEndTimePicker={handleEndTimeConfirm}
      handleSaveButtonClick={handleSaveButtonClick}
      isSavingOtherDate={props.isSavingOtherDate}
      isStartTimePickerVisible={isStartTimePickerVisible}
      isEndTimePickerVisible={isEndTimePickerVisible}
      isStartTimeError={isStartTimeError}
      isEndTimeError={isEndTimeError}
    />
  );
};
export default connect(mapStateToProps)(OtherDateScreenContainer);
