import moment from "moment";
import {TIME_DISPLAY_FORMAT} from "../lib/dateFormat";
import {Box, Heading, HStack, PresenceTransition, Text, VStack} from "native-base";
import React, {useEffect, useState} from "react";
import {Attendance, AttendanceBreak, Timelog} from "../state";
import {useTranslation} from "react-i18next";

interface TimeSheetToWorkingTimeProgressBarProps {
  currentAttendance: Attendance;
  attendanceBreaksList: AttendanceBreak[];
  timeLogs: Timelog[];
  currentServerTime: string;
  isOpen: boolean;
}

interface ProgressionBarComponentProps {
  timeLogHours: number;
  timeLogMinutes: number;
  workingHours: number;
  workingMinutes: number;
  percentagesOfWorkTimeAndTimeLog: number;
  isOpen: boolean;
}

const ProgressionBarComponent = (props: ProgressionBarComponentProps) => {
  const {t}: { t: any } = useTranslation();

  const totalWorkingTimeHours = t('tapOutBottomSheet.workingDuration', {
    hours: props.workingHours,
    minutes: props.workingMinutes,
  });
  const totalTimeLogsHours = t('tapOutBottomSheet.workingDuration', {
    hours: props.timeLogHours,
    minutes: props.timeLogMinutes,
  });

  return (
    <Box
      borderRadius={8}
      h={12}
      w="full"
      bg="coolGray.300"
      overflow="hidden"
    >
      <PresenceTransition
        visible={props.isOpen}
        initial={{
          translateX: -props.percentagesOfWorkTimeAndTimeLog * 10,
        }}
        animate={{
          translateX: 0,
          transition: {
            duration: 1000,
          }
        }}
      >
        <Box
          h="full"
          borderRadius={8}
          style={{
            width: `${props.percentagesOfWorkTimeAndTimeLog}%`,
          }}
          bg="success.500"
          alignItems="flex-end"
          justifyContent="flex-end"
        />
      </PresenceTransition>
      <HStack
        px={3}
        w="full"
        h="full"
        position="absolute"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading
          fontSize="sm"
          color="darkText"
        >
          {totalTimeLogsHours}
        </Heading>
        <Heading
          fontSize="sm"
          color="darkText"
        >
          {totalWorkingTimeHours}
        </Heading>
      </HStack>
    </Box>
  );
};

export const TimeSheetToWorkingTimeProgressBar = (props: TimeSheetToWorkingTimeProgressBarProps) => {
  const {t}: { t: any } = useTranslation();
  const [totalBreakHours, setTotalBreakHours] = useState(0);
  const [workingHours, setWorkingHours] = useState(0);
  const [workingMinutes, setWorkingMinutes] = useState(0);
  const [timeLogHours, setTimeLogHours] = useState(0);
  const [timeLogMinutes, setTimeLogMinutes] = useState(0);
  const [workingTimeInMinutes, setWorkingTimeInMinutes] = useState(0);
  const [timeLogsInMinutes, setTimeLogsInMinutes] = useState(0);

  useEffect(() => {
    if (props.isOpen) {
      const startTime = moment(props.currentAttendance.startTime, TIME_DISPLAY_FORMAT);
      const endTime = moment(props.currentServerTime, TIME_DISPLAY_FORMAT);
      const submittedEndTime = moment(props.currentAttendance.endTime, TIME_DISPLAY_FORMAT);
      let workingDuration = moment.duration(
        endTime.diff(startTime) - totalBreakHours * 3600000
      );
      if (props.currentAttendance.docstatus === 1) {
        workingDuration = moment.duration(
          submittedEndTime.diff(startTime) - totalBreakHours * 3600000
        );
      }

      let totalTimeLogsHours = 0;
      props.timeLogs.forEach(timeLog => {
        totalTimeLogsHours += timeLog.duration;
      });
      const calculateTimeLogHours = Math.floor(totalTimeLogsHours);
      const calculateTimeLogMinutes = (totalTimeLogsHours % 1) * 60;
      setTimeLogHours(calculateTimeLogHours);
      setTimeLogMinutes(calculateTimeLogMinutes);
      setWorkingHours(parseInt(String(workingDuration.asHours())));
      setWorkingMinutes(parseInt(String(workingDuration.minutes() % 60)));
      return;
    }
  }, [props.currentAttendance, props.isOpen, props.timeLogs, props.currentServerTime, totalBreakHours]);

  useEffect(() => {
    if (props.isOpen) {
      setWorkingTimeInMinutes((workingHours * 60) + workingMinutes);
      setTimeLogsInMinutes((timeLogHours * 60) + timeLogMinutes);
    }
  }, [workingHours, workingMinutes, timeLogHours, timeLogMinutes, props.isOpen]);

  useEffect(() => {
    if (props.isOpen) {
      let totalHours = 0;
      props.attendanceBreaksList.forEach((attendanceBreak: AttendanceBreak) => {
        totalHours += attendanceBreak.totalHours;
      });
      setTotalBreakHours(totalHours);
    }
  }, [props.attendanceBreaksList, props.isOpen]);

  const calculatePercentage = (timeLogs: number, workingTime: number) => {
    if (timeLogs >= workingTime) {
      return 100;
    }
    return Math.floor((timeLogs / workingTime) * 100);
  };

  return (
    <VStack space="xs">
      <HStack alignItems="center">
        <HStack
          space={1}
          alignItems="flex-start"
        >
          <Text
            fontSize="md"
            color="success.500"
          >
            {t("tapOutBottomSheet.timesheet")}
          </Text>
          <Text
            fontSize="md"
            color="success.500"
            bold
          >
            ({calculatePercentage(timeLogsInMinutes, workingTimeInMinutes)}%)
          </Text>
        </HStack>
        <Box
          flex={1}
          alignItems="flex-end"
        >
          <Text
            fontSize="md"
            color="darkText"
          >
            {t("tapOutBottomSheet.workingTime")}
          </Text>
        </Box>
      </HStack>
      <ProgressionBarComponent
        workingHours={workingHours}
        workingMinutes={workingMinutes}
        timeLogHours={timeLogHours}
        timeLogMinutes={timeLogMinutes}
        percentagesOfWorkTimeAndTimeLog={calculatePercentage(timeLogsInMinutes, workingTimeInMinutes)}
        isOpen={props.isOpen}
      />
    </VStack>
  );
};
