import React from "react";
import {createStackNavigator} from "@react-navigation/stack";
import {ActivityScreenContainer} from "../Containers/ActivityScreenContainer";
import {Heading, useTheme} from 'native-base';
import {StyleProp, ViewStyle} from "react-native";
import { useTranslation } from "react-i18next";

export const ActivityStackNavigator = () => {
  const ActivityStack = createStackNavigator();
  const theme = useTheme();
  const {t}: { t: any } = useTranslation();

  const headerStyle: StyleProp<ViewStyle> = {
    backgroundColor: theme.colors.secondary[500],
    borderBottomColor: theme.colors.primary[500],
    shadowColor: theme.colors.secondary[500],
  }


  return (
    <ActivityStack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
      }}
    >
      <ActivityStack.Screen name="ActivityScreen"
                            options={{
                              headerTitle: () =>
                                <Heading
                                  color="primary.500"
                                  fontWeight={600}
                                >
                                  {String(t('stackHeader.activityScreen'))}
                                </Heading>,
                              headerStyle: headerStyle,
                            }}
                            component={ActivityScreenContainer}/>
    </ActivityStack.Navigator>
  )
}
