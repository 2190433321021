import React, {useCallback, useEffect} from "react";
import {MonthlyInfo, State, User} from "../state";
import {ListRenderItem, ListRenderItemInfo} from "react-native";
import {Avatar, Center, FlatList, Flex, Heading, HStack, Image, Text, VStack} from 'native-base';
import {fetchMonthlyInfoList} from "../actions/actionsMonthlyInfo";
import {connect, useDispatch, useSelector} from "react-redux";
import {LoadingComponentFullScreen} from "../Components/LoadingComponent";
import * as Analytics from "expo-firebase-analytics";

interface LeaderboardScreenContainerProps {
  isRefreshing: boolean;
  monthlyInfoList: MonthlyInfo[];
  isFetchingMonthlyInfos: boolean;
  currentUser?: User;
}

const mapStateToProps = (state: State) => {
  return {
    currentUser: state.control.currentUser,
    isRefreshing: state.session.isRefreshing,
    monthlyInfoList: state.data.monthlyInfos,
    isFetchingMonthlyInfos: state.session.monthlyInfos.isFetching
  }
}

const LeaderboardScreenContainer: React.FC<LeaderboardScreenContainerProps> = (props: LeaderboardScreenContainerProps) => {
  const isRefreshing = props.isRefreshing;
  const monthlyInfoList = props.monthlyInfoList;
  const isFetchingMonthlyInfos = props.isFetchingMonthlyInfos;

  const gamificationFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.gamification);
  const pointSystemFeatureStatus = gamificationFeatureStatus ? gamificationFeatureStatus.pointSystemEnabled : null

  const dispatch = useDispatch();

  useEffect(() => {
    Analytics.setCurrentScreen("Leaderboard Screen", "Leaderboard Screen");
  }, []);

  useEffect(() => {
    handleScreenRefresh();
  }, [props.currentUser, pointSystemFeatureStatus]);

  const handleScreenRefresh = useCallback(() => {
    if (pointSystemFeatureStatus && props.currentUser) {
      dispatch(fetchMonthlyInfoList(props.currentUser.company, props.currentUser.branch));
    }
  }, [props.currentUser, pointSystemFeatureStatus, monthlyInfoList]);

  const renderMonthlyInfoRows: ListRenderItem<MonthlyInfo> = (data: ListRenderItemInfo<MonthlyInfo>) => {
    const leaderboard1 = require('../assets-directory/leaderboard-icons/leaderboard-01.png');
    const leaderboard2 = require('../assets-directory/leaderboard-icons/leaderboard-02.png');
    const leaderboard3 = require('../assets-directory/leaderboard-icons/leaderboard-03.png');

    const monthlyInfo = data.item;
    const index = data.index;

    return (
      <HStack key={index} mt={2} pb={1} h={50}
              bg={ monthlyInfo.employeeFullName === props.currentUser?.employeeFullName ?
                    'secondary_transparent.100'
                  :
                    null
                  }
      >
        <Flex ml={2} w={10}>
          <Center h={"full"}>
            {
              (index === 0) ?
                <Image size={"xs"}
                       alt={"no-1"}
                       source={leaderboard1}/>
                :
                (index === 1) ?
                  <Image size={"xs"}
                         alt={"no-2"}
                         source={leaderboard2}/>
                  :
                  (index === 2) ?
                    <Image size={"xs"}
                           alt={"no-3"}
                           source={leaderboard3}/>
                    :

                    <Text fontSize={"2xl"} fontWeight={600} lineHeight={"xl"}>
                      {index + 1}
                    </Text>
            }
          </Center>
        </Flex>
        <Center>
          <Avatar key={monthlyInfo.employeeProfileImageUrl}
                  source={ monthlyInfo.employeeProfileImageUrl !== "" ?
                          { uri: monthlyInfo.employeeProfileImageUrl }
                          : require('../../assets/logo-gram-03.png')
                        }
                  bg={"primary.500"}
                  size={10}
                  ml={2}
          >
            <Avatar.Badge borderColor={"white"}
                          borderWidth={1}
                          bgColor={
                            (monthlyInfo.isOnline === 1) ?
                              (monthlyInfo.isBreak === 1) ?
                                "success.500"
                                :
                                "emerald.500"
                              :
                              "warmGray.500"
                          }
            />
          </Avatar>
        </Center>
        <Flex flex={1} justifyContent={"space-around"} mr={1}>
          <Text isTruncated={true} maxW={"full"} fontSize={"lg"} ml={2}>
            {monthlyInfo.employeeFullName}
          </Text>
        </Flex>
        {
          pointSystemFeatureStatus ?
            <VStack mt={"auto"} ml={"auto"} mr={4} space={0}>
              <Heading textAlign={"center"}>
                {monthlyInfo.point}
              </Heading>
              <Text fontSize={"2xs"} lineHeight={"2xs"} fontWeight={600} textAlign={"center"}>
                POINTS
              </Text>
            </VStack>
          :
            null
        }
      </HStack>
    )
  };

  return (
    <React.Fragment>
      {
        isFetchingMonthlyInfos ?
          <LoadingComponentFullScreen/>
          :
          null
      }
      <FlatList data={props.monthlyInfoList}
                renderItem={renderMonthlyInfoRows}
                keyExtractor={(item, index) => {return String(index)}}
                refreshing={isRefreshing}
                initialNumToRender={12}
                onRefresh={handleScreenRefresh}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(LeaderboardScreenContainer);
