import React, {useEffect} from "react";
import {PerformanceAttribute, State, TimesheetDetail, User} from "../state";
import {connect, useDispatch} from "react-redux";
import {getUserInfo} from "../actions/actionsAuth";
import {ProfileScreen} from "../screens/ProfileScreen";
import {fetchPerformanceAttribute} from "../actions/actionGamification";
import {LoadingComponentFullScreen} from "../Components/LoadingComponent";
import * as Analytics from "expo-firebase-analytics";
import { Center } from "native-base";
import {CommonActions, useNavigation} from "@react-navigation/native";
import {fetchTimesheetDetail} from "../actions/actionsTimesheet";
import moment from "moment";

interface ProfileScreenContainerProps {
  isFetchingPerformanceAttribute: boolean;
  isGettingUserInfo: boolean;
  isUploadingImage: boolean;
  isFetchingTimesheetDetail: boolean;
  currentUser?: User;
  timesheetDetail?: TimesheetDetail;
  currentPerformanceAttribute?: PerformanceAttribute;
}

const mapStateToProps = (state: State) => {
  return {
    isLoggingOut: state.session.isLoggingOut,
    isFetchingPerformanceAttribute: state.session.performanceAttribute.isFetching,
    isGettingUserInfo: state.session.isGettingUserInfo,
    isUploadingImage: state.session.isUploadingImage,
    isFetchingTimesheetDetail: state.session.timesheetDetail.isFetching,
    currentUser: state.control.currentUser,
    timesheetDetail: state.control.timesheetDetail,
    currentPerformanceAttribute: state.control.currentPerformanceAttribute
  }
}

const ProfileScreenContainer = (props: ProfileScreenContainerProps) => {
  const SCREEN_NAME = "Profile Screen";
  const isFetchingPerformanceAttribute = props.isFetchingPerformanceAttribute;
  const isGettingUserInfo = props.isGettingUserInfo;
  const isUploadingImage = props.isUploadingImage;
  const isFetchingTimesheetDetail = props.isFetchingTimesheetDetail;
  const currentUser = props.currentUser;
  const timesheetDetail = props.timesheetDetail;
  const hasTimesheetFeature = currentUser ? !!currentUser.featureList.timesheet : false;

  const performanceAttribute = props.currentPerformanceAttribute;
  const totalWorkHour = performanceAttribute?.totalWorkHour;
  const totalEarlyEntry = performanceAttribute?.totalEarlyEntry;
  const totalLateLeave = performanceAttribute?.totalLateLeave;
  const totalFullDay = performanceAttribute?.totalFullDay;

  const dispatch = useDispatch();
  const navigation = useNavigation();

  useEffect(() => {
    Analytics.setCurrentScreen(SCREEN_NAME, SCREEN_NAME);
  }, []);

  useEffect(() => {
    if (currentUser && !performanceAttribute) {
      dispatch(fetchPerformanceAttribute(currentUser.employeeName));
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (currentUser && hasTimesheetFeature) {
      dispatch(fetchTimesheetDetail(currentUser.employeeName, moment()));
    }
  }, [currentUser, hasTimesheetFeature]);

  const handleScreenRefresh = () => {
    if (currentUser) {
      dispatch(fetchPerformanceAttribute(currentUser.employeeName));
      dispatch(getUserInfo(currentUser.username));
    }
    if (currentUser && hasTimesheetFeature) {
      dispatch(fetchTimesheetDetail(currentUser.employeeName, moment()));
    }
  };

  const handleChangeScreen = (screenName: string) => {
    navigation.dispatch(
      CommonActions.navigate(screenName)
    );
  };

  return (
    <React.Fragment>
      { isFetchingPerformanceAttribute
      || isGettingUserInfo
      || isUploadingImage
      || isFetchingTimesheetDetail ?
        <Center bg={"secondary.500"} h={"full"} flex={1}>
          <LoadingComponentFullScreen size="lg" />
        </Center>
        :
        <ProfileScreen
          isRefreshing={isFetchingPerformanceAttribute}
          hasTimesheetFeature={hasTimesheetFeature}
          currentUser={currentUser}
          timesheetDetail={timesheetDetail}
          totalWorkHour={totalWorkHour ? totalWorkHour : 0}
          totalEarlyEntry={totalEarlyEntry ? totalEarlyEntry : 0}
          totalLateLeave={totalLateLeave ? totalLateLeave : 0}
          totalFullDay={totalFullDay ? totalFullDay : 0}
          handleScreenRefresh={handleScreenRefresh}
          handleChangeScreen={handleChangeScreen}
        />
      }
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(ProfileScreenContainer);
