import * as SecureStore from 'expo-secure-store';
import {addApiKey, updateGettingApiKeyFromSecureStoreStatus} from "../actions/actionsAuth";
import axios from "axios";
import {Store} from "redux";

export const extractApiKeyFromSecureStore = (store: Store) => {
  const dispatch = store.dispatch;

  SecureStore.getItemAsync("apiKey").then((result) => {
    dispatch(updateGettingApiKeyFromSecureStoreStatus(true));
    if (result) {
      axios.defaults.headers.common['Authorization'] = result;
      return Promise.resolve(dispatch(addApiKey(result)));
    } else {
      return Promise.reject("Unable to get API Key from the secure store");
    }
  })
    .then(() => {
      dispatch(updateGettingApiKeyFromSecureStoreStatus(false));
    })
    .catch(() => {
      dispatch(updateGettingApiKeyFromSecureStoreStatus(false));
    })
}
