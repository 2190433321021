import React from "react";
import {connect, useDispatch} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import {CompanyData, State} from "../state";
import {
  Avatar,
  Button,
  Center,
  Flex,
  HStack,
  Icon, IconButton,
  ScrollView,
  Text,
  useContrastText,
  VStack
} from "native-base";
import { updateSelectedCompany} from "../actions/actionsAuth";
import {Entypo } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";

interface CompanyListScreen {
  selectedCompany?: CompanyData;
  companyData: CompanyData[];
}

const mapStateToProps = (state: State) => {
  return {
    selectedCompany: state.control.selectedCompany,
    companyData: state.control.companyData,
  }
};

const CompanyListScreen = (props: CompanyListScreen) => {

  const colorContrastLight = useContrastText("primary.100");
  const dispatch = useDispatch();
  const navigation: any = useNavigation();
  const {t}: { t: any } = useTranslation();

  let loginScreenSelectedCompany = props.selectedCompany;
  let companyData = props.companyData;

  const handleSelectCompany = (selectedCompany: CompanyData) => {
      Promise.resolve(dispatch(updateSelectedCompany(selectedCompany)))
        .then(() => {
          navigation.navigate("LoginScreen" as never);
        })
  };

  const handleAddCompany = () => {
    navigation.navigate('JoinCompanyScreen');
  };

  const renderCompanyList = (singleCompanies: CompanyData[]) => {
    let singleCompanyElements: JSX.Element[] = [];
    singleCompanies.forEach((singleCompany: CompanyData) => {
      singleCompanyElements.push(
        <Flex 
          key={singleCompany.companyId} 
          borderWidth={2} 
          borderRadius={5}
          mb={5} 
          borderColor={ loginScreenSelectedCompany === singleCompany ? "primary.500" : "muted.400"}
          pr={4}
        >
          <Button
            variant="solid"
            bg={"secondary.500"}
            onPress={() => handleSelectCompany(singleCompany)}
            justifyContent="space-between" 
          >
            <HStack>
              <Avatar 
                key={singleCompany.companyImage}
                source={singleCompany.companyImage !== ""? 
                  {uri: singleCompany.companyImage} : require('../../assets/logo-gram-03.png')
                }
                bg={"primary.500"}
                size={"md"}
                my={2}
              />
              <VStack ml={3} mr={5} my={2} w={"250px"}justifyContent="space-evenly" safeAreaX={true}>
                <Text color={colorContrastLight} fontWeight="bold" fontSize={"md"} isTruncated>{singleCompany.companyName}</Text>
                <Text color={colorContrastLight} isTruncated>{singleCompany.branchName}</Text>
              </VStack>
            </HStack>
          </Button>
        </Flex>
      )
    })
    return singleCompanyElements
  }

  return (
    <React.Fragment>
      <Center bg={"secondary.500"} flex={1}>
        <VStack w={"full"} flex={1}>
          <ScrollView paddingTop={8} paddingBottom={5} px={"5"}>
            {companyData && Object.keys(companyData).length > 0 ?
              renderCompanyList(companyData) 
              : 
              <Text>{String(t('companyListScreen.noCompanyAddedText'))}</Text>
            }
          </ScrollView>
            <IconButton 
              icon={
                <Icon 
                  as={Entypo} 
                  name="circle-with-plus" 
                  mb={5} 
                  size={16} 
                  color={"primary.500"}
                />
              } 
              onPress={handleAddCompany} 
              position={"absolute"} 
              right={2} 
              bottom={2}
            />
        </VStack>
      </Center>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(CompanyListScreen);
