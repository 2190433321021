import React from "react";
import {Spinner} from "native-base";
import { ResponsiveValue } from "styled-system";

interface LoadingComponentFullScreenProps {
  size?: ResponsiveValue<"sm" | "lg">
}

export const LoadingComponentFullScreen = (props: LoadingComponentFullScreenProps) => {
  return (
    <Spinner size={props.size? props.size : "lg"}
             color={"primary.500"}
             position={'absolute'}
             opacity={0.3}
             bottom={0}
             top={0}
             zIndex={10}
             flex={1}
    />
  )
}