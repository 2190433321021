import {Attendance, State} from "../state";
import {useTranslation} from "react-i18next";
import {Box, Button, Divider, Heading, HStack, Icon, Text, VStack} from "native-base";
import {Feather} from "@expo/vector-icons";
import React from "react";
import moment from "moment";
import {useSelector} from "react-redux";

interface AttendanceProps {
  currentAttendance?: Attendance;
  isCheckingIn: boolean;
  isCheckingOut: boolean;
  isBreakingIn: boolean;
  isBreakingOut: boolean;
  isFetchingLocation: boolean;
  handleCheckInClick: () => void;
  handleCheckOutClick: () => void;
  handleTakeABreakButtonPress: () => void;
  handleBackToWorkButtonPress: () => void;
}

export const AttendanceComponent = (props: AttendanceProps) => {
  const currentAttendanceBreak = useSelector((state: State) => state.control.currentAttendanceBreak);
  const attendanceFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.attendance);
  const takeABreakFeatureStatus = attendanceFeatureStatus && attendanceFeatureStatus.takeABreak;

  const {t}: { t: any } = useTranslation();
  return (
    <Box
      flex={1}
      bg="secondary.500"
      borderRadius={10}
      shadow={5}
      p={4}
    >
      <VStack
        space="md"
      >
        <Heading
          fontSize="lg"
        >
          {String(t('homeScreen.attendanceMenuLabel')).toUpperCase()}
        </Heading>
        {
          (!props.currentAttendance) ?
            <HStack
              space="xs"
              alignItems="center"
            >
              <Icon
                as={<Feather name="clock" />}
                size="xs"
                color="primary.500"
              />
              <Text
                fontSize="sm"
                color="darkText"
              >
                {t('homeScreen.haveNotTapIn')}
              </Text>
            </HStack>
            :
            <VStack space="xs">
              <HStack
                space="xs"
                alignItems="center"
              >
                <Icon
                  as={<Feather name="clock" />}
                  size="xs"
                  color="primary.500"
                />
                <HStack
                  space="xs"
                  alignItems="center"
                >
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    color="primary.500"
                  >
                    TAP IN
                  </Text>
                  <Text
                    fontSize="sm"
                    color="darkText"
                  >
                    {moment(props.currentAttendance.startTime, ('hh:mm:ss')).format('hh:mm A')}
                  </Text>
                  <Text
                    fontSize="sm"
                    color="darkText"
                  >
                    {moment(props.currentAttendance.attendanceDate).format('DD MMMM YYYY')}
                  </Text>
                </HStack>
              </HStack>
              {
                (props.currentAttendance.docstatus !== 0) &&
                <>
                  <Divider
                    borderColor="muted.300"
                    borderWidth={0.5}
                    mt={2}
                    mb={3}
                  />
                  <HStack
                    space="xs"
                    alignItems="center"
                  >
                    <Icon
                      as={<Feather name="clock" />}
                      size="xs"
                      color="danger.500"
                    />
                    <HStack
                      space="xs"
                      alignItems="center"
                    >
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        color="danger.500"
                      >
                        TAP OUT
                      </Text>
                      <Text
                        fontSize="sm"
                        color="darkText"
                      >
                        {moment(props.currentAttendance.endTime, ('hh:mm:ss')).format('hh:mm A')}
                      </Text>
                      <Text
                        fontSize="sm"
                        color="darkText"
                      >
                        {moment(props.currentAttendance.attendanceDate).format('DD MMMM YYYY')}
                      </Text>
                    </HStack>
                  </HStack>
                </>
              }
            </VStack>
        }
        {
          (!props.currentAttendance) ?
            <Button
              colorScheme="primary"
              size="md"
              _text={{
                fontFamily: "heading",
              }}
              isLoading={props.isCheckingIn || props.isFetchingLocation}
              onPress={props.handleCheckInClick}
            >
              TAP IN
            </Button>
            :
            (props.currentAttendance.docstatus === 0) ?
              <HStack
                flex={1}
                space="sm"
              >
                {
                  takeABreakFeatureStatus ?
                    currentAttendanceBreak ?
                      <Button
                        flex={1}
                        colorScheme="success"
                        size="md"
                        _text={{
                          fontFamily: "heading",
                        }}
                        isDisabled={props.isCheckingOut || props.isFetchingLocation}
                        isLoading={props.isBreakingOut}
                        onPress={props.handleBackToWorkButtonPress}
                      >
                        {String(t('attendanceScreen.backToWorkButton'))}
                      </Button>
                      :
                      <Button
                        flex={1}
                        colorScheme="success"
                        size="md"
                        _text={{
                          fontFamily: "heading",
                        }}
                        isDisabled={props.isCheckingOut || props.isFetchingLocation}
                        isLoading={props.isBreakingIn}
                        onPress={props.handleTakeABreakButtonPress}
                      >
                        {String(t('attendanceScreen.takeABreakButton'))}
                      </Button>
                    :
                    null
                }
                <Button
                  flex={1}
                  colorScheme="danger"
                  size="md"
                  _text={{
                    fontFamily: "heading",
                  }}
                  isDisabled={props.isBreakingIn || props.isBreakingOut}
                  isLoading={props.isCheckingOut || props.isFetchingLocation}
                  onPress={props.handleCheckOutClick}
                >
                  TAP OUT
                </Button>
              </HStack>
              :
              <Button
                colorScheme="dark"
                opacity={0.5}
                size="md"
                disabled
                _text={{
                  fontFamily: "heading",
                  fontWeight: "bold",
                }}
              >
                {String(t('homeScreen.checkOutStatus'))}
              </Button>
        }
      </VStack>
    </Box>
  );
};
