import {ImageProps, RefreshControl} from "react-native";
import {
  Avatar,
  Box,
  Flex,
  Heading,
  HStack, Icon,
  Image, Pressable,
  ScrollView,
  Spinner,
  Text, useTheme,
  VStack
} from "native-base";
import React from "react";
import {Attendance, CompanyData, State, Timelog} from "../state";
import { SingleMenu } from "../Components/SingleMenu";
import { TimelogList } from "../Components/TimelogList";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {AttendanceComponent} from "../Components/AttendanceComponent";
import {Feather} from "@expo/vector-icons";

interface HomeScreenProps {
  greetingText: string;
  username: string;
  profileImageUrl: string;
  designation: string;
  designationIcon: string;
  currentAttendance?: Attendance;
  selectedCompany?: CompanyData;
  currentTimelogs: Timelog[];
  attendanceIcon: ImageProps;
  leaveApplicationIcon: ImageProps;
  timesheetIcon: ImageProps;
  isCheckingIn: boolean;
  isCheckingOut: boolean;
  isBreakingIn: boolean;
  isBreakingOut: boolean;
  isFetchingLocation: boolean;
  isFetchingTimesheet: boolean;
  currentLanguage: string;
  languages: string[];
  handleCheckInClick: () => void;
  handleCheckOutClick: () => void;
  handleCreateTimelog: () => void;
  handleChangeScreenOnPress: (screenName: string) => void;
  handleTakeABreakButtonPress: () => void;
  handleBackToWorkButtonPress: () => void;
  handleSingleTimesheetOnPress: (timeLog: Timelog) => void;
  handleRefresh: () => void;
  handleSelectLanguage: (language: string) => void;
}

interface HeaderComponentProps {
  selectedCompany?: CompanyData;
  greetingText: string;
  username: string;
  profileImageUrl: string;
  designation: string;
  designationIcon: string;
  handleChangeScreenOnPress: (screenName: string) => void;
}

const HeaderComponent = (props: HeaderComponentProps) => {
  return (
    <VStack
      mt={4}
      space="lg"
    >
      <HStack
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Heading
            color="secondary.500"
            fontSize="md"
            isTruncated
            noOfLines={1}
          >
            {`${props.selectedCompany?.companyName} - ${props.selectedCompany?.branchName}`}
          </Heading>
        </Box>
        <Pressable
          p={2}
          borderRadius={5}
          onPress={() => props.handleChangeScreenOnPress("CompanyScreen")}
        >
          <Icon
            as={Feather}
            name="info"
            color="secondary.500"
            size="sm"
          />
        </Pressable>
      </HStack>
      <HStack space="md">
        <Avatar
          size={20}
          source={props.profileImageUrl !== "" ?
            {uri: props.profileImageUrl}
            : require('../../assets/logo-gram-03.png')}
        />
        <VStack
          flex={1}
          justifyContent="space-between"
        >
          <Box>
            <Text
              color="secondary.500"
              fontSize="md"
            >
              {props.greetingText}
            </Text>
          </Box>
          <Box>
            <Text
              color="secondary.500"
              fontFamily="body"
              bold
              fontSize="xl"
              isTruncated
              numberOfLines={1}
            >
              {props.username}
            </Text>
          </Box>
          <HStack>
            <Image
              source={{uri: props.designationIcon}}
              size='2xs'
              alt='designation-logo'
            />
            <Text
              color="secondary.500"
              fontFamily="body"
              fontSize="md"
              noOfLines={1}
              isTruncated={true}
              ml={2}
            >
              {props.designation}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
};

export const HomeScreen = (props: HomeScreenProps) => {

  const attendanceFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.attendance);
  const timeSheetFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.timesheet);
  const leaveApplicationFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.leaveApplication);

  const {t}: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Flex
      flex={1}
      bgColor="primary.500"
      safeAreaTop
    >
      <VStack
        w="full"
        space="sm"
        px={{base: 6, md: 16, lg: 24}}
        mb={8}
      >
        <HeaderComponent
          selectedCompany={props.selectedCompany}
          greetingText={props.greetingText}
          username={props.username}
          profileImageUrl={props.profileImageUrl}
          designation={props.designation}
          designationIcon={props.designationIcon}
          handleChangeScreenOnPress={props.handleChangeScreenOnPress}
        />
      </VStack>
      <ScrollView
        bg={"secondary.500"}
        borderTopRadius={{base: 14}}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={props.handleRefresh}
            tintColor={theme.colors.primary[500]}
            titleColor={theme.colors.primary[500]}
            title={t('scrollView.pullToRefresh')}
          />
        }
      >
        <VStack
          mt={4}
          flex={1}
          space="lg"
        >
          <Flex
            w="full"
            justifyContent="space-around"
            flexDirection="row"
            px={{base: 6, md: 16, lg: 24}}
          >
            { attendanceFeatureStatus &&
              <SingleMenu
                icon={props.attendanceIcon}
                label={String(t('homeScreen.attendanceMenuLabel'))}
                handleMenuPress={() => props.handleChangeScreenOnPress("OtherDate")}
              />
            }
            { timeSheetFeatureStatus &&
              <SingleMenu
                icon={props.timesheetIcon}
                label={String(t('homeScreen.timesheetMenuLabel'))}
                handleMenuPress={() => props.handleChangeScreenOnPress("Timesheet")}
              />
            }
            { leaveApplicationFeatureStatus &&
              <SingleMenu
                icon={props.leaveApplicationIcon}
                label={String(t('homeScreen.leavesMenuLabel'))}
                handleMenuPress={() => props.handleChangeScreenOnPress("LeaveApplication")}
              />
            }
          </Flex>
          <Box
            px={{base: 6, md: 16, lg: 24}}
            mb={4}
          >
            { attendanceFeatureStatus &&
              <AttendanceComponent
                currentAttendance={props.currentAttendance}
                isCheckingIn={props.isCheckingIn}
                isCheckingOut={props.isCheckingOut}
                isBreakingIn={props.isBreakingIn}
                isBreakingOut={props.isBreakingOut}
                isFetchingLocation={props.isFetchingLocation}
                handleCheckInClick={props.handleCheckInClick}
                handleCheckOutClick={props.handleCheckOutClick}
                handleTakeABreakButtonPress={props.handleTakeABreakButtonPress}
                handleBackToWorkButtonPress={props.handleBackToWorkButtonPress}
              />
            }
          </Box>
          { timeSheetFeatureStatus ?
            <VStack mb={2}>
              <Box px={{base: 6, md: 16, lg: 24}}>
                <Heading
                  fontFamily="heading"
                  size="md"
                  fontWeight={600}
                  color="primary.500"
                >
                  {String(t('homeScreen.timesheetHeader'))}
                </Heading>
              </Box>
              {
                props.isFetchingTimesheet ?
                  <Spinner animating={true} size={"sm"} color={"primary.200"}/>
                  :
                  <TimelogList
                    isVertical={false}
                    isToday={true}
                    currentTimelogs={props.currentTimelogs}
                    handleCreateNewTimelog={props.handleCreateTimelog}
                    handleSingleTimesheetOnPress={props.handleSingleTimesheetOnPress}
                  />
              }
            </VStack>
            :
            null
          }
        </VStack>
      </ScrollView>
    </Flex>
  );
};
