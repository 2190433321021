import React, {useCallback, useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {
  createLeaveApplication,
  fetchLeaveApplicationList,
  fetchLeaveCountData,
  updateLeaveImageData,
  uploadLeaveAppImage
} from "../actions/actionLeaveApplication";
import {ImageData, LeaveApplication, LeaveBalance, LeaveCount, State, User} from "../state";
import {LeaveApplicationScreen} from "../screens/LeaveApplicationScreen";
import {Platform} from "react-native";
import moment from "moment";
import {DATE_DISPLAY_FORMAT_SHORT} from "../lib/dateFormat";
import * as Analytics from "expo-firebase-analytics";
import {Camera} from "expo-camera";
import {useNavigation} from "@react-navigation/native";
import {updateErrorModalStatus} from "../actions/actions";
import { useToast } from "native-base";
import { useTranslation } from "react-i18next";
import {LeaveApplicationMenu} from "../Components/LeaveApplicationMenu";
import {CASUAL_LEAVE} from "../master-data/constants";

export interface LeaveApplicationScreenContainerProps {
  currentUser?: User;
  leaveApplications: LeaveApplication[];
  leaveCount?: LeaveCount;
  leaveBalance?: LeaveBalance;
  isFetchingLeaveCount: boolean;
  isFetchingLeaveApplications: boolean;
  isStoringLeaveApplication: boolean;
  tempImage?: ImageData;
}

const mapStateToProps = (state: State) => {
  return {
    currentUser: state.control.currentUser,
    tempImage: state.control.currentLeaveAppImage,
    leaveApplications: state.data.leaveApplications,
    leaveCount: state.data.leaveCount,
    leaveBalance: state.data.leaveBalance,
    isFetchingLeaveCount: state.session.isFetchingLeaveCount,
    isFetchingLeaveApplications: state.session.leaveApplications.isFetching,
    isStoringLeaveApplication: state.session.leaveApplications.isStoring
  };
};

const LeaveApplicationScreenContainer = (props: LeaveApplicationScreenContainerProps) => {
  const dispatch = useDispatch();
  const navigation: any = useNavigation();
  const toast = useToast();
  const {t}: { t: any } = useTranslation();

  const currentUser = props.currentUser;
  const currentEmployeeName = currentUser ? currentUser.employeeName : "";
  const employmentType = currentUser ? currentUser.employmentType : "";
  const leaveApplications = props.leaveApplications;
  const leaveBalance = props.leaveBalance;
  const isFetchingLeaveCount = props.isFetchingLeaveCount;
  const isFetchingLeaveApplications = props.isFetchingLeaveApplications;
  const isStoringLeaveApplication = props.isStoringLeaveApplication;
  const tempImage = props.tempImage;
  const isUploadingImage = tempImage ? tempImage.isUploading : false;

  const initialState = {
    username: "",
    designation: "",
    leaveType: "",
    fromDate: "",
    toDate: "",
    reason: "",
    leaveDocname: "",
    errorState: {
      fromDate: false,
      toDate: false,
      reason: false,
      image: false
    }
  };
  const [isFromDatePickerOpen, setIsFromDatePickerOpen] = useState(false);
  const [isToDatePickerOpen, setIsToDatePickerOpen] = useState(false);
  const [isLeaveMenuOpen, setIsLeaveMenuOpen] = useState(false);
  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setFromDate] = useState(initialState.fromDate);
  const [toDate, setToDate] = useState(initialState.toDate);
  const [reason, setReason] = useState(initialState.reason);
  const [errorState, setErrorState] = useState(initialState.errorState);

  const leaveApplicationFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.leaveApplication);

  const casualLeaveReasonReqdFeatureStatus = leaveApplicationFeatureStatus ? leaveApplicationFeatureStatus.casualLeaveReasonReqd : null
  const sickLeaveReasonReqdFeatureStatus = leaveApplicationFeatureStatus ? leaveApplicationFeatureStatus.sickLeaveReasonReqd : null
  const sickLeaveMedCertReqdFeatureStatus = leaveApplicationFeatureStatus ? leaveApplicationFeatureStatus.sickLeaveMedCertReqd : null
  const hasLeaveFeature = !!currentUser?.featureList.leaveApplication;
  const hasCasualLeaveFeature = !!currentUser?.featureList.leaveApplication?.casualLeaveEnabled;
  const hasSickLeaveFeature = !!currentUser?.featureList.leaveApplication?.sickLeaveEnabled;

  useEffect(() => {
    if (tempImage && !tempImage.isUploading && (fromDate != "" || toDate != "" || reason != "" || tempImage)) {
      setIsLeaveMenuOpen(true);
    }
  }, [fromDate, toDate, reason, tempImage]);

  useEffect(() => {
    Analytics.setCurrentScreen("Leave Application Screen", "Leave Application Screen");
  }, []);

  useEffect(() => {
    handleRefreshScreen();
  }, [dispatch, currentEmployeeName, hasLeaveFeature, hasCasualLeaveFeature, hasSickLeaveFeature]);

  useEffect(() => {
    handleSetLeaveType();
  }, [leaveBalance]);

  const handleSetLeaveType = useCallback(() => {
    let leaveTypes: string[] = [];
    if (leaveBalance) {
      Object.keys(leaveBalance).forEach(leave => leaveTypes.push(leave));
    }
    if (leaveTypes.length <= 0) {
      leaveTypes.push(CASUAL_LEAVE);
    }
    setLeaveType(leaveTypes[0]);
  }, [leaveBalance]);

  const handleRefreshScreen = () => {
    if (currentEmployeeName && hasLeaveFeature) {
      dispatch(fetchLeaveApplicationList(currentEmployeeName));
      dispatch(fetchLeaveCountData(
        currentEmployeeName,
        hasCasualLeaveFeature,
        hasSickLeaveFeature,
      ));
    }
  };

  const handleClearData = () => {
    handleSetLeaveType();
    setFromDate(initialState.fromDate);
    setToDate(initialState.toDate);
    setReason(initialState.reason);
    setErrorState(initialState.errorState);
    dispatch(updateLeaveImageData(undefined));
  };

  const validateFormData = (leaveType: string): boolean => {

    const dateDiff = moment(fromDate).diff(toDate, 'day');

    if (dateDiff > 0) return false;

    if (leaveType === "Sick Leave") {
      if (sickLeaveReasonReqdFeatureStatus === 1 && sickLeaveMedCertReqdFeatureStatus === 1) {
        return !!toDate && !!fromDate && !!reason && !!tempImage;
      } else if(sickLeaveMedCertReqdFeatureStatus === 1) {
        return !!toDate && !!fromDate && !!tempImage;
      } else if(sickLeaveReasonReqdFeatureStatus === 1) {
        return !!toDate && !!fromDate && !!reason;
      } else {
        return !!toDate && !!fromDate;
      }
    }

    if (casualLeaveReasonReqdFeatureStatus === 1) {
      return !!toDate && !!fromDate && !!reason;
    } else {
      return !!toDate && !!fromDate;
    }
  };

  const displayWarningLabels = (leaveType: string) => {
    const dateDiff = moment(fromDate).diff(toDate, 'day');
    let imageError = false;

    if (leaveType === "Sick Leave" && !tempImage && sickLeaveMedCertReqdFeatureStatus === 1) {
      imageError = true;
    }

    let reasonError = false;

    if ((leaveType === "Sick Leave" && sickLeaveReasonReqdFeatureStatus === 1) ||
        (leaveType === "Casual Leave" && casualLeaveReasonReqdFeatureStatus === 1)){
      reasonError = reason === '';
    }

    setErrorState({
      fromDate: !fromDate || dateDiff > 0,
      toDate: !toDate || dateDiff > 0,
      reason: reasonError,
      image: imageError
    });
  };

  const submitLeaveApplication = async () => {
    const isValid = validateFormData(leaveType);

    if (isValid && currentEmployeeName) {
      Analytics.logEvent('apply_leave', {
        leave_type: leaveType
      });
      Promise.resolve(dispatch(createLeaveApplication(
        currentEmployeeName,
        leaveType,
        fromDate,
        toDate,
        reason
      )))
        .then((leaveApplicationName: any) => {
          if (tempImage) {
            return dispatch(uploadLeaveAppImage(leaveApplicationName, tempImage))
          }
        })
        .then(() => {
          handleCloseLeaveApplicationMenu();
          handleRefreshScreen();
        })
        .catch(() => {
          dispatch(updateErrorModalStatus({
            isOpen: true,
            title: String(t('modal.errorModal.createLeaveApplicationErrorTitle')),
            content: String(t('modal.errorModal.createLeaveApplicationError'))
          }));
        });
    } else {
      displayWarningLabels(leaveType);
    }
  };

  const openFromDatePicker = () => {
    setIsFromDatePickerOpen(true);
  };

  const closeFromDatePicker = () => {
    setIsFromDatePickerOpen(false);
  };

  const openToDatePicker = () => {
    setIsToDatePickerOpen(true);
  };

  const closeToDatePicker = () => {
    setIsToDatePickerOpen(false);
  };

  const handleLeaveTypeOnChange = (value: string) => {
    setLeaveType(value);
    setErrorState(initialState.errorState);
  };

  const handleFromDateSelectionChange = (date: Date) => {
    // this seems to be a bug from datetimepicker lib.
    // without this line, the datepicker will be displayed twice
    setIsFromDatePickerOpen(Platform.OS === 'ios');
    if (date) {
      setFromDate(moment(date).format(DATE_DISPLAY_FORMAT_SHORT));
    }
    setIsFromDatePickerOpen(false);
    setErrorState(initialState.errorState);
  };

  const handleToDateSelectionChange = (date: Date) => {
    // this seems to be a bug from datetimepicker lib.
    // without this line, the datepicker will be displayed twice
    setIsToDatePickerOpen(Platform.OS === 'ios');
    if (date) {
      setToDate(moment(date).format(DATE_DISPLAY_FORMAT_SHORT));
    }
    setIsToDatePickerOpen(false);
    setErrorState(initialState.errorState);
  };

  const handleReasonChange = (string: string) => {
    setReason(string);
    setErrorState(initialState.errorState);
  };

  const handleLaunchCamera = () => {
    setErrorState(initialState.errorState);
    Camera.requestCameraPermissionsAsync().then((status) => {
      if (status.granted) {
        setIsLeaveMenuOpen(false);
        navigation.navigate('Camera', {
          isAllowUploadFromGallery: false,
          uploadType: "Leave Application"
        });

        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    })
    .catch(() => {
      toast.show({
        title: String(t('modal.errorModal.cameraPermissionError'))
      })
    });
  };

  const handleOpenLeaveMenuOnPress = () => {
    setIsLeaveMenuOpen(true);
  };

  const handleCloseLeaveApplicationMenu = () => {
    setIsLeaveMenuOpen(false);
    handleClearData();
  };

  return (
    <React.Fragment>
      <LeaveApplicationScreen
        employmentType={employmentType}
        leaveApplications={leaveApplications}
        isRefreshing={isFetchingLeaveCount || isFetchingLeaveApplications}
        handleRefreshScreen={handleRefreshScreen}
        handleOpenLeaveMenuOnPress={handleOpenLeaveMenuOnPress}
      />
      <LeaveApplicationMenu
        isOpen={isLeaveMenuOpen}
        isFromDatePickerOpen={isFromDatePickerOpen}
        isToDatePickerOpen={isToDatePickerOpen}
        isStoringLeaveApplication={isStoringLeaveApplication || isUploadingImage}
        isFromError={errorState.fromDate}
        isToError={errorState.toDate}
        isReasonError={errorState.reason}
        isImageError={errorState.image}
        selectedFromDate={fromDate}
        selectedToDate={toDate}
        leaveType={leaveType}
        leaveBalance={leaveBalance}
        reason={reason}
        tempImage={tempImage}
        handleFromDateSelectionChange={handleFromDateSelectionChange}
        handleToDateSelectionChange={handleToDateSelectionChange}
        handleReasonChange={handleReasonChange}
        handleLeaveTypeOnChange={handleLeaveTypeOnChange}
        openFromDatePicker={openFromDatePicker}
        closeFromDatePicker={closeFromDatePicker}
        openToDatePicker={openToDatePicker}
        closeToDatePicker={closeToDatePicker}
        handleSubmitLeaveApplication={submitLeaveApplication}
        handleLaunchCamera={handleLaunchCamera}
        handleMenuOnClose={handleCloseLeaveApplicationMenu}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(LeaveApplicationScreenContainer);
