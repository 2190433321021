import React from "react";
import {
  Button,
  Center,
  Container,
  Heading,
  Pressable,
  ScrollView,
  Text,
} from "native-base";
import { RefreshControl } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// State
import { Attendance, AttendanceBreak, State } from "../state";


interface AttendanceScreenProps {
  currentAttendance?: Attendance;
  currentAttendanceBreak?: AttendanceBreak;
  currentTime: string;
  textColor: string;
  workDuration: string;
  isCheckingIn: boolean;
  isCheckingOut: boolean;
  isBreakingIn: boolean;
  isBreakingOut: boolean;
  isFetchingLocation: boolean;
  isRefreshing: boolean;
  handleCheckInButtonPress: () => void;
  handleCheckOutButtonPress: () => void;
  handleTakeABreakButtonPress: () => void;
  handleBackToWorkButtonPress: () => void;
  handleScreenRefresh: () => void;
}

export const AttendanceScreen: React.FC<AttendanceScreenProps> = (
  props: AttendanceScreenProps
) => {
  const navigation = useNavigation();
  const attendanceFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.attendance);
  const takeABreakFeatureStatus = attendanceFeatureStatus ? attendanceFeatureStatus.takeABreak : null;
  const otherDateFeatureStatus = attendanceFeatureStatus ? attendanceFeatureStatus.otherDate : null
  
  const {t}: { t: any } = useTranslation();
  
  let currentAttendance = props.currentAttendance;
  let currentAttendanceBreak = props.currentAttendanceBreak;
  let currentTime = props.currentTime;
  let isCheckingIn = props.isCheckingIn;
  let isCheckingOut = props.isCheckingOut;
  let isBreakingIn = props.isBreakingIn;
  let isBreakingOut = props.isBreakingOut;
  let isFetchingLocation = props.isFetchingLocation;
  let isRefreshing = props.isRefreshing;
  let textColor = props.textColor;
  let workDuration = props.workDuration;

  return (
    <ScrollView
      zIndex={1}
      py={16}
      refreshControl={
        <RefreshControl
          refreshing={isRefreshing}
          onRefresh={props.handleScreenRefresh}
        />
      }
    >
      <Center>
        <Text
          fontSize={"xl"}
          fontWeight={600}
          color={textColor}
          flexWrap={"wrap"}
          w={"90%"}
          textAlign={"center"}
        >
          {!currentAttendance
            ? String(t('attendanceScreen.notCheckedInStatus'))
            : currentAttendance.docstatus === 0
            ? currentAttendanceBreak
              ? String(t('attendanceScreen.breakStatus'))
              : String(t('attendanceScreen.checkedInStatus')).concat(workDuration)
            : String(t('attendanceScreen.checkOutStatusStr1')).concat(workDuration).concat(String(t('attendanceScreen.checkOutStatusStr2')))}
        </Text>
        <Heading fontSize={"8xl"} color={textColor} mt={10}>
          {currentTime}
        </Heading>
        {takeABreakFeatureStatus ? (
          <Container mt={10}>
            {currentAttendance && currentAttendance.docstatus === 0 ? (
              !currentAttendanceBreak ? (
                <Button
                  colorScheme="success"
                  size={"md"}
                  w={48}
                  isLoading={isBreakingIn}
                  onPress={props.handleTakeABreakButtonPress}
                >
                  {String(t('attendanceScreen.takeABreakButton'))}
                </Button>
              ) : (
                <Button
                  colorScheme="success"
                  size={"md"}
                  w={48}
                  isLoading={isBreakingOut}
                  onPress={props.handleBackToWorkButtonPress}
                >
                  {String(t('attendanceScreen.backToWorkButton'))}
                </Button>
              )
            ) : null}
          </Container>
        ) : null}
        <Container mt={6}>
          {!currentAttendance ? (
            <Button
              colorScheme="success"
              size={"md"}
              w={48}
              isLoading={isCheckingIn || isFetchingLocation}
              onPress={props.handleCheckInButtonPress}
            >
              {String(t('attendanceScreen.checkInButton'))}
            </Button>
          ) : currentAttendance.docstatus === 0 ? (
            <Button
              colorScheme="danger"
              size={"md"}
              w={48}
              isLoading={isCheckingOut || isFetchingLocation}
              onPress={props.handleCheckOutButtonPress}
            >
              {String(t('attendanceScreen.checkOutButton'))}
            </Button>
          ) : null}
        </Container>
        {otherDateFeatureStatus ? (
          <Pressable onPress={() => navigation.navigate("OtherDate" as never)}>
            <Text
              fontSize={"md"}
              fontWeight={"600"}
              mt={6}
              color={textColor}
              italic
              underline
            >
              {String(t('attendanceScreen.otherDateButton'))}
            </Text>
          </Pressable>
        ) : null}
      </Center>
    </ScrollView>
  );
};
