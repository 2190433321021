import {Box, HStack, Icon, Pressable, Text, VStack} from "native-base";
import React from "react";
import { useTranslation } from 'react-i18next';
import {Timelog} from "../state";
import {Feather} from "@expo/vector-icons";

interface SingleTimesheetCardProps {
  timeLog: Timelog;
  isVertical: boolean;
  isToday: boolean;
  handleSingleTimesheetOnPress: (timeLog: Timelog) => void;
}

export const SingleTimesheetCard = (props: SingleTimesheetCardProps) => {
  const {t}: { t: any } = useTranslation();

  const hourDuration = Math.floor(props.timeLog.duration);
  const minuteDuration = (props.timeLog.duration % 1) * 60;

  return (
    <React.Fragment>
      <Pressable
        flex={1}
        bg="primary.500"
        pl={3}
        pr={1}
        py={2}
        w={props.isVertical ? "full" : 40}
        borderRadius={10}
        disabled={!props.isToday}
        _pressed={{
          opacity: 0.8,
        }}
        onPress={() => props.handleSingleTimesheetOnPress(props.timeLog)}
      >
        <VStack
          flex={1}
          space={3}
        >
          <HStack justifyContent="space-between">
            <VStack>
              <Text numberOfLines={2} fontSize="sm" color="secondary.500" bold>
                {props.timeLog.projectTitle}
              </Text>
              <Text fontSize="sm" color="secondary.500">
                {props.timeLog.activity}
              </Text>
            </VStack>
            {
              (props.isToday && props.isVertical) &&
              <Box>
                <Icon
                  as={Feather}
                  name="more-vertical"
                  size="sm"
                  color="light.100"
                />
              </Box>
            }
          </HStack>
          <Text fontSize="2xs" color="secondary.500" mt="auto">
            {`${hourDuration} ${t('modal.timesheetModal.durationTimeHoursCardLabel')} ${minuteDuration} ${t('modal.timesheetModal.durationTimeMinsCardLabel')}`}
          </Text>
        </VStack>
      </Pressable>
    </React.Fragment>
  );
};
