import {DailyRewardModalProps, ModalProps} from "../state";

export const UPDATE_ERROR_MODAL = "UPDATE_ERROR_MODAL";
export const OPEN_DAILY_REWARD_MODAL = "OPEN_DAILY_REWARD_MODAL";
export const UPDATE_DAILY_REWARD_MODAL = "UPDATE_DAILY_REWARD_MODAL";
export const UPDATE_SUCCESS_MODAL = "UPDATE_SUCCESS_MODAL";
export const UPDATE_TIMESHEET_FORM_MODAL_OPEN = "UPDATE_TIMESHEET_FORM_MODAL_OPEN";
export const UPDATE_LEAVE_FORM_MODAL_OPEN = "UPDATE_LEAVE_FORM_MODAL_OPEN";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const UPDATE_OPENING_TIME = "UPDATE_OPENING_TIME";
export const UPDATE_CLOSING_TIME = "UPDATE_CLOSING_TIME";
export const UPDATE_TODAY_DATE = "UPDATE_TODAY_DATE";
export const UPDATE_EXPO_PUSH_TOKEN = "UPDATE_EXPO_PUSH_TOKEN";
export const UPDATE_IS_INITIAL_ON_BOARDING = "UPDATE_IS_INITIAL_ON_BOARDING";

export const updateErrorModalStatus = (errorModal: ModalProps) => {
  return {type: UPDATE_ERROR_MODAL, payload: errorModal};
};

export const updateDailyRewardModalOpen = (open: boolean) => {
  return {type: OPEN_DAILY_REWARD_MODAL, payload: open};
};

export const updateDailyRewardModalStatus = (
  dailyRewardModal: DailyRewardModalProps
) => {
  return {type: UPDATE_DAILY_REWARD_MODAL, payload: dailyRewardModal};
};

export const updateSuccessModalStatus = (successModal: ModalProps) => {
  return {type: UPDATE_SUCCESS_MODAL, payload: successModal};
};

export const updateTimesheetFormModalOpen = (isOpen: boolean) => {
  return {type: UPDATE_TIMESHEET_FORM_MODAL_OPEN, payload: isOpen};
};

export const updateLeaveFormModalOpen = (isOpen: boolean) => {
  return {type: UPDATE_LEAVE_FORM_MODAL_OPEN, payload: isOpen};
};

export const updateLanguage = (language: string) => {
  return {type: UPDATE_LANGUAGE, payload: language};
};

export const updateOpeningTime = (timeString: string) => {
  return {type: UPDATE_OPENING_TIME, payload: timeString}
}

export const updateClosingTime = (timeString: string) => {
  return {type: UPDATE_CLOSING_TIME, payload: timeString}
}

export const updateTodayDate = (todayDate: string) => {
  return {type: UPDATE_TODAY_DATE, payload: todayDate}
}

export const updateExpoPushToken = (expoPushToken: string) => {
  return { type: UPDATE_EXPO_PUSH_TOKEN, payload: expoPushToken };
};

export const updateIsInitialOnBoarding = (isInitialOnBoarding: boolean) => {
  return { type: UPDATE_IS_INITIAL_ON_BOARDING, payload: isInitialOnBoarding };
};
