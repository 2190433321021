import {Box, HStack, VStack, Text, Circle, PresenceTransition} from "native-base";
import React from "react";
import {TimesheetDetail} from "../state";
import {useTranslation} from "react-i18next";
import {FULL_BAR_WIDTH, MINIMUM_BAR_WIDTH} from "../master-data/constants";

interface ProjectProgressBarComponentProps {
  timesheetDetail: TimesheetDetail;
}

interface LegendProps {
  projectName: string;
  colorCode: string;
}

const SingleLegend = (props: LegendProps) => {
  return (
    <Box
      p={2}
      mr={1}
      mb={1}
      w="48%"
    >
      <HStack
        space="xs"
        alignItems="center"
      >
        <Box flex={1}>
          <Circle
            bg={props.colorCode}
            size={4}
          />
        </Box>
        <Box flex={7}>
          <Text color="darkText">
            {props.projectName}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};

export const ProjectProgressBarComponent = (props: ProjectProgressBarComponentProps) => {
  const {t}: { t: any } = useTranslation();
  let legendsData: LegendProps[] = [];
  let otherProjects = 0;

  const renderProgressBars = (timesheetDetail: TimesheetDetail) => {
    let monthlyPerformanceElements: JSX.Element[] = [];
    const workingHours = timesheetDetail.workingHours;
    Object.entries(timesheetDetail.projects)
      .sort((
        [, projectA],
        [, projectB],
      ) => projectA.totalProjectHours - projectB.totalProjectHours)
      .forEach(([key, value]) => {
      const totalProjectHours = value.totalProjectHours;
      const equation = (totalProjectHours / workingHours).toFixed(2);
      const barWidth = Math.round(Number(equation) * FULL_BAR_WIDTH);
      if (barWidth < MINIMUM_BAR_WIDTH) {
        otherProjects += barWidth;
      }
      if (barWidth >= MINIMUM_BAR_WIDTH) {
        legendsData.push({
          projectName: key,
          colorCode: value.colorCode,
        });
        monthlyPerformanceElements.push(
          <PresenceTransition
            key={key}
            style={{
              flex: barWidth
            }}
            visible={true}
            initial={{
              scaleX: 0,
            }}
            animate={{
              scaleX: 1,
              transition: {
                duration: 500,
              }
            }}
          >
            <Box
              h={3}
              bg={value.colorCode}
            />
          </PresenceTransition>
        );
      }
    });
    monthlyPerformanceElements.push(
      <PresenceTransition
        key="otherProjects"
        style={{
          flex: otherProjects
        }}
        visible={true}
        initial={{
          scaleX: 0,
        }}
        animate={{
          scaleX: 1,
          transition: {
            duration: 500,
          }
        }}
      >
        <Box
          h={3}
          bg="coolGray.500"
        />
      </PresenceTransition>
    );
    return (
      <>
        {monthlyPerformanceElements}
      </>
    );
  };

  const Legends = () => (
    <HStack
      flexWrap="wrap"
      alignItems="center"
    >
      {legendsData.map(legend => (
        <SingleLegend
          key={legend.projectName}
          projectName={legend.projectName}
          colorCode={legend.colorCode}
        />
      ))}
      {
        otherProjects > 0 &&
        <SingleLegend
          key="otherProjects"
          projectName={t('profileScreen.others')}
          colorCode="coolGray.500"
        />
      }
    </HStack>
  );

  return (
    <VStack space="md">
      <Box
        h={3}
        flex={1}
        bg="muted.400"
        borderRadius="full"
        overflow="hidden"
      >
        <HStack>
          {renderProgressBars(props.timesheetDetail)}
        </HStack>
      </Box>
      <Legends />
    </VStack>
  );
};
