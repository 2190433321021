import React, {useEffect, useState} from "react";
import {State} from "../state";
import {connect, useDispatch} from "react-redux";
import * as Analytics from "expo-firebase-analytics";
import {DeveloperScreen} from "../screens/DeveloperScreen";
import {Platform} from "react-native";
import moment from "moment";
import {TIME_DISPLAY_FORMAT} from "../lib/dateFormat";
import {updateClosingTime, updateOpeningTime} from "../actions/actions";

interface DeveloperScreenContainerProps {
  openingTime: string;
  closingTime: string;
  expoPushToken: string;
}

const mapStateToProps = (state: State) => {
  return {
    openingTime: state.control.openingTime,
    closingTime: state.control.closingTime,
    expoPushToken: state.session.expoPushToken,
  }
}

const DeveloperScreenContainer = (props: DeveloperScreenContainerProps) => {
  const SCREEN_NAME = "Developer Screen";

  const selectedOpeningTime = props.openingTime;
  const selectedClosingTime = props.closingTime;
  const expoPushToken = props.expoPushToken;
  const [isOpeningTimePickerVisible, setIsOpeningTimePickerVisible] = useState(false);
  const [isClosingTimePickerVisible, setIsClosingTimePickerVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    Analytics.setCurrentScreen(SCREEN_NAME, SCREEN_NAME);
  }, []);

  const handleShowOpeningTimePicker = () => {
    setIsOpeningTimePickerVisible(true);
  }

  const handleShowClosingTimePicker = () => {
    setIsClosingTimePickerVisible(true);
  }

  const handleHideOpeningTimePicker = () => {
    setIsOpeningTimePickerVisible(false);
  }

  const handleHideClosingTimePicker = () => {
    setIsClosingTimePickerVisible(false);
  }

  const handleConfirmOpeningTimePicker = (time: Date) => {
    // this seems to be a bug from datetimepicker lib.
    // without this line, the datepicker will be displayed twice
    setIsOpeningTimePickerVisible(Platform.OS === "ios");
    const timeString = moment(time).format(TIME_DISPLAY_FORMAT);
    dispatch(updateOpeningTime(timeString));
    setIsOpeningTimePickerVisible(false);
  }

  const handleConfirmClosingTimePicker = (time: Date) => {
    // this seems to be a bug from datetimepicker lib.
    // without this line, the datepicker will be displayed twice
    setIsClosingTimePickerVisible(Platform.OS === "ios");
    const timeString = moment(time).format(TIME_DISPLAY_FORMAT);
    dispatch(updateClosingTime(timeString));
    setIsClosingTimePickerVisible(false);
  }

  return (
    <DeveloperScreen selectedOpeningTime={selectedOpeningTime}
                     selectedClosingTime={selectedClosingTime}
                     expoPushToken={expoPushToken}
                     isOpeningTimePickerVisible={isOpeningTimePickerVisible}
                     isClosingTimePickerVisible={isClosingTimePickerVisible}
                     showOpeningTimePicker={handleShowOpeningTimePicker}
                     showClosingTimePicker={handleShowClosingTimePicker}
                     hideOpeningTimePicker={handleHideOpeningTimePicker}
                     hideClosingTimePicker={handleHideClosingTimePicker}
                     handleConfirmOpeningTimePicker={handleConfirmOpeningTimePicker}
                     handleConfirmClosingTimePicker={handleConfirmClosingTimePicker}
    />
  );
}

export default connect(mapStateToProps)(DeveloperScreenContainer);
