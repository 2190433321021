import React, {useEffect, useState} from "react";
import {Achievement, State, User} from "../state";
import {connect, useDispatch} from "react-redux";
import {fetchAchievementList} from "../actions/actionGamification";
import AchievementScreen from "../screens/AchievementScreen";
import AchievementModal from "../Components/AchievementModal";
import {LoadingComponentFullScreen} from "../Components/LoadingComponent";
import * as Analytics from "expo-firebase-analytics";

export interface AchievementPageContainerProps {
  currentAchievements: Achievement[];
  currentUser?: User;
  baseUrl?: string;
  isRefreshing: boolean;
  isFetchingAchievements: boolean;
}

const mapStateToProps = (state: State) => {
  return {
    currentUser: state.control.currentUser,
    currentAchievements: state.control.currentAchievements,
    baseUrl: state.control.selectedCompany?.serverUrl,
    isRefreshing: state.session.isRefreshing,
    isFetchingAchievements: state.session.achievements.isFetching
  }
};

const AchievementPageContainer: React.FC<AchievementPageContainerProps> = (props: AchievementPageContainerProps) => {
  const currentUser = props.currentUser;
  const currentAchievements = props.currentAchievements;
  const hasPointSystemFeature = !!props.currentUser?.featureList.gamification?.pointSystemEnabled;
  const baseUrl = props.baseUrl;
  const isRefreshing = props.isRefreshing;
  const isFetchingAchievements = props.isFetchingAchievements;
  const dispatch = useDispatch();

  const [isAchievementModalOpen, setIsAchievementModalOpen] = useState(false);
  const [selectedAchievement, setSelectedAchievement] = useState<Achievement | undefined>(undefined);

  useEffect(() => {
    Analytics.setCurrentScreen("Achievement Screen", "Achievement Screen");
  }, []);

  useEffect(() => {
    if (currentUser && hasPointSystemFeature) {
      dispatch(fetchAchievementList(currentUser.employeeName));
    }
  }, [currentUser, dispatch, hasPointSystemFeature])

  let handleOpenAchievementModal = (achievementName: string) => {
    const selectedAchievement: Achievement | undefined = props.currentAchievements.find((achievement: Achievement) => achievement.name === achievementName);

    if (!selectedAchievement) {
      return;
    }

    setIsAchievementModalOpen(true);
    setSelectedAchievement(selectedAchievement);
  };

  const handleCloseAchievementModal = () => {
    setIsAchievementModalOpen(false);
  };

  const handleScreenRefresh = () => {
    if (currentUser && hasPointSystemFeature) {
      dispatch(fetchAchievementList(currentUser.employeeName));
    }
  };

  return (
    <React.Fragment>
      {
        isFetchingAchievements ?
          <LoadingComponentFullScreen/>
          :
          <React.Fragment>
            <AchievementScreen isRefreshing={isRefreshing}
                               currentAchievements={currentAchievements}
                               baseUrl={baseUrl ? baseUrl : ""}
                               handleScreenRefresh={handleScreenRefresh}
                               handleOpenAchievementModal={handleOpenAchievementModal}
            />
            <AchievementModal isOpen={isAchievementModalOpen}
                              selectedAchievement={selectedAchievement}
                              baseUrl={baseUrl ? baseUrl : ""}
                              handleCloseAchievementModal={handleCloseAchievementModal}
            />
          </React.Fragment>
      }
    </React.Fragment>
  )
};

export default connect(mapStateToProps)(AchievementPageContainer);
