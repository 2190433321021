import React, { useEffect, useRef, useState } from "react";
import { Animated } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { LocationObject } from "expo-location/src/Location.types";
import { Flex, ZStack } from "native-base";
import * as Analytics from "expo-firebase-analytics";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

// Actions
import { getCurrentServerTime } from "../actions/actionsDateTime";
import { updateErrorModalStatus } from "../actions/actions";
import {
  createAttendanceBreak,
  fetchAttendanceBreaks,
  fetchTodayAttendance,
  postCheckInAttendance,
  submitAttendance,
  updateAttendanceBreak,
} from "../actions/actionsAttendance";

// Components
import CheckOutConfirmationModal from "../Components/CheckOutConfirmationModal";
import AttendanceBackground from "../Components/AttendanceBackground";
import { LoadingComponentFullScreen } from "../Components/LoadingComponent";

// Constants
import {
  FIXED_TIMEZONE_NAME,
  formatDateYYYYMMDD,
  TIME_DISPLAY_FORMAT,
  TIME_FRAPPE_FORMAT,
} from "../lib/dateFormat";

// Screens
import { AttendanceScreen } from "../screens/AttendanceScreen";

// State
import { Attendance, AttendanceBreak, State, User } from "../state";

// Styles
import { attendanceScreenStyle } from "../styles/attendanceScreenStyles";

// Utils
import { requestLocationAndThenDoAction } from "../utils/systemUtils";


export interface AttendancePageContainerProps
  extends StackScreenProps<any, any> {
  currentUser?: User;
  currentAttendance?: Attendance;
  currentAttendanceBreak?: AttendanceBreak;
  currentServerTime: string;
  isCheckingIn: boolean;
  isCheckingOut: boolean;
  isBreakingIn: boolean;
  isBreakingOut: boolean;
  isFetchingBreak: boolean;
  isFetchingServerTime: boolean;
  isRefreshing: boolean;
  attendanceBreaksList: AttendanceBreak[];
}

const mapStateToProps = (state: State) => {
  return {
    currentUser: state.control.currentUser,
    currentAttendance: state.control.currentAttendance,
    currentAttendanceBreak: state.control.currentAttendanceBreak,
    currentServerTime: state.control.currentServerTime,
    isCheckingIn: state.session.isCheckingIn,
    isCheckingOut: state.session.isCheckingOut,
    isBreakingIn: state.session.isBreakingIn,
    isBreakingOut: state.session.isBreakingOut,
    attendanceBreaksList: state.data.attendanceBreaks,
    isFetchingServerTime: state.session.serverTime.isFetching,
    isFetchingBreak: state.session.attendanceBreaks.isFetching,
    isRefreshing: state.session.isRefreshing,
  };
};

const AttendancePageContainer: React.FC<AttendancePageContainerProps> = (props: AttendancePageContainerProps) => {
  const dispatch = useDispatch();
  const {t}: { t: any } = useTranslation();
  const todayDate = formatDateYYYYMMDD(new Date());

  let currentUser = props.currentUser;
  let currentAttendance = props.currentAttendance;
  let currentAttendanceName = currentAttendance ? currentAttendance.name : "";
  let currentAttendanceBreak = props.currentAttendanceBreak;
  let currentServerTime = props.currentServerTime;
  let attendanceBreaksList = props.attendanceBreaksList;
  let isFetchingServerTime = props.isFetchingServerTime;
  let isFetchingBreak = props.isFetchingBreak;
  let isRefreshing = props.isRefreshing;

  const [currentServerTimeState, setCurrentServerTimeState] = useState("");
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [totalBreakHours, setTotalBreakHours] = useState(0);
  const [totalWorkHour, setTotalWorkHour] = useState("");
  const [sunStyle, setSunStyle] = useState("#f2db7c");
  const [hillStyles, setHillStyles] = useState<string[]>([
    "#2C5960",
    "#1f4751",
    "#102b34",
  ]);
  const [svgBgColor, setSvgBgColor] = useState("#44b4d3");
  const [textColor, setTextColor] = useState("#1f4751");
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);

  const currentTimeMoment = moment(currentServerTimeState, "hh:mm");
  const currentHour = currentTimeMoment.hour();

  const attendanceFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.attendance);
  const attendanceLocationFeatureStatus = attendanceFeatureStatus ? attendanceFeatureStatus.locationReqd : null;

  const moveAnimation = useRef(new Animated.Value(0)).current;

  const move = () => {
    Animated.timing(moveAnimation, {
      toValue: -200,
      duration: 2500,
      useNativeDriver: true,
    }).start();
  };

  useEffect(() => {
    dispatch(getCurrentServerTime());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchTodayAttendance(currentUser.employeeName, todayDate));
    }
  }, [currentUser, todayDate, dispatch]);

  useEffect(() => {
    if (currentAttendanceName) {
      dispatch(fetchAttendanceBreaks(currentAttendanceName));
    }
  }, [currentAttendanceName, dispatch]);

  useEffect(() => {
    let totalHours = 0;
    attendanceBreaksList.forEach((attendanceBreak: AttendanceBreak) => {
      totalHours += attendanceBreak.totalHours;
    });
    setTotalBreakHours(totalHours);
  }, [attendanceBreaksList]);

  useEffect(() => {
    const nowTime = currentServerTime
      ? moment(currentServerTime)
          .tz(FIXED_TIMEZONE_NAME)
          .format(TIME_DISPLAY_FORMAT)
      : moment().tz(FIXED_TIMEZONE_NAME).format(TIME_DISPLAY_FORMAT);
    setCurrentServerTimeState(nowTime);
  }, [currentServerTime]);

  useEffect(() => {
    let timer = setTimeout(() => {
      const updatedServerTimeState = moment(
        currentServerTimeState,
        TIME_DISPLAY_FORMAT
      )
        .add(1, "minutes")
        .format(TIME_DISPLAY_FORMAT);
      if (updatedServerTimeState !== "Invalid date") {
        setCurrentServerTimeState(updatedServerTimeState);
      }
    }, 60000);
    return () => {
      clearTimeout(timer);
    };
  }, [currentServerTimeState]);

  useEffect(() => {
    if (currentHour >= 4 && currentHour < 6) {
      setSvgBgColor("#548b9e");
      setTextColor("#081619");
      setHillStyles(["#1f4547", "#143235", "#081619"]);
    } else if (currentHour >= 6 && currentHour < 11) {
      setSvgBgColor("#44b4d3");
      setTextColor("#1f4751");
      setHillStyles(["#2C5960", "#1f4751", "#102b34"]);
      setSunStyle("#f2db7c");
      move();
    } else if (currentHour >= 11 && currentHour < 14) {
      setSvgBgColor("#abdaed");
      setTextColor("#3B8183");
      setHillStyles(["#396d39", "#215422", "#103312"]);
      setSunStyle("#f3da60");
      move();
    } else if (currentHour >= 14 && currentHour < 16) {
      setSvgBgColor("#a7d3d4");
      setTextColor("#3B8183");
      setHillStyles(["#244424", "#183818", "#0c1e0c"]);
      setSunStyle("#f4dfbe");
      move();
    } else if (currentHour >= 16 && currentHour < 19) {
      setSvgBgColor("#f7a090");
      setTextColor("#8c4946");
      setHillStyles(["#24464c", "#0f2b34", "#0f181d"]);
      setSunStyle("#ab5e53");
      move();
    } else if (currentHour >= 19 || currentHour < 2) {
      setSvgBgColor("#002a4f");
      setTextColor("#ECEAF9");
      setHillStyles(["#2f2f51", "#202035", "#10101a"]);
      setSunStyle("#ab5e53");
    }
  }, [currentHour]);

  useEffect(() => {
    if (currentAttendance) {
      let floatTotalWorkingHours;

      if (currentAttendance.docstatus === 1) {
        floatTotalWorkingHours = moment.duration(
          moment(currentAttendance.endTime, TIME_FRAPPE_FORMAT).diff(
            moment(currentAttendance.startTime, TIME_FRAPPE_FORMAT)
          ) - totalBreakHours * 3600000
        );
      } else {
        floatTotalWorkingHours = moment.duration(
          moment().diff(
            moment(currentAttendance.startTime, TIME_FRAPPE_FORMAT)
          ) - totalBreakHours * 3600000
        );
      }

      let hourFormat = floatTotalWorkingHours.hours() > 0 ? 
        (String(floatTotalWorkingHours.hours()) + " " + String(t('modal.timesheetModal.durationTimeHoursFormLabel')) + " ") : "";

      let minsFormat = floatTotalWorkingHours.minutes() > 0 ? 
        (String(floatTotalWorkingHours.minutes()) + " " + String(t('modal.timesheetModal.durationTimeMinsFormLabel'))) : "";

      let totalWorkHourStr =  String(t('attendanceScreen.secondsStr'));

      if (floatTotalWorkingHours.hours() > 0 || floatTotalWorkingHours.minutes() > 0){
        totalWorkHourStr = (hourFormat + minsFormat)
      }

      setTotalWorkHour(totalWorkHourStr);
    }
  }, [props.currentAttendance, totalBreakHours, currentServerTimeState]);

  const handleTakeABreakButtonPress = () => {
    if (currentAttendance && currentUser) {
      Analytics.logEvent("take_break");
      dispatch(
        createAttendanceBreak(currentUser.employeeName, currentAttendance.name)
      );
    }
  };

  const handleBackToWorkButtonPress = () => {
    if (currentAttendanceBreak) {
      Analytics.logEvent("finish_break");
      dispatch(updateAttendanceBreak(currentAttendanceBreak.name));
    }
  };

  const handleCheckInAction = (location?: LocationObject): Promise<any> => {
    if (currentUser) {
      Analytics.logEvent("check_in");
      dispatch(
        postCheckInAttendance(
          currentUser.employeeName,
          location ? location.coords.latitude : undefined,
          location ? location.coords.longitude : undefined
        )
      );
      return Promise.resolve();
    } else {
      return Promise.reject(String(t('modal.errorModal.usernameError')));
    }
  };

  const handleCheckOutAction = (location?: LocationObject): Promise<any> => {
    if (currentAttendance) {
      Analytics.logEvent("check_out");
      dispatch(
        submitAttendance(
          currentAttendance.name,
          location ? location.coords.latitude : undefined,
          location ? location.coords.longitude : undefined
        )
      );
      return Promise.resolve();
    } else {
      return Promise.reject(String(t('modal.errorModal.usernameError')));
    }
  };

  const handleLocationAttendance = (successfulAction: (location: LocationObject) => Promise<any>, type: string) => {
    let errorMsg = "";

    if (type == "check-out"){
      errorMsg = String(t('modal.errorModal.checkOutErrorTitle'))
    }
    else{
      errorMsg = String(t('modal.errorModal.checkInErrorTitle'))
    }

    setIsFetchingLocation(true);

    const failedAction = () => {
      dispatch(
        updateErrorModalStatus({
          title: errorMsg,
          content: String(t('modal.errorModal.locationError')),
          isOpen: true,
        })
      );
    };

    requestLocationAndThenDoAction(successfulAction, failedAction).finally(() => {
        setIsFetchingLocation(false);
      }
    );
  }

  const handleCheckInButtonPress = () => {
    if (attendanceLocationFeatureStatus === 1) {
      handleLocationAttendance(handleCheckInAction, "check-in");
    } else {
      handleCheckInAction();
    }
  };

  const handleCheckOutButtonPress = () => {
    setIsOpenConfirmationModal(true);
  };

  const handleConfirmCheckOut = () => {
    if (attendanceLocationFeatureStatus === 1) {
      handleLocationAttendance(handleCheckOutAction, "check-out");
    }
    else{
      handleCheckOutAction();
    }
    setIsOpenConfirmationModal(false);
  };

  const handleCloseConfirmationModal = () => {
    setIsOpenConfirmationModal(false);
  };

  const handleScreenRefresh = () => {
    dispatch(getCurrentServerTime());
  };

  return (
    <React.Fragment>
      {isFetchingBreak || isFetchingServerTime ? (
        <LoadingComponentFullScreen />
      ) : null}
      <ZStack flex={1}>
        <Flex zIndex={-1} w={"full"} h={"full"} bgColor={svgBgColor}>
          <AttendanceBackground hillStyles={hillStyles} />
          <Animated.View
            style={{
              ...attendanceScreenStyle.animatedSun,
              backgroundColor: sunStyle,
            }}
          />
        </Flex>
        <AttendanceScreen
          currentAttendance={currentAttendance}
          currentAttendanceBreak={currentAttendanceBreak}
          currentTime={currentServerTimeState}
          textColor={textColor}
          workDuration={totalWorkHour}
          isCheckingIn={props.isCheckingIn}
          isCheckingOut={props.isCheckingOut}
          isBreakingIn={props.isBreakingIn}
          isBreakingOut={props.isBreakingOut}
          isFetchingLocation={isFetchingLocation}
          isRefreshing={isRefreshing}
          handleCheckInButtonPress={handleCheckInButtonPress}
          handleCheckOutButtonPress={handleCheckOutButtonPress}
          handleTakeABreakButtonPress={handleTakeABreakButtonPress}
          handleBackToWorkButtonPress={handleBackToWorkButtonPress}
          handleScreenRefresh={handleScreenRefresh}
        />
      </ZStack>
      <CheckOutConfirmationModal
        isOpen={isOpenConfirmationModal}
        handleCloseConfirmationModal={handleCloseConfirmationModal}
        handleConfirmCheckOut={handleConfirmCheckOut}
        isCheckingOut={props.isCheckingOut}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(AttendancePageContainer);
