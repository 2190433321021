export const durationHourOptions: string[] = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08"
]

export const durationMinuteOptions: string[] = [
  "0",
  "15",
  "30",
  "45"
];