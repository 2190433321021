import React, {useEffect} from "react";
import { connect, useDispatch } from "react-redux";
import { updateLanguage } from "../actions/actions";
import { State } from "../state";
import * as Analytics from "expo-firebase-analytics";
import {LanguageSelectionScreen} from "../screens/LanguageSelectionScreen";

interface LanguageSelectionScreenContainerProps {
  language: string;
}

const mapStateToProps = (state: State) => {
  return {
    language: state.control.language,
  };
};

const LanguageSelectionScreenContainer = (props: LanguageSelectionScreenContainerProps) => {
  const dispatch = useDispatch();

  const handleChangeLanguage = (lng: string) => {
    dispatch(updateLanguage(lng));
  };

  useEffect(() => {
    Analytics.setCurrentScreen("Language Selection");
  }, []);

  return (
    <LanguageSelectionScreen
      language={props.language}
      handleChangeLanguage={handleChangeLanguage}
    />
  );
};

export default connect(mapStateToProps)(LanguageSelectionScreenContainer);
