import {Alert, Linking, Platform} from "react-native";
import * as IntentLauncher from "expo-intent-launcher";
import * as LocationGet from "expo-location";
import {LocationObject, LocationPermissionResponse} from "expo-location";

const SHOWING_SYSTEM_SETTINGS = "showing system settings";

export const openAppSettings = () => {
  if (Platform.OS == 'ios') {
    Linking.openURL('app-settings:');
  } else {
    if (IntentLauncher != null) {
      IntentLauncher.startActivityAsync(
        IntentLauncher.ActivityAction.MANAGE_ALL_APPLICATIONS_SETTINGS
      );
    }
  }
};

export const showSystemSettingsAlert = () => {
  return (
    Alert.alert("Location Permission needed", "Please allow location permission for this application", [
      {
        text: 'Open App Permission',
        onPress: () => openAppSettings(),
        style: 'cancel',
      }
    ])
  )
}

export const requestLocationAndThenDoAction = (successfulAction: (location: LocationObject) => Promise<any>, failedAction: () => void) => {
  return LocationGet.requestForegroundPermissionsAsync()
    .then((response: LocationPermissionResponse) => {
      const status = response.status;
      if (status === 'granted') {
        return LocationGet.getCurrentPositionAsync({});
      } else {
        showSystemSettingsAlert();
        return Promise.reject(SHOWING_SYSTEM_SETTINGS);
      }
    })
    .then((location: LocationObject) => {
      return successfulAction(location);
    })
    .catch((error: string) => {
      if (error === SHOWING_SYSTEM_SETTINGS) {
        return;
      } else {
        failedAction();
      }
    });
}

export const getLocationFromNotificationAction = async () => {
  const { status } = await LocationGet.requestForegroundPermissionsAsync();
  if (status === 'granted') {
    return await LocationGet.getCurrentPositionAsync({});
  } else {
    showSystemSettingsAlert();
  }
};
