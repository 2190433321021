import * as React from "react"
import {Path} from "react-native-svg"
import {createIcon} from "native-base";

export const ProfileIconActive = () => {
  const ProfileIcon = createIcon({
    viewBox: "0 0 283.46 283.46",
    path: [
      <Path
        d="M136.31 248.93l103.88-1.11a8.91 8.91 0 008.36-11.77c-5.59-16.41-19.36-45.89-49-59.72-3.48-1.63-6.23-1.93-8.88.86-7.86 8.24-28.15 26.06-53.79 28a9.77 9.77 0 01-1.53 0c-25.64-2-44.55-18.69-52.41-26.93a9 9 0 00-10.26-2c-29.65 13.83-43.42 43.31-49 59.72A8.91 8.91 0 0032 247.82l103.88 1.11h.41z"
        fill="#e15c54"
      />,
      <Path
        fill="#FCB25D"
        d="M136.3 30.73s-59.18 8.35-50.52 61 16 68.38 24.83 75.12 24 17.65 39.06 8.34S179 137 183.25 123.82s14.57-65.16-4.05-76.4-42.9-16.69-42.9-16.69z"
      />,
      <Path
        fill="#3B8183"
        d="M58.7 254.06c-8.87 0-17.12 0-23.91-.06a18.9 18.9 0 01-15.88-8.8 18.5 18.5 0 01-1.23-17.71c15.1-32.8 55.74-53.73 57.46-54.61a6.63 6.63 0 116 11.83c-.38.2-38.26 19.76-51.42 48.33a5.24 5.24 0 00.38 5 5.67 5.67 0 004.77 2.65c33.56.22 103.23-.22 103.92-.22a6.64 6.64 0 010 13.27c-.46.04-45.02.32-80.09.32z"
      />,
      <Path
        fill="#3B8183"
        d="M216.45 254.06c-35.07 0-79.63-.28-80.19-.28a6.64 6.64 0 010-13.27c.7 0 70.38.44 103.93.22a5.67 5.67 0 004.77-2.65 5.22 5.22 0 00.37-5c-13.15-28.57-51-48.13-51.42-48.33a6.64 6.64 0 016-11.83c1.72.88 42.35 21.81 57.46 54.61a18.5 18.5 0 01-1.24 17.71 18.87 18.87 0 01-15.87 8.8c-6.69-.04-14.95.02-23.81.02z"
      />,
      <Path
        fill="#3B8183"
        d="M137.44 34.9s32.77 14.88 36.1 46.1c1 30.83-.33 64.64-33.83 96.1 21.83-8.35 58.44-68.45 50.25-98.91S167.91 33.3 137.44 34.9z"
      />,
      <Path
        fill="#3B8183"
        d="M136.3 185.43c-34.46 0-60.22-50-60.22-94.71 0-40.19 23.48-65.15 61.27-65.15 37.6 0 59.17 22.94 59.17 62.94 0 44.82-26.31 96.92-60.22 96.92zm1-146.59c-30.5 0-48 18.91-48 51.88 0 41.1 23.26 81.45 47 81.45 22.82 0 46.95-43 46.95-83.66 0-32.96-15.44-49.67-45.9-49.67z"
      />
    ],
  })
  return <ProfileIcon size={"md"}/>
}

export const ProfileIconInactive = () => {
  const ProfileIcon = createIcon({
    viewBox: "0 0 283.46 283.46",
    path: [
      <Path
        d="M136.31 248.93l103.88-1.11a8.91 8.91 0 008.36-11.77c-5.59-16.41-19.36-45.89-49-59.72-3.48-1.63-6.23-1.93-8.88.86-7.86 8.24-28.15 26.06-53.79 28a9.77 9.77 0 01-1.53 0c-25.64-2-44.55-18.69-52.41-26.93a9 9 0 00-10.26-2c-29.65 13.83-43.42 43.31-49 59.72A8.91 8.91 0 0032 247.82l103.88 1.11h.41z"
        fill="none"
      />,
      <Path
        fill="none"
        d="M136.3 30.73s-59.18 8.35-50.52 61 16 68.38 24.83 75.12 24 17.65 39.06 8.34S179 137 183.25 123.82s14.57-65.16-4.05-76.4-42.9-16.69-42.9-16.69z"
      />,
      <Path
        fill="grey"
        d="M58.7 254.06c-8.87 0-17.12 0-23.91-.06a18.9 18.9 0 01-15.88-8.8 18.5 18.5 0 01-1.23-17.71c15.1-32.8 55.74-53.73 57.46-54.61a6.63 6.63 0 116 11.83c-.38.2-38.26 19.76-51.42 48.33a5.24 5.24 0 00.38 5 5.67 5.67 0 004.77 2.65c33.56.22 103.23-.22 103.92-.22a6.64 6.64 0 010 13.27c-.46.04-45.02.32-80.09.32z"
      />,
      <Path
        fill="grey"
        d="M216.45 254.06c-35.07 0-79.63-.28-80.19-.28a6.64 6.64 0 010-13.27c.7 0 70.38.44 103.93.22a5.67 5.67 0 004.77-2.65 5.22 5.22 0 00.37-5c-13.15-28.57-51-48.13-51.42-48.33a6.64 6.64 0 016-11.83c1.72.88 42.35 21.81 57.46 54.61a18.5 18.5 0 01-1.24 17.71 18.87 18.87 0 01-15.87 8.8c-6.69-.04-14.95.02-23.81.02z"
      />,
      <Path
        fill="grey"
        d="M137.44 34.9s32.77 14.88 36.1 46.1c1 30.83-.33 64.64-33.83 96.1 21.83-8.35 58.44-68.45 50.25-98.91S167.91 33.3 137.44 34.9z"
      />,
      <Path
        fill="grey"
        d="M136.3 185.43c-34.46 0-60.22-50-60.22-94.71 0-40.19 23.48-65.15 61.27-65.15 37.6 0 59.17 22.94 59.17 62.94 0 44.82-26.31 96.92-60.22 96.92zm1-146.59c-30.5 0-48 18.91-48 51.88 0 41.1 23.26 81.45 47 81.45 22.82 0 46.95-43 46.95-83.66 0-32.96-15.44-49.67-45.9-49.67z"
      />
    ],
  })
  return <ProfileIcon size={"md"}/>
}