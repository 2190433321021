import {connect, useDispatch} from "react-redux";
import React, {useEffect} from "react";
import {LeaveBalance, State, User} from "../state";
import {LeaveBalanceScreen} from "../screens/LeaveBalanceScreen";
import {
  fetchLeaveApplicationList, fetchLeaveCountData,
} from "../actions/actionLeaveApplication";
import * as Analytics from "expo-firebase-analytics";

interface LeaveBalanceScreenContainerProps {
  leaveBalance?: LeaveBalance;
  currentUser?: User;
  isFetchingLeaveCount: boolean;
}

const mapStateToProps = (state: State) => {
  return {
    currentUser: state.control.currentUser,
    leaveBalance: state.data.leaveBalance,
    isFetchingLeaveCount: state.session.isFetchingLeaveCount,
  };
};

const LeaveBalanceScreenContainer = (props: LeaveBalanceScreenContainerProps) => {
  const dispatch = useDispatch();
  const hasLeaveFeature = !!props.currentUser?.featureList.leaveApplication;
  const hasCasualLeaveFeature = !!props.currentUser?.featureList.leaveApplication?.casualLeaveEnabled;
  const hasSickLeaveFeature = !!props.currentUser?.featureList.leaveApplication?.sickLeaveEnabled;

  useEffect(() => {
    Analytics.setCurrentScreen("Leave Balance Screen", "Leave Balance Screen");
  }, []);

  const handleRefreshScreen = () => {
    if (props.currentUser && hasLeaveFeature) {
      dispatch(fetchLeaveApplicationList(props.currentUser.employeeName));
      dispatch(fetchLeaveCountData(
        props.currentUser.employeeName,
        hasCasualLeaveFeature,
        hasSickLeaveFeature,
      ));
    }
  };

  return (
    <LeaveBalanceScreen
      leaveBalance={props.leaveBalance}
      isFetchingLeaveCount={props.isFetchingLeaveCount}
      handleRefreshScreen={handleRefreshScreen}
    />
  );
};

export default connect(mapStateToProps)(LeaveBalanceScreenContainer);
