import React from "react";
import {Achievement} from "../state";
import {ListRenderItem, ListRenderItemInfo} from "react-native";
import {Center, FlatList, Image, Pressable, Text} from 'native-base';

interface AchievementScreenProps {
  isRefreshing: boolean;
  currentAchievements: Achievement[];
  baseUrl: string;
  handleScreenRefresh: () => void;
  handleOpenAchievementModal: (achievementName: string) => void;
}

const AchievementScreen: React.FC<AchievementScreenProps> = (props: AchievementScreenProps) => {
  const baseUrl = props.baseUrl;

  const renderAchievements: ListRenderItem<Achievement> = (data: ListRenderItemInfo<Achievement>) => {
    const achievement = data.item;

    return (
      <Pressable key={achievement.achievementName}
                 onPress={() => props.handleOpenAchievementModal(achievement.name)}
                 w={24}
                 h={"full"}
                 mt={4}
      >
        {
          (achievement.currentProgress >= achievement.threshold) ?
            <Center>
              <Image source={{uri: baseUrl.concat(achievement.icon)}}
                     alt="achievement-icon"
                     size={"md"}
              />
            </Center>
            :
            <Center>
              <Image source={{uri: baseUrl.concat(achievement.icon)}}
                     alt="achievement-icon"
                     size={"md"}
                     style={{tintColor: 'grey'}}/>
              <Image source={{uri: baseUrl.concat(achievement.icon)}}
                     alt="achievement-icon-locked"
                     size={"md"}
                     style={{
                       opacity: 0.2,
                       position: "absolute"
                     }}/>
            </Center>
        }
        <Text mt={2} textAlign={"center"}>
          {achievement.achievementName}
        </Text>
      </Pressable>
    )
  };

  return (
    <FlatList py={2}
              px={6}
              columnWrapperStyle={{justifyContent: "space-between"}}
              data={props.currentAchievements}
              keyExtractor={(item, index) => String(index)}
              numColumns={3}
              refreshing={props.isRefreshing}
              renderItem={renderAchievements}
              onRefresh={props.handleScreenRefresh}
    />
  );
};

export default AchievementScreen;
