import React from "react";
import {Button, HStack, Modal} from "native-base";
import { useTranslation } from "react-i18next";

interface CheckOutConfirmationModalProps {
  isOpen: boolean;
  isCheckingOut: boolean;
  handleCloseConfirmationModal: () => void;
  handleConfirmCheckOut: () => void;
}

const CheckOutConfirmationModal: React.FC<CheckOutConfirmationModalProps> = (props: CheckOutConfirmationModalProps) => {
  const {t}: { t: any } = useTranslation();

  return (
    <Modal isOpen={props.isOpen}
           size={"lg"}
           overlayVisible={true}
           closeOnOverlayClick={true}
    >
      <Modal.Content bgColor={"secondary.400"}>
        <Modal.Header>{String(t('modal.checkOutConfirmationModal.title'))}</Modal.Header>
        <Modal.Body>{String(t('modal.checkOutConfirmationModal.content'))}</Modal.Body>
        <Modal.Footer bgColor={"secondary.500"}>
          <HStack flex={1}>
            <Button colorScheme={"success"}
                    size={"sm"}
                    flex={1}
                    onPress={props.handleCloseConfirmationModal}>
              {String(t('button.cancelButton'))}
            </Button>
            <Button colorScheme={"danger"}
                    size={"sm"}
                    flex={1}
                    ml={4}
                    isLoading={props.isCheckingOut}
                    onPress={props.handleConfirmCheckOut}>
              {String(t('attendanceScreen.checkOutButton'))}
            </Button>
          </HStack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default CheckOutConfirmationModal;