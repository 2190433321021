import {StyleSheet} from "react-native";
import {widthPercentageToDP as wp} from 'react-native-responsive-screen';

export const attendanceScreenStyle = StyleSheet.create({
  animatedSun: {
    width: wp(10),
    height: wp(10),
    position: "absolute",
    zIndex: -1,
    top: '80%',
    left: '5%',
    borderRadius: 100 / 2
  }
})