import React from "react";
import {
  Box, Button, Center, Circle,
  Divider, Fab,
  FlatList,
  FormControl,
  Heading,
  HStack,
  Icon, Image,
  Input,
  Modal,
  Pressable, Select,
  Text,
  VStack
} from "native-base";
import {Feather, FontAwesome} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";
import {
  ActivityTemplate,
  Project,
  TimeSheetTemplate,
  TimesheetTemplateModalData,
  TimesheetTemplateMode
} from "../state";
import {ListRenderItem, ListRenderItemInfo} from "react-native";
import moment from "moment";
import {durationHourOptions, durationMinuteOptions} from "../master-data/selectOptions";

interface TimesheetTemplateScreenProps {
  timesheetTemplates: TimeSheetTemplate[];
  singleTimesheetTemplateData: TimesheetTemplateModalData;
  modalMode: TimesheetTemplateMode;
  projects: Project[];
  activityTypes: string[];
  selectedTemplate: number;
  isEditMode: boolean;
  isEditTemplateModalOpen: boolean;
  isStoringTimelogs: boolean;
  handleSettingsOnPress: () => void;
  handleSaveOnPress: () => void;
  handleApplyOnPress: () => void;
  handleCloseEditTemplateModal: () => void;
  handleSaveEditedActivityTemplate: () => void;
  handleCreateNewTemplate: () => void;
  handleOpenNewTemplateModal: () => void;
  handleCloseTimesheetTemplateScreenOnPress: () => void;
  handleDiscardTimesheetTemplateOnPress: () => void;
  handleOpenEditTemplateModal: (activity: ActivityTemplate, projectTitle: string, activityIndex: number) => void;
  handleSelectSingleActivityTemplate: (activity: ActivityTemplate, projectTitle: string, activityIndex: number) => void;
  handleValueOnChange: (field: string, value: string) => void;
  handleDeleteSingleTemplateOnPress: (projectTitle: TimeSheetTemplate, activityIndex: number) => void;
}

interface HeaderComponentProps {
  isEmptyList: boolean;
  isEditMode: boolean;
  handleSettingsOnPress: () => void;
  handleSaveOnPress: () => void;
  handleCloseTimesheetTemplateScreenOnPress: () => void;
  handleDiscardTimesheetTemplateOnPress: () => void;
}

interface SingleTimesheetTemplateCardProps {
  activity: ActivityTemplate;
  timesheetTemplate: TimeSheetTemplate;
  isEditMode: boolean;
  activityIndex: number;
  handleOpenEditTemplateModal: (activity: ActivityTemplate, projectTitle: string, activityIndex: number) => void;
  handleSelectSingleActivityTemplate: (activity: ActivityTemplate, projectTitle: string, activityIndex: number) => void;
  handleDeleteSingleTemplateOnPress: (projectTitle: TimeSheetTemplate, activityIndex: number) => void;
}

interface TimesheetTemplateModalProps {
  singleTimesheetTemplateData: TimesheetTemplateModalData;
  modalMode: TimesheetTemplateMode;
  projects: Project[];
  activityTypes: string[];
  isEditMode: boolean;
  isEditTemplateModalOpen: boolean;
  handleCloseEditTemplateModal: () => void;
  handleSaveEditedActivityTemplate: () => void;
  handleCreateNewTemplate: () => void;
  handleValueOnChange: (field: string, value: string) => void;
}

const HeaderComponent = (props: HeaderComponentProps) => {
  const {t}: { t: any } = useTranslation();
  return (
    <HStack
      px={5}
      mt={5}
      alignItems="center"
      justifyContent="space-between"
    >
      <HStack
        alignItems="center"
        space="sm"
      >
        {
          !props.isEditMode &&
            <Pressable
              p={2}
              borderRadius={5}
              _pressed={{
                bg: 'light.200',
              }}
              onPress={props.handleCloseTimesheetTemplateScreenOnPress}
            >
              <Icon
                as={Feather}
                name="arrow-left"
                size={6}
                color="primary.500"
              />
            </Pressable>
        }
        <Heading>
          {t('stackHeader.timesheetTemplateScreen')}
        </Heading>
      </HStack>
      <Button
        borderRadius={5}
        bg="success.500"
        _pressed={{
          bg: 'light.200'
        }}
        leftIcon={
          <Icon
            as={Feather}
            name={props.isEditMode ? "save" : "edit-2"}
            size={5}
            color="light.100"
          />}
        onPress={props.isEditMode ? props.handleSaveOnPress : props.handleSettingsOnPress}
      >
        <Text
          fontSize="md"
          fontFamily="heading"
          color="light.100"
        >
          {
            props.isEditMode ?
              t('timesheetTemplateScreen.save')
              : props.isEmptyList ?
                t('timesheetTemplateScreen.create')
                : t('timesheetTemplateScreen.edit')
          }
        </Text>
      </Button>
    </HStack>
  );
};

const SingleTimesheetTemplateCardComponent = (props: SingleTimesheetTemplateCardProps) => {
  const {t}: { t: any } = useTranslation();
  return (
    <Pressable
      flex={1}
      _pressed={{
        bg: 'light.200',
      }}
      bg={props.activity.isSelected ? 'primary.100' : 'transparent'}
      disabled={props.isEditMode}
      onPress={() => props.handleSelectSingleActivityTemplate(
        props.activity,
        props.timesheetTemplate.projectTitle,
        props.activityIndex,
      )}
      justifyContent="center"
    >
      <VStack
        space={1}
        alignItems="center"
      >
        <HStack
          px={5}
          py={2}
          alignItems="center"
          space="md"
        >
          {
            !props.isEditMode &&
            <Circle
              p={2}
              size={4}
              borderWidth={2}
              borderColor="primary.500"
            >
              {
                props.activity.isSelected &&
                <Icon
                  as={FontAwesome}
                  name="check"
                  size={3}
                  color="success.500"
                />
              }
            </Circle>
          }
          <VStack
            space={1}
            flex={props.isEditMode ? 3 : 5}
          >
            <Text
              fontSize="md"
              fontWeight="bold"
              numberOfLines={1}
            >
              {props.activity.activityTitle}
            </Text>
            {
              props.activity.description !== '' &&
              <Text
                fontSize="md"
                numberOfLines={1}
              >
                {props.activity.description}
              </Text>
            }
          </VStack>
          <HStack
            alignItems="center"
            space="md"
            flex={3}
          >
            <Pressable
              bg="success.200"
              borderColor="success.500"
              borderWidth={1}
              borderRadius={5}
              px={3}
              py={1}
              justifyContent="center"
              onPress={() =>
                props.handleOpenEditTemplateModal(
                  props.activity,
                  props.timesheetTemplate.projectTitle,
                  props.activityIndex,
                )}
            >
              <HStack
                flex={1}
                space="sm"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <VStack
                  alignItems="center"
                >
                  <Text
                    fontSize="xl"
                    fontFamily="heading"
                    color="darkText"
                    mb={-1}
                  >
                    {moment(props.activity.workingHourDurations, 'HH')
                      .format('HH')}
                  </Text>
                  <Text
                    fontSize="xs"
                    color="darkText"
                  >
                    {t('timesheetTemplateScreen.hours')}
                  </Text>
                </VStack>
                <VStack
                  alignItems="center"
                >
                  <Text
                    fontSize="xl"
                    fontFamily="heading"
                    color="darkText"
                    mb={-1}
                  >
                    {moment(props.activity.workingMinuteDurations, 'mm')
                      .format('mm')}
                  </Text>
                  <Text
                    fontSize="xs"
                    color="darkText"
                  >
                    {t('timesheetTemplateScreen.minutes')}
                  </Text>
                </VStack>
              </HStack>
            </Pressable>
            {
              props.isEditMode &&
              <Pressable
                flex={1}
                p={2}
                borderRadius={5}
                _pressed={{
                  bg: 'light.200',
                }}
                onPress={() => props.handleDeleteSingleTemplateOnPress(
                  props.timesheetTemplate,
                  props.activityIndex,
                )}
                alignItems="flex-end"
              >
                <Icon
                  as={Feather}
                  name="trash-2"
                  size={4}
                  color="danger.500"
                />
              </Pressable>
            }
          </HStack>
        </HStack>
        <Divider borderWidth={0.5} borderColor="primary.500" />
      </VStack>
    </Pressable>
  );
};

const TimesheetTemplateModalComponent = (props: TimesheetTemplateModalProps) => {
  const {t}: { t: any } = useTranslation();

  const renderProjectList = (data: Project) => (
    <Select.Item
      key={data.name}
      label={data.projectName}
      value={data.name}
    />
  );

  const renderDropdownList = (data: string) => (
    <Select.Item
      key={data}
      label={data}
      value={data}
    />
  );

  return (
    <Modal
      isOpen={props.isEditTemplateModalOpen}
      onClose={props.handleCloseEditTemplateModal}
    >
      <Modal.Content bgColor="secondary.400">
        <Modal.Body>
          <VStack
            py={3}
            space="sm"
          >
            {
              props.isEditMode &&
              <VStack
                space="sm"
              >
                <FormControl>
                  <FormControl.Label>
                    Project
                  </FormControl.Label>
                  <Select
                    isDisabled={props.modalMode === 'Edit' || !props.isEditMode}
                    placeholder={t('modal.timesheetModal.projectPlaceholder')}
                    selectedValue={props.singleTimesheetTemplateData.projectTitle || ''}
                    _selectedItem={{
                      bg: "success.200",
                    }}
                    onValueChange={(value) => props.handleValueOnChange('projectTitle', value)}
                  >
                    {props.projects.map(renderProjectList)}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormControl.Label>
                    Activity
                  </FormControl.Label>
                  <Select
                    isDisabled={!props.isEditMode}
                    placeholder={t('modal.timesheetModal.activityPlaceholder')}
                    selectedValue={props.singleTimesheetTemplateData.activityTitle || ''}
                    _selectedItem={{
                      bg: "success.200",
                    }}
                    onValueChange={(value) => props.handleValueOnChange('activityTitle', value)}
                  >
                    {props.activityTypes.map(renderDropdownList)}
                  </Select>
                </FormControl>
              </VStack>
            }
            <FormControl>
              <FormControl.Label>
                {t('modal.timesheetModal.durationFormLabel')}
              </FormControl.Label>
              <HStack
                space="sm"
                alignItems="center"
              >
                <Select
                  flex={1}
                  placeholder="00"
                  selectedValue={
                    moment(props.singleTimesheetTemplateData.workingHourDurations, "HH")
                      .format("HH")
                    || ''}
                  _selectedItem={{
                    bg: "success.200",
                  }}
                  onValueChange={(value) => props.handleValueOnChange('workingHourDurations', value)}
                >
                  {durationHourOptions.map(renderDropdownList)}
                </Select>
                <Select
                  flex={1}
                  placeholder="00"
                  selectedValue={moment(props.singleTimesheetTemplateData.workingMinuteDurations, "mm")
                      .format("mm")
                    || ''}
                  _selectedItem={{
                    bg: "success.200"
                  }}
                  onValueChange={(value) => props.handleValueOnChange('workingMinuteDurations', value)}
                >
                  {durationMinuteOptions.map(renderDropdownList)}
                </Select>
              </HStack>
            </FormControl>
            {
              props.isEditMode &&
              <FormControl>
                <FormControl.Label>
                  Note
                </FormControl.Label>
                <Input
                  isDisabled={!props.isEditMode}
                  value={String(props.singleTimesheetTemplateData.description) || ''}
                  onChangeText={(value) => props.handleValueOnChange('description', value)}
                />
              </FormControl>
            }
          </VStack>
        </Modal.Body>
        <Modal.Footer bg="secondary.400">
          <Button
            colorScheme="danger"
            size="md"
            flex={1}
            onPress={props.modalMode === "Create" ?
              props.handleCreateNewTemplate
              : props.handleSaveEditedActivityTemplate}
          >
            {String(t('button.submitButton'))}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const EmptyListComponent = () => {
  const {t}: { t: any } = useTranslation();
  return (
    <Center
      flex={1}
      px={4}
    >
      <Image
        key="emptyTemplate"
        alt="emptyTemplate"
        size={48}
        resizeMode="contain"
        source={require('../../assets/illustrations/completed_task_flatline.png')}
      />
      <Text
        fontSize="md"
        color="muted.500"
        textAlign="center"
        italic
      >
        {t('timesheetTemplateScreen.emptyList')}
      </Text>
    </Center>
  )
}

export const TimesheetTemplateScreen = (props: TimesheetTemplateScreenProps) => {
  const renderTemplates: ListRenderItem<TimeSheetTemplate> = (data: ListRenderItemInfo<TimeSheetTemplate>) => {
    const item = data.item;
    return (
      <VStack flex={1}>
        <Box
          px={5}
          mb={2}
        >
          <Heading
            fontSize="xl"
            fontWeight="bold"
          >
            {item.projectTitle}
          </Heading>
        </Box>
        {
          item.activities.map((activity, index) => (
            <SingleTimesheetTemplateCardComponent
              key={activity.activityTitle + index}
              isEditMode={props.isEditMode}
              activity={activity}
              timesheetTemplate={item}
              activityIndex={index}
              handleOpenEditTemplateModal={props.handleOpenEditTemplateModal}
              handleSelectSingleActivityTemplate={props.handleSelectSingleActivityTemplate}
              handleDeleteSingleTemplateOnPress={props.handleDeleteSingleTemplateOnPress}
            />
          ))
        }
      </VStack>
    );
  };

  return (
    <VStack
      flex={1}
      safeAreaTop
      space="lg"
      bg="secondary.500"
    >
      <HeaderComponent
        isEmptyList={props.timesheetTemplates.length <= 0}
        isEditMode={props.isEditMode}
        handleSettingsOnPress={props.handleSettingsOnPress}
        handleSaveOnPress={props.handleSaveOnPress}
        handleDiscardTimesheetTemplateOnPress={props.handleDiscardTimesheetTemplateOnPress}
        handleCloseTimesheetTemplateScreenOnPress={props.handleCloseTimesheetTemplateScreenOnPress}
      />
      <FlatList
        data={props.timesheetTemplates}
        keyExtractor={(item, index) => String(index)}
        renderItem={renderTemplates}
        w="full"
        h="full"
        ItemSeparatorComponent={() => <Box mt={8} />}
        ListEmptyComponent={
          <EmptyListComponent />
        }
        contentContainerStyle={{
          paddingVertical: 10,
          flexGrow: 1,
        }}
      />
      {
        !props.isEditMode &&
        <Button
          mx={5}
          colorScheme={props.selectedTemplate <= 0 ? "dark" : "success"}
          _text={{
            fontFamily: 'heading',
          }}
          size="lg"
          isLoading={props.isStoringTimelogs}
          disabled={props.selectedTemplate <= 0}
          onPress={props.handleApplyOnPress}
        >
          APPLY
        </Button>
      }
      {
        props.isEditMode &&
        <Fab
          placement="bottom-right"
          colorScheme="success"
          size="lg"
          icon={<Icon as={Feather} name="plus" />}
          onPress={props.handleOpenNewTemplateModal}
        />
      }
      <TimesheetTemplateModalComponent
        modalMode={props.modalMode}
        isEditMode={props.isEditMode}
        isEditTemplateModalOpen={props.isEditTemplateModalOpen}
        singleTimesheetTemplateData={props.singleTimesheetTemplateData}
        projects={props.projects}
        activityTypes={props.activityTypes}
        handleCloseEditTemplateModal={props.handleCloseEditTemplateModal}
        handleValueOnChange={props.handleValueOnChange}
        handleSaveEditedActivityTemplate={props.handleSaveEditedActivityTemplate}
        handleCreateNewTemplate={props.handleCreateNewTemplate}
      />
    </VStack>
  );
};
