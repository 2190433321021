import {Box, Heading, Icon, IconButton, useTheme} from "native-base";
import React from "react";
import {useSelector} from 'react-redux';
import {createStackNavigator} from "@react-navigation/stack";
import {StyleProp, ViewStyle} from "react-native";
import LoginScreen from '../screens/LoginScreen';
import CompanyServerSearchScreen from '../screens/CompanyServerSearchScreen';
import {State} from "../state";
import CompanyListScreen from "../screens/CompanyListScreen";
import {Ionicons} from "@expo/vector-icons";
import {useNavigation} from "@react-navigation/native";
import JoinCompanyScreen from "../screens/JoinCompanyScreen";
import {ScanQRCodeScreen} from "../screens/ScanQRCodeScreen";
import { useTranslation } from 'react-i18next';
import OnBoardingScreenContainer from "../Containers/OnBoardingScreenContainer";
import LanguageSelectionScreenContainer from "../Containers/LanguageSelectionScreenContainer";

export const LoginStackNavigator = () => {
  const LoginStack = createStackNavigator();
  const companyData = useSelector((state: State) => state.control.companyData);
  const isInitialOnBoarding = useSelector((state: State) => state.control.isInitialOnBoarding);
  const theme = useTheme();
  const navigation = useNavigation();
  const {t}: { t: any } = useTranslation();

  const headerStyle: StyleProp<ViewStyle> = {
    backgroundColor: theme.colors.primary[500],
    borderBottomColor: theme.colors.primary[500],
    shadowColor: theme.colors.primary[500],
    borderBottomWidth: 3,
  }

  return (
    <LoginStack.Navigator
      initialRouteName={
        isInitialOnBoarding ?
          "OnBoardingScreen"
          : companyData.length > 0 ? "LoginScreen" : "JoinCompanyScreen"}
      screenOptions={{
        animationEnabled: false,
        headerBackTitleVisible: false,
      }}
    >
      <LoginStack.Screen name="OnBoardingScreen"
                         options={{
                           headerShown: false,
                         }}
                         component={OnBoardingScreenContainer}
      />
      <LoginStack.Screen name="LoginScreen"
                         options={{
                           headerShown: false
                         }}
                         component={LoginScreen}/>
      <LoginStack.Screen name="CompanyServerSearchScreen"
                         options={{
                           headerShown: false
                         }}
                         component={CompanyServerSearchScreen}/>
      <LoginStack.Screen name="CompanyListScreen"
                         options={{
                           headerTitle: () => <Heading color={"secondary.500"}>{String(t('stackHeader.companyListScreen'))}</Heading>,
                           headerStyle: headerStyle,
                           headerLeft: () => <IconButton
                             icon={<Icon as={Ionicons} name={"md-arrow-back-sharp"} color={"secondary.500"} ml={4}/>}
                             onPress={() => navigation.navigate("LoginScreen" as never)}/>,
                           headerTitleAlign: "left",
                         }}
                         component={CompanyListScreen}/>
      <LoginStack.Screen name="JoinCompanyScreen"
                         options={{
                           headerShown: companyData.length > 0,
                           headerTitle: "",
                           headerStyle: headerStyle,
                           headerLeft: () => <IconButton
                             icon={<Icon as={Ionicons} name={"md-arrow-back-sharp"} color={"secondary.500"} ml={4}/>}
                             onPress={() => navigation.navigate("CompanyListScreen" as never)}/>,
                         }}
                         component={JoinCompanyScreen}/>
      <LoginStack.Screen name="ScanQRCodeScreen"
                         options={{
                           headerTitle: () => <Heading color={"secondary.500"}>{String(t('stackHeader.scanQRCodeScreen'))}</Heading>,
                           headerStyle: headerStyle,
                           headerLeft: () => <IconButton
                             icon={<Icon as={Ionicons} name={"md-arrow-back-sharp"} color={"secondary.500"} ml={4}/>}
                             onPress={() => navigation.navigate("JoinCompanyScreen" as never)}/>,
                           headerTitleAlign: "left",
                         }}
                         component={ScanQRCodeScreen}/>
      <LoginStack.Screen
        name="LanguageSelectionLogin"
        options={{
          headerTitle: () => (
            <Heading
              color={"primary.500"}
              fontWeight={600}
            >
              {String(t("stackHeader.languageSelectionScreen"))}
            </Heading>
          ),
          headerBackground: () => <Box flex={1} bg="secondary.500" />,
          headerStyle: headerStyle,
        }}
        component={LanguageSelectionScreenContainer}
      />
    </LoginStack.Navigator>
  )
}
