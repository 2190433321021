import {Box, Heading, Icon, Pressable, useTheme, View} from "native-base";
import TapCamera from "../Components/TapCamera";
import AttendanceScreenContainer from "../Containers/AttendanceScreenContainer";
import TimesheetScreenContainer from "../Containers/TimesheetScreenContainer";
import TimesheetTemplateScreenContainer from "../Containers/TimesheetTemplateScreenContainer";
import {LeaveApplicationTopTabNavigator} from "./LeaveApplicationTopTabNavigator";
import OtherDateScreenContainer from "../Containers/OtherDateScreenContainer";
import React from "react";
import {createStackNavigator} from "@react-navigation/stack";
import {ActivityStackNavigator} from "./ActivityStackNavigator";
import HomeScreenContainer from "../Containers/HomeScreenContainer";
import {Feather} from "@expo/vector-icons";
import {StackActions} from "@react-navigation/native";
import {ProfileStackNavigator} from "./ProfileStackNavigator";
import {HomeIconActive, HomeIconInactive} from "../icons/HomeIcon";
import {AchievementIconActive, AchievementIconInactive} from "../icons/AchievementIcon";
import {ProfileIconActive, ProfileIconInactive} from "../icons/ProfileIcon";
import {Platform, StyleProp, ViewStyle} from "react-native";
import {heightPercentageToDP as hp} from "react-native-responsive-screen";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {State} from "../state";
import {CompanyTopTabNavigator} from "./CompanyTopTabNavigator";
import {CompanyRulesDetailScreenContainer} from "../Containers/CompanyRulesDetailScreenContainer";

export const HomeStackNavigator = () => {
  const Stack = createStackNavigator();
  const Tab = createBottomTabNavigator();
  const theme = useTheme();
  const {t}: { t: any } = useTranslation();

  const activityFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.gamification);
  const selectedCompany = useSelector((state: State) => state.control.selectedCompany);

  const renderScreenOptions = () => {
    return ({route}: any) => ({
      headerShown: false,
      tabBarIcon: ({focused,}: any) => {
        if (route.name === "Home") {
          return focused ? <HomeIconActive/> : <HomeIconInactive/>;
        } else if (route.name === "Activity") {
          return focused ? <AchievementIconActive/> : <AchievementIconInactive/>
        } else if (route.name === "Profile") {
          return focused ? <ProfileIconActive/> : <ProfileIconInactive/>;
        }
      },
      tabBarActiveTintColor: '#E25B55',
      tabBarInactiveTintColor: 'gray',
      tabBarStyle: {
        height: Platform.OS === "ios" ? hp(12) : hp(8),
        backgroundColor: theme.colors.secondary[500],
      },
      tabBarItemStyle: {
        marginVertical: 10,
        backgroundColor: theme.colors.secondary[500]
      },
      tabBarIconStyle: {
        width: hp(5),
      },
      tabBarLabelStyle: {
        fontSize: hp(1.5),
      },
    });
  }

  const headerStyle: StyleProp<ViewStyle> = {
    backgroundColor: theme.colors.secondary[500],
    borderBottomColor: theme.colors.primary[500],
    shadowColor: theme.colors.secondary[500],
  }

  const HomeTabs = () => {
    return (
      <Tab.Navigator
        screenOptions={renderScreenOptions()}
        initialRouteName={"Home"}
      >
        {activityFeatureStatus?
          <Tab.Screen name={"Activity"}
                      component={ActivityStackNavigator}
                      options={{
                        tabBarAllowFontScaling: false,
                        tabBarLabel: String(t('bottomTab.activity'))
                      }}
          />
          :
          null
        }
        <Tab.Screen name={"Home"}
                    component={HomeScreenContainer}
                    options={({navigation}) => {
                      return ({
                        tabBarAllowFontScaling: false,
                        headerTitle: "",
                        headerBackground: () => <Box flex={1} bg="primary.500" />,
                        headerLeft: () =>
                          <Box px={5}>
                            <Heading
                              color="secondary.500"
                              fontSize="md"
                              isTruncated
                              noOfLines={1}
                            >
                              {`${selectedCompany?.companyName} - ${selectedCompany?.branchName}`}
                            </Heading>
                          </Box>,
                        headerRight: () =>
                          <Pressable
                            px={5}
                            onPress={() => navigation.navigate("CompanyScreen")}
                          >
                            <Icon
                              as={Feather}
                              name="info"
                              color="secondary.500"
                              size="sm"
                            />
                          </Pressable>,
                        headerShown: false,
                      })
                    }}
                    listeners={({navigation, route}: any) => ({
                      blur: () => {
                        if (route.state && route.state.index > 0) {
                          navigation.dispatch(StackActions.popToTop());
                        }
                      }
                    })}
        />
        <Tab.Screen name={"Profile"}
                    component={ProfileStackNavigator}
                    options={{
                      tabBarAllowFontScaling: false,
                      tabBarLabel: String(t('bottomTab.profile'))
                    }}
        />
      </Tab.Navigator>
    );
  };

  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerTintColor: theme.colors.primary[500],
      }}
    >
      <Stack.Screen name="HomeStack"
                    options={{
                      headerShown: false,
                    }}
                    component={HomeTabs}
      />
      <Stack.Screen name={"Camera"}
                    options={{
                      headerTitle: "",
                      headerLeft: () => (<View/>),
                      headerTransparent: true,
                      headerShown: true,
                    }}
                    component={TapCamera}
      />
      <Stack.Screen name="Attendance"
                    options={{
                      headerTitle: () =>(
                        <Heading
                          color={"primary.500"}
                          fontWeight={600}
                        >
                          {String(t('stackHeader.attendanceScreen'))}
                        </Heading>
                      ),
                      headerStyle: headerStyle,
                    }}
                    component={AttendanceScreenContainer}
      />
      <Stack.Screen name="Timesheet"
                    options={{
                      headerTitle: () => (
                        <Heading
                          color={"primary.500"}
                          fontWeight={600}
                        >
                          {String(t('stackHeader.timesheetScreen'))}
                        </Heading>
                      ),
                      headerStyle: headerStyle,
                    }}
                    component={TimesheetScreenContainer}
      />
      <Stack.Screen name="TimesheetTemplateScreen"
                    options={{
                      headerShown: false,
                    }}
                    component={TimesheetTemplateScreenContainer}
      />
      <Stack.Screen name="LeaveApplication"
                    options={{
                      headerTitle: () => (
                        <Heading
                          color={"primary.500"}
                          fontWeight={600}
                        >
                          {String(t('stackHeader.leaveApplicationScreen'))}
                        </Heading>
                      ),
                      headerStyle: headerStyle,
                    }}
                    component={LeaveApplicationTopTabNavigator}
      />
      <Stack.Screen name="OtherDate"
                    options={{
                      headerTitle: () => (
                        <Heading
                          color={"primary.500"}
                          fontWeight={600}
                        >
                          {String(t("stackHeader.attendanceScreen"))}
                        </Heading>
                      ),
                      headerStyle: headerStyle,
                    }}
                    component={OtherDateScreenContainer}
      />
      <Stack.Screen name="CompanyScreen"
                    options={{
                      headerTitle: () => (
                        <Heading
                          color="primary.500"
                          fontWeight={600}
                        >
                          {String(t("stackHeader.companyScreen"))}
                        </Heading>
                      ),
                      headerStyle: headerStyle,
                    }}
                    component={CompanyTopTabNavigator}
      />
      <Stack.Screen name="CompanyRulesDetailScreen"
                    options={{
                      headerTitle: () => (
                        <Heading
                          color="primary.500"
                          fontWeight={600}
                        >
                          {String(t("stackHeader.companyRulesDetailScreen"))}
                        </Heading>
                      ),
                      headerStyle: headerStyle,
                    }}
                    component={CompanyRulesDetailScreenContainer}
      />
    </Stack.Navigator>
  );
};
