import React from "react";
import {Center, FormControl, HStack, Select, Text, VStack, WarningOutlineIcon} from "native-base";
import {durationHourOptions, durationMinuteOptions} from "../master-data/selectOptions";
import { useTranslation } from "react-i18next";
import {Project} from "../state";

interface TimesheetFormModalContentContainerProps {
  projects: Project[];
  activityTypes: string[];
  errorState: { [componentName: string]: boolean };
  selectedProject: string;
  selectedActivity: string;
  selectedHour: string;
  selectedMinute: string;
  handleSelectActivity: (activityType: string) => void;
  handleSelectProject: (project: string) => void;
  handleSelectHour: (hour: string) => void;
  handleSelectMinute: (minute: string) => void;
}

export const TimesheetFormModalContentContainer: React.FC<TimesheetFormModalContentContainerProps> = (props: TimesheetFormModalContentContainerProps) => {
  const selectedProject = props.selectedProject;
  const selectedActivity = props.selectedActivity;
  const selectedHour = props.selectedHour;
  const selectedMinute = props.selectedMinute;
  const projects = props.projects;
  const activityTypes = props.activityTypes;

  const {t}: {t: any} = useTranslation();

  const renderProjectList = (data: Project) => (
    <Select.Item
      key={data.name}
      label={data.projectName}
      value={data.name}
    />
  );

  const renderDropdownList = (data: string) => (
    <Select.Item
      key={data}
      label={data}
      value={data}
    />
  );

  const handleActivityChange = (activityName: string) => {
    props.handleSelectActivity(activityName);
  }

  const handleProjectChange = (projectName: string) => {
    props.handleSelectProject(projectName);
  }

  const handleHourDurationChange = (hour: string) => {
    props.handleSelectHour(hour);
  }

  const handleMinuteDurationChange = (minute: string) => {
    props.handleSelectMinute(minute);
  }

  return (
    <VStack flex={1}>
      <VStack>
        <FormControl isInvalid={props.errorState.project}>
          <FormControl.Label>
            <Text fontSize={"xs"}>{String(t('modal.timesheetModal.projectNameFormLabel'))}</Text>
          </FormControl.Label>
          <Select placeholder={String(t('modal.timesheetModal.projectPlaceholder'))}
                  selectedValue={selectedProject}
                  _selectedItem={{
                    bg: "success.200"
                  }}
                  onValueChange={handleProjectChange}
          >
            {projects.map(renderProjectList)}
          </Select>
          <FormControl.ErrorMessage
            leftIcon={<WarningOutlineIcon size="xs"/>}
          >
            {String(t('modal.timesheetModal.projectNameFormError'))}
          </FormControl.ErrorMessage>
        </FormControl>
      </VStack>
      <VStack mt={4}>
        <FormControl isInvalid={props.errorState.activity}>
          <FormControl.Label>
            <Text fontSize={"xs"}>{String(t('modal.timesheetModal.activityTypeFormLabel'))}</Text>
          </FormControl.Label>
          <Select placeholder={String(t('modal.timesheetModal.activityPlaceholder'))}
                  selectedValue={selectedActivity}
                  _selectedItem={{
                    bg: "success.200"
                  }}
                  onValueChange={handleActivityChange}>
            {activityTypes.map(renderDropdownList)}
          </Select>
          <FormControl.ErrorMessage
            leftIcon={<WarningOutlineIcon size="xs"/>}
          >
           {String(t('modal.timesheetModal.activityTypeFormError'))}
          </FormControl.ErrorMessage>
        </FormControl>
      </VStack>
      <VStack mt={4}>
        <FormControl isInvalid={props.errorState.duration}>
          <FormControl.Label>
            <Text fontSize={"xs"}>{String(t('modal.timesheetModal.durationFormLabel'))}</Text>
          </FormControl.Label>
          <HStack>
            <HStack flex={1}>
              <Select flex={1}
                      placeholder="00"
                      selectedValue={selectedHour}
                      _selectedItem={{
                        bg: "success.200"
                      }}
                      onValueChange={handleHourDurationChange}
              >
                {durationHourOptions.map(renderDropdownList)}
              </Select>
              <Center>
                <Text fontSize={"sm"} ml={2}>{String(t('modal.timesheetModal.durationTimeHoursFormLabel'))}</Text>
              </Center>
            </HStack>
            <HStack flex={1} ml={4}>
              <Select flex={1}
                      placeholder="00"
                      selectedValue={selectedMinute}
                      _selectedItem={{
                        bg: "success.200"
                      }}
                      onValueChange={handleMinuteDurationChange}
              >
                {durationMinuteOptions.map(renderDropdownList)}
              </Select>
              <Center>
                <Text fontSize={"sm"} ml={2}>{String(t('modal.timesheetModal.durationTimeMinsFormLabel'))}</Text>
              </Center>
            </HStack>
          </HStack>
          <FormControl.ErrorMessage
            leftIcon={<WarningOutlineIcon size="xs"/>}
          >
            {String(t('modal.timesheetModal.durationFormError'))}
          </FormControl.ErrorMessage>
        </FormControl>
      </VStack>
    </VStack>
  );
};
