import { ImageProps } from "react-native";
import { Center, Image, Pressable, Text} from "native-base";
import React from "react";

interface SingleMenuProps {
  icon: ImageProps;
  label: string;
  handleMenuPress: () => void;
}

export const SingleMenu = (props: SingleMenuProps) => {
  return (
    <Pressable
      onPress={props.handleMenuPress}
    >
      <Center>
        <Image source={props.icon} size="md" alt={props.label} />
        <Text>{props.label}</Text>
      </Center>
    </Pressable>
  );
};
