import {Heading, Text, Icon, VStack, ScrollView, Center, useTheme, HStack, Box} from "native-base";
import React from "react";
import {LeaveBalance, LeaveBalanceDetail} from "../state";
import {FontAwesome} from "@expo/vector-icons";
import {useTranslation} from "react-i18next";
import {RefreshControl} from "react-native";

interface LeaveBalanceScreenProps {
  leaveBalance?: LeaveBalance;
  isFetchingLeaveCount: boolean;
  handleRefreshScreen: () => void;
}

interface LeaveCountComponentProps {
  title: string;
  leaveBalanceDetail: LeaveBalanceDetail;
}

interface LeaveBalanceComponentProps {
  leaveBalance: LeaveBalance;
}

const LeaveCountComponent = (props: LeaveCountComponentProps) => {
  let leaveApplicationStatus: JSX.Element[] = [];
  const leaveBalanceDetail = props.leaveBalanceDetail;
  const usedLeaveBalance = leaveBalanceDetail.leaveAllocation - leaveBalanceDetail.currentLeaveBalance;
  const remainingLeaveBalance = leaveBalanceDetail.currentLeaveBalance - leaveBalanceDetail.totalFutureLeaveApplication;
  const {t}: { t: any } = useTranslation();

  for (let i = 0; i < remainingLeaveBalance; i++) {
    leaveApplicationStatus.push(
      <Icon
        as={FontAwesome}
        name="heart"
        key={'leave-available-'.concat(String(i))}
        size="sm"
        color="red.500"
        mr={2}
        mt={2}
      />
    );
  }
  for (let i = 0; i < leaveBalanceDetail.totalFutureLeaveApplication; i++) {
    leaveApplicationStatus.push(
      <Icon
        as={FontAwesome}
        name="heart"
        key={'booked-leave-'.concat(String(i))}
        size="sm"
        color="coolGray.400"
        mr={2}
        mt={2}
      />
    );
  }
  for (let i = 0; i < usedLeaveBalance; i++) {
    leaveApplicationStatus.push(
      <Icon
        as={FontAwesome}
        name="heart-o"
        key={'leave-total-'.concat(String(i))}
        size="sm"
        color="red.500"
        mr={2}
        mt={2}
      />
    );
  }

  return (
    <VStack
      flex={1}
      borderWidth={1}
      borderRadius={10}
      borderColor="muted.400"
      p={3}
    >
      <Heading
        fontSize="lg"
        color="primary.500"
      >
        {props.title}
      </Heading>
      {
        leaveBalanceDetail.leaveAllocation > 0 ?
          <HStack
            flex={1}
            flexWrap="wrap"
          >
            {leaveApplicationStatus}
          </HStack>
          :
          <Box flex={1}>
            <Text
              color="primary.500"
              italic
            >
              {t('leaveApplicationScreen.noCasualLeaveCount')}
            </Text>
          </Box>
      }
    </VStack>
  );
};

const LeaveBalanceComponent = (props: LeaveBalanceComponentProps) => {
  let leaveBalanceElement: JSX.Element[] = [];
  Object.entries(props.leaveBalance).forEach(([leaveType, detail]) => {
    leaveBalanceElement.push(
      <LeaveCountComponent
        key={leaveType}
        title={leaveType}
        leaveBalanceDetail={detail}
      />
    );
  });
  return (
    <VStack
      flex={1}
      space="md"
    >
      {leaveBalanceElement}
    </VStack>
  );
};

export const LeaveBalanceScreen = (props: LeaveBalanceScreenProps) => {

  const {t}: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Center
      flex={1}
      bg="secondary.500"
      safeAreaX
    >
      <ScrollView
        h="full"
        w="full"
        contentContainerStyle={{
          padding: 16,
        }}
        refreshControl={
          <RefreshControl
            refreshing={props.isFetchingLeaveCount}
            onRefresh={props.handleRefreshScreen}
            tintColor={theme.colors.primary[500]}
            titleColor={theme.colors.primary[500]}
            title={t('scrollView.pullToRefresh')}
          />
        }
      >
        <VStack space="lg">
          {
            props.leaveBalance &&
            <LeaveBalanceComponent
              leaveBalance={props.leaveBalance}
            />
          }
        </VStack>
      </ScrollView>
    </Center>
  );
};
