import {
  Achievement,
  Attendance,
  AttendanceBreak,
  LeaveApplication,
  LeaveCount,
  MonthlyInfo,
  PerformanceAttribute,
  Timelog,
  CompanyData,
  User,
  FeatureList,
  FeatureListAttendance,
  FeatureListTimesheet,
  FeatureListLeaveApplication,
  FeatureListGamification, TimesheetDetail, ProjectDetail, LeaveBalanceDetail, CompanyInformation, Project
} from "../state";
import {CASUAL_LEAVE, SICK_LEAVE} from "../master-data/constants";

// used to format the response of a GET call to
// api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_data_from_company_id
export const extractCompanyData = (companyData: any): CompanyData => {
  return {
    serverUrl: companyData.server_url,
    companyId: companyData.company_id,
    companyName: companyData.company_name,
    branchName: companyData.branch_name,
    companyImage: companyData.company_image_url
  };
};

// used to format the response of a GET call to
// api/resource/Attendance endpoint
export const extractAttendanceList = (unformattedAttendanceList: any[]): Attendance[] => {
  let formattedAttendanceList: Attendance[] = [];
  unformattedAttendanceList.forEach((unformattedAttendance: any) => {
    formattedAttendanceList.push({
      name: unformattedAttendance.name,
      attendanceDate: unformattedAttendance.attendance_date,
      startTime: unformattedAttendance.start_time,
      endTime: unformattedAttendance.end_time,
      employeeName: unformattedAttendance.employee,
      docstatus: unformattedAttendance.docstatus
    })
  });
  return formattedAttendanceList;
};

// used to format the response of a GET call to
// api/resource/Attendance endpoint
export const extractNewAttendance = (unformattedAttendance: any): Attendance => {
  return {
    name: unformattedAttendance.name,
    attendanceDate: unformattedAttendance.attendance_date,
    startTime: unformattedAttendance.start_time,
    endTime: unformattedAttendance.end_time,
    employeeName: unformattedAttendance.employee,
    docstatus: unformattedAttendance.docstatus
  };
};

// used to format the response of a GET call to
// api/resource/Attendance endpoint
export const extractCurrentAttendanceBreak = (unformattedAttendanceBreakList: any[]): AttendanceBreak[] => {
  let formattedAttendanceBreakList: AttendanceBreak[] = [];
  unformattedAttendanceBreakList.forEach((unformattedAttendanceBreak: any) => {
    if (unformattedAttendanceBreak.total_hours === 0) {
      formattedAttendanceBreakList.push({
        name: unformattedAttendanceBreak.attendance_break,
        startTime: unformattedAttendanceBreak.start_time,
        endTime: unformattedAttendanceBreak.end_time,
        totalHours: unformattedAttendanceBreak.total_hours
      })
    }
  });
  return formattedAttendanceBreakList;
};

// used to format the response of a GET call to
// api/resource/Attendance endpoint
export const extractAttendanceBreakList = (unformattedAttendanceBreakList: any[]): AttendanceBreak[] => {
  let formattedAttendanceBreakList: AttendanceBreak[] = [];
  unformattedAttendanceBreakList.forEach((unformattedAttendanceBreak: any) => {
    formattedAttendanceBreakList.push({
      name: unformattedAttendanceBreak.attendance_break,
      startTime: unformattedAttendanceBreak.start_time,
      endTime: unformattedAttendanceBreak.end_time,
      totalHours: unformattedAttendanceBreak.total_hours
    })
  });
  return formattedAttendanceBreakList;
};

// used to format the response of a POST call to
// api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.create_new_attendance endpoint and api/resource/Attendance endpoint
export const extractAttendanceData = (frappeResponse: any, withEndTime: boolean): Attendance => {
  return {
    name: frappeResponse.name,
    attendanceDate: frappeResponse.attendance_date,
    employeeName: frappeResponse.employee,
    startTime: frappeResponse.start_time,
    endTime: withEndTime ? frappeResponse.end_time : "",
    docstatus: frappeResponse.docstatus
  };
};

// used to format the response of a POST call to
// api/resource/Attendance%20Break endpoint
export const extractAttendanceBreakData = (frappeResponse: any): AttendanceBreak => {
  return {
    name: frappeResponse.name,
    startTime: frappeResponse.start_time,
    endTime: frappeResponse.end_time,
    totalHours: frappeResponse.total_hours
  };
};

// used to format the response of a GET call to
// api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_monthly_info endpoint
export const extractMonthlyInfoList = (rawMonthlyInfoList: any[]): MonthlyInfo[] => {
  let formattedMonthlyInfoList: MonthlyInfo[] = [];
  rawMonthlyInfoList.forEach((rawMonthlyInfo: any) => {
    formattedMonthlyInfoList.push({
      name: rawMonthlyInfo.employee,
      employeeFullName: rawMonthlyInfo.employee_full_name,
      employeeProfileImageUrl: rawMonthlyInfo.employee_profile_image_url,
      attendanceStreak: rawMonthlyInfo.attendance_streak,
      point: rawMonthlyInfo.point,
      month: rawMonthlyInfo.month,
      year: rawMonthlyInfo.year,
      isOnline: rawMonthlyInfo.is_online,
      isBreak: rawMonthlyInfo.is_break
    })
  });
  return formattedMonthlyInfoList;
};

// used to format the response of a GET call to
// /api/resource/Project endpoint
export const extractProject = (rawData: any[]): Project[] => {
  let formattedData: Project[] = [];
  rawData.forEach((dataRow: any) => {
    formattedData.push({
      name: dataRow.name,
      projectName: dataRow.project_name,
    });
  });
  return formattedData;
};

// used to format the response of a GET call to
// /api/resource/Activity%20Type endpoint
export const extractActivity = (rawData: any[]): string[] => {
  let formattedData: string[] = [];
  rawData.forEach((dataRow: any) => {
    formattedData.push(dataRow.name)
  });
  return formattedData;
};

export const formatTimeLogsSubmission = (timeLogs: Timelog[]) => {
  const formattedTimeLogs: any[] = [];
  timeLogs.forEach(timeLog => {
    formattedTimeLogs.push({
      name: timeLog.name,
      index: timeLog.index,
      project: timeLog.projectName,
      activity_type: timeLog.activity,
      duration: timeLog.duration,
    });
  });
  return formattedTimeLogs;
};

// used to format the response of a GET call to
// /api/resource/Timesheet endpoint
export const extractTimelog = (rawData: any[]): Timelog[] => {
  let formattedData: Timelog[] = [];
  rawData.forEach((dataRow: any) => {
    formattedData.push({
      name: dataRow.name,
      timesheetName: dataRow.timesheet_name,
      index: dataRow.index,
      projectName: dataRow.project_name,
      projectTitle: dataRow.project_title,
      duration: dataRow.duration,
      activity: dataRow.activity,
    })
  });
  return formattedData;
};

export const extractTimelogAfterAddingTimelog = (rawData: any[]): Timelog[] => {
  let formattedData: Timelog[] = [];
  rawData.forEach((dataRow: any) => {
    formattedData.push({
      name: dataRow.name,
      timesheetName: dataRow.parent,
      index: dataRow.idx,
      projectName: dataRow.project,
      projectTitle: dataRow.project_name,
      duration: dataRow.hours,
      activity: dataRow.activity_type,
    })
  });
  return formattedData;
};

// used to format the response of a GET call to
// /api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_leave_application endpoint
export const extractLeaveApplicationList = (rawLeaveApplicationList: any[]): LeaveApplication[] => {
  let formattedLeaveApplicationList: LeaveApplication[] = [];
  rawLeaveApplicationList.forEach((rawLeaveApplication: any) => {
    formattedLeaveApplicationList.push({
      name: rawLeaveApplication.name,
      employeeName: rawLeaveApplication.employee_name,
      fromDate: rawLeaveApplication.from_date,
      toDate: rawLeaveApplication.to_date,
      leaveType: rawLeaveApplication.leave_type,
      status: rawLeaveApplication.status,
    })
  });
  return formattedLeaveApplicationList;
};

// used to format the response of a GET call to
// /api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_leave_balance_and_allocation
export const extractLeaveCountData = (rawLeaveCountData: any): LeaveCount => {
  return ({
    casualLeaveAllocation: rawLeaveCountData.casual_leave_allocation,
    casualLeaveBalance: rawLeaveCountData.current_casual_leave_balance,
    sickLeaveAllocation: rawLeaveCountData.sick_leave_allocation,
    sickLeaveBalance: rawLeaveCountData.current_sick_leave_balance,
  })
};

const extractLeaveBalanceDetail = (rawLeaveBalanceDetail: any): LeaveBalanceDetail => {
  return {
    leaveAllocation: rawLeaveBalanceDetail.leave_allocation,
    currentLeaveBalance: rawLeaveBalanceDetail.current_leave_balance,
    totalFutureLeaveApplication: rawLeaveBalanceDetail.total_future_leave_applications,
  };
};

export const extractLeaveBalance = (
  rawLeaveBalance: any,
  hasCasualLeaveFeature: boolean,
  hasSickLeaveFeature: boolean,
) => {
  let formattedLeaveBalance: { [leaveType: string]: LeaveBalanceDetail } = {};
  Object.entries(rawLeaveBalance).forEach(([leaveType, value]) => {
    if (leaveType === CASUAL_LEAVE && hasCasualLeaveFeature) {
      formattedLeaveBalance[leaveType] = extractLeaveBalanceDetail(value);
    } else if (leaveType === SICK_LEAVE && hasSickLeaveFeature) {
      formattedLeaveBalance[leaveType] = extractLeaveBalanceDetail(value);
    } else if (leaveType !== CASUAL_LEAVE && leaveType !== SICK_LEAVE) {
      formattedLeaveBalance[leaveType] = extractLeaveBalanceDetail(value);
    }
  });
  return formattedLeaveBalance;
}

// used to format the response of a GET call to
// /api/resource/Achievement
export const extractAchievementList = (unformattedAchievementList: any[]): Achievement[] => {
  let formattedAchievementList: Achievement[] = [];
  unformattedAchievementList.forEach((unformattedAchievement: any) => {
    formattedAchievementList.push({
      name: unformattedAchievement.name,
      achievementTypeName: unformattedAchievement.achievement_type_name,
      icon: unformattedAchievement.icon_1,
      threshold: unformattedAchievement.threshold_1,
      description: unformattedAchievement.achievement_description,
      achievementName: unformattedAchievement.achievement_name,
      currentProgress: unformattedAchievement.current_progress
    });
  });
  return formattedAchievementList;
};

// used to format the response of a GET call to
// /api/resource/Performance%20Attribute
export const extractPerformanceAttribute = (unformattedPerformanceAttribute: any): PerformanceAttribute => {
  return {
    totalWorkHour: unformattedPerformanceAttribute.total_work_hour,
    totalOverworkHour: unformattedPerformanceAttribute.total_overwork_hour,
    totalEarlyExit: unformattedPerformanceAttribute.total_early_exit,
    totalLateEntry: unformattedPerformanceAttribute.total_late_entry,
    totalEarlyEntry: unformattedPerformanceAttribute.total_early_entry,
    totalLateLeave: unformattedPerformanceAttribute.total_late_leave,
    totalAbsence: unformattedPerformanceAttribute.total_absence,
    totalFullDay: unformattedPerformanceAttribute.total_full_day,
    totalHalfDay: unformattedPerformanceAttribute.total_half_day,
    totalTimesheetSubmitted: unformattedPerformanceAttribute.total_timesheet_submitted,
    totalMonthlyTrophyGet: unformattedPerformanceAttribute.total_monthly_trophy_get
  };
};

export const extractUserInfo = (unformattedUserInfo: any): User => {
  return{
    username: unformattedUserInfo.user_id,
    employeeName: unformattedUserInfo.employee_id,
    employeeFullName: unformattedUserInfo.employee_name,
    employmentType: unformattedUserInfo.employment_type,
    designation: unformattedUserInfo.designation_name,
    designationIconUrl: unformattedUserInfo.designation_icon_url,
    monthlyInfoName: unformattedUserInfo.monthly_info_id,
    performanceAttributeName: unformattedUserInfo.performance_attribute_id,
    company: unformattedUserInfo.company,
    branch: unformattedUserInfo.branch,
    profileImageUrl: unformattedUserInfo.profile_image_url,
    featureList: extractFeatureList(unformattedUserInfo.feature_list)
  }
}

export const extractFeatureList = (unformattedFeatureList: any): FeatureList => {
  return{
    attendance: unformattedFeatureList.attendance ? extractFeatureListAttendance(unformattedFeatureList.attendance) : null,
    timesheet: unformattedFeatureList.timesheet ? extractFeatureListTimesheet(unformattedFeatureList.timesheet) : null,
    leaveApplication: unformattedFeatureList.leave_application ? extractFeatureListLeaveApplication(unformattedFeatureList.leave_application) : null,
    gamification: unformattedFeatureList.gamification ? extractFeatureListGamification(unformattedFeatureList.gamification) : null
  }
}

export const extractFeatureListAttendance = (unformattedFeatureListAttendance: any): FeatureListAttendance => {
  return{
    takeABreak: unformattedFeatureListAttendance.take_a_break,
    otherDate: unformattedFeatureListAttendance.other_date,
    locationReqd: unformattedFeatureListAttendance.location_reqd
  }
}

export const extractFeatureListTimesheet = (unformattedFeatureListTimesheet: any): FeatureListTimesheet => {
  return{
    otherDate: unformattedFeatureListTimesheet.other_date
  }
}

export const extractFeatureListLeaveApplication = (unformattedFeatureListLeaveApplication: any): FeatureListLeaveApplication => {
  return{
    casualLeaveEnabled: unformattedFeatureListLeaveApplication.casual_leave_enabled,
    casualLeaveReasonReqd: unformattedFeatureListLeaveApplication.casual_leave_reason_reqd,
    sickLeaveEnabled: unformattedFeatureListLeaveApplication.sick_leave_enabled,
    sickLeaveReasonReqd: unformattedFeatureListLeaveApplication.sick_leave_reason_reqd,
    sickLeaveMedCertReqd: unformattedFeatureListLeaveApplication.sick_leave_med_cert_reqd
  }
}

export const extractFeatureListGamification = (unformattedFeatureListGamification: any): FeatureListGamification => {
  return{
    pointSystemEnabled: unformattedFeatureListGamification.point_system_enabled,
    achievementEnabled: unformattedFeatureListGamification.achievement_enabled
  }
}

const formatProjectActivities = (unformattedActivities: {[activityName: string]: number}) => {
  let formattedProjectActivities: { [activityName: string]: number } = {};
  Object.entries(unformattedActivities).forEach(([key, value]) => {
    formattedProjectActivities[key] = value;
  });
  return formattedProjectActivities;
};

const formatProjectDetail = (unformattedProjectDetail: any): ProjectDetail => {
  return {
    totalProjectHours: unformattedProjectDetail.total_project_hours,
    colorCode: unformattedProjectDetail.color_code,
    activities: formatProjectActivities(unformattedProjectDetail.activities),
  };
};

const formatSingleProject = (unformattedSingleProject: any) => {
  let formattedProject: { [projectName: string]: ProjectDetail } = {};
  Object.entries(unformattedSingleProject).forEach(([key, value]) => {
    formattedProject[key] = formatProjectDetail(value);
  });
  return formattedProject;
}

export const extractTimesheetDetails = (unformattedTimesheetDetails: any): TimesheetDetail => {
  return {
    mostActiveProject: unformattedTimesheetDetails.most_active_project,
    mostActivity: unformattedTimesheetDetails.most_activity,
    workingHours: unformattedTimesheetDetails.working_hours,
    projects: formatSingleProject(unformattedTimesheetDetails.projects),
  };
};

export const extractCompanyInformation = (unformattedCompanyInformation: any): CompanyInformation => {
  return {
    companyName: unformattedCompanyInformation.company_name || '',
    companyDescription: unformattedCompanyInformation.company_description || '',
    companyLogo: unformattedCompanyInformation.company_logo || '',
    email: unformattedCompanyInformation.email || '',
    phone: unformattedCompanyInformation.phone_no || '',
    address: unformattedCompanyInformation.address || '',
    codeOfConduct: unformattedCompanyInformation.code_of_conduct || '',
    visionMission: unformattedCompanyInformation.vision_mission || '',
    whatsAppNumber: unformattedCompanyInformation.whatsapp_number || '',
  };
};
