import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import idTranslation from "../assets/locales/id/translation.json";
import enTranslation from "../assets/locales/en/translation.json";
import { NativeModules, Platform } from "react-native";
import { updateLanguage } from "./actions/actions";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "./master-data/constants";

const resources = {
  id: {
    translation: idTranslation,
  },
  in: {
    translation: idTranslation,
  },
  en: {
    translation: enTranslation,
  },
};

export const initI18N = (store: any) => {
  const locale =
    Platform.OS === "ios"
      ? NativeModules.SettingsManager.settings.AppleLocale || NativeModules.SettingsManager.settings.AppleLanguages[0]
      : NativeModules.I18nManager.localeIdentifier;

  let state = store.getState();

  const initialLanguage = SUPPORTED_LANGUAGES.includes(locale.split("_")[0]) ? locale.split("_")[0] : DEFAULT_LANGUAGE;
  store.dispatch(updateLanguage(initialLanguage));

  i18n.use(initReactI18next).init({
    resources,
    lng: state.control.language,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};
