import 'react-native-gesture-handler';
import React from 'react';
import {useFonts} from 'expo-font';
import {
  Oswald_200ExtraLight,
  Oswald_300Light,
  Oswald_400Regular,
  Oswald_500Medium,
  Oswald_600SemiBold,
  Oswald_700Bold
} from '@expo-google-fonts/oswald';
import {
  Raleway_100Thin,
  Raleway_100Thin_Italic,
  Raleway_200ExtraLight,
  Raleway_200ExtraLight_Italic,
  Raleway_300Light,
  Raleway_300Light_Italic,
  Raleway_400Regular,
  Raleway_400Regular_Italic,
  Raleway_500Medium,
  Raleway_500Medium_Italic,
  Raleway_600SemiBold,
  Raleway_600SemiBold_Italic,
  Raleway_700Bold,
  Raleway_700Bold_Italic,
  Raleway_800ExtraBold,
  Raleway_800ExtraBold_Italic,
  Raleway_900Black,
  Raleway_900Black_Italic
} from '@expo-google-fonts/raleway';
import AppLoading from "expo-app-loading";
import {applyMiddleware, createStore} from "redux";
import {reducers} from "./src/reducers";
import {Provider} from "react-redux";
import {createLogger} from "redux-logger";
import thunkMiddleware from "redux-thunk";
import TapApplication from "./src/TapApplication";
import {composeWithDevTools} from "redux-devtools-extension";
import {PersistConfig} from "redux-persist/es/types";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import {State} from "./src/state";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {persistReducer, persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import {extendTheme, NativeBaseProvider} from 'native-base';
import {defaultTheme} from "./tap-theme";
import {extractApiKeyFromSecureStore} from "./src/utils/secureStoreUtils";
import { initI18N } from './src/i18n';
import axios from "axios";
import {API_TIMEOUT} from "./src/master-data/constants";
import * as Sentry from "sentry-expo";
import * as Application from 'expo-application';
import {REACT_NATIVE_APPLICATION_SENTRY_DSN} from "@env";

axios.defaults.timeout = API_TIMEOUT;

const App = () => {

  Sentry.init({
    dsn: REACT_NATIVE_APPLICATION_SENTRY_DSN,
    autoSessionTracking: true,
    enableAutoSessionTracking: true,
    enableInExpoDevelopment: process.env.REACT_NATIVE_APPLICATION_ENVIRONMENT === 'development',
    sessionTrackingIntervalMillis: 20000,
    environment: process.env.REACT_NATIVE_APPLICATION_ENVIRONMENT,
    release: `v${Application.nativeApplicationVersion}, b${Application.nativeBuildVersion}`,
    ignoreErrors: ['Firebase is not configured.'],
    debug: process.env.REACT_NATIVE_APPLICATION_ENVIRONMENT !== 'production',
  });

  let [fontsLoaded] = useFonts({
    Oswald_200ExtraLight,
    Oswald_300Light,
    Oswald_400Regular,
    Oswald_500Medium,
    Oswald_600SemiBold,
    Oswald_700Bold,
    Raleway_100Thin,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light,
    Raleway_300Light_Italic,
    Raleway_400Regular,
    Raleway_400Regular_Italic,
    Raleway_500Medium,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black,
    Raleway_900Black_Italic
  });

  const logger = createLogger({
    level: "info",
    collapsed: true,
    diff: true
  });

  const middleware = __DEV__ ?
    composeWithDevTools(applyMiddleware(
      thunkMiddleware,
      logger
    ))
    :
    applyMiddleware(
      thunkMiddleware
    )
  ;

  const reducerPersistConfig: PersistConfig<State> = {
    key: "tap-state",
    storage: AsyncStorage,
    blacklist: ["data", "communication", "session"],
    whitelist: ["control"],
    stateReconciler: autoMergeLevel2
  };

  const persistedReducer = persistReducer(reducerPersistConfig, reducers);

  const store = createStore(persistedReducer, middleware);

  const persistor = persistStore(store);

  initI18N(store);
  extractApiKeyFromSecureStore(store);

  const theme = extendTheme({
    colors: defaultTheme,
    fontConfig: {
      Oswald: {
        200: {
          normal: 'Oswald_200ExtraLight',
        },
        300: {
          normal: 'Oswald_300Light'
        },
        400: {
          normal: 'Oswald_400Regular'
        },
        500: {
          normal: 'Oswald_500Medium'
        },
        600: {
          normal: 'Oswald_600SemiBold'
        },
        700: {
          normal: 'Oswald_700Bold'
        },
      },
      Raleway: {
        100: {
          normal: 'Raleway_100Thin',
          italic: 'Raleway_100Thin_Italic'
        },
        200: {
          normal: 'Raleway_200ExtraLight',
          italic: 'Raleway_200ExtraLight_Italic'
        },
        300: {
          normal: 'Raleway_300Light',
          italic: 'Raleway_300Light_Italic'
        },
        400: {
          normal: 'Raleway_400Regular',
          italic: 'Raleway_400Regular_Italic'
        },
        500: {
          normal: 'Raleway_500Medium',
          italic: 'Raleway_500Medium_Italic'
        },
        600: {
          normal: 'Raleway_600SemiBold',
          italic: 'Raleway_600SemiBold_Italic'
        },
        700: {
          normal: 'Raleway_700Bold',
          italic: 'Raleway_700Bold_Italic'
        },
        800: {
          normal: 'Raleway_800ExtraBold',
          italic: 'Raleway_800ExtraBold_Italic'
        },
        900: {
          normal: 'Raleway_900Black',
          italic: 'Raleway_900Black_Italic'
        }
      }
    },
    fonts: {
      heading: 'Oswald',
      body: 'Raleway',
      mono: 'Raleway'
    },
    components: {
      Heading: {
        defaultProps: {
          allowFontScaling: false,
        },
        baseStyle: (colorMode: string) => {
          return {
            color: colorMode === 'dark' ? 'secondary.500' : 'primary.500',
            fontWeight: 500
          };
        },
      },
      Input: {
        defaultProps: {
          allowFontScaling: false,
        },
      },
      Select: {
        defaultProps: {
          allowFontScaling: false,
        },
      },
      Icon: {
        defaultProps: {
          allowFontScaling: false,
        },
      },
      Text: {
        defaultProps: {
          allowFontScaling: false,
        },
        baseStyle: (colorMode: string) => {
          return {
            color: colorMode === 'dark' ? 'secondary.500' : 'primary.500',
            fontWeight: 500
          };
        },
      }
    }
  });

  if (!fontsLoaded) {
    return (<AppLoading/>)
  } else {
    return (
      <React.Fragment>
        <Provider store={store}>
          <PersistGate loading={<AppLoading/>} persistor={persistor}>
            <NativeBaseProvider theme={theme}>
              <TapApplication/>
            </NativeBaseProvider>
          </PersistGate>
        </Provider>
      </React.Fragment>
    );
  }
}

export default App;
