import React, {useState} from "react";
import ActivityScreen from "../screens/ActivityScreen";

export const ActivityScreenContainer = () => {
  let initialState = {
    currentActiveActivityIndex: 0
  };

  const [currentActiveActivityIndex, setCurrentActiveActivityIndex] = useState(initialState.currentActiveActivityIndex);

  const handlePageChange = (index: number) => {
    setCurrentActiveActivityIndex(index);
  };

  const shouldLoadComponent = (index: number) => {
    return index === currentActiveActivityIndex;
  }

  return (
    <ActivityScreen currentActiveActivityIndex={currentActiveActivityIndex}
                    handlePageChange={handlePageChange}
                    shouldLoadComponent={shouldLoadComponent}
    />
  )
};
