import React from "react";
import {Box, Button, Divider, HStack, Icon, ScrollView, Text, useTheme, VStack} from "native-base";
import { Calendar, DateData } from "react-native-calendars";
import { FontAwesome } from "@expo/vector-icons";
import {DATE_DISPLAY_FORMAT_FULL, TIME_DISPLAY_FORMAT} from "../lib/dateFormat";
import moment from "moment";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import { useTranslation } from "react-i18next";

// Components
import { TapInputElement } from "../Components/TapInputElement";


interface OtherDateScreenProps {
  selectedDate: DateData;
  selectedStartTime: string;
  selectedEndTime: string;
  isStartTimeFromServerExist: boolean;
  isEndTimeFromServerExist: boolean;
  isSavingOtherDate: boolean;
  isStartTimePickerVisible: boolean;
  isEndTimePickerVisible: boolean;
  isFeatureAvailable: boolean;
  hasAttendanceDates: string[];
  isStartTimeError: boolean;
  isEndTimeError: boolean;
  handleSelectedDateChange: (date: DateData) => void;
  handleOnMonthChange: (date: DateData) => void;
  showStartTimePicker: () => void;
  showEndTimePicker: () => void;
  hideStartTimePicker: () => void;
  hideEndTimePicker: () => void;
  handleConfirmStartTimePicker: (time: Date) => void;
  handleConfirmEndTimePicker: (time: Date) => void;
  handleSaveButtonClick: () => void;
}

const OtherDateScreen: React.FC<OtherDateScreenProps> = (props: OtherDateScreenProps) => {
  const {t}: { t: any } = useTranslation();
  const hasAttendance = {
    key: "hasAttendance",
    selectedDotColor: "#FCB25D",
    color: "#FCB25D",
    marked: true,
  };
  const isCheckOutLaterThanCheckIn =
    props.selectedStartTime &&
    props.selectedEndTime &&
    props.selectedStartTime > props.selectedEndTime;

  const floatTotalWorkingHours = moment.duration(
    moment(props.selectedEndTime, TIME_DISPLAY_FORMAT).diff(
      moment(props.selectedStartTime, TIME_DISPLAY_FORMAT)
    )
  );
  const floatTotalWorkingHoursFormat =
    floatTotalWorkingHours.hours() + " " +
    String(t('modal.timesheetModal.durationTimeHoursFormLabel')) + " " +
    floatTotalWorkingHours.minutes() + " " +
    String(t('modal.timesheetModal.durationTimeMinsFormLabel'));

  const theme = useTheme();

  const getMarkedDates = () => {
    let markedDates: { [date: string]: any } = {};
    props.hasAttendanceDates.forEach((singleDate: string) => {
      markedDates[singleDate] = { dots: [hasAttendance] };
    });
    markedDates[props.selectedDate.dateString] = Object.assign(
      {},
      markedDates[props.selectedDate.dateString],
      {
        selected: true,
        selectedColor: theme.colors.success[500],
      }
    );
    return markedDates;
  };

  const renderCalendarArrow = (direction: "left" | "right") => {
    if (direction === "left") {
      return (
        <Icon
          as={FontAwesome}
          size={"sm"}
          name="caret-left"
          color={"secondary.100"}
        />
      );
    }
    if (direction === "right") {
      return (
        <Icon
          as={FontAwesome}
          size={"sm"}
          name="caret-right"
          color={"secondary.100"}
        />
      );
    }
    return null;
  };

  return (
    <ScrollView
      w="full"
      h="full"
      contentContainerStyle={{
        padding: 20,
      }}
      bg="secondary.500"
    >
      <VStack
        flex={1}
        space="lg"
      >
        <Box>
          <Calendar
            style={{
              borderRadius: wp(2),
              alignSelf: "center",
              width: "100%",
              paddingVertical: hp(1),
              backgroundColor: theme.colors.primary[500],
            }}
            renderArrow={renderCalendarArrow}
            hideExtraDays={true}
            theme={{
              calendarBackground: theme.colors.primary[500],
              dayTextColor: theme.colors.secondary[100],
              textSectionTitleColor: theme.colors.secondary[100],
              todayTextColor: theme.colors.success[500],
              arrowColor: theme.colors.secondary[100],
              monthTextColor: theme.colors.secondary[100],
              textMonthFontSize: hp(3),
              textDayHeaderFontSize: hp(1.8),
              textDayFontSize: hp(2),
            }}
            onDayPress={(nextDate) => {
              props.handleSelectedDateChange(nextDate);
            }}
            onMonthChange={(date) => props.handleOnMonthChange(date)}
            markedDates={getMarkedDates()}
            markingType={"multi-dot"}
            enableSwipeMonths={true}
          />
        </Box>
        <VStack space="md">
          <Text
            fontSize="xl"
            fontWeight="bold"
            color="muted.500"
          >
            Detail
          </Text>
          <VStack space="xs">
            <Box
              borderColor="muted.400"
              borderRadius={10}
              borderWidth={1}
            >
              <Box
                px={4}
                py={2}
              >
                <Text
                  fontSize="md"
                  bold
                >
                  {moment(props.selectedDate.dateString).format(DATE_DISPLAY_FORMAT_FULL)}
                </Text>
              </Box>
              <Divider
                borderColor="muted.400"
                borderWidth={0.5}
              />
              <HStack
                justifyContent="space-between"
                p={4}
                space="md"
              >
                <TapInputElement
                  mode="time"
                  labelName="TAP IN"
                  errorText={String(t('otherDateScreen.checkInTimeError'))}
                  isError={props.isStartTimeError}
                  selectedValue={props.selectedStartTime}
                  isDateTimePickerOpen={props.isStartTimePickerVisible}
                  readOnly={props.isStartTimeFromServerExist}
                  iconName="clock-o"
                  openDateTimePicker={props.showStartTimePicker}
                  closeDateTimePicker={props.hideStartTimePicker}
                  handleValueChange={props.handleConfirmStartTimePicker}
                />
                <TapInputElement
                  mode="time"
                  labelName="TAP OUT"
                  errorText={String(t('otherDateScreen.checkOutTimeError'))}
                  isError={props.isEndTimeError}
                  selectedValue={props.selectedEndTime}
                  isDateTimePickerOpen={props.isEndTimePickerVisible}
                  readOnly={props.isEndTimeFromServerExist}
                  iconName="clock-o"
                  openDateTimePicker={props.showEndTimePicker}
                  closeDateTimePicker={props.hideEndTimePicker}
                  handleValueChange={props.handleConfirmEndTimePicker}
                />
              </HStack>
            </Box>
            <HStack>
              {isCheckOutLaterThanCheckIn ? (
                <Text color={"warning.500"} fontSize={"xs"}>
                  {String(t('otherDateScreen.timeErrorText'))}
                </Text>
              ) : floatTotalWorkingHours &&
              floatTotalWorkingHours.hours() +
              floatTotalWorkingHours.minutes() >
              0 ? (
                <React.Fragment>
                  <Text color={"primary.500"} fontSize={"sm"}>
                    {String(t('otherDateScreen.totalWorkingHours'))}{" "}
                  </Text>
                  <Text color={"primary.500"} fontSize={"sm"} bold>
                    {floatTotalWorkingHoursFormat}
                  </Text>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
            </HStack>
          </VStack>
          {
            props.isFeatureAvailable ?
              <Button
                size={"md"}
                colorScheme={"success"}
                bg={props.isStartTimeFromServerExist && props.isEndTimeFromServerExist  ? "muted.300" : "primary.500"}
                onPress={props.handleSaveButtonClick}
                isLoading={props.isSavingOtherDate}
                disabled={
                  props.isStartTimeFromServerExist && props.isEndTimeFromServerExist
                }
                _text={{
                  fontSize: "md",
                  fontWeight: "bold",
                  fontFamily: "heading",
                  color: props.isStartTimeFromServerExist && props.isEndTimeFromServerExist  ? "muted.500" : "light.100" }}
              >{
                props.isStartTimeFromServerExist && props.isEndTimeFromServerExist  ?
                  String(t('button.savedButton')) :
                  String(t('button.saveButton'))
              }
              </Button>
              :
              <Box>
                <Text italic>
                  {t('otherDateScreen.featureUnavailable')}
                </Text>
              </Box>
          }
        </VStack>
      </VStack>
    </ScrollView>
  );
};

export default OtherDateScreen;
