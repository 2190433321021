import React, {useEffect, useState} from "react";
import {Attendance, AttendanceBreak, State, Timelog, User} from "../state";
import {connect, useDispatch} from "react-redux";
import {editTimeLog, fetchTimelogs} from "../actions/actionsTimesheet";
import {
  DATE_DISPLAY_FORMAT_SHORT,
  FIXED_TIMEZONE_NAME,
  formatDateUIFriendly,
  formatDateYYYYMMDD, TIME_DISPLAY_FORMAT
} from "../lib/dateFormat";
import moment from "moment";
import TimesheetFormModalContainer from "../Containers/TimesheetFormModalContainer";
import {updateTimesheetFormModalOpen} from "../actions/actions";
import {TimesheetScreen} from "../screens/TimesheetScreen";
import * as Analytics from "expo-firebase-analytics";
import {initialControlState} from "../initialStates";
import {CommonActions, useNavigation} from "@react-navigation/native";
import {getCurrentServerTime} from "../actions/actionsDateTime";
import {fetchAttendanceBreaks} from "../actions/actionsAttendance";

interface TimesheetScreenContainerProps {
  currentUser?: User;
  currentAttendance?: Attendance;
  currentTimelogs: Timelog[];
  attendanceBreaksList: AttendanceBreak[];
  currentServerTime: string;
  isFetchingTimelogs: boolean;
}

const mapStateToProps = (state: State) => {
  return {
    currentUser: state.control.currentUser,
    currentAttendance: state.control.currentAttendance,
    currentServerTime: state.control.currentServerTime,
    currentTimelogs: state.data.timelogs,
    attendanceBreaksList: state.data.attendanceBreaks,
    isFetchingTimelogs: state.session.timelogs.isFetching
  }
}

const TimesheetScreenContainer = (props: TimesheetScreenContainerProps) => {
  const currentUser = props.currentUser;
  const currentAttendance = props.currentAttendance;
  const currentAttendanceName = currentAttendance ? currentAttendance.name : '';
  const attendanceBreaksList = props.attendanceBreaksList;
  const employeeName = currentUser ? currentUser.employeeName : "";
  const isFetchingTimelogs = props.isFetchingTimelogs;

  const [momentDate, setMomentDate] = useState(moment().toDate());
  const [selectedDate, setSelectedDate] = useState(moment().format(DATE_DISPLAY_FORMAT_SHORT));
  const [currentTimelogs, setCurrentTimelogs] = useState<Timelog[]>(props.currentTimelogs);
  const [currentServerTime, setCurrentServerTime] =
    useState(moment().tz(FIXED_TIMEZONE_NAME).format(TIME_DISPLAY_FORMAT));
  const [singleTimeLog, setSingleTimeLog] = useState<Timelog>(initialControlState.initialTimeLog);
  const [isToday, setIsToday] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const today = moment().toDate();

  const dispatch = useDispatch();
  const navigation = useNavigation();

  useEffect(() => {
    Analytics.setCurrentScreen("Timesheet Screen", "Timesheet Screen");
  }, []);

  useEffect(() => {
    if (employeeName) {
      dispatch(fetchTimelogs(employeeName, formatDateYYYYMMDD(momentDate)));
      dispatch(getCurrentServerTime());
    }
  }, [employeeName, dispatch, momentDate]);

  useEffect(() => {
    if (props.currentServerTime) {
      setCurrentServerTime(moment(props.currentServerTime).tz(FIXED_TIMEZONE_NAME).format(TIME_DISPLAY_FORMAT));
      return;
    }
  }, [props.currentServerTime]);

  useEffect(() => {
    setCurrentTimelogs(props.currentTimelogs);
  }, [props.currentTimelogs]);

  useEffect(() => {
    if (currentAttendanceName) {
      dispatch(fetchAttendanceBreaks(currentAttendanceName));
    }
  }, [currentAttendanceName]);

  const handleCreateTimelog = () => {
    dispatch(updateTimesheetFormModalOpen(true));
  };

  const handleLeftArrowClick = () => {
    const subtractedMomentDate = moment(momentDate).subtract(1, 'days').toDate();
    setMomentDate(subtractedMomentDate);
    setSelectedDate(formatDateUIFriendly(subtractedMomentDate));
    if (formatDateUIFriendly(subtractedMomentDate) !== formatDateUIFriendly(today)){
      setIsToday(false);
    }else{
      setIsToday(true);
    }
  };

  const handleRightArrowClick = () => {
    const addedMomentDate = moment(momentDate).add(1, 'days').toDate();
    setMomentDate(addedMomentDate);
    setSelectedDate(formatDateUIFriendly(addedMomentDate));
    if (formatDateUIFriendly(addedMomentDate) !== formatDateUIFriendly(today)){
      setIsToday(false);
    }else{
      setIsToday(true);
    }
  };

  const handleDateOnChange = (date: string) => {
    const formatDate = moment(date, DATE_DISPLAY_FORMAT_SHORT).toDate();
    if (moment(date, DATE_DISPLAY_FORMAT_SHORT).isSame(moment(), 'day')){
      setIsToday(true);
    }else{
      setIsToday(false);
    }
    setMomentDate(formatDate);
    setSelectedDate(date);
  };

  const handleSingleTimesheetOnPress = (timeLog: Timelog) => {
    setSingleTimeLog(timeLog);
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
    setIsDeleteConfirmationModalOpen(false);
    setSingleTimeLog(initialControlState.initialTimeLog);
  };

  const handleDeleteOnPress = () => {
    const newCurrentTimeLogs = [...currentTimelogs];
    const selectedTimeLogIndex = newCurrentTimeLogs
      .findIndex(timeLog => timeLog.name === singleTimeLog.name);
    const updatedCurrentTimeLogs = [
      ...newCurrentTimeLogs.slice(0, selectedTimeLogIndex),
      ...newCurrentTimeLogs.slice(selectedTimeLogIndex + 1),
    ];
    dispatch(editTimeLog(updatedCurrentTimeLogs, "Delete"));
    setIsDeleteConfirmationModalOpen(false);
    handleCloseTimesheetFormModal();
  };

  const handleEditTimesheetOnPress = () => {
    setIsMenuOpen(false);
    dispatch(updateTimesheetFormModalOpen(true));
  };

  const handleDeleteTimesheetOnPress = () => {
    setIsMenuOpen(false);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCloseTimesheetFormModal = () => {
    setSingleTimeLog(initialControlState.initialTimeLog);
  };

  const handleOpenTemplateOnPress = () => {
    navigation.dispatch(
      CommonActions.navigate('TimesheetTemplateScreen')
    );
  };

  return (
    <React.Fragment>
      <TimesheetScreen
        currentAttendance={currentAttendance}
        isFetchingTimelogs={isFetchingTimelogs}
        isMenuOpen={isMenuOpen}
        isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
        currentTimelogs={currentTimelogs}
        attendanceBreaksList={attendanceBreaksList}
        selectedDate={selectedDate}
        currentServerTime={currentServerTime}
        handleLeftArrowClick={handleLeftArrowClick}
        handleRightArrowClick={handleRightArrowClick}
        handleDateOnChange={handleDateOnChange}
        handleCreateTimelog={handleCreateTimelog}
        handleSingleTimesheetOnPress={handleSingleTimesheetOnPress}
        handleEditTimesheetOnPress={handleEditTimesheetOnPress}
        handleDeleteTimesheetOnPress={handleDeleteTimesheetOnPress}
        handleDeleteOnPress={handleDeleteOnPress}
        handleOpenTemplateOnPress={handleOpenTemplateOnPress}
        handleCloseMenu={handleCloseMenu}
        isToday={isToday}
      />
      <TimesheetFormModalContainer
        date={momentDate}
        timeLog={singleTimeLog}
        handleCloseTimesheetFormModal={handleCloseTimesheetFormModal}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(TimesheetScreenContainer);
