import React from "react";
import {State} from "../state";
import {updateDailyRewardModalOpen} from "../actions/actions";
import {Button, Center, Modal, Text, VStack} from "native-base";
import {connect, useDispatch} from "react-redux";
import { useTranslation } from "react-i18next";

interface DailyRewardModalProps {
  isOpen: boolean;
  attendanceStreak: number;
  todayPoint: number;
}

const mapStateToProps = (state: State) => {
  return {
    isOpen: state.control.dailyRewardModal.isOpen,
    attendanceStreak: state.control.dailyRewardModal.attendanceStreak,
    todayPoint: state.control.dailyRewardModal.todayPoint
  };
};

const DailyRewardModalContainer: React.FC<DailyRewardModalProps> = (props: DailyRewardModalProps) => {
  const dispatch = useDispatch();
  const {t}: { t: any } = useTranslation();
  
  const handleCloseDailyRewardModal = () => {
    dispatch(updateDailyRewardModalOpen(false));
  };

  return (
    <Modal isOpen={props.isOpen}
           size={"lg"}
           overlayVisible={true}
           closeOnOverlayClick={true}>
      <Modal.Content bgColor={"secondary.400"}>
        <Modal.Header>
          {props.attendanceStreak ? 
            String(t('modal.dailyRewardModal.congratulationsHeader')) 
          : 
            String(t('modal.dailyRewardModal.noAttendanceStreakHeader'))
          }
        </Modal.Header>
        <Modal.Body>
          {
            props.attendanceStreak ?
              <VStack>
                <Text>{String(t('modal.dailyRewardModal.attendanceStreakText1'))}</Text>
                <Center>
                  <Text fontSize={"8xl"} lineHeight={"sm"}>{props.attendanceStreak}</Text>
                </Center>
                <Text>{String(t('modal.dailyRewardModal.attendanceStreakText2'))}</Text>
              </VStack>
              :
              <Text>
                {String(t('modal.dailyRewardModal.noAttendanceStreakText'))}
              </Text>
          }
        </Modal.Body>
        <Modal.Footer bgColor={"secondary.500"}>
          <Button colorScheme={"danger"}
                  size={"md"}
                  flex={1}
                  onPress={handleCloseDailyRewardModal}
          >
            {String(t('button.closeButton'))}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default connect(mapStateToProps)(DailyRewardModalContainer);