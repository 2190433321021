import {CommunicationState, ControlState, DataState, SessionState} from "./state";

export const initialDataState: DataState = {
  attendances: [],
  monthlyInfos: [],
  leaveApplications: [],
  attendanceBreaks: [],
  projects: [],
  activityTypes: [],
  timelogs: [],
  todayTimelogs: [],
};

export const initialCommunicationState: CommunicationState = {
  httpCalls: []
};

export const initialControlState: ControlState = {
  selectedUrl: "",
  language: 'en',
  urlList: [],
  openingTime: "08:00",
  closingTime: "17:00",
  counter: 0,
  currentUserName: "",
  currentServerTime: "",
  todayDate: "",
  isSearchingCompanyServer: false,
  isInitialOnBoarding: true,
  companyData: [],

  errorModal: {
    isOpen: false
  },

  successModal: {
    isOpen: false
  },

  currentAchievements: [],
  currentMonthlyInfo: {
    name: "",
    employeeFullName: "",
    attendanceStreak: 0,
    point: 0,
    month: "",
    year: "",
  },
  initialTimeLog: {
    name: '',
    timesheetName: '',
    activity: '',
    projectName: '',
    duration: 0,
    index: 0,
  },
  isNewTimesheetModalOpen: false,
  isNewLeaveModalOpen: false,
  dailyRewardModal: {
    isOpen: false,
    todayPoint: 0,
    attendanceStreak: 0
  },
  achievementModal: {
    isOpen: false,
    name: "",
    achievementName: "",
    description: "",
    icon: "",
    threshold: 0,
    currentProgress: 0,
  },
  timesheetTemplates: [],
};

const initialApiCallAttribute = {
  isFetching: false,
  isStoring: false,
  lastUpdated: 0
};

export const initialSessionState: SessionState = {
  apiKey: '',
  expoPushToken: '',
  isGettingApiKeyFromSecureStore: false,
  isRefreshing: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isGettingLoggedUser: false,
  isGettingUserInfo: false,
  isCreatingLeave: false,
  isCheckingIn: false,
  isCheckingOut: false,
  isBreakingIn: false,
  isBreakingOut: false,
  isSavingOtherDate: false,
  isFetchingLeaveCount: false,
  isUploadingImage: false,
  isSubmittingExpoPushToken: false,
  isFetchingCompanyInformation: false,

  serverTime: initialApiCallAttribute,
  attendances: initialApiCallAttribute,
  monthlyInfos: initialApiCallAttribute,
  performanceAttribute: initialApiCallAttribute,
  achievements: initialApiCallAttribute,
  attendanceBreaks: initialApiCallAttribute,
  leaveApplications: initialApiCallAttribute,
  timelogs: initialApiCallAttribute,
  timesheetDetail: initialApiCallAttribute,
};
