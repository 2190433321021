import React from "react";
import {Button, Heading, HStack, Modal, Pressable, Text} from "native-base";
import {AVAILABLE_RATIOS} from "../master-data/constants";
import { useTranslation } from "react-i18next";

interface SelectRatioModalProps {
  isOpen: boolean;
  selectedRatio: string;
  handleSelectRatio: (ratio: string) => void;
  handleCloseModal: () => void;
}

export const SelectRatioModal: React.FC<SelectRatioModalProps> = (props: SelectRatioModalProps) => {
  const {t}: { t: any } = useTranslation();
  const renderAvailableRatioModalContent = () => {
    let ratioElements: JSX.Element[] = [];
    AVAILABLE_RATIOS.forEach((ratio: string) => {
      const isSelected = ratio === props.selectedRatio;
      ratioElements.push(
        <Pressable key={ratio}
                   bg={isSelected ? "primary.500" : "secondary.100"}
                   borderWidth={2}
                   px={4}
                   py={1}
                   borderRadius={10}
                   borderColor={"primary.500"}
                   onPress={() => props.handleSelectRatio(ratio)}
        >
          <Text color={isSelected ? "secondary.100" : "primary.500"} textAlign={"center"}>
            {ratio}
          </Text>
        </Pressable>
      )
    })
    return (
      <HStack flexWrap={"wrap"} justifyContent={"space-between"}>
        {ratioElements}
      </HStack>
    )
  }

  return (
    <Modal isOpen={props.isOpen}
           overlayVisible={true}
           closeOnOverlayClick={true}
           size={"xl"}
           onClose={props.handleCloseModal}
    >
      <Modal.Content bgColor={"secondary.400"}>
        <Modal.CloseButton/>
        <Modal.Header>
          <Heading fontSize={"lg"}>{String(t('modal.selectRatioModal.selectRatioText'))}</Heading>
        </Modal.Header>
        <Modal.Body>
          {renderAvailableRatioModalContent()}
        </Modal.Body>
        <Modal.Footer bgColor={"secondary.500"} justifyContent="space-between">
          <Button colorScheme="danger"
                  size={"md"}
                  w={"full"}
                  onPress={props.handleCloseModal}>
            {String(t('button.closeButton'))}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};