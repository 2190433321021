import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {CommonActions, useNavigation} from "@react-navigation/native";
import {useTranslation} from 'react-i18next'
import moment from "moment-timezone";
import {Attendance, AttendanceBreak, CompanyData, State, Timelog, User} from "../state";

import {
  AFTERNOON_START_HOUR,
  EVENING_START_HOUR, FIXED_TIMEZONE_NAME,
  formatDateYYYYMMDD,
  MIDNIGHT_HOUR,
  MORNING_START_HOUR,
  NIGHT_START_HOUR,
  NOON_START_HOUR, TIME_DISPLAY_FORMAT
} from "../lib/dateFormat";
import {
  ENGLISH_LANGUAGE_DISPLAYED,
  INDONESIAN_LANGUAGE_DISPLAYED,
  SUPPORTED_LANGUAGES,
  UNKNOWN_LANGUAGE_DISPLAYED,
} from "../master-data/constants";
import {
  createAttendanceBreak, fetchAttendanceBreaks, fetchTodayAttendance,
  postCheckInAttendance,
  submitAttendance,
  updateAttendanceBreak, updateCurrentAttendanceBreak
} from "../actions/actionsAttendance";
import {editTimeLog, fetchTimelogs} from "../actions/actionsTimesheet";
import TimesheetFormModalContainer from "./TimesheetFormModalContainer";
import {
  updateErrorModalStatus,
  updateLanguage,
  updateTimesheetFormModalOpen,
  updateTodayDate,
} from "../actions/actions";
import {HomeScreen} from "../screens/HomeScreen";
import {requestLocationAndThenDoAction} from "../utils/systemUtils";
import {LocationObject} from "expo-location/src/Location.types";
import * as Analytics from "expo-firebase-analytics";
import {Center, HStack, Spinner, Text} from "native-base";
import {getUserInfo} from "../actions/actionsAuth";
import {BottomSheetMenuComponent} from "../Components/TimeLogMenuComponent";
import {DeleteConfirmationModalComponent} from "../Components/DeleteConfirmationModal";
import {initialControlState} from "../initialStates";
import {TapOutBottomSheetComponent} from "../Components/TapOutBottomSheetComponent";
import {getCurrentServerTime} from "../actions/actionsDateTime";

interface HomeScreenProps {
  isCallingAuthApi: boolean;
  currentUserName: string;
  currentUser?: User;
  currentAttendance?: Attendance;
  currentAttendanceBreak?: AttendanceBreak;
  selectedCompany?: CompanyData;
  currentServerTime: string;
  todayTimelogs: Timelog[];
  attendanceBreaksList: AttendanceBreak[];
  isCheckingIn: boolean;
  isCheckingOut: boolean;
  isBreakingIn: boolean;
  isBreakingOut: boolean;
  isFetchingTimelogs: boolean;
  language: string;
}

const mapStateToProps = (state: State) => {
  return {
    isCallingAuthApi:
      state.session.isLoggingIn ||
      state.session.isLoggingOut ||
      state.session.isGettingLoggedUser ||
      state.session.isGettingUserInfo ||
      state.session.attendances.isFetching,
    currentUser: state.control.currentUser,
    currentUserName: state.control.currentUserName,
    currentAttendance: state.control.currentAttendance,
    currentAttendanceBreak: state.control.currentAttendanceBreak,
    currentServerTime: state.control.currentServerTime,
    selectedCompany: state.control.selectedCompany,
    todayTimelogs: state.data.todayTimelogs,
    attendanceBreaksList: state.data.attendanceBreaks,
    isCheckingIn: state.session.isCheckingIn,
    isCheckingOut: state.session.isCheckingOut,
    isBreakingIn: state.session.isBreakingIn,
    isBreakingOut: state.session.isBreakingOut,
    isFetchingTimelogs: state.session.timelogs.isFetching,
    language: state.control.language
  };
};

const HomeScreenContainer = (props: HomeScreenProps) => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const {t}: { t: any } = useTranslation();

  const todayDate = formatDateYYYYMMDD(new Date());
  const currentUser = props.currentUser;
  const currentUserName = props.currentUserName;
  const employeeName = currentUser?.employeeName;
  const userProfileImage = currentUser?.profileImageUrl;
  const currentAttendance = props.currentAttendance;
  const currentAttendanceName = currentAttendance ? currentAttendance.name : '';
  const attendanceBreaksList = props.attendanceBreaksList;
  const selectedCompany = props.selectedCompany;
  const todayTimelogs = props.todayTimelogs;
  const todayTimelogsLength = todayTimelogs.length;
  const isCallingAuthApi = props.isCallingAuthApi;
  const isFetchingTimelogs = props.isFetchingTimelogs;
  const attendanceIcon = require("../../assets/menu-icons/Attendance.png");
  const leaveApplicationIcon = require("../../assets/menu-icons/LeaveApplication.png");
  const timesheetIcon = require("../../assets/menu-icons/Timesheet.png");

  const navigation = useNavigation();

  const attendanceFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.attendance);
  const attendanceLocationFeatureStatus = attendanceFeatureStatus ? attendanceFeatureStatus.locationReqd : null;
  const hasTimesheetFeature = currentUser ? !!currentUser.featureList.timesheet : false;
  const hasAttendanceFeature = currentUser ? !!currentUser.featureList.attendance : false;
  const hasAttendanceBreakFeature = currentUser ? !!currentUser.featureList.attendance?.takeABreak : false;

  let initialState = {
    greetingText: String(t('homeScreen.noonGreeting')),
    username: "",
    designation: "",
    designationIcon: "",
  };

  const [greetingText, setGreetingText] = useState(initialState.greetingText);
  const [username, setUsername] = useState(initialState.username);
  const [designation, setDesignation] = useState(initialState.designation);
  const [designationIcon, setDesignationIcon] = useState(initialState.designationIcon);
  const [singleTimeLog, setSingleTimeLog] = useState<Timelog>(initialControlState.initialTimeLog);
  const [currentServerTime, setCurrentServerTime] =
    useState(moment().tz(FIXED_TIMEZONE_NAME).format(TIME_DISPLAY_FORMAT));
  const [isTapOutComponentOpen, setIsTapOutComponentOpen] = useState(false);
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  useEffect(() => {
    Analytics.setCurrentScreen("Home Screen", "Home Screen");
  }, []);

  useEffect(() => {
    dispatch(updateTodayDate(todayDate));
  }, [todayDate]);

  useEffect(() => {
    const currentHour = parseFloat(new Date().getHours().toString());

    if (currentHour >= MORNING_START_HOUR && currentHour < NOON_START_HOUR) {
      setGreetingText(String(t('homeScreen.morningGreeting')));
    } else if (
      currentHour >= NOON_START_HOUR &&
      currentHour < AFTERNOON_START_HOUR
    ) {
      setGreetingText(String(t('homeScreen.noonGreeting')));
    } else if (
      currentHour >= AFTERNOON_START_HOUR &&
      currentHour < EVENING_START_HOUR
    ) {
      setGreetingText(String(t('homeScreen.afternoonGreeting')));
    } else if (
      currentHour >= EVENING_START_HOUR &&
      currentHour < NIGHT_START_HOUR
    ) {
      setGreetingText(String(t('homeScreen.eveningGreeting')));
    } else if (currentHour >= NIGHT_START_HOUR && currentHour < MIDNIGHT_HOUR) {
      setGreetingText(String(t('homeScreen.nightGreeting')));
    } else {
      setGreetingText(String(t('homeScreen.generalGreeting')));
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      setUsername(currentUser.employeeFullName);
      setDesignation(currentUser.designation);
      setDesignationIcon(currentUser.designationIconUrl);
    }
  }, [currentUser]);

  useEffect(() => {
    if (employeeName && todayTimelogsLength < 1 && hasTimesheetFeature) {
      dispatch(fetchTimelogs(employeeName, todayDate));
    }
  }, [employeeName, todayTimelogsLength, todayDate, isTapOutComponentOpen, hasTimesheetFeature]);

  useEffect(() => {
    if (currentAttendanceName && hasAttendanceBreakFeature && hasAttendanceFeature) {
      dispatch(fetchAttendanceBreaks(currentAttendanceName));
    }
  }, [currentAttendanceName, isTapOutComponentOpen, hasAttendanceBreakFeature, hasAttendanceFeature]);

  useEffect(() => {
    if (props.currentServerTime) {
      setCurrentServerTime(moment(props.currentServerTime).tz(FIXED_TIMEZONE_NAME).format(TIME_DISPLAY_FORMAT));
    }
  }, [props.currentServerTime, isTapOutComponentOpen]);

  useEffect(() => {
    if (attendanceBreaksList.length <= 0) {
      dispatch(updateCurrentAttendanceBreak(undefined));
    }
  },[attendanceBreaksList]);

  const handleCheckInAction = (location?: LocationObject): Promise<any> => {
    if (currentUser) {
      Analytics.logEvent("check_in");
      dispatch(
        postCheckInAttendance(
          currentUser.employeeName,
          location ? location.coords.latitude : undefined,
          location ? location.coords.longitude : undefined
        )
      );
      return Promise.resolve();
    } else {
      return Promise.reject(String(t('modal.errorModal.usernameError')));
    }
  };

  const handleCheckOutAction = (location?: LocationObject): Promise<any> => {
    if (currentAttendance) {
      Analytics.logEvent("check_out");
      dispatch(
        submitAttendance(
          currentAttendance.name,
          location ? location.coords.latitude : undefined,
          location ? location.coords.longitude : undefined
        )
      );
      return Promise.resolve();
    } else {
      return Promise.reject(String(t('modal.errorModal.usernameError')));
    }
  };

  const handleLocationAttendance = (
    successfulAction: (location: LocationObject) => Promise<any>,
    type: string
  ) => {
    let errorMsg = "";

    if (type === "check-out") {
      errorMsg = String(t('modal.errorModal.checkOutErrorTitle'));
    } else {
      errorMsg = String(t('modal.errorModal.checkInErrorTitle'));
    }

    setIsFetchingLocation(true);

    const failedAction = () => {
      dispatch(
        updateErrorModalStatus({
          title: errorMsg,
          content: String(t('modal.errorModal.locationError')),
          isOpen: true,
        })
      );
    };

    return requestLocationAndThenDoAction(successfulAction, failedAction).finally(
      () => {
        setIsFetchingLocation(false);
      }
    );
  };

  const handleCheckInClick = () => {
    if (attendanceLocationFeatureStatus === 1) {
      return handleLocationAttendance(handleCheckInAction, "check-in");
    } else {
      return handleCheckInAction();
    }
  };

  const handleCheckOutClick = () => {
    setIsTapOutComponentOpen(true);
    dispatch(getCurrentServerTime());
  };

  const handleTapOutOnPress = () => {
    setIsTapOutComponentOpen(false);
    if (attendanceLocationFeatureStatus === 1) {
      handleLocationAttendance(handleCheckOutAction, "check-out");
    } else {
      handleCheckOutAction();
    }
  };

  const handleCloseTapOutComponent = () => {
    setIsTapOutComponentOpen(false);
  };

  const handleCreateTimelog = () => {
    dispatch(updateTimesheetFormModalOpen(true));
  };

  const handleChangeScreenOnPress = (screenName: string) => {
    navigation.dispatch(
      CommonActions.navigate(screenName)
    );
  };

  const handleTimesheetMenuPress = () => {
    setIsTapOutComponentOpen(false);
    navigation.dispatch(
      CommonActions.navigate("Timesheet")
    );
  };

  const resolveDisplayedLanguage = (language: string) => {
    switch (language) {
      case "en":
        Analytics.logEvent('switch_to_english', {
          name: 'Changing Language',
          screen: 'Setting',
          purpose: 'To change system language to English',
        });
        return ENGLISH_LANGUAGE_DISPLAYED;
      case "id":
        Analytics.logEvent('switch_to_bahasa_indonesia', {
          name: 'Changing Language',
          screen: 'Setting',
          purpose: 'To change system language to Bahasa Indonesia',
        });
        return INDONESIAN_LANGUAGE_DISPLAYED;
      default:
        return UNKNOWN_LANGUAGE_DISPLAYED;
    }
  }

  const handleSelectLanguage = (language: string) => {
    i18n.changeLanguage(language).then(() => {
      dispatch(updateLanguage(language));
    })
  };

  const handleTakeABreakButtonPress = () => {
    if (currentAttendance && currentUser) {
      Analytics.logEvent("take_break");
      dispatch(
        createAttendanceBreak(currentUser.employeeName, currentAttendance.name)
      );
    }
  };

  const handleSingleTimesheetOnPress = (timeLog: Timelog) => {
    setSingleTimeLog(timeLog);
    setIsMenuOpen(true);
  };

  const handleEditTimesheetOnPress = () => {
    setIsMenuOpen(false);
    dispatch(updateTimesheetFormModalOpen(true));
  };

  const handleDeleteTimesheetOnPress = () => {
    setIsMenuOpen(false);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleDeleteOnPress = () => {
    const newCurrentTimeLogs = [...todayTimelogs];
    const selectedTimeLogIndex = newCurrentTimeLogs
      .findIndex(timeLog => timeLog.name === singleTimeLog.name);
    const updatedCurrentTimeLogs = [
      ...newCurrentTimeLogs.slice(0, selectedTimeLogIndex),
      ...newCurrentTimeLogs.slice(selectedTimeLogIndex + 1),
    ];
    dispatch(editTimeLog(updatedCurrentTimeLogs, "Delete"));
    setIsDeleteConfirmationModalOpen(false);
    handleCloseTimesheetFormModal();
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
    setIsDeleteConfirmationModalOpen(false);
    setSingleTimeLog(initialControlState.initialTimeLog);
  };

  const handleCloseTimesheetFormModal = () => {
    setSingleTimeLog(initialControlState.initialTimeLog);
  };

  const handleRefresh = () => {
    dispatch(getUserInfo(currentUserName));
    if (employeeName && hasAttendanceFeature) {
      dispatch(fetchTodayAttendance(employeeName, todayDate));
    }
    if (employeeName && hasTimesheetFeature) {
      dispatch(fetchTimelogs(employeeName, todayDate));
    }
  };

  const handleBackToWorkButtonPress = () => {
    if (props.currentAttendanceBreak) {
      Analytics.logEvent("finish_break");
      dispatch(updateAttendanceBreak(props.currentAttendanceBreak.name));
    }
  };

  return !username || isCallingAuthApi ? (
    <Center bg={"secondary.500"} h={"full"} flex={1}>
      <HStack>
        <Spinner size={"lg"}/>
        <Text fontSize={"xl"} ml={6}>Loading...</Text>
      </HStack>
    </Center>
  ) : (
    <React.Fragment>
      <HomeScreen
        greetingText={greetingText}
        username={username}
        profileImageUrl={userProfileImage ? userProfileImage : ""}
        designation={designation}
        designationIcon={designationIcon}
        currentAttendance={currentAttendance}
        selectedCompany={selectedCompany}
        currentTimelogs={todayTimelogs}
        attendanceIcon={attendanceIcon}
        leaveApplicationIcon={leaveApplicationIcon}
        timesheetIcon={timesheetIcon}
        isCheckingOut={props.isCheckingOut}
        isCheckingIn={props.isCheckingIn}
        isBreakingIn={props.isBreakingIn}
        isBreakingOut={props.isBreakingOut}
        isFetchingTimesheet={isFetchingTimelogs}
        isFetchingLocation={isFetchingLocation}
        handleCheckOutClick={handleCheckOutClick}
        handleCheckInClick={handleCheckInClick}
        handleCreateTimelog={handleCreateTimelog}
        handleChangeScreenOnPress={handleChangeScreenOnPress}
        handleSelectLanguage={handleSelectLanguage}
        handleTakeABreakButtonPress={handleTakeABreakButtonPress}
        handleBackToWorkButtonPress={handleBackToWorkButtonPress}
        handleSingleTimesheetOnPress={handleSingleTimesheetOnPress}
        handleRefresh={handleRefresh}
        currentLanguage={resolveDisplayedLanguage(props.language)}
        languages={SUPPORTED_LANGUAGES}
      />
      <TimesheetFormModalContainer
        date={new Date()}
        timeLog={singleTimeLog}
        handleCloseTimesheetFormModal={handleCloseTimesheetFormModal}
      />
      <BottomSheetMenuComponent
        isMenuOpen={isMenuOpen}
        isButtonDisabled={todayTimelogsLength <= 1}
        handleEditTimesheetOnPress={handleEditTimesheetOnPress}
        handleDeleteTimesheetOnPress={handleDeleteTimesheetOnPress}
        handleCloseMenu={handleCloseMenu}
      />
      <DeleteConfirmationModalComponent
        title={t('modal.timesheetModal.deleteTimesheetHeader')}
        description={t('modal.timesheetModal.deleteTimesheetConfirmation')}
        isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
        handleDeleteOnPress={handleDeleteOnPress}
        handleCloseMenu={handleCloseMenu}
      />
      <TapOutBottomSheetComponent
        isOpen={isTapOutComponentOpen}
        hasTimesheetFeature={hasTimesheetFeature}
        todayTimelogs={todayTimelogs}
        attendanceBreaksList={attendanceBreaksList}
        currentAttendance={currentAttendance}
        currentServerTime={currentServerTime}
        handleFillTimesheetOnPress={handleTimesheetMenuPress}
        handleTapOutOnPress={handleTapOutOnPress}
        onClose={handleCloseTapOutComponent}
      />
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(HomeScreenContainer);
