import {CompanyData, ModalProps, State, User} from "../state";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {updateErrorModalStatus} from "./actions";
import axios from "axios";
import {updateCurrentAchievementList, updateCurrentPerformanceAttribute} from "./actionGamification";
import {updateCurrentMonthlyInfo, updateCurrentMonthlyInfoList} from "./actionsMonthlyInfo";
import {initialControlState} from "../initialStates";
import {updateAttendanceBreakList, updateCurrentAttendance, updateCurrentAttendanceBreak} from "./actionsAttendance";
import {
  updateActivitiesNameList,
  updateProjectsNameList,
  updateTimelogList,
  updateTodayTimelogList
} from "./actionsTimesheet";
import {updateLeaveApplicationList, updateLeaveCount} from "./actionLeaveApplication";
import * as SecureStore from "expo-secure-store";
import {extractCompanyData, extractUserInfo} from "./frappeResponse";
import i18n from "i18next";
import {captureErrorSentry} from "./httpUtils";

// Company and Server Authentication
export const ADD_NEW_COMPANY_DATA = "ADD_NEW_COMPANY_DATA";
export const UPDATE_SELECTED_COMPANY = "UPDATE_SELECTED_COMPANY";
export const DELETE_COMPANY_DATA = "DELETE_COMPANY_DATA";
export const UPDATE_SEARCHING_COMPANY_SERVER_STATUS = "UPDATE_SEARCHING_COMPANY_SERVER_STATUS";

// User Authentication
export const UPDATE_LOGGING_IN_STATUS = "UPDATE_LOGGING_IN_STATUS";
export const UPDATE_LOGGING_OUT_STATUS = "UPDATE_LOGGING_OUT_STATUS";
export const ADD_API_KEY = "ADD_API_KEY";
export const REMOVE_API_KEY = "REMOVE_API_KEY";
export const UPDATE_GETTING_API_KEY_FROM_SECURE_STORE_STATUS = "UPDATE_GETTING_API_KEY_FROM_SECURE_STORE_STATUS";
export const UPDATE_GETTING_LOGGED_USER_STATUS = "UPDATE_GETTING_LOGGED_USER_STATUS";
export const UPDATE_GETTING_USER_INFO_STATUS = "UPDATE_GETTING_USER_INFO_STATUS";
export const UPDATE_SUBMITTING_EXPO_PUSH_TOKEN = "UPDATE_SUBMITTING_EXPO_PUSH_TOKEN";

export const UPDATE_CURRENT_USERNAME = "UPDATE_CURRENT_USERNAME";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

export const addNewCompanyData = (companyData: CompanyData) => {
  return {type: ADD_NEW_COMPANY_DATA, payload: companyData};
}

export const updateSelectedCompany = (companyData: CompanyData | undefined) => {
  return {type: UPDATE_SELECTED_COMPANY, payload: companyData};
}

export const deleteCompanyData = (companyId: string) => {
  return {type: DELETE_COMPANY_DATA, payload: companyId};
}

export const updateLoggingInStatus = (authenticatingStatus: boolean) => {
  return {type: UPDATE_LOGGING_IN_STATUS, payload: authenticatingStatus};
};

export const updateIsSearchingCompanyServerStatus = (searchingStatus: boolean) => {
  return {type: UPDATE_SEARCHING_COMPANY_SERVER_STATUS, payload: searchingStatus};
}

export const updateLoggingOutStatus = (loggingOutStatus: boolean) => {
  return {type: UPDATE_LOGGING_OUT_STATUS, payload: loggingOutStatus};
};

export const addApiKey = (apiKey: string) => {
  return {type: ADD_API_KEY, payload: apiKey};
};

export const removeApiKey = () => {
  return {type: REMOVE_API_KEY};
}

export const updateGettingApiKeyFromSecureStoreStatus = (status: boolean) => {
  return {type: UPDATE_GETTING_API_KEY_FROM_SECURE_STORE_STATUS, payload: status};
};

export const updateGettingLoggedUserStatus = (gettingLoggedUserStatus: boolean) => {
  return {type: UPDATE_GETTING_LOGGED_USER_STATUS, payload: gettingLoggedUserStatus};
};

export const updateGettingUserInfoStatus = (gettingUserInfoStatus: boolean) => {
  return {type: UPDATE_GETTING_USER_INFO_STATUS, payload: gettingUserInfoStatus};
};

export const updateCurrentUserName = (userName: string) => {
  return {type: UPDATE_CURRENT_USERNAME, payload: userName};
};

export const updateCurrentUserInfo = (user?: User) => {
  return {type: UPDATE_CURRENT_USER, payload: user};
};

export const updateSubmittingExpoPushToken = (isSubmitting: boolean) => {
  return { type: UPDATE_SUBMITTING_EXPO_PUSH_TOKEN, payload: isSubmitting };
};

export const cleanUserData = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(updateCurrentUserInfo(undefined));
  dispatch(updateCurrentUserName(""));
  dispatch(updateGettingLoggedUserStatus(false));
  dispatch(updateCurrentPerformanceAttribute(undefined));
  dispatch(updateCurrentMonthlyInfo(initialControlState.currentMonthlyInfo));
  dispatch(updateCurrentAttendance(undefined));
  dispatch(updateCurrentAchievementList([]));
  dispatch(updateTimelogList([]));
  dispatch(updateTodayTimelogList([]));
  dispatch(updateLeaveApplicationList([]));
  dispatch(updateCurrentMonthlyInfoList([]));
  dispatch(updateAttendanceBreakList([]));
  dispatch(updateCurrentAttendanceBreak(undefined));
  dispatch(updateProjectsNameList([]));
  dispatch(updateActivitiesNameList([]));
  dispatch(updateLeaveCount(undefined));
}

export const failedAction403 = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  cleanUserData(dispatch);

  Promise.resolve(dispatch(removeApiKey())).then(() => {
    return SecureStore.deleteItemAsync('apiKey')
  }).then(() => {
    delete axios.defaults.headers.common["Authorization"];
  });
};

export const failedAction401 = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(removeApiKey());
};

export const handleNoBaseUrlError = (errorModalStatus: ModalProps, dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  errorModalStatus.content = i18n.t('modal.errorModal.noBaseUrlError');
  dispatch(updateErrorModalStatus(errorModalStatus));
  failedAction403(dispatch);
}

export const handleHttpError = (response: any,
                                errorModalStatus: ModalProps,
                                dispatch: ThunkDispatch<{}, {}, AnyAction>,
                                statusUpdater?: (status: boolean) => { type: string, payload: any }
) => {
  if (response.status === 401) {
    failedAction401(dispatch);
    errorModalStatus.content = i18n.t('httpErrorCode.401');
  } else if (response.status === 403) {
    failedAction403(dispatch);
    errorModalStatus.content = i18n.t('httpErrorCode.403');
  } else if (response.status === 404) {
    errorModalStatus.content = i18n.t('httpErrorCode.404');
  } else if (response.status === 408) {
    errorModalStatus.content = i18n.t('httpErrorCode.408');
  } else if (response.status === 500) {
    errorModalStatus.content = i18n.t('httpErrorCode.500');
  } else {
    errorModalStatus.content = i18n.t('httpErrorCode.general');
  }

  if (statusUpdater) {
    dispatch(statusUpdater(false));
  }
  if (errorModalStatus.content) {
    dispatch(updateErrorModalStatus(errorModalStatus));
  }
};

export const handleCompanyServerSearch = (serverUrl?: string, companyId?: string, fullUrl?: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<any> => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.companyServerSearchErrorTitle')
    };

    let url = "";

    if(fullUrl){
      url = fullUrl
    }
    else if (companyId && serverUrl){
      const queryParam = "data={\"company_id\":\"".concat(companyId).concat("\"}");
      url = "https://" + serverUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_data_from_company_id?".concat(queryParam);
    }

    dispatch(updateIsSearchingCompanyServerStatus(true));

    return axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateSelectedCompany(extractCompanyData(response.data.message)));
          dispatch(updateIsSearchingCompanyServerStatus(false));
          return Promise.resolve();
        } else {
          captureErrorSentry("Error when fetching company server", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateLoggingInStatus);
        }
      })
      .catch((error: any) => {
        dispatch(updateIsSearchingCompanyServerStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const handleLogin = (
  username: string,
  password: string,
  expoPushToken: string,
  onSuccessfulAction?: () => void,
) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.loginErrorTitle')
    };

    const loginData = new URLSearchParams();

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany) {
      baseUrl = selectedCompany.serverUrl;
      loginData.append("data", encodeURIComponent(JSON.stringify({
        user: username,
        password: password,
        company: selectedCompany.companyName,
        branch: selectedCompany.branchName,
        expo_push_token: expoPushToken,
      })));
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.login_and_generate_api_keys";
    dispatch(updateLoggingInStatus(true));

    axios({
      method: 'post',
      url: encodeURI(url),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data: loginData,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
      validateStatus: () => true
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateLoggingInStatus(false));
          if (onSuccessfulAction) {
            onSuccessfulAction();
          }

          return Promise.resolve(response.data.api_token);
        } else {
          captureErrorSentry("Error when logging in", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateLoggingInStatus);
          return Promise.reject();
        }
      })
      .then((apiKey: any) => {
        dispatch(addApiKey(apiKey));
        axios.defaults.headers.common['Authorization'] = apiKey;
        return SecureStore.setItemAsync("apiKey", apiKey);
      })
      .catch((error: any) => {
        dispatch(updateLoggingInStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
        dispatch(removeApiKey());
      });
  };
};

export const handleLogout = (employeeId: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.logoutErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany) {
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const logoutData = new URLSearchParams();
    logoutData.append("data", encodeURIComponent(JSON.stringify({
      employee_id: employeeId,
    })));

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.clear_expo_push_token";

    dispatch(updateLoggingOutStatus(true));

    axios({
      method: 'put',
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      url: url,
      data: logoutData,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          cleanUserData(dispatch);
        } else {
          captureErrorSentry("Error when logging out", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateLoggingOutStatus);
        }
      })
      .then(() => {
        dispatch(removeApiKey());
        return SecureStore.deleteItemAsync('apiKey')
      })
      .then(() => {
        delete axios.defaults.headers.common["Authorization"];
        dispatch(updateLoggingOutStatus(false));
      })
      .catch(error => {
        dispatch(updateLoggingOutStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const getLoggedUser = () => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.getLoggedUserErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany) {
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/frappe.auth.get_logged_user";

    dispatch(updateGettingLoggedUserStatus(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateCurrentUserName(response.data.message));
          dispatch(updateGettingLoggedUserStatus(false));
        } else {
          captureErrorSentry("Error when fetching logged user", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateGettingLoggedUserStatus);
          dispatch(updateLoggingOutStatus(false));
          dispatch(updateCurrentUserName(""));
        }
      })
      .catch(error => {
        dispatch(updateGettingLoggedUserStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const getUserInfo = (username: string) => {
  return async (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.getUserInfoErrorTitle')
    };

    const expoPushToken = getState().session.expoPushToken;
    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany) {
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const queryParam = "data={\"username\":\"".concat(username).concat("\"}");
    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_user_information_v2?".concat(queryParam);

    dispatch(updateGettingUserInfoStatus(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          if (Object.keys(response.data).length === 0 && response.data.constructor === Object) {
            errorModalStatus.content = i18n.t('modal.errorModal.getUserInfoError1').concat(username)
              .concat(i18n.t('modal.errorModal.getUserInfoError2'));
            dispatch(updateGettingUserInfoStatus(false));
            dispatch(updateErrorModalStatus(errorModalStatus));
            dispatch(removeApiKey());
            cleanUserData(dispatch);
          } else {
            if (!response.data.message.expo_push_token || response.data.message.expo_push_token !== expoPushToken) {
              dispatch(handleSubmitExpoPushToken(response.data.message.employee_id, expoPushToken));
            }
            dispatch(updateCurrentUserInfo(extractUserInfo(response.data.message)));
            dispatch(updateGettingUserInfoStatus(false));
          }
        } else {
          captureErrorSentry("Error when fetching user info", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateGettingUserInfoStatus);
          dispatch(removeApiKey());
          cleanUserData(dispatch);
        }
      })
      .catch((error: any) => {
        dispatch(updateGettingUserInfoStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const handleSubmitExpoPushToken = (employeeName: string, expoPushToken: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.postExpoPushTokenError')
    };

    const expoPushTokenData = new URLSearchParams();

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany) {
      baseUrl = selectedCompany.serverUrl;

      expoPushTokenData.append("data", encodeURIComponent(JSON.stringify({
        employee_name: employeeName,
        expo_push_token: expoPushToken,
      })));
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.post_expo_push_token";
    dispatch(updateSubmittingExpoPushToken(true));

    axios({
      method: 'post',
      url: encodeURI(url),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data: expoPushTokenData,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateSubmittingExpoPushToken(false));
          return Promise.resolve();
        } else {
          captureErrorSentry("Error when submitting expo token", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateSubmittingExpoPushToken);
          return Promise.reject();
        }
      })
      .catch((error: any) => {
        dispatch(updateSubmittingExpoPushToken(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};
