import React from "react";
import {
  Box,
  Center,
  FlatList,
  Icon,
  Pressable,
  Text,
  useTheme,
  VStack
} from "native-base";
import {LeaveApplication, State} from "../state";
import {Feather} from "@expo/vector-icons";
import {SingleLeaveApplicationCard} from "../Components/SingleLeaveApplicationCard";
import {useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import {RefreshControl} from "react-native";

export interface LeaveApplicationScreenProps {
  employmentType: string;
  leaveApplications: LeaveApplication[];
  isRefreshing: boolean;
  handleRefreshScreen: () => void;
  handleOpenLeaveMenuOnPress: () => void;
}

const FloatingButtonComponent = ({ ...props }) => {
  return (
    <Pressable
      p={3}
      bg="success.500"
      position="absolute"
      bottom={5}
      right={5}
      borderRadius="full"
      _pressed={{
        bg: 'success.600',
      }}
      onPress={props.handleOpenLeaveMenuOnPress}
    >
      <Icon
        as={Feather}
        name="plus"
        size="xl"
        color="secondary.500"
      />
    </Pressable>
  );
};

export const LeaveApplicationScreen = (props: LeaveApplicationScreenProps) => {
  const leaveApplications = props.leaveApplications;
  const isRefreshing = props.isRefreshing;

  const {t}: { t: any } = useTranslation();
  const theme = useTheme();

  const leaveApplicationFeatureStatus = useSelector((state: State) => !!state.control.currentUser?.featureList.leaveApplication);

  return (
    <VStack
      flex={1}
      h="full"
      bg="secondary.500"
    >
      <FlatList
        data={leaveApplications}
        keyExtractor={(item, index) => String(index)}
        renderItem={({ item }) => (
          <SingleLeaveApplicationCard
            key={item.name}
            status={item.status}
            leaveType={item.leaveType}
            leaveFrom={item.fromDate}
            leaveTo={item.toDate}
          />
        )}
        ItemSeparatorComponent={() => <Box my={2} />}
        ListEmptyComponent={
          <Center flex={1}>
            <Text color={"primary.500"} fontWeight={500} italic>
              {String(t('leaveApplicationScreen.noLeaveApplicationText'))}
            </Text>
          </Center>
        }
        contentContainerStyle={{
          padding: 15,
        }}
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={props.handleRefreshScreen}
            tintColor={theme.colors.primary[500]}
            titleColor={theme.colors.primary[500]}
            title={t('scrollView.pullToRefresh')}
          />
        }
      />
      {
        leaveApplicationFeatureStatus &&
        <FloatingButtonComponent { ...props } />
      }
    </VStack>
  );
};
