import {Achievement, ModalProps, PerformanceAttribute, State} from "../state";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {captureErrorSentry, constructFields, constructFilters} from "./httpUtils";
import axios from "axios";
import {extractAchievementList, extractPerformanceAttribute} from "./frappeResponse";
import {handleHttpError, handleNoBaseUrlError} from "./actionsAuth";
import i18n from "i18next";
import {updateErrorModalStatus} from "./actions";

export const UPDATE_CURRENT_PERFORMANCE_ATTRIBUTE = "UPDATE_CURRENT_PERFORMANCE_ATTRIBUTE";
export const UPDATE_FETCHING_PERFORMANCE_ATTRIBUTE_STATUS = "UPDATE_FETCHING_PERFORMANCE_ATTRIBUTE_STATUS";
export const UPDATE_FETCHING_ACHIEVEMENT_LIST_ATTRIBUTE = "UPDATE_FETCHING_ACHIEVEMENT_LIST_ATTRIBUTE";
export const UPDATE_CURRENT_ACHIEVEMENT_LIST = "UPDATE_CURRENT_ACHIEVEMENT_LIST";

export const updateCurrentPerformanceAttribute = (currentPerformanceAttribute?: PerformanceAttribute) => {
  return {type: UPDATE_CURRENT_PERFORMANCE_ATTRIBUTE, payload: currentPerformanceAttribute};
};

export const updateCurrentAchievementList = (currentAchievementList: Achievement[]) => {
  return {type: UPDATE_CURRENT_ACHIEVEMENT_LIST, payload: currentAchievementList};
};

export const updateFetchingPerformanceAttributeStatus = (status: boolean) => {
  return {
    type: UPDATE_FETCHING_PERFORMANCE_ATTRIBUTE_STATUS,
    payload: {
      isFetching: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const updateFetchingAchievementListAttribute = (status: boolean) => {
  return {
    type: UPDATE_FETCHING_ACHIEVEMENT_LIST_ATTRIBUTE,
    payload: {
      isFetching: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const fetchPerformanceAttribute = (employeeName: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchPerformanceAttributeErrorTitle')
    };

    const fields = constructFields([
      "total_work_hour",
      "total_overwork_hour",
      "total_early_exit",
      "total_late_entry",
      "total_early_entry",
      "total_late_leave",
      "total_absence",
      "total_full_day",
      "total_half_day",
      "total_timesheet_submitted",
      "total_monthly_trophy_get"
    ])
    const filters = constructFilters({
      employee: employeeName
    })

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/resource/Performance Attribute?".concat(fields).concat("&").concat(filters);

    dispatch(updateFetchingPerformanceAttributeStatus(true));

    axios({
      method: 'get',
      url: encodeURI(url),
      responseType: 'json',
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            dispatch(updateCurrentPerformanceAttribute(extractPerformanceAttribute(response.data.data[0])));
          }
          dispatch(updateFetchingPerformanceAttributeStatus(false));
        } else {
          captureErrorSentry("Error when fetching performance attribute", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingPerformanceAttributeStatus);
        }
      })
      .catch(error => {
        dispatch(updateFetchingPerformanceAttributeStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const fetchAchievementList = (employeeName: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchAchievementsErrorTitle')
    };

    const fields = constructFields([
      "name",
      "icon_1",
      "threshold_1",
      "current_progress",
      "achievement_description",
      "achievement_name",
      "achievement_type_name"
    ])
    const filters = constructFilters({
      employee: employeeName
    })

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/resource/Achievement?".concat(fields).concat("&").concat(filters);

    dispatch(updateFetchingAchievementListAttribute(true));

    axios({
      method: 'get',
      url: url,
      responseType: 'json',
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateCurrentAchievementList(extractAchievementList(response.data.data)));
          dispatch(updateFetchingAchievementListAttribute(false));
        } else {
          captureErrorSentry("Error when fetching achievement list", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingAchievementListAttribute);
        }
      })
      .catch(error => {
        dispatch(updateFetchingAchievementListAttribute(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};
