import {Heading, HStack, Icon, Pressable, useTheme, View} from "native-base";
import ProfileScreenContainer from "../Containers/ProfileScreenContainer";
import React from "react";
import {createStackNavigator} from "@react-navigation/stack";
import {StyleProp, ViewStyle} from "react-native";
import {useTranslation} from 'react-i18next';
import DeveloperScreenContainer from "../Containers/DeveloperScreenContainer";
import MonthlyPerformanceScreenContainer from "../Containers/MonthlyPerformanceScreenContainer";
import {FontAwesome} from "@expo/vector-icons";
import SettingsScreenContainer from "../Containers/SettingsScreenContainer";
import LanguageSelectionScreenContainer from "../Containers/LanguageSelectionScreenContainer";
import TapCamera from "../Components/TapCamera";

export const ProfileStackNavigator = () => {
  const ProfileStack = createStackNavigator();

  const theme = useTheme();
  const {t}: { t: any } = useTranslation();

  const headerStyle: StyleProp<ViewStyle> = {
    backgroundColor: theme.colors.secondary[500],
    borderBottomColor: theme.colors.primary[500],
    shadowColor: theme.colors.secondary[500],
  };

  return (
    <ProfileStack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerTintColor: theme.colors.primary[500],
      }}
    >
      <ProfileStack.Screen
        name="ProfileScreen"
        options={({navigation}) => {
          return ({
            headerTitle: () =>
              <HStack
                w="full"
                alignItems="center"
                justifyContent="space-between"
              >
                <Heading
                  color="primary.500"
                  fontWeight={600}
                >
                  {String(t('stackHeader.profileScreen'))}
                </Heading>
                <Pressable
                  onPress={() => navigation.navigate('SettingsScreen')}
                >
                  <Icon
                    as={FontAwesome}
                    name="gear"
                    color="success.500"
                    size="md"
                  />
                </Pressable>
              </HStack>,
            headerStyle: headerStyle,
          })
        }}
        component={ProfileScreenContainer}
      />
      <ProfileStack.Screen
        name="MonthlyPerformanceScreen"
        options={{
          headerTitle: () =>
            <Heading
              color="primary.500"
              fontWeight={600}
            >
              {String(t('stackHeader.monthlyPerformanceScreen'))}
            </Heading>,
          headerStyle: headerStyle
        }}
        component={MonthlyPerformanceScreenContainer}
      />
      <ProfileStack.Screen
        name="DeveloperScreen"
        options={{
          headerTitle: () =>
            <Heading
              color={"primary.500"}
              fontWeight={600}
            >
              {String(t('stackHeader.developerScreen'))}
            </Heading>,
          headerStyle: headerStyle,
        }}
        component={DeveloperScreenContainer}
      />
      <ProfileStack.Screen
        name="SettingsScreen"
        options={{
          headerTitle: () =>
            <Heading
              color="primary.500"
              fontWeight={600}
            >
              {String(t('stackHeader.settingsScreen'))}
            </Heading>,
          headerStyle: headerStyle,
        }}
        component={SettingsScreenContainer}
      />
      <ProfileStack.Screen
        name="LanguageSelectionProfile"
        options={{
          headerTitle: () => (
            <Heading
              color="primary.500"
              fontWeight={600}
            >
              {String(t("stackHeader.languageSelectionScreen"))}
            </Heading>
          ),
          headerStyle: headerStyle,
        }}
        component={LanguageSelectionScreenContainer}
      />
      <ProfileStack.Screen
        name="ProfileCamera"
        options={{
          headerTitle: "",
          headerLeft: () => (<View/>),
          headerTransparent: true,
          headerShown: true,
        }}
        component={TapCamera}
      />
    </ProfileStack.Navigator>
  );
};
