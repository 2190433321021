import React from "react";
import {
  Actionsheet,
  Box,
  Button,
  FormControl,
  HStack,
  Icon,
  Input, KeyboardAvoidingView,
  ScrollView, Select,
  Text,
  VStack
} from "native-base";
import {FontAwesome, Ionicons, MaterialIcons} from "@expo/vector-icons";
import {ImageData, LeaveBalance} from "../state";
import {TapInputElement} from "./TapInputElement";
import {useTranslation} from "react-i18next";
import {updateLeaveImageData} from "../actions/actionLeaveApplication";
import {useDispatch} from "react-redux";
import {CASUAL_LEAVE, LEAVE_WITHOUT_PAY} from "../master-data/constants";

interface LeaveApplicationMenuProps {
  isOpen: boolean;
  isFromDatePickerOpen: boolean;
  isToDatePickerOpen: boolean;
  isFromError: boolean;
  isToError: boolean;
  isReasonError: boolean;
  isImageError: boolean;
  isStoringLeaveApplication: boolean;
  selectedFromDate: string;
  selectedToDate: string;
  leaveType: string;
  reason: string;
  leaveBalance?: LeaveBalance;
  tempImage?: ImageData;
  handleMenuOnClose: () => void;
  openFromDatePicker: () => void;
  closeFromDatePicker: () => void;
  openToDatePicker: () => void;
  closeToDatePicker: () => void;
  handleLaunchCamera: () => void;
  handleFromDateSelectionChange: (date: Date) => void;
  handleToDateSelectionChange: (date: Date) => void;
  handleReasonChange: (value: string) => void;
  handleLeaveTypeOnChange: (value: string) => void;
  handleSubmitLeaveApplication: (leaveType: string) => void;
}

interface LeaveItemProps {
  label: string;
  value: string;
  leaveBalance: number;
}

const LeaveItem = (props: LeaveItemProps) => {
  return (
    <Select.Item
      key={props.value}
      label={props.label}
      value={props.value}
      rightIcon={
        <HStack
          flex={1}
          space={2}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Icon
            as={FontAwesome}
            name="heart"
            size="xs"
            color="danger.500"
          />
          <Text
            fontSize="md"
            color="muted.500"
          >
            {props.leaveBalance}
          </Text>
        </HStack>
      }
    />
  );
};

const InputComponent = ({ ...props}: LeaveApplicationMenuProps) => {
  const {t}: { t: any } = useTranslation();
  const dispatch = useDispatch();

  const handleImageButtonPress = () => {
    if (props.tempImage) {
      dispatch(updateLeaveImageData(undefined));
    } else {
      props.handleLaunchCamera();
    }
  };

  const renderImageText = (tempImage: ImageData) => {
    if (tempImage && tempImage.url) {
      let name_array = (tempImage.url).split("/");
      name_array = name_array[name_array.length - 1].split("-")
      return ("IMG_" + name_array[name_array.length - 1])
    } else {
      return "";
    }
  };

  return (
    <VStack space="sm">
      <FormControl isRequired>
        <FormControl.Label>
          {t('leaveApplicationScreen.leaveType')}
        </FormControl.Label>
        <Select
          defaultValue={props.leaveBalance && Object.keys(props.leaveBalance).length > 0 ?
            props.leaveType : ""}
          onValueChange={props.handleLeaveTypeOnChange}
        >
          {
            props.leaveBalance && Object.keys(props.leaveBalance).length > 0 ?
              Object.entries(props.leaveBalance).map(([leaveType, value], index) => (
                <LeaveItem
                  key={`${leaveType}-${index}`}
                  label={leaveType}
                  value={leaveType}
                  leaveBalance={value.currentLeaveBalance}
                />
              ))
              :
              <LeaveItem
                label={LEAVE_WITHOUT_PAY}
                value={CASUAL_LEAVE}
                leaveBalance={0}
              />
          }
        </Select>
      </FormControl>
      <FormControl isRequired>
        <HStack
          flex={1}
          alignItems="center"
          space="sm"
        >
          <TapInputElement
            mode="date"
            labelName={String(t('modal.leaveModal.fromDateText'))}
            isError={props.isFromError}
            selectedValue={props.selectedFromDate}
            isDateTimePickerOpen={props.isFromDatePickerOpen}
            iconName="calendar"
            errorText={String(t('modal.leaveModal.fromDateErrorText'))}
            openDateTimePicker={props.openFromDatePicker}
            closeDateTimePicker={props.closeFromDatePicker}
            handleValueChange={props.handleFromDateSelectionChange}
          />
          <TapInputElement
            mode="date"
            labelName={String(t('modal.leaveModal.toDateText'))}
            isError={props.isToError}
            selectedValue={props.selectedToDate}
            isDateTimePickerOpen={props.isToDatePickerOpen}
            iconName="calendar"
            errorText={String(t('modal.leaveModal.toDateErrorText'))}
            openDateTimePicker={props.openToDatePicker}
            closeDateTimePicker={props.closeToDatePicker}
            handleValueChange={props.handleToDateSelectionChange}
          />
        </HStack>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={props.isReasonError}
      >
        <FormControl.Label>
          {t('leaveApplicationScreen.reason')}
        </FormControl.Label>
        <Input
          value={props.reason}
          onChangeText={props.handleReasonChange}
        />
        <FormControl.ErrorMessage>
          <Text color="danger.500">
            {String(t('modal.leaveModal.reasonErrorText'))}
          </Text>
        </FormControl.ErrorMessage>
      </FormControl>
      {
        props.tempImage ?
          <HStack
            w="full"
            justifyContent="space-between"
          >
            <Text
              color="black"
              paddingBottom={5}
            >
              {renderImageText(props.tempImage)}
            </Text>
            <Icon
              as={Ionicons}
              name="close"
              size="sm"
              onPress={handleImageButtonPress}
            />
          </HStack>
          :
          props.leaveType === 'Sick Leave' &&
          <Button
            alignSelf="flex-end"
            colorScheme="success"
            px={4}
            py={2}
            rightIcon={
              <Icon
                as={MaterialIcons}
                name="attach-file"
                size={5}
                color="secondary.500"
              />
            }
            isLoading={props.isStoringLeaveApplication}
            onPress={handleImageButtonPress}
          >
            <Text
              fontFamily="heading"
              fontSize="md"
              color={props.isStoringLeaveApplication ? "muted.500" : "secondary.500"}
            >
              {t('leaveApplicationScreen.attachFile')}
            </Text>
          </Button>
      }
      <VStack
        mt={5}
        space={2}
      >
        {
          props.isImageError &&
          <Box>
            <Text color="danger.500">
              {String(t('modal.leaveModal.medicalCertError'))}
            </Text>
          </Box>
        }
        <Box flex={1}>
          <Text
            fontSize="xs"
            fontWeight="bold"
            color="danger.500"
          >
            {
              props.leaveBalance && props.leaveBalance[props.leaveType]?.currentLeaveBalance <= 0 ?
                t('modal.leaveModal.noLeaveBalanceOnSelectedLeaveType', {leaveType: props.leaveType})
                : null
            }
          </Text>
        </Box>
        <Button
          _text={{
            fontFamily: 'heading',
          }}
          isLoading={props.isStoringLeaveApplication}
          onPress={() => props.handleSubmitLeaveApplication(props.leaveType)}
        >
          {t('leaveApplicationScreen.saveButton')}
        </Button>
      </VStack>
    </VStack>
  );
};

export const LeaveApplicationMenu = (props: LeaveApplicationMenuProps) => {
  const { t } = useTranslation();
  return (
    <Actionsheet
      isOpen={props.isOpen}
      onClose={props.handleMenuOnClose}
    >
      <Actionsheet.Content bg="secondary.500">
        <KeyboardAvoidingView
          w="full"
          keyboardVerticalOffset={30}
          behavior="padding"
          enabled
          flexDirection="column"
          justifyContent="center"
        >
          <ScrollView
            w="full"
            contentContainerStyle={{
              padding: 10,
              paddingBottom: 20,
            }}
          >
            <VStack space="md">
              <Box flex={1}>
                <Text
                  fontFamily="heading"
                  fontSize="xl"
                >
                  {t('leaveApplicationScreen.createLeave')}
                </Text>
              </Box>
              <InputComponent { ...props } />
            </VStack>
          </ScrollView>
        </KeyboardAvoidingView>
      </Actionsheet.Content>
    </Actionsheet>
  );
};
