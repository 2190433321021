import {StyleSheet} from "react-native";
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';

export const tapCameraStyles = StyleSheet.create({
  camera: {
    flex: 1,
    width: wp(100),
    height: "100%"
  },
  cameraButtonContainer: {
    flex: 1,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    paddingVertical: hp(3),
    backgroundColor: "transparent",
    alignItems: "center",
    zIndex: 2
  },
  cropOverlayTop: {
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    width: wp(100),
    height: hp(15.5),
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 2
  },
  cropOverlayMiddle: {
    flex: 1,
    position: "absolute",
    top: hp(15.5),
    left: 0,
    right: 0,
    width: wp(100),
    height: wp(100),
    borderWidth: hp(0.5),
    borderColor: "white"
  },
  cropOverlayBottom: {
    flex: 1,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: wp(100),
    height: hp(100) - hp(15) - wp(100),
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 2
  },
  cropOverlayFull: {
    flex: 1,
    position: "absolute",
    top: 0,
    height: hp(100),
    width: wp(100),
    zIndex: 1
  },
  imagePreviewContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  imageContainer: {
    height: hp(50),
  },
  imagePreviewButtonSection: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  }
});
