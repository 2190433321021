import React, {useEffect} from "react";
import {
  Button,
  Center,
  Icon,
  Image,
  Text,
  VStack,
  HStack, Divider
} from "native-base";
import {MaterialIcons, Feather } from "@expo/vector-icons";
import * as Analytics from "expo-firebase-analytics";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";

const JoinCompanyScreen = () => {
  const SCREEN_NAME = "Join Company Screen";

  const navigation = useNavigation();
  const {t}: { t: any } = useTranslation();

  useEffect(() => {
    Analytics.setCurrentScreen(SCREEN_NAME, SCREEN_NAME);
  }, []);

  const handleInputManuallyClick = () => {
    navigation.navigate('CompanyServerSearchScreen' as never);
  }

  const handleScanQRCodeClick = () => {
    navigation.navigate('ScanQRCodeScreen' as never);
  }

  return (
      <Center bg={"primary.500"} flex={1} p={"7"}>
        <Image source={require('../../assets/JoinCompany.png')}
               size="xl"
               alt="TAP Logo"
        />
        <VStack alignItems={"center"} w={{base: "full", md: "4/6", lg: "4/6"}} px={{base:15, md: 24, lg: 64}}>
          <Text color={"secondary.500"} fontWeight={"bold"} fontSize={"3xl"} py={5}>{String(t('companyServerSearchScreen.joinCompanyHeader'))}</Text>
          <Text color={"secondary.500"} fontSize={"sm"}>{String(t('joinCompanyScreen.paragraphText1'))}</Text>
          <Text color={"secondary.500"} fontSize={"sm"}>{String(t('joinCompanyScreen.paragraphText2'))}</Text>
        </VStack>
        <Button
          mt={4}
          borderRadius={"10"}
          bgColor="secondary.500"
          justifyContent={"flex-start"}
          w={{base: "full", md: "4/6", lg: "4/6"}}
          onPress={handleScanQRCodeClick}
        >
          <HStack alignItems={"center"} space={"md"} p={2}>
            <Icon as={MaterialIcons} name={"qr-code-scanner"} color={"primary.500"} size={"lg"}/>
            <Text color={"primary.500"} fontWeight={"bold"} fontSize={"xl"}>{String(t('joinCompanyScreen.scanQRCodeText'))}</Text>
          </HStack>
        </Button>

        <HStack py={5} space={"sm"} alignItems={"center"}>
          <Divider thickness={"2"} borderColor={"secondary.500"} width={"30%"}/>
          <Text color={"secondary.500"} fontSize={"sm"} fontWeight={"bold"}>{String(t('joinCompanyScreen.dividerText'))}</Text>
          <Divider thickness={"2"} borderColor={"secondary.500"} width={"30%"}/>
        </HStack>


        <Button
          borderRadius={"10"}
          bgColor="secondary.500"
          justifyContent={"flex-start"}
          w={{base: "full", md: "4/6", lg: "4/6"}}
          mb={"10"}
          onPress={handleInputManuallyClick}
        >
          <HStack alignItems={"center"} space={"md"} p={2}>
            <Icon as={Feather} name={"edit-2"} color={"primary.500"} size={"lg"} />
            <Text color={"primary.500"} fontWeight={"bold"} fontSize={"xl"}>{String(t('joinCompanyScreen.manualInputText'))}</Text>
          </HStack>
        </Button>
      </Center>
  );
}

export default JoinCompanyScreen;
