import * as React from "react";
import {Path} from "react-native-svg";
import {createIcon} from "native-base";

export const AchievementIconActive = () => {
  const AchievementIcon = createIcon({
    viewBox: "0 0 283.46 283.46",
    path: [
      <Path
        d="M211.9 247.62H68c-3.28 0-5.28-4.13-3.57-7.33l11.26-21a4.13 4.13 0 013.55-2.29h122.1a4.13 4.13 0 013.63 2.41l10.56 21c1.61 3.2-.41 7.21-3.63 7.21z"
        fill="#e15c54"
      />,
      <Path
        d="M76.55 30.94a4.4 4.4 0 00-4.09 6c5.31 13.18 16.82 47.09 16.82 93.48 0 19.93 15 35.78 33.75 41.75a4.42 4.42 0 013.07 4.58l-3.67 42.37a4.4 4.4 0 004.39 4.79h25.51a4.41 4.41 0 004.39-4.78l-3.57-42.55a4.41 4.41 0 013-4.55c18.47-6.07 33.25-21.72 33.25-41.41 0-46.62 12.23-80.37 17.94-93.58a4.4 4.4 0 00-4-6.14z"
        fill="#fbb360"
      />,
      <Path
        d="M211.9 254.78H68a10.27 10.27 0 01-8.83-5.13 11.62 11.62 0 01-.26-11.42l11.26-21a10.38 10.38 0 019.09-5.6h37.62l2.86-33C97.7 171 83 152.19 83 131.36c0-45.6-11.45-78.93-16.37-91.14a10.67 10.67 0 019.91-14.65h126.79a10.66 10.66 0 019.8 14.89c-5.24 12.13-17.42 45.29-17.42 91.09 0 20.52-14.48 39.21-36.19 46.9l2.79 33.18h39a10.36 10.36 0 019.23 5.86l10.56 21a11.69 11.69 0 01-.48 11.29 10.27 10.27 0 01-8.72 5zm-2-10.66zM71 242.24h138l-9.09-18.07h-49.13L146.91 178a10.63 10.63 0 017.32-11c14.41-4.73 28.94-17.53 28.94-35.46 0-45.18 11.37-78.86 17.36-93.44H79.28c5.6 14.6 16.26 48.34 16.26 93.25 0 18.24 14.76 31.11 29.39 35.78a10.62 10.62 0 017.41 11.08l-4 45.95H80.65z"
        fill="#3c8183"
      />,
      <Path
        d="M75.9 147.34a6.28 6.28 0 01-2.49-.52c-19.75-8.53-37.08-27.85-42.16-47-3.05-11.47-1.4-21.9 4.75-30.12l.47-.55c6.09-6.42 13.26-9.76 21.33-9.9h.54C74.23 59.24 87.42 72 88 72.52a6.27 6.27 0 01-8.76 9C79 81.3 68.77 71.58 58 71.78c-4.52.1-8.5 2-12.17 5.73-3.66 5.07-4.49 11.5-2.46 19.12 4 15.17 19.08 31.8 35 38.69a6.27 6.27 0 01-2.49 12zM204.25 151.7a6.27 6.27 0 01-2.49-12c15.94-6.89 31-23.52 35-38.69 2-7.62 1.19-14-2.47-19.12-3.66-3.76-7.65-5.63-12.17-5.73h-.37c-10.69 0-20.75 9.61-20.85 9.71a6.27 6.27 0 01-8.75-9c.56-.55 13.75-13.28 29.61-13.28h.55c8.06.15 15.24 3.48 21.33 9.9l.46.55c6.18 8.22 7.83 18.65 4.77 30.15-5.07 19.12-22.41 38.44-42.16 47a6.22 6.22 0 01-2.46.51z"
        fill="#3B8183"
      />
    ],
  })
  return <AchievementIcon size={"md"}/>
}

export const AchievementIconInactive = () => {
  const AchievementIcon = createIcon({
    viewBox: "0 0 283.46 283.46",
    path: [
      <Path
        d="M211.9 247.62H68c-3.28 0-5.28-4.13-3.57-7.33l11.26-21a4.13 4.13 0 013.55-2.29h122.1a4.13 4.13 0 013.63 2.41l10.56 21c1.61 3.2-.41 7.21-3.63 7.21z"
        fill="none"
      />,
      <Path
        d="M76.55 30.94a4.4 4.4 0 00-4.09 6c5.31 13.18 16.82 47.09 16.82 93.48 0 19.93 15 35.78 33.75 41.75a4.42 4.42 0 013.07 4.58l-3.67 42.37a4.4 4.4 0 004.39 4.79h25.51a4.41 4.41 0 004.39-4.78l-3.57-42.55a4.41 4.41 0 013-4.55c18.47-6.07 33.25-21.72 33.25-41.41 0-46.62 12.23-80.37 17.94-93.58a4.4 4.4 0 00-4-6.14z"
        fill="none"
      />,
      <Path
        d="M211.9 254.78H68a10.27 10.27 0 01-8.83-5.13 11.62 11.62 0 01-.26-11.42l11.26-21a10.38 10.38 0 019.09-5.6h37.62l2.86-33C97.7 171 83 152.19 83 131.36c0-45.6-11.45-78.93-16.37-91.14a10.67 10.67 0 019.91-14.65h126.79a10.66 10.66 0 019.8 14.89c-5.24 12.13-17.42 45.29-17.42 91.09 0 20.52-14.48 39.21-36.19 46.9l2.79 33.18h39a10.36 10.36 0 019.23 5.86l10.56 21a11.69 11.69 0 01-.48 11.29 10.27 10.27 0 01-8.72 5zm-2-10.66zM71 242.24h138l-9.09-18.07h-49.13L146.91 178a10.63 10.63 0 017.32-11c14.41-4.73 28.94-17.53 28.94-35.46 0-45.18 11.37-78.86 17.36-93.44H79.28c5.6 14.6 16.26 48.34 16.26 93.25 0 18.24 14.76 31.11 29.39 35.78a10.62 10.62 0 017.41 11.08l-4 45.95H80.65z"
        fill="grey"
      />,
      <Path
        fill="grey"
        d="M75.9 147.34a6.28 6.28 0 01-2.49-.52c-19.75-8.53-37.08-27.85-42.16-47-3.05-11.47-1.4-21.9 4.75-30.12l.47-.55c6.09-6.42 13.26-9.76 21.33-9.9h.54C74.23 59.24 87.42 72 88 72.52a6.27 6.27 0 01-8.76 9C79 81.3 68.77 71.58 58 71.78c-4.52.1-8.5 2-12.17 5.73-3.66 5.07-4.49 11.5-2.46 19.12 4 15.17 19.08 31.8 35 38.69a6.27 6.27 0 01-2.49 12zM204.25 151.7a6.27 6.27 0 01-2.49-12c15.94-6.89 31-23.52 35-38.69 2-7.62 1.19-14-2.47-19.12-3.66-3.76-7.65-5.63-12.17-5.73h-.37c-10.69 0-20.75 9.61-20.85 9.71a6.27 6.27 0 01-8.75-9c.56-.55 13.75-13.28 29.61-13.28h.55c8.06.15 15.24 3.48 21.33 9.9l.46.55c6.18 8.22 7.83 18.65 4.77 30.15-5.07 19.12-22.41 38.44-42.16 47a6.22 6.22 0 01-2.46.51z"
      />
    ],
  })
  return <AchievementIcon size={"md"}/>
}