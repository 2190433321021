import React, {useEffect, useState} from "react";
import {SettingsScreen} from "../screens/SettingsScreen";
import {CommonActions, useNavigation} from "@react-navigation/native";
import {State, User} from "../state";
import {connect, useDispatch} from "react-redux";
import * as Analytics from "expo-firebase-analytics";
import {
  ENGLISH_LANGUAGE_SELECTION,
  INDONESIAN_LANGUAGE_SELECTION,
  UNKNOWN_LANGUAGE_DISPLAYED
} from "../master-data/constants";
import {handleLogout} from "../actions/actionsAuth";

interface SettingsScreenContainer {
  currentUser?: User;
  language: string;
  isLoggingOut: boolean;
}

const mapStateToProps = (state: State) => {
  return {
    language: state.control.language,
    currentUser: state.control.currentUser,
    isLoggingOut: state.session.isLoggingOut,
  }
}

const SettingsScreenContainer = (props: SettingsScreenContainer) => {

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState("");

  useEffect(() => {
    if (props.language) {
      resolveDisplayedLanguage(props.language);
    }
  }, [props.language]);

  const resolveDisplayedLanguage = (language: string) => {
    switch (language) {
      case "en":
        Analytics.logEvent('switch_to_english', {
          name: 'Changing Language',
          screen: 'Setting',
          purpose: 'To change system language to English',
        });
        return setLanguage(ENGLISH_LANGUAGE_SELECTION);
      case "id":
        Analytics.logEvent('switch_to_bahasa_indonesia', {
          name: 'Changing Language',
          screen: 'Setting',
          purpose: 'To change system language to Bahasa Indonesia',
        });
        return setLanguage(INDONESIAN_LANGUAGE_SELECTION);
      default:
        return UNKNOWN_LANGUAGE_DISPLAYED;
    }
  };

  const handleSettingOnPress = (screenName: string) => {
    navigation.dispatch(
      CommonActions.navigate(screenName)
    );
  };

  const handleSettingOnLongPress = () => {
    navigation.dispatch(
      CommonActions.navigate("DeveloperScreen")
    );
  };

  const handleLogoutOnPress = () => {
    if (props.currentUser) {
      Analytics.logEvent('log_out');
      dispatch(handleLogout(props.currentUser.employeeName));
    }
  };

  return (
    <SettingsScreen
      language={language}
      isLoggingOut={props.isLoggingOut}
      handleSettingOnPress={handleSettingOnPress}
      handleSettingOnLongPress={handleSettingOnLongPress}
      handleLogoutOnPress={handleLogoutOnPress}
    />
  );
};

export default connect(mapStateToProps)(SettingsScreenContainer);
