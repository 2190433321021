import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  Input,
  InputGroup,
  InputLeftAddon,
  KeyboardAvoidingView,
  Text,
  VStack,
  Spinner,
  View,
  FormControl, 
  HStack, 
  Icon, 
  Heading,
  Avatar,
} from "native-base";
import { connect, useDispatch } from "react-redux";

import { Platform } from "react-native";
import * as Analytics from "expo-firebase-analytics";
import { useNavigation} from "@react-navigation/native";

import { State, CompanyData } from "../state";
import {
  handleCompanyServerSearch,
  deleteCompanyData,
  addNewCompanyData,
  updateSelectedCompany,
} from "../actions/actionsAuth";
import {MaterialIcons} from "@expo/vector-icons";
import { useTranslation } from "react-i18next";

interface CompanyServerSearchScreenProps {
  isSearchingCompanyServer: boolean;
  selectedCompany?: CompanyData;
  companyData: CompanyData[];
}

const mapStateToProps = (state: State) => {
  return {
    isSearchingCompanyServer: state.control.isSearchingCompanyServer,
    selectedCompany: state.control.selectedCompany,
    companyData: state.control.companyData
  };
};

const CompanyServerSearchScreen = (props: CompanyServerSearchScreenProps) => {
  const SCREEN_NAME = "Company Server Search Screen";

  // States
  const [serverUrl, setServerUrl] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [formInvalid, setFormInvalid] = useState(false);

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const {t}: { t: any } = useTranslation();

  const isSearchingCompanyServer = props.isSearchingCompanyServer;
  const selectedCompany = props.selectedCompany;
  const companyData = props.companyData;
  let foundIndex = -1;

  if(selectedCompany){
    foundIndex = companyData.findIndex( (company:CompanyData) => company.companyId === selectedCompany.companyId);
  }

  useEffect(() => {
    Analytics.setCurrentScreen(SCREEN_NAME, SCREEN_NAME);
  }, []);

  const onServerUrlChange = (text: string) => {
    setServerUrl(text);
  };

  const onCompanyIdChange = (text: string) => {
    setCompanyId(text);
  };

  const handleNextButtonClick = () => {
    if (serverUrl && companyId) {
      Analytics.logEvent("searching_server_and_company");
      
      dispatch(handleCompanyServerSearch(serverUrl, companyId));
    }
    else{
      setFormInvalid(true);
    }
  };

  const handleAddButtonClick = () => {
    if (selectedCompany){
      if(foundIndex >= 0){
        navigation.navigate("LoginScreen" as never);
        return;
      }
      Promise.resolve(dispatch(addNewCompanyData(selectedCompany)))
      .then(() => {
        navigation.navigate("LoginScreen" as never);
      })
    }
  };

  const handleClearData = () => {
    if(selectedCompany){
      dispatch(deleteCompanyData(selectedCompany.companyId));
      dispatch(updateSelectedCompany(undefined));
      setCompanyId("");
      setServerUrl("");
    }
  };

  const handleScanQRCodeClick = () => {
    navigation.navigate('ScanQRCodeScreen' as never);
  };

  return (
    <KeyboardAvoidingView
      bg={"secondary.500"}
      flex={1}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <Center bg={"secondary.500"} flex={1}>
        {
          isSearchingCompanyServer ? 
            <Spinner size="lg" color="grey" />
          : 
            <VStack w={"full"}>
              {
                !selectedCompany ? 
                  <View px={4}>
                    <Heading
                      color="primary.500"
                      py={2} 
                      fontSize="2xl"
                      fontWeight={600}
                    >
                      {String(t('companyServerSearchScreen.joinCompanyHeader'))}
                    </Heading>
                    <Text color="black" mb={10}>
                      {String(t('companyServerSearchScreen.formHeader'))}
                    </Text>
                    <FormControl isInvalid={formInvalid}>
                      <FormControl.Label>
                        <Text color="primary.500">{String(t('companyServerSearchScreen.serverUrlFormLabel'))}</Text>
                      </FormControl.Label>
                      <InputGroup borderColor={"primary.500"}>
                        <InputLeftAddon
                          bg={"secondary.500"}
                          children={<Text color="primary.500">https://</Text>}
                        />
                        <Input
                          placeholder="your-erpnext-url"
                          size="lg"
                          autoCorrect={false}
                          bgColor={"secondary.500"}
                          autoCapitalize="none"
                          onChangeText={onServerUrlChange}
                          flex={3}
                        />
                      </InputGroup>
                      <FormControl.Label mt={10}>
                        <Text color="primary.500">{String(t('companyServerSearchScreen.companyIdFormLabel'))}</Text>
                      </FormControl.Label>
                      <Input
                        borderColor={"primary.500"}
                        placeholder="e.g., 0123ABC"
                        size="lg"
                        autoCorrect={false}
                        bgColor={"secondary.500"}
                        color={"black"}
                        onChangeText={onCompanyIdChange}
                      />
                    </FormControl>
                    <Button
                      colorScheme={"primary"}
                      size="md"
                      py={4}
                      mt={16}
                      onPress={handleNextButtonClick}
                      fontWeight={"bold"}
                    >
                      {String(t('button.nextButton'))}
                    </Button>
                    <Center>
                      <Text color={"primary.500"} fontSize={"sm"} fontWeight={"bold"} py={5}>{String(t('joinCompanyScreen.dividerText'))}</Text>
                    </Center>
                      <Button onPress={handleScanQRCodeClick} bg={"#FBB35F"}>
                        <HStack space={"md"} p={2} alignItems={"center"}>
                          <Icon as={MaterialIcons} name={"qr-code-scanner"} color={"secondary.500"} size={"sm"}/>
                          <Text color={"secondary.500"} fontWeight={"bold"} fontSize={"md"}>{String(t('joinCompanyScreen.scanQRCodeText'))}</Text>
                        </HStack>
                      </Button>
                  </View>
                : 
                  <View px={4}>
                    <Center>
                      <Avatar 
                        key={selectedCompany.companyImage}
                        source={selectedCompany.companyImage !== ""? {uri: selectedCompany.companyImage} : require('../../assets/logo-gram-03.png')}
                        bg={"primary.500"}
                        size="2xl"
                      />
                      <Text color="black" textAlign="center" fontWeight={"bold"} fontSize={"xl"} mt={5}>
                        {selectedCompany.companyName}
                      </Text>
                      <Text color="black" textAlign="center" fontSize={"md"}>
                        {selectedCompany.branchName}
                      </Text>
                    </Center>
                    <Button
                      colorScheme={"primary"}
                      size="md"
                      mt={100}
                      onPress={handleAddButtonClick}
                    >
                      {foundIndex>= 0 ? String(t('button.selectButton')) :String(t('button.addButton'))}
                    </Button>
                    <Button
                      colorScheme={"secondary"}
                      size="md"
                      mt={5}
                      onPress={handleClearData}
                    >
                      <Text color="black">
                        {String(t('button.backButton'))}
                      </Text>
                    </Button>
                  </View>
            }
          </VStack>
        }
      </Center>
    </KeyboardAvoidingView>
  );
};

export default connect(mapStateToProps)(CompanyServerSearchScreen);
