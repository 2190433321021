import React, { useEffect, useState } from "react";
import { Dimensions, View } from "react-native";
import { Center, Flex, Modal } from "native-base";
import { useDispatch } from "react-redux";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { BarCodeScanner, BarCodeScannerResult } from "expo-barcode-scanner";
import BarcodeMask from "react-native-barcode-mask";
import { PermissionResponse, Camera } from "expo-camera";
import { useTranslation } from "react-i18next";

// Actions
import { handleCompanyServerSearch } from "../actions/actionsAuth";

// Constants
import { DEFAULT_CAMERA_RATIO } from "../master-data/constants";

// Styles
import { tapCameraStyles } from "../styles/tapCameraStyles";

export const ScanQRCodeScreen = () => {
  const [hasCameraPermission, setHasCameraPermission]: any = useState(null);
  const [scanned, setScanned] = useState(false);
  const [isNoPermissionModalOpen, setIsNoPermissionModalOpen] = useState(true);

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { t }: { t: any } = useTranslation();
  const isFocused = useIsFocused();

  //Scanning Area
  const finderWidth: number = 300; //barcode mask
  const finderHeight: number = 300; //barcode mask
  const width = Dimensions.get("window").width;
  const height = Dimensions.get("window").height;
  const viewMinX = (width - finderWidth) / 2;
  const viewMinY = (height - finderHeight) / 3;

  useEffect(() => {
    Camera.requestCameraPermissionsAsync().then(
      (response: PermissionResponse) => {
        if (response.status === "granted") {
          setHasCameraPermission(true);
        } else {
          setIsNoPermissionModalOpen(true);
        }
      }
    );
    return () => {
      setScanned(false);
    };
  }, []);

  const handleQRCodeScanned = (scanningResult: BarCodeScannerResult) => {
    let data = scanningResult.data;
    setScanned(true);
    // Getting the Server URL and Company ID from data
    data = data.substring(1, data.length - 1);

    Promise.resolve(
      dispatch(handleCompanyServerSearch(undefined, undefined, data))
    ).then(() => {
      setScanned(false);
      if (data.includes("at_erpnext_tap_extension")) {
        navigation.navigate("CompanyServerSearchScreen" as never);
      }
    });
  };

  return (
    <View style={{
      height: "100%",
      width: "100%",
      backgroundColor: 'black'
    }}>
    
      {hasCameraPermission ? (
        <Flex flex={1}>
          <Camera
            style={tapCameraStyles.camera}
            onBarCodeScanned={scanned ? undefined : handleQRCodeScanned}
            ratio={DEFAULT_CAMERA_RATIO}
          ></Camera>
          <BarcodeMask edgeColor="#62B1F6" showAnimatedLine height={300} width={300} />
        </Flex>
      ) : (
        <Modal
          isOpen={isNoPermissionModalOpen}
          onClose={() => navigation.navigate("JoinCompanyScreen" as never)}
        >
          <Modal.Content maxWidth={"400px"}>
            <Modal.CloseButton />
            <Modal.Header>
              {String(t("modal.errorModal.cameraPermissionError"))}
            </Modal.Header>
            <Modal.Body>
              {String(t("modal.errorModal.QRCodeCameraPermissionErrorText"))}
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
   
    </View>
  );
};
