import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {CompanyInformation, ModalProps, State} from "../state";
import i18n from "i18next";
import {handleHttpError, handleNoBaseUrlError} from "./actionsAuth";
import axios, {AxiosResponse} from "axios";
import {extractCompanyInformation} from "./frappeResponse";
import {updateErrorModalStatus} from "./actions";
import {captureErrorSentry, constructFields, constructFilters} from "./httpUtils";

export const UPDATE_COMPANY_INFORMATION = "UPDATE_COMPANY_INFORMATION";
export const UPDATE_FETCHING_COMPANY_INFORMATION_STATUS = "UPDATE_FETCHING_COMPANY_INFORMATION_STATUS";

export const updateCompanyInformation = (companyInformation: CompanyInformation) => {
  return { type: UPDATE_COMPANY_INFORMATION, payload: companyInformation };
}

export const updateFetchingCompanyInformationStatus = (status: boolean) => {
  return { type: UPDATE_FETCHING_COMPANY_INFORMATION_STATUS, payload: status };
};

export const fetchCompanyInformation = (companyName: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchCompanyInformation')
    };

    const fields = constructFields([
      "company_name",
      "company_description",
      "company_logo",
      "email",
      "phone_no",
      "address",
      "vision_mission",
      "code_of_conduct",
      "whatsapp_number",
    ]);

    const filters = constructFilters({
      name: companyName,
    });

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = `${baseUrl}/api/resource/Company?${fields}&${filters}`;

    dispatch(updateFetchingCompanyInformationStatus(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch(updateCompanyInformation(extractCompanyInformation(response.data.data[0])));
          dispatch(updateFetchingCompanyInformationStatus(false));
        } else {
          captureErrorSentry("Error when fetching company information", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingCompanyInformationStatus);
        }
      })
      .catch(error => {
        dispatch(updateFetchingCompanyInformationStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};
