export const TIME_DISPLAY_FORMAT = 'HH:mm'; // 22:10
export const TIME_FRAPPE_FORMAT = 'HH:mm:ss'; // 22:10:11
export const ATTENDANCE_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_DISPLAY_FORMAT = "DD MMMM YYYY";
export const DATE_DISPLAY_FORMAT_FULL = "dddd, DD MMMM YYYY";
export const DATE_DISPLAY_FORMAT_SHORT = "YYYY-MM-DD";

export const FIXED_TIMEZONE_NAME = 'Asia/Jakarta';

export const DEFAULT_TIME = "00:00:00";

export const DAWN_START_HOUR = 4;
export const MORNING_START_HOUR = 6;
export const NOON_START_HOUR = 11;
export const AFTERNOON_START_HOUR = 15;
export const EVENING_START_HOUR = 17;
export const NIGHT_START_HOUR = 19;
export const MIDNIGHT_HOUR = 23;

export const formatDateYYYYMMDD = (date?: any) => {
  let YYYY = date.getFullYear().toString();
  let MM = (date.getMonth() + 1).toString();
  let DD = date.getDate().toString();

  if (MM.length < 2) {
    MM = "0".concat(MM);
  }
  if (DD.length < 2) {
    DD = "0".concat(DD);
  }
  return YYYY + ("-") + (MM) + ("-") + (DD);
}

export const formatDateUIFriendly = (receivedDate: any) => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  let day = days[receivedDate.getDay()];
  let date = receivedDate.getDate();
  let month = months[receivedDate.getMonth()];
  let year = receivedDate.getFullYear();

  return day + ", " + date + " " + month + " " + year;
}
