import React, {useEffect, useRef, useState} from "react";
import {Box, Heading, HStack, Icon, Image, Pressable, Text, useTheme, VStack} from "native-base";
import {OnBoarding} from "../state";
import {Animated, Dimensions, FlatList, ListRenderItem, StyleSheet} from "react-native";
import {useTranslation} from "react-i18next";
import {Ionicons} from "@expo/vector-icons";

interface OnBoardingScreenProps {
  onBoardingData: OnBoarding[];
  selectedLanguage: string;
  handleSkipOnPress: () => void;
  handleOpenLanguageSelection: () => void;
}

interface ComponentProps {
  numberOfContents?: number;
  scrollX: Animated.Value;
}

interface HeaderComponentProps {
  selectedLanguage: string;
  handleOpenLanguageSelection: () => void;
}

const { width } = Dimensions.get('screen');

const ScrollBulletsIndicator = (props: ComponentProps) => {
  let bulletElements: JSX.Element[] = [];
  const numberOfContents = props.numberOfContents || 0;
  for (let i = 0; i < numberOfContents; i++) {
    const inputRange = [(i - 1) * width, i * width, (i + 1) * width];
    const opacity = props.scrollX.interpolate({
      inputRange,
      outputRange: [0.4, 1, 0.4],
      extrapolate: 'clamp',
    });
    const widthRange = props.scrollX.interpolate({
      inputRange,
      outputRange: [8, 25, 8],
      extrapolate: 'clamp',
    });
    const theme = useTheme();
    bulletElements.push(
      <Animated.View
        key={`indicator-${i}`}
        style={{
          height: 8,
          width: widthRange,
          borderRadius: 100,
          backgroundColor: theme.colors.success[500],
          opacity,
        }}
      />
    );
  }
  return (
    <HStack space="sm">
      {bulletElements}
    </HStack>
  );
};

const Backdrop = (props: ComponentProps) => {
  const theme = useTheme();
  const backgroundColors = [theme.colors.secondary[300], theme.colors.secondary[400], theme.colors.secondary[500]];
  const backgroundColor = props.scrollX.interpolate({
    inputRange: backgroundColors.map((color, i) => width * i),
    outputRange: backgroundColors.map(color => color),
  });

  return (
    <Animated.View
      style={[
        StyleSheet.absoluteFillObject,
        {
          backgroundColor,
        }
      ]}
    />
  );
};

const HeaderComponent = (props: HeaderComponentProps) => {
  return (
    <HStack
      px={5}
      w="full"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box flex={1}>
        <Image
          key="tapLogo"
          alt="tapLogo"
          source={require('../../assets/onboarding/tap-logo-onboarding.png')}
          resizeMode="contain"
          size={20}
        />
      </Box>
      <Box flex={1}>
        <Pressable
          p={2}
          onPress={props.handleOpenLanguageSelection}
        >
          <HStack
            justifyContent="flex-end"
            alignItems="center"
          >
            <Text
              fontSize="xl"
              fontFamily="heading"
              color="primary.500"
              bold
            >
              {props.selectedLanguage.toUpperCase()}
            </Text>
            <Icon
              as={Ionicons}
              name="chevron-down-outline"
              color="primary.500"
              size="sm"
            />
          </HStack>
        </Pressable>
      </Box>
    </HStack>
  );
};

export const OnBoardingScreen = (props: OnBoardingScreenProps) => {
  const [index, setIndex] = useState(0);

  const {t}: { t: any } = useTranslation();
  const ref = useRef<FlatList>(null);
  const scrollX = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (index < props.onBoardingData.length) {
      ref.current?.scrollToIndex({
        index,
        animated: true,
      });
    }
  }, [index]);

  const renderItem: ListRenderItem<OnBoarding> = (data) => {
    const item = data.item;
    return (
      <VStack
        flex={1}
        space="lg"
        key={item.title}
        alignItems="center"
        w={width}
      >
        <Box flex={3}>
          <Image
            flex={1}
            key={item.title}
            alt={item.title}
            source={item.imageUrl}
            resizeMode="contain"
          />
        </Box>
        <VStack
          flex={1}
          space={3}
          alignItems="center"
        >
          <Heading
            color="primary.500"
            textAlign="center"
          >
            {item.title}
          </Heading>
          <Text
            fontSize="md"
            textAlign="center"
            color="primary.500"
            numberOfLines={2}
          >
            {item.subTitle}
          </Text>
        </VStack>
      </VStack>
    );
  };

  return (
    <VStack
      flex={1}
      bg="secondary.400"
      safeAreaTop
      alignItems="center"
      w="full"
    >
      <Backdrop scrollX={scrollX} />
      <HeaderComponent
        selectedLanguage={props.selectedLanguage}
        handleOpenLanguageSelection={props.handleOpenLanguageSelection}
      />
      <Animated.FlatList
        ref={ref}
        initialScrollIndex={index}
        data={props.onBoardingData}
        overScrollMode="never"
        renderItem={renderItem}
        keyExtractor={(item, index) => String(index)}
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={32}
        style={{
          marginVertical: 20,
        }}
        pagingEnabled
        onEndReachedThreshold={0.5}
        onEndReached={() => setIndex(props.onBoardingData.length - 1)}
        horizontal
        onScroll={
          Animated.event(
            [{nativeEvent: {contentOffset: {x: scrollX}}}],
            {useNativeDriver: false},
        )}
      />
      <Box
        p={4}
        mb={8}
        w="full"
        alignItems="center"
      >
        <Box
          flex={1}
          mb={8}
        >
          <ScrollBulletsIndicator
            numberOfContents={props.onBoardingData.length}
            scrollX={scrollX}
          />
        </Box>
        <Pressable
          w="full"
          bg="transparent"
          alignItems="center"
          justifyContent="center"
          borderRadius={8}
          borderWidth={index >= props.onBoardingData.length - 1 ? 2 : 0}
          borderColor="primary.500"
          p={3}
          _pressed={{
            opacity: 0.5
          }}
          onPress={props.handleSkipOnPress}
        >
          <Text
            fontSize="md"
            color="primary.500"
            bold
          >
            {t("onBoardingScreen.button")}
          </Text>
        </Pressable>
      </Box>
    </VStack>
  );
};
