import {SceneMap, TabView} from "react-native-tab-view";
import React, {useEffect, useState} from "react";
import {DEVICE_WIDTH} from "../master-data/constants";
import {TabBarComponent} from "../Components/TabBarComponent";
import CompanyScreenContainer from "../Containers/CompanyScreenContainer";
import CompanyRulesScreenContainer from "../Containers/CompanyRulesScreenContainer";
import {Avatar, Text, VStack} from "native-base";
import {CompanyInformation, State} from "../state";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import RenderHtml from 'react-native-render-html';
import { useWindowDimensions } from 'react-native';

interface CompanyInformationComponentProps {
  companyInformation: CompanyInformation
}

const CompanyInformationComponent = (props: CompanyInformationComponentProps) => {
  const { width } = useWindowDimensions();
  return (
    <VStack
      space="xs"
      bg="secondary.500"
      px={4}
      mt={4}
    >
      <Avatar
        borderRadius={5}
        key={props.companyInformation.companyLogo}
        source={ props.companyInformation.companyLogo !== '' ?
          { uri: props.companyInformation.companyLogo }
          : require('../../assets/logo-gram-03.png')
        }
        size="xl"
      />
      <Text
        fontSize="lg"
        bold
        color="darkText"
        numberOfLines={1}
      >
        {props.companyInformation.companyName}
      </Text>
      <RenderHtml
        contentWidth={width}
        source={{
          html: props.companyInformation.companyDescription
        }}
      />
    </VStack>
  );
};

export const CompanyTopTabNavigator = () => {

  const initialCompanyInformation: CompanyInformation = {
    companyName: '',
    companyDescription: '',
    companyLogo: '',
    email: '',
    phone: '',
    address: '',
    codeOfConduct: '',
    visionMission: '',
    whatsAppNumber: '',
  };
  const {t}: { t: any } = useTranslation();

  const selectedCompany = useSelector((state: State) => state.control.selectedCompany);
  const companyInformation = useSelector((state: State) => state.control.companyInformation);

  const [company, setCompany] = useState<CompanyInformation>(initialCompanyInformation);

  useEffect(() => {
    if (companyInformation && selectedCompany) {
      let updatedCompanyInformation: CompanyInformation;
      updatedCompanyInformation = {
        ...companyInformation,
        companyLogo: companyInformation.companyLogo !== '' ?
          selectedCompany.serverUrl.concat(companyInformation.companyLogo) : '',
      };
      setCompany(updatedCompanyInformation);
    }
  }, [companyInformation, selectedCompany]);

  const initialLayout = { width: DEVICE_WIDTH };

  const renderScene = SceneMap({
    first: CompanyScreenContainer,
    second: CompanyRulesScreenContainer,
  });

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: 'first', title: t('companyScreen.aboutCompany') },
    { key: 'second', title: t('companyScreen.companyRules') },
  ]);

  return (
    <VStack
      flex={1}
      space="xs"
      bg="secondary.500"
    >
      <CompanyInformationComponent
        companyInformation={company}
      />
      <TabView
        onIndexChange={setIndex}
        navigationState={{ index, routes }}
        renderScene={renderScene}
        renderTabBar={props => <TabBarComponent {...props} /> }
        initialLayout={initialLayout}
      />
    </VStack>
  );
};
