import React, {useEffect, useState} from "react";
import {
  Avatar,
  Button,
  Center,
  Circle,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  KeyboardAvoidingView,
  Pressable,
  Spinner,
  Text,
  useContrastText,
  VStack,
} from "native-base";
import {connect, useDispatch} from "react-redux";
import {
  deleteCompanyData, handleCompanyServerSearch,
  handleLogin,
  updateSelectedCompany,
} from "../actions/actionsAuth";
import {State, CompanyData} from "../state";
import {Feather} from "@expo/vector-icons";
import {Platform} from "react-native";
import * as Analytics from "expo-firebase-analytics";
import {useNavigation, useRoute} from "@react-navigation/native";
import base64 from 'base-64';
import { useTranslation } from "react-i18next";

interface LoginScreenProps {
  isAuthenticating: boolean;
  expoPushToken: string;
  selectedCompany?: CompanyData;
  companyData: CompanyData[];
}

const mapStateToProps = (state: State) => {
  return {
    selectedCompany: state.control.selectedCompany,
    expoPushToken: state.session.expoPushToken,
    companyData: state.control.companyData,
    isAuthenticating: state.session.isLoggingIn
      || state.session.isLoggingOut
      || state.session.isGettingLoggedUser
      || state.session.isGettingUserInfo
      || state.session.isGettingApiKeyFromSecureStore
  }
}

const LoginScreen = (props: LoginScreenProps) => {
  const SCREEN_NAME = "Login Screen";

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const colorContrastLight = useContrastText("primary.100");
  const buttonColorContrastLight = useContrastText("success.500");

  const dispatch = useDispatch();
  const {t}: { t: any } = useTranslation();
  const navigation = useNavigation();
  const route = useRoute();
  const params: any = route.params;

  const isAuthenticating = props.isAuthenticating;
  const selectedCompany = props.selectedCompany;
  const companyData = props.companyData;
  const expoPushToken = props.expoPushToken;

  let serverURL: string;
  let companyID: string;
  let deepLinkUserName: string;
  let deepLinkPassword: string;

  useEffect(() => {
    Analytics.setCurrentScreen(SCREEN_NAME, SCREEN_NAME);
  }, []);

  useEffect(() => {
    if (params && params.credentials) {
      const credentials: string = base64.decode(params.credentials);
      serverURL = credentials.split(":")[0];
      companyID = credentials.split(":")[1];
      deepLinkUserName = credentials.split(":")[2];
      deepLinkPassword = credentials.split(":")[3];
      setUserName(credentials.split(":")[2]);
      setPassword(credentials.split(":")[3]);
      Promise.resolve(
        dispatch(handleCompanyServerSearch(serverURL, companyID))
      ).then( () => {
        if(deepLinkUserName && deepLinkPassword){
          Analytics.logEvent('log_in');
          dispatch(handleLogin(deepLinkUserName, deepLinkPassword, expoPushToken));
        }
      });
    }
  }, []);

  const renderIcon = () => (
    <Center>
      <Icon as={Feather}
            name={passwordVisible ? 'eye-off' : 'eye'}
            mx={4}
            size={"xs"}
            color={colorContrastLight}
            onPress={() => setPasswordVisible(!passwordVisible)}
      />
    </Center>
  );

  const onUserNameChange = (text: string) => {
    setUserName(text);
  }

  const onPasswordChange = (text: string) => {
    setPassword(text);
  }

  const handleLoginButtonClick = () => {
    if (userName && password) {
      Analytics.logEvent('log_in');
      dispatch(handleLogin(userName, password, expoPushToken));
    }
  };

  const handleCompanyList = () => {
    navigation.navigate('CompanyListScreen' as never);
  }

  const handleClearCompanies = () => {
    Promise.resolve(companyData.forEach((company) => {
      dispatch(deleteCompanyData(company.companyId));
    }))
      .then(() => {
        dispatch(updateSelectedCompany(undefined))
      })
      .then(() => {
        navigation.navigate("JoinCompanyScreen" as never);
      })
  }

  return (
    <KeyboardAvoidingView bg={"primary.500"}
                          flex={1}
                          behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <Center bg={"primary.500"} flex={1}>
        <Image source={require('../../assets/tap-icon-1024x1024.png')}
               size="2xl"
               alt="TAP Logo"
        />
        {
          isAuthenticating ?
            <Spinner size="lg" color="grey" />
          :
            <VStack px={{base: 16, md: 24, lg: 64}}>
              {
                selectedCompany ?
                  <Flex>
                    <Button
                      variant="solid"
                      bgColor="secondary.500"
                      onPress={handleCompanyList}
                    >
                      <HStack maxW={"300"}>
                        <Avatar
                          key={selectedCompany.companyImage}
                          source={selectedCompany.companyImage !== ""? {uri: selectedCompany.companyImage} : require('../../assets/logo-gram-03.png')}
                          bg={"primary.500"}
                          size={"md"}
                          my={1}
                          ml={1}
                        />
                        <VStack ml={3} mr={5} justifyContent="center" >
                          <Text color={colorContrastLight} fontWeight="bold" fontSize={"md"} isTruncated maxW={"150px"} w={"100%"}>{selectedCompany.companyName}</Text>
                          <Text color={colorContrastLight} isTruncated maxW={"150px"} w={"100%"}>{selectedCompany.branchName}</Text>
                        </VStack>
                        <Pressable my={2} mr={2} onPress={handleCompanyList}>
                          <Circle size={9} bg={"orange.300"} alignContent="center">
                            <Icon as={Feather} size={4} name={"repeat"} color={"white"} />
                          </Circle>
                        </Pressable>
                      </HStack>
                    </Button>
                  </Flex>
                :
                  null
              }
              <Input placeholder='Email'
                    textContentType="username"
                    autoCapitalize="none"
                    autoCompleteType="username"
                    size='lg'
                    autoCorrect={false}
                    onChangeText={onUserNameChange}
                    placeholderTextColor={colorContrastLight}
                    color={colorContrastLight}
                    variant="filled"
                    mt={10}
                    value={userName}
              />
              <Input placeholder='Password'
                    secureTextEntry={!passwordVisible}
                    autoCompleteType="password"
                    textContentType="password"
                    size='lg'
                    InputRightElement={renderIcon()}
                    onChangeText={onPasswordChange}
                    placeholderTextColor={colorContrastLight}
                    color={colorContrastLight}
                    variant="filled"
                    mt={5}
                    value={password}
              />
              <Button
                colorScheme={'success'}
                size='md'
                mt={10}
                onPress={handleLoginButtonClick}
              >
                {String(t('button.loginButton'))}
              </Button>
              <Button colorScheme={'secondary'}
                      size={'md'}
                      variant={'ghost'}
                      mt={5}
                      onPress={handleClearCompanies}
                      _text={{color: buttonColorContrastLight}}
                      _pressed={{bg: "transparent"}}
              >
                {String(t('button.clearCompaniesButton'))}
              </Button>
            </VStack>
          }
      </Center>
    </KeyboardAvoidingView>
  );
}

export default connect(mapStateToProps)(LoginScreen);
