import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import moment from "moment";
import axios, {AxiosResponse} from 'axios';
import i18n from "i18next";

// Actions
import {updateDailyRewardModalStatus, updateErrorModalStatus, updateSuccessModalStatus} from "./actions";
import {handleHttpError, handleNoBaseUrlError} from "./actionsAuth";

// Constants
import {TIME_DISPLAY_FORMAT, TIME_FRAPPE_FORMAT} from "../lib/dateFormat";

// State
import {Attendance, AttendanceBreak, ModalProps, State} from "../state";

// Utils
import {
  extractAttendanceBreakData,
  extractAttendanceBreakList,
  extractAttendanceData,
  extractAttendanceList,
  extractCurrentAttendanceBreak,
  extractNewAttendance
} from "./frappeResponse";

import {captureErrorSentry, constructComplexFilters, constructFields} from "./httpUtils";

export const UPDATE_FETCHING_ATTENDANCES_ATTRIBUTE = "UPDATE_FETCHING_ATTENDANCES_ATTRIBUTE";
export const UPDATE_FETCHING_ATTENDANCE_BREAK_ATTRIBUTE = "UPDATE_FETCHING_ATTENDANCE_BREAK_ATTRIBUTE";

export const UPDATE_ATTENDANCE_LIST = "UPDATE_ATTENDANCE_LIST";
export const ADD_NEW_ATTENDANCE = "ADD_NEW_ATTENDANCE";
export const UPDATE_ATTENDANCE_BREAK_LIST = "UPDATE_ATTENDANCE_BREAK_LIST";

export const UPDATE_ATTENDANCE_CHECKEDIN_STATUS = "UPDATE_ATTENDANCE_CHECKEDIN_STATUS";
export const UPDATE_ATTENDANCE_CHECKEDOUT_STATUS = "UPDATE_ATTENDANCE_CHECKEDOUT_STATUS";

export const UPDATE_IS_CHECKING_IN_STATUS = "UPDATE_IS_CHECKING_IN_STATUS";
export const UPDATE_IS_CHECKING_OUT_STATUS = "UPDATE_IS_CHECKING_OUT_STATUS";

export const UPDATE_ATTENDANCE_BREAKIN_STATUS = "UPDATE_ATTENDANCE_BREAKIN_STATUS";
export const UPDATE_ATTENDANCE_BREAKOUT_STATUS = "UPDATE_ATTENDANCE_BREAKOUT_STATUS";

export const UPDATE_IS_BREAKING_IN_STATUS = "UPDATE_IS_BREAKING_IN_STATUS";
export const UPDATE_IS_BREAKING_OUT_STATUS = "UPDATE_IS_BREAKING_OUT_STATUS";

export const UPDATE_IS_SAVING_OTHER_DATE_STATUS = "UPDATE_IS_SAVING_OTHER_DATE_STATUS"

export const UPDATE_CURRENT_ATTENDANCE = "UPDATE_CURRENT_ATTENDANCE";
export const UPDATE_CURRENT_ATTENDANCE_BREAK = "UPDATE_CURRENT_ATTENDANCE_BREAK";

export const updateAttendanceCheckedInStatus = (isAttendanceCheckedIn: boolean) => {
  return {type: UPDATE_ATTENDANCE_CHECKEDIN_STATUS, payload: isAttendanceCheckedIn};
};

export const updateAttendanceCheckedOutStatus = (isAttendanceCheckedOut: boolean) => {
  return {type: UPDATE_ATTENDANCE_CHECKEDOUT_STATUS, payload: isAttendanceCheckedOut};
};

export const updateCheckingInStatus = (isCheckingIn: boolean) => {
  return {type: UPDATE_IS_CHECKING_IN_STATUS, payload: isCheckingIn};
};

export const updateCheckingOutStatus = (isCheckingOut: boolean) => {
  return {type: UPDATE_IS_CHECKING_OUT_STATUS, payload: isCheckingOut};
};

export const updateBreakingInStatus = (isBreakingIn: boolean) => {
  return {type: UPDATE_IS_BREAKING_IN_STATUS, payload: isBreakingIn};
};

export const updateBreakingOutStatus = (isBreakingOut: boolean) => {
  return {type: UPDATE_IS_BREAKING_OUT_STATUS, payload: isBreakingOut};
};

export const updateSavingOtherDateStatus = (isSavingOtherDate: boolean) => {
  return {type: UPDATE_IS_SAVING_OTHER_DATE_STATUS, payload: isSavingOtherDate};
};

export const updateFetchingAttendancesAttribute = (status: boolean) => {
  return {
    type: UPDATE_FETCHING_ATTENDANCES_ATTRIBUTE,
    payload: {
      isFetching: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const updateFetchingAttendancesBreak = (status: boolean) => {
  return {
    type: UPDATE_FETCHING_ATTENDANCE_BREAK_ATTRIBUTE,
    payload: {
      isFetching: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const updateCurrentAttendanceList = (attendanceList: Attendance[]) => {
  return {type: UPDATE_ATTENDANCE_LIST, payload: attendanceList};
};

export const updateCurrentAttendanceBreak = (currentAttendanceBreak?: AttendanceBreak) => {
  return {type: UPDATE_CURRENT_ATTENDANCE_BREAK, payload: currentAttendanceBreak};
};

export const updateAttendanceBreakList = (attendanceBreakList?: AttendanceBreak[]) => {
  return {type: UPDATE_ATTENDANCE_BREAK_LIST, payload: attendanceBreakList};
};

export const addNewAttendance = (currentAttendance: Attendance) => {
  return {type: ADD_NEW_ATTENDANCE, payload: currentAttendance}
};

export const updateCurrentAttendance = (currentAttendance?: Attendance) => {
  return {type: UPDATE_CURRENT_ATTENDANCE, payload: currentAttendance}
};

export const fetchAttendances = (
  employeeName: string,
  selectedMonth: { firstDay: string; lastDay: string },
) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    const attendanceFilters = constructComplexFilters([
      {
        key: "employee",
        operator: "=",
        value: employeeName
      },
      {
        key: "docstatus",
        operator: "=",
        value: "1"
      },
      {
        key: "attendance_date",
        operator: ">=",
        value: selectedMonth.firstDay,
      },
      {
        key: "attendance_date",
        operator: "<=",
        value: selectedMonth.lastDay,
      },
    ])
    const attendanceFields = constructFields([
      "name",
      "attendance_date",
      "start_time",
      "end_time",
      "employee",
      "docstatus"
    ])

    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchAttendancesErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      if (!baseUrl) {
        handleNoBaseUrlError(errorModalStatus, dispatch);
      }
    }

    const url = baseUrl + "/api/resource/Attendance?".concat(attendanceFilters).concat("&").concat(attendanceFields);

    dispatch(updateFetchingAttendancesAttribute(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch(updateCurrentAttendanceList(extractAttendanceList(response.data.data)));
          dispatch(updateFetchingAttendancesAttribute(false));
        } else {
          captureErrorSentry("Error when fetching attendances", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingAttendancesAttribute);
        }
      })
      .catch(error => {
        dispatch(updateFetchingAttendancesAttribute(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const fetchTodayAttendance = (employeeName: string, todayDate: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    const attendanceFilters = constructComplexFilters([
      {
        key: "employee",
        operator: "=",
        value: employeeName
      },
      {
        key: "attendance_date",
        operator: "=",
        value: todayDate
      },
      {
        key: "docstatus",
        operator: "!=",
        value: "2"
      }
    ])
    const attendanceFields = constructFields(["name", "attendance_date", "start_time", "end_time", "docstatus"]);

    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchTodayAttendanceErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }
    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }
    const url = baseUrl + "/api/resource/Attendance?".concat(attendanceFilters).concat("&").concat(attendanceFields);

    dispatch(updateFetchingAttendancesAttribute(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          const user = response.data.data
          dispatch(updateCurrentAttendance(user.length > 0 ? extractNewAttendance(user[0]) : undefined));
          dispatch(updateFetchingAttendancesAttribute(false));
        } else {
          captureErrorSentry("Error when fetching today attendance", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingAttendancesAttribute);
        }
      })
      .catch(error => {
        dispatch(updateFetchingAttendancesAttribute(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const postCheckInAttendance = (employee: string, latitude?: number, longitude?: number) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.checkInErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }
    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.create_new_attendance";

    const createCheckInData = new URLSearchParams();
    createCheckInData.append("data", JSON.stringify({
      employee: employee,
      check_in_latitude: latitude,
      check_in_longitude: longitude
    }));

    dispatch(updateCheckingInStatus(true));

    axios({
      method: 'post',
      url: url,
      data: createCheckInData,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateCurrentAttendance(extractAttendanceData(response.data.message, false)));
          dispatch(updateAttendanceCheckedInStatus(true));
          dispatch(updateCheckingInStatus(false));
          dispatch(updateSuccessModalStatus({
            isOpen: true,
            title: i18n.t('modal.successModal.title'),
            content: i18n.t('modal.successModal.checkInSuccess')
          }));
        } else {
          captureErrorSentry("Error when TAP In", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateCheckingInStatus);
          dispatch(updateAttendanceCheckedInStatus(false));
        }
      })
      .catch(error => {
        dispatch(updateAttendanceCheckedInStatus(false));
        dispatch(updateCheckingInStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  }
};

export const submitAttendance = (attendanceId: string, latitude?: number, longitude?: number) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.checkOutErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.submit_attendance";

    const submitAttendanceData = new URLSearchParams();
    submitAttendanceData.append("data", JSON.stringify({
      attendance: attendanceId,
      check_out_latitude: latitude,
      check_out_longitude: longitude
    }));

    dispatch(updateCheckingOutStatus(true));

    axios({
      method: 'post',
      url: url,
      data: submitAttendanceData,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          const updatedAttendance = extractAttendanceData(response.data.message.attendance, true);
          dispatch(updateCurrentAttendance(updatedAttendance));
          dispatch(addNewAttendance(updatedAttendance));
          dispatch(updateAttendanceCheckedOutStatus(true));
          dispatch(updateCheckingOutStatus(false));
          dispatch(updateDailyRewardModalStatus({
            isOpen: true,
            todayPoint: response.data.message.point,
            attendanceStreak: response.data.message.attendance_streak
          }));
        } else {
          captureErrorSentry("Error when TAP Out", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateAttendanceCheckedOutStatus);
        }
      })
      .catch(error => {
        dispatch(updateAttendanceCheckedOutStatus(false));
        dispatch(updateCheckingOutStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  }
};

export const createOtherDateAttendance = (employee: string, attendanceDate: string, startTime: string, endTime: string, status: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.otherDateAttendanceErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/resource/Attendance";

    const attendanceData = new URLSearchParams();
    attendanceData.append("data", JSON.stringify({
      employee: employee,
      status: status,
      attendance_date: attendanceDate,
      start_time: moment(startTime, TIME_DISPLAY_FORMAT).format(TIME_FRAPPE_FORMAT),
      end_time: moment(endTime, TIME_DISPLAY_FORMAT).format(TIME_FRAPPE_FORMAT),
      docstatus: 1
    }));

    dispatch(updateSavingOtherDateStatus(true));

    axios({
      method: 'post',
      url: url,
      data: attendanceData,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          const updatedAttendance = extractAttendanceData(response.data.data, true);
          dispatch(addNewAttendance(updatedAttendance));
          dispatch(updateSavingOtherDateStatus(false));
        } else {
          captureErrorSentry("Error when submitting other date", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateSavingOtherDateStatus);
        }
      })
      .catch(error => {
        dispatch(updateSavingOtherDateStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  }
};

export const fetchAttendanceBreaks = (attendanceId: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchAttendanceBreaksErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/resource/Attendance/".concat(attendanceId).concat("?fields=%22attendance_break_table%22");

    dispatch(updateFetchingAttendancesBreak(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch(updateAttendanceBreakList(extractAttendanceBreakList(response.data.data.attendance_break_table)));
          dispatch(updateCurrentAttendanceBreak(extractCurrentAttendanceBreak(response.data.data.attendance_break_table)[0]));
          dispatch(updateFetchingAttendancesBreak(false));
        } else {
          captureErrorSentry("Error when fetching attendance breaks", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingAttendancesBreak);
        }
      })
      .catch(error => {
        dispatch(updateFetchingAttendancesBreak(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const createAttendanceBreak = (employee: string, attendanceId: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.createAttendanceBreakErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/resource/Attendance%20Break";
    const createBreakInData = new URLSearchParams();
    createBreakInData.append("data", JSON.stringify({
      employee_name: employee,
      attendance_name: attendanceId
    }));

    dispatch(updateBreakingInStatus(true));

    axios({
      method: 'post',
      url: url,
      data: createBreakInData,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateCurrentAttendanceBreak(extractAttendanceBreakData(response.data.data)));
          dispatch(updateBreakingInStatus(false));
          dispatch(updateSuccessModalStatus({
            isOpen: true,
            title: i18n.t('modal.successModal.title'),
            content: i18n.t('modal.successModal.breakSuccess')
          }));
        } else {
          captureErrorSentry("Error when submitting break", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateBreakingInStatus);
        }
      })
      .catch(error => {
        dispatch(updateBreakingInStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  }
};

export const updateAttendanceBreak = (attendanceBreakId: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.updateAttendanceBreakErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.update_attendance_break";
    const createBreakOutData = new URLSearchParams();
    createBreakOutData.append("data", JSON.stringify({
      attendance_break: attendanceBreakId
    }));

    dispatch(updateBreakingOutStatus(true));

    axios({
      method: 'post',
      url: url,
      data: createBreakOutData,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateCurrentAttendanceBreak(undefined));
          dispatch(updateBreakingOutStatus(false));
          dispatch(updateSuccessModalStatus({
            isOpen: true,
            title: i18n.t('modal.successModal.title'),
            content: i18n.t('modal.successModal.backToWorkSuccess')
          }));
        } else {
          captureErrorSentry("Error when updating break", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateBreakingOutStatus);
        }
      })
      .catch(error => {
        dispatch(updateBreakingOutStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  }
};
