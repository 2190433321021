import React from "react";
import {Box, Heading, HStack, Image, Pressable, ScrollView, Text, useTheme, VStack} from "native-base";
import {Attendance, AttendanceBreak, State, Timelog} from "../state";
import {TimelogList} from "../Components/TimelogList";
import {LoadingComponentFullScreen} from "../Components/LoadingComponent";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {BottomSheetMenuComponent} from "../Components/TimeLogMenuComponent";
import {DeleteConfirmationModalComponent} from "../Components/DeleteConfirmationModal";
import {CalendarProvider, ExpandableCalendar} from "react-native-calendars";
import {TimeSheetToWorkingTimeProgressBar} from "../Components/TimeSheetToWorkingTimeProgressBar";
import moment from "moment";
import {DATE_DISPLAY_FORMAT_FULL} from "../lib/dateFormat";

interface TimesheetScreenProps {
  currentAttendance?: Attendance
  isFetchingTimelogs: boolean;
  isMenuOpen: boolean;
  isDeleteConfirmationModalOpen: boolean;
  currentTimelogs: Timelog[];
  attendanceBreaksList: AttendanceBreak[];
  selectedDate: string;
  currentServerTime: string;
  handleLeftArrowClick: () => void;
  handleRightArrowClick: () => void;
  handleDateOnChange: (date: string) => void;
  handleCreateTimelog: () => void;
  handleSingleTimesheetOnPress: (timeLog: Timelog) => void;
  handleEditTimesheetOnPress: () => void;
  handleDeleteTimesheetOnPress: () => void;
  handleDeleteOnPress: () => void;
  handleOpenTemplateOnPress: () => void;
  handleCloseMenu: () => void;
  isToday: boolean;
}

interface AccumulateWorkTimeProps {
  currentTimelogs: Timelog[];
  attendanceBreaksList: AttendanceBreak[];
  currentAttendance: Attendance;
  currentServerTime: string;
}

interface ButtonGroupComponentProps {
  handleCreateTimelog: () => void;
  handleOpenTemplateOnPress: () => void;
}

const ButtonGroupComponent = (props: ButtonGroupComponentProps) => (
  <HStack
    mt={2}
    justifyContent="center"
    alignItems="center"
    space="md"
  >
    <Pressable
      flex={1}
      p={2}
      borderWidth={2}
      borderRadius={5}
      borderColor="primary.500"
      alignItems="center"
      _pressed={{
        bg: 'muted.300'
      }}
      onPress={props.handleCreateTimelog}
    >
      <Text
        fontFamily="heading"
      >
        ADD MANUALLY
      </Text>
    </Pressable>
    <Pressable
      flex={1}
      p={2}
      borderRadius={5}
      borderWidth={2}
      borderColor="success.500"
      bg="success.500"
      _pressed={{
        bg: 'success.600',
        borderColor: 'success.600',
      }}
      alignItems="center"
      onPress={props.handleOpenTemplateOnPress}
    >
      <Text
        fontFamily="heading"
        color="secondary.500"
      >
        ADD FROM TEMPLATE
      </Text>
    </Pressable>
  </HStack>
);

const AccumulateWorkTimeComponent = (props: AccumulateWorkTimeProps) => {
  const {t}: { t: any } = useTranslation();
  return (
    <VStack
      space="sm"
      mt={4}
      px={2}
    >
      <Box>
        <Text
          fontSize="md"
          color="darkText"
        >
          {t("tapOutBottomSheet.accumulateWorkingTime")}
        </Text>
      </Box>
      <VStack
        borderWidth={1}
        borderRadius={8}
        p={3}
        space="sm"
        borderColor="coolGray.300"
      >
        <TimeSheetToWorkingTimeProgressBar
          currentAttendance={props.currentAttendance}
          attendanceBreaksList={props.attendanceBreaksList}
          timeLogs={props.currentTimelogs}
          currentServerTime={props.currentServerTime}
          isOpen
        />
      </VStack>
    </VStack>
  );
};

const EmptyListComponent = () => {
  const {t}: { t: any } = useTranslation();
  return (
    <ScrollView
      w="full"
      h="full"
      contentContainerStyle={{
        paddingVertical: 10,
        flexGrow: 1,
        justifyContent: 'center',
      }}
      px={4}
    >
      <VStack alignItems="center">
        <Image
          key="emptyTimesheet"
          alt="emptyTimesheet"
          size={48}
          resizeMode="contain"
          source={require('../../assets/illustrations/completed_task_flatline.png')}
        />
        <Text
          italic
          fontSize="md"
          color="primary.500"
          textAlign="center"
        >
          {String(t('timesheetScreen.noTimelogsText'))}
        </Text>
      </VStack>
    </ScrollView>
  );
};

const SelectedDayDisplay = ({ ...props }) => (
  <Box
    px={5}
    pt={6}
  >
    <Heading
      fontSize="md"
    >
      {moment(props.selectedDate).format(DATE_DISPLAY_FORMAT_FULL)}
    </Heading>
  </Box>
);

export const TimesheetScreen = (props: TimesheetScreenProps) => {
  const isFetchingTimelogs = props.isFetchingTimelogs;
  const currentTimelogs = props.currentTimelogs;
  const selectedDate = props.selectedDate;
  const isToday = props.isToday;

  const timesheetFeatureStatus = useSelector((state: State) => state.control.currentUser?.featureList.timesheet);
  const otherDateFeatureStatus = timesheetFeatureStatus && !!timesheetFeatureStatus.otherDate;

  const {t}: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Box h="full">
        <CalendarProvider
          date={selectedDate}
          disabledOpacity={0.6}
          style={{
            flexGrow: 1,
          }}
          onDateChanged={props.handleDateOnChange}
        >
          <ExpandableCalendar
            firstDay={1}
            hideKnob
            enableSwipeMonths
            onDayPress={(date) => props.handleDateOnChange(date.dateString)}
            markedDates={{
              [selectedDate]: { selected: true, selectedColor: theme.colors.success[500] }
            }}
            theme={{
              calendarBackground: theme.colors.secondary[500],
              dayTextColor: theme.colors.primary[500],
              textSectionTitleColor: theme.colors.primary[500],
              selectedDayBackgroundColor: theme.colors.success[500],
              selectedDayTextColor: theme.colors.secondary[500],
              todayTextColor: theme.colors.success[500],
              arrowColor: theme.colors.primary[500],
              monthTextColor: theme.colors.primary[500],
              textDayFontFamily: theme.fontConfig.Oswald[700].normal,
              textDayHeaderFontFamily: theme.fontConfig.Raleway[500].normal,
              textMonthFontFamily: theme.fontConfig.Oswald[700].normal,
            }}
            disablePan={true}
          />
          <VStack
            bg="secondary.500"
            flex={1}
          >
            <SelectedDayDisplay selectedDate={selectedDate} />
            <VStack
              px={2}
              flex={1}
              space="sm"
            >
              {
                props.currentAttendance && isToday &&
                <AccumulateWorkTimeComponent
                  attendanceBreaksList={props.attendanceBreaksList}
                  currentTimelogs={currentTimelogs}
                  currentAttendance={props.currentAttendance}
                  currentServerTime={props.currentServerTime}
                />
              }
              {
                isFetchingTimelogs ?
                  <LoadingComponentFullScreen />
                  :
                  currentTimelogs.length > 0 ?
                    <TimelogList
                      isVertical={true}
                      isToday={isToday}
                      currentTimelogs={currentTimelogs}
                      handleCreateNewTimelog={props.handleCreateTimelog}
                      handleSingleTimesheetOnPress={props.handleSingleTimesheetOnPress}
                    />
                    :
                    <EmptyListComponent />
              }
            </VStack>
            {otherDateFeatureStatus || (!otherDateFeatureStatus && isToday) ?
              <Box
                mt="auto"
                bg="secondary.500"
                p={4}
              >
                <ButtonGroupComponent
                  handleCreateTimelog={props.handleCreateTimelog}
                  handleOpenTemplateOnPress={props.handleOpenTemplateOnPress}
                />
              </Box>
              :
              null
            }
          </VStack>
        </CalendarProvider>
      </Box>
      <BottomSheetMenuComponent
        isMenuOpen={props.isMenuOpen}
        isButtonDisabled={props.currentTimelogs.length <= 1}
        handleEditTimesheetOnPress={props.handleEditTimesheetOnPress}
        handleDeleteTimesheetOnPress={props.handleDeleteTimesheetOnPress}
        handleCloseMenu={props.handleCloseMenu}
      />
      <DeleteConfirmationModalComponent
        title={t('modal.timesheetModal.deleteTimesheetHeader')}
        description={t('modal.timesheetModal.deleteTimesheetConfirmation')}
        isDeleteConfirmationModalOpen={props.isDeleteConfirmationModalOpen}
        handleDeleteOnPress={props.handleDeleteOnPress}
        handleCloseMenu={props.handleCloseMenu}
      />
    </>
  );
};
