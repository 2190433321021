import React from "react";
import {Actionsheet, Box, Divider, Heading, HStack, Icon, Pressable, ScrollView, Text, VStack} from "native-base";
import {Attendance, AttendanceBreak, Timelog} from "../state";
import {TimeSheetToWorkingTimeProgressBar} from "./TimeSheetToWorkingTimeProgressBar";
import {useTranslation} from "react-i18next";
import {Feather} from "@expo/vector-icons";
import moment from "moment";
import {TIME_DISPLAY_FORMAT} from "../lib/dateFormat";

interface TapOutBottomSheetComponentProps {
  todayTimelogs: Timelog[];
  attendanceBreaksList: AttendanceBreak[];
  currentAttendance?: Attendance;
  currentServerTime: string;
  isOpen: boolean;
  hasTimesheetFeature: boolean;
  handleFillTimesheetOnPress: () => void;
  handleTapOutOnPress: () => void;
  onClose: () => void;
}

interface ButtonGroupProps {
  hasTimesheetFeature: boolean;
  handleFillTimesheetOnPress: () => void;
  handleTapOutOnPress: () => void;
}

interface WorkTimeCounterProps {
  attendanceBreaksList: AttendanceBreak[];
  currentAttendance: Attendance;
  currentServerTime: string;
}

const HeaderComponent = () => (
  <Box
    w="full"
    px={4}
  >
    <Heading>
      TAP OUT
    </Heading>
    <Divider
      borderWidth={1}
      my={2}
      borderColor="coolGray.300"
    />
  </Box>
);

const ButtonGroupComponent = (props: ButtonGroupProps) => {
  const {t}: { t: any } = useTranslation();
  return (
    <VStack space="sm">
      {
        props.hasTimesheetFeature &&
        <Pressable
          flex={1}
          p={2}
          borderWidth={2}
          borderRadius={5}
          borderColor="primary.500"
          alignItems="center"
          _pressed={{
            bg: 'muted.300'
          }}
          onPress={props.handleFillTimesheetOnPress}
        >
          <Text
            fontFamily="heading"
          >
            {t('tapOutBottomSheet.fillTimesheet')}
          </Text>
        </Pressable>
      }
      <Pressable
        flex={1}
        p={2}
        borderRadius={5}
        bg="danger.500"
        alignItems="center"
        _pressed={{
          bg: 'muted.300'
        }}
        onPress={props.handleTapOutOnPress}
      >
        <Text
          fontFamily="heading"
          color="secondary.500"
        >
          TAP OUT
        </Text>
      </Pressable>
    </VStack>
  );
};

const WorkTimeCounter = (props: WorkTimeCounterProps) => {
  const {t}: { t: any } = useTranslation();
  const startTime = moment(props.currentAttendance.startTime, TIME_DISPLAY_FORMAT);
  const endTime = moment(props.currentServerTime, TIME_DISPLAY_FORMAT);
  const submittedEndTime = moment(props.currentAttendance.endTime, TIME_DISPLAY_FORMAT);
  let totalBreakHours = 0;
  props.attendanceBreaksList.forEach((attendanceBreak: AttendanceBreak) => {
    totalBreakHours += attendanceBreak.totalHours;
  });

  let workingDuration = moment.duration(
    endTime.diff(startTime) - totalBreakHours * 3600000
  );
  if (props.currentAttendance.docstatus === 1) {
    workingDuration = moment.duration(
      submittedEndTime.diff(startTime) - totalBreakHours * 3600000
    );
  }
  const workingHours = (parseInt(String(workingDuration.asHours())));
  const workingMinutes = (parseInt(String(workingDuration.minutes() % 60)));

  return (
    <Box
      py={4}
      px={3}
      borderWidth={1}
      borderRadius={5}
      borderColor="coolGray.500"
    >
      <HStack
        space="sm"
        alignItems="center"
      >
        <Icon
          as={Feather}
          name="clock"
          size={5}
          color="darkText"
        />
        <Text
          fontSize="sm"
          color="darkText"
        >
          {t('tapOutBottomSheet.workingDurationCounter', {
            hours: workingHours,
            minutes: workingMinutes,
          })}
        </Text>
      </HStack>
    </Box>
  );
};

export const TapOutBottomSheetComponent = (props: TapOutBottomSheetComponentProps) => {

  return (
    <Actionsheet
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Actionsheet.Content bg="secondary.500">
        <HeaderComponent />
        <ScrollView
          w="full"
          contentContainerStyle={{
            paddingVertical: 10,
          }}
        >
          <VStack
            space="xl"
            px={4}
          >
            {
              props.currentAttendance &&
              (props.hasTimesheetFeature ?
                <TimeSheetToWorkingTimeProgressBar
                  currentAttendance={props.currentAttendance}
                  attendanceBreaksList={props.attendanceBreaksList}
                  timeLogs={props.todayTimelogs}
                  currentServerTime={props.currentServerTime}
                  isOpen={props.isOpen}
                />
                :
                <WorkTimeCounter
                  currentAttendance={props.currentAttendance}
                  currentServerTime={props.currentServerTime}
                  attendanceBreaksList={props.attendanceBreaksList}
                />)
            }
            <ButtonGroupComponent
              hasTimesheetFeature={props.hasTimesheetFeature}
              handleFillTimesheetOnPress={props.handleFillTimesheetOnPress}
              handleTapOutOnPress={props.handleTapOutOnPress}
            />
          </VStack>
        </ScrollView>
      </Actionsheet.Content>
    </Actionsheet>
  );
};
