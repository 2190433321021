import React from "react";
import {Box, Select} from "native-base";

interface DropdownComponentProps {
  data: string[];
  selectedDropdown: string;
  placeholder: string;
  handleDropdownOnChange: (value: string) => void;
}

export const DropdownComponent = (props: DropdownComponentProps) => {

  return (
    <Box flex={1}>
      <Select
        selectedValue={props.selectedDropdown}
        accessibilityLabel={props.placeholder}
        placeholder={props.placeholder}
        fontSize="sm"
        fontWeight="bold"
        _selectedItem={{
          bg: "secondary.500",
        }}
        borderWidth={1}
        borderColor="primary.500"
        onValueChange={itemValue => props.handleDropdownOnChange(itemValue)}
      >
        {
          props.data.map(projectName => (
            <Select.Item
              key={projectName}
              label={projectName}
              value={projectName}
            />
          ))
        }
      </Select>
    </Box>
  );
};
