import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {OnBoarding, State} from "../state";
import {OnBoardingScreen} from "../screens/OnBoardingScreen";
import {useTranslation} from "react-i18next";
import {updateIsInitialOnBoarding} from "../actions/actions";
import {CommonActions, useNavigation} from "@react-navigation/native";

interface OnBoardingScreenContainerProps {
  language: string;
}

const mapStateToProps = (state: State) => {
  return {
    language: state.control.language,
  }
}

const OnBoardingScreenContainer = (props: OnBoardingScreenContainerProps) => {

  const {t}: { t: any } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [selectedLanguage, setSelectedLanguage] = useState(props.language);

  useEffect(() => {
    setSelectedLanguage(props.language);
  }, [props.language]);

  const onBoardingData: OnBoarding[] = [
    {
      imageUrl: require('../../assets/onboarding/onboarding-image-1.png'),
      title: t('onBoardingScreen.title.one'),
      subTitle: t('onBoardingScreen.subtitle.one'),
    },
    {
      imageUrl: require('../../assets/onboarding/onboarding-image-2.png'),
      title: t('onBoardingScreen.title.two'),
      subTitle: t('onBoardingScreen.subtitle.two'),
    },
    {
      imageUrl: require('../../assets/onboarding/onboarding-image-3.png'),
      title: t('onBoardingScreen.title.three'),
      subTitle: t('onBoardingScreen.subtitle.three'),
    },
  ];

  const handleSkipOnPress = () => {
    dispatch(updateIsInitialOnBoarding(false));
    navigation.dispatch(
      CommonActions.navigate('JoinCompanyScreen')
    );
  };

  const handleOpenLanguageSelection = () => {
    navigation.dispatch(
      CommonActions.navigate("LanguageSelectionLogin")
    );
  };

  return (
    <OnBoardingScreen
      onBoardingData={onBoardingData}
      selectedLanguage={selectedLanguage}
      handleSkipOnPress={handleSkipOnPress}
      handleOpenLanguageSelection={handleOpenLanguageSelection}
    />
  );
};

export default connect(mapStateToProps)(OnBoardingScreenContainer);
