import React, {useEffect, useState} from "react";
import {Project, State, Timelog} from "../state";
import {updateTimesheetFormModalOpen} from "../actions/actions";
import {
  addNewTimelog,
  editTimeLog,
  fetchActivityTypes,
  fetchProjects,
  fetchTimelogs
} from "../actions/actionsTimesheet";
import {Button, Heading, Modal} from "native-base";
import {formatDateUIFriendly, formatDateYYYYMMDD} from "../lib/dateFormat";
import {connect, useDispatch} from "react-redux";
import {TimesheetFormModalContentContainer} from "./TimesheetFormModalContentContainer";
import * as Analytics from "expo-firebase-analytics";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface TimesheetFormModalProps {
  isOpen: boolean;
  isStoringTimelog: boolean;
  date: Date;
  timeLog: Timelog;
  todayTimelogs: Timelog[];
  employee?: string;
  projects: Project[];
  activityTypes: string[];
  handleCloseTimesheetFormModal: () => void;
}

const mapStateToProps = (state: State) => {
  return {
    projects: state.data.projects,
    activityTypes: state.data.activityTypes,
    todayTimelogs: state.data.todayTimelogs,
    employee: state.control.currentUser?.employeeName,
    isOpen: state.control.isNewTimesheetModalOpen,
    isStoringTimelog: state.session.timelogs.isStoring
  }
};

const TimesheetFormModalContainer: React.FC<TimesheetFormModalProps> = (props: TimesheetFormModalProps) => {
  const initialState = {
    errorState: {
      activity: false,
      project: false,
      duration: false
    }
  }

  const [timeLogName, setTimeLogName] = useState("");
  const [activityPicked, setActivityPicked] = useState("");
  const [projectPicked, setProjectPicked] = useState("");
  const [durationHourPicked, setDurationHourPicked] = useState("");
  const [durationMinutePicked, setDurationMinutePicked] = useState("");
  const [errorState, setErrorState] = useState(initialState.errorState);
  const dispatch = useDispatch();
  const {t}: {t: any} = useTranslation();
  const todayDate = formatDateYYYYMMDD(new Date());

  useEffect(() => {
    if (props.timeLog) {
      const hourDuration = Math.floor(props.timeLog.duration);
      const minuteDuration = (props.timeLog.duration % 1) * 60;
      setTimeLogName(props.timeLog.name);
      setProjectPicked(props.timeLog.projectName);
      setActivityPicked(props.timeLog.activity);
      setDurationHourPicked(moment(hourDuration, 'HH').format('HH'));
      setDurationMinutePicked(moment(minuteDuration, 'mm').format('mm'));
    }
  }, [props.timeLog]);

  useEffect(() => {
    dispatch(fetchProjects());
    dispatch(fetchActivityTypes());
  }, [dispatch]);

  const handleClearData = () => {
    setTimeLogName("");
    setProjectPicked("");
    setActivityPicked("");
    setDurationHourPicked("");
    setDurationMinutePicked("");
  };

  const handleCloseTimesheetFormModal = () => {
    handleClearData();
    dispatch(updateTimesheetFormModalOpen(false));
    setErrorState(initialState.errorState);
    props.handleCloseTimesheetFormModal();
  }

  const validateFormData = (): boolean => {
    return !!activityPicked && !!projectPicked && (!!durationHourPicked || !!durationMinutePicked);
  }

  const displayWarningLabels = () => {
    let updatedErrorState = Object.assign({}, initialState.errorState);
    if (!projectPicked) {
      updatedErrorState = Object.assign({}, updatedErrorState, {
        project: true
      })
    }
    if (!activityPicked) {
      updatedErrorState = Object.assign({}, updatedErrorState, {
        activity: true
      })
    }
    if (!durationHourPicked && !durationMinutePicked) {
      updatedErrorState = Object.assign({}, updatedErrorState, {
        duration: true
      })
    }
    setErrorState(updatedErrorState);
  }

  const submitTimelog = () => {
    const isValid = validateFormData();
    if (isValid && props.employee) {
      let durationFull = Number(durationHourPicked) + (Number(durationMinutePicked) / 60);
      if (timeLogName && props.timeLog) {
        Analytics.logEvent('edit_timelog', {
          project_name: projectPicked,
          activity_name: activityPicked,
          duration: durationFull
        });
        const updatedTimeLogs = [...props.todayTimelogs];
        const selectedTimeLogIndex = updatedTimeLogs.findIndex(timeLog => timeLog.name === props.timeLog.name);
        updatedTimeLogs[selectedTimeLogIndex] = {
          ...props.timeLog,
          duration: durationFull,
          activity: activityPicked,
          projectName: projectPicked,
        };
        dispatch(editTimeLog(
          updatedTimeLogs,
          "Edit",
          handleCloseTimesheetFormModal,
        ));
        dispatch(fetchTimelogs(props.employee, todayDate));
      } else {
        Analytics.logEvent('add_timelog', {
          project_name: projectPicked,
          activity_name: activityPicked,
          duration: durationFull
        });
        dispatch(addNewTimelog(
          props.employee,
          formatDateYYYYMMDD(props.date),
          projectPicked,
          activityPicked,
          durationFull,
          false,
          handleCloseTimesheetFormModal,
        ));
      }
      handleClearData();
    } else {
      displayWarningLabels();
    }
  }

  const handleSelectActivity = (activityType: string) => {
    setActivityPicked(activityType);
    setErrorState(initialState.errorState);
  }

  const handleSelectProject = (project: string) => {
    setProjectPicked(project);
    setErrorState(initialState.errorState);
  }

  const handleSelectHour = (hour: string) => {
    setDurationHourPicked(hour);
    setErrorState(initialState.errorState);
  }

  const handleSelectMinute = (minute: string) => {
    setDurationMinutePicked(minute);
    setErrorState(initialState.errorState);
  }

  return (
    <Modal isOpen={props.isOpen}
           size={"lg"}
           overlayVisible={true}
           closeOnOverlayClick={true}
           onClose={handleCloseTimesheetFormModal}
    >
      <Modal.Content bgColor={"secondary.400"}>
        <Modal.CloseButton/>
        <Modal.Header>
          <Heading fontSize={"lg"}>{formatDateUIFriendly(props.date)}</Heading>
        </Modal.Header>
        <Modal.Body>
          <TimesheetFormModalContentContainer
            activityTypes={props.activityTypes}
            projects={props.projects}
            errorState={errorState}
            selectedProject={projectPicked}
            selectedActivity={activityPicked}
            selectedHour={durationHourPicked}
            selectedMinute={durationMinutePicked}
            handleSelectActivity={handleSelectActivity}
            handleSelectProject={handleSelectProject}
            handleSelectHour={handleSelectHour}
            handleSelectMinute={handleSelectMinute}
          />
        </Modal.Body>
        <Modal.Footer bgColor={"secondary.500"}>
          <Button colorScheme={"danger"}
                  size={"md"}
                  flex={1}
                  isLoading={props.isStoringTimelog}
                  onPress={submitTimelog}>
            {String(t('button.submitButton'))}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default connect(mapStateToProps)(TimesheetFormModalContainer);
