import {ModalProps, MonthlyInfo, State} from "../state";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {captureErrorSentry} from "./httpUtils";
import axios from 'axios';
import {extractMonthlyInfoList} from "./frappeResponse";
import {handleHttpError, handleNoBaseUrlError} from "./actionsAuth";
import i18n from "i18next";
import {updateErrorModalStatus} from "./actions";

export const UPDATE_MONTHLY_INFO_LIST = "UPDATE_MONTHLY_INFO_LIST";
export const UPDATE_MONTHLY_INFO = "UPDATE_MONTHLY_INFO";

export const UPDATE_FETCHING_MONTHLY_INFOS_ATTRIBUTE = "UPDATE_FETCHING_MONTHLY_INFOS_ATTRIBUTE";

export const updateFetchingMonthlyInfosAttribute = (status: boolean) => {
  return {
    type: UPDATE_FETCHING_MONTHLY_INFOS_ATTRIBUTE,
    payload: {
      isFetching: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const updateCurrentMonthlyInfoList = (monthlyInfoList: MonthlyInfo[]) => {
  return {type: UPDATE_MONTHLY_INFO_LIST, payload: monthlyInfoList};
};

export const updateCurrentMonthlyInfo = (monthlyInfo: MonthlyInfo) => {
  return {type: UPDATE_MONTHLY_INFO, payload: monthlyInfo}
};

export const fetchMonthlyInfoList = (companyName: string, branchName: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchMonthlyInfoListErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany) {
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const queryParam = "data={\"company\":\"".concat(companyName).concat("\", \"branch\":\"").concat(branchName).concat("\"}");
    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_monthly_info?".concat(queryParam)

    dispatch(updateFetchingMonthlyInfosAttribute(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateCurrentMonthlyInfoList(extractMonthlyInfoList(response.data.message)));
          dispatch(updateFetchingMonthlyInfosAttribute(false));
        } else {
          captureErrorSentry("Error when fetching monthly info list", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingMonthlyInfosAttribute);
        }
      })
      .catch(error => {
        dispatch(updateFetchingMonthlyInfosAttribute(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};
