import {Dimensions} from "react-native";

export const DEFAULT_IMAGE_WIDTH = 1080;
export const DEFAULT_IMAGE_HEIGHT = 1080;
export const DEFAULT_CAMERA_RATIO = "16:9";
export const AVAILABLE_RATIOS = ["16:9", "18:5.9", "19:9", "20:9"];

export const OPENING_TIME_CHANNEL = "before-opening-time";
export const OPENING_TIME = "08:00:00";
export const CLOSING_TIME = "17:00:00";

export const API_TIMEOUT = 20000; // 20 seconds
export const MINIMUM_BAR_WIDTH = 3;
export const FULL_BAR_WIDTH = 100;

export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES =  ['id','en'];
export const ENGLISH_LANGUAGE_DISPLAYED = "EN";
export const ENGLISH_LANGUAGE_SELECTION = "English";
export const INDONESIAN_LANGUAGE_DISPLAYED = "ID";
export const INDONESIAN_LANGUAGE_SELECTION = "Bahasa Indonesia";
export const UNKNOWN_LANGUAGE_DISPLAYED = "NONE";
export const CASUAL_LEAVE = "Casual Leave";
export const SICK_LEAVE = "Sick Leave";
export const LEAVE_WITHOUT_PAY = "Leave Without Pay";

export const DEVICE_WIDTH = Dimensions.get('window').width;
export const DEVICE_HEIGHT = Dimensions.get('window').height;

export const TAP_NOTIFICATION_CHANNEL = 'TAP_NOTIFICATION_CHANNEL';
export const TAP_IN_NOTIFICATION_CHANNEL = 'TAP_IN_NOTIFICATION_CHANNEL';
export const TAP_OUT_NOTIFICATION_CHANNEL = 'TAP_OUT_NOTIFICATION_CHANNEL';

export const TAP_IN_BUTTON = 'TAP_IN_BUTTON';
export const TAP_OUT_BUTTON = 'TAP_OUT_BUTTON';
export const TAP_IGNORE_BUTTON = 'TAP_IGNORE_BUTTON';
