import * as React from "react";
import {Path} from "react-native-svg";
import {createIcon} from "native-base";

export const HomeIconActive = () => {
  const HomeIcon = createIcon({
    viewBox: "0 0 283.46 283.46",
    path: [
      <Path
        fill="#fbb35f"
        d="M43.84 129.97l2.89 115.93h63.08v-72.85H168l3.99 70.63 58.64 2.22V123.3l-93.39-89.28-93.4 95.95z"
      />,
      <Path
        d="M238.06 120.27L146 28.21c-.11-.12-.2-.26-.31-.38a7.86 7.86 0 00-11 0c-.12.12-.2.26-.31.38l-92.07 92.06a7.71 7.71 0 00-2.25 5.67V237.2a17.16 17.16 0 0017.14 17.15h45.14a17.17 17.17 0 0017.16-17.15v-58a1.67 1.67 0 011.67-1.67h40.7a1.67 1.67 0 011.66 1.67v58a17.17 17.17 0 0017.16 17.15h42.48a17.16 17.16 0 0017.15-17.15V126.12v-.15a7.7 7.7 0 00-2.26-5.7zm-14.89 118.59h-42.48a1.67 1.67 0 01-1.69-1.66v-58a17.17 17.17 0 00-17.15-17.16h-40.7A17.18 17.18 0 00104 179.23v58a1.67 1.67 0 01-1.67 1.66H57.2a1.66 1.66 0 01-1.66-1.66V129l84.64-84.7 84.65 84.7v108.2a1.67 1.67 0 01-1.66 1.66z"
        fill="#3c8183"
      />,
      <Path
        d="M182.63 254.35h-34.7a7.75 7.75 0 110-15.49h34.7a7.75 7.75 0 010 15.49z"
        fill="#e15c54"
      />
    ],
  })
  return <HomeIcon size={"md"}/>
}

export const HomeIconInactive = () => {
  const HomeIcon = createIcon({
    viewBox: "0 0 283.46 283.46",
    path: [
      <Path
        fill="none"
        d="M43.84 129.97l2.89 115.93h63.08v-72.85H168l3.99 70.63 58.64 2.22V123.3l-93.39-89.28-93.4 95.95z"
      />,
      <Path
        d="M238.06 120.27L146 28.21c-.11-.12-.2-.26-.31-.38a7.86 7.86 0 00-11 0c-.12.12-.2.26-.31.38l-92.07 92.06a7.71 7.71 0 00-2.25 5.67V237.2a17.16 17.16 0 0017.14 17.15h45.14a17.17 17.17 0 0017.16-17.15v-58a1.67 1.67 0 011.67-1.67h40.7a1.67 1.67 0 011.66 1.67v58a17.17 17.17 0 0017.16 17.15h42.48a17.16 17.16 0 0017.15-17.15V126.12v-.15a7.7 7.7 0 00-2.26-5.7zm-14.89 118.59h-42.48a1.67 1.67 0 01-1.69-1.66v-58a17.17 17.17 0 00-17.15-17.16h-40.7A17.18 17.18 0 00104 179.23v58a1.67 1.67 0 01-1.67 1.66H57.2a1.66 1.66 0 01-1.66-1.66V129l84.64-84.7 84.65 84.7v108.2a1.67 1.67 0 01-1.66 1.66z"
        fill="grey"
      />,
      <Path
        d="M182.63 254.35h-34.7a7.75 7.75 0 110-15.49h34.7a7.75 7.75 0 010 15.49z"
        fill="grey"
      />
    ],
  })
  return <HomeIcon size={"md"}/>
}