import {Text, useTheme} from "native-base";
import {TabBar} from "react-native-tab-view";
import React from "react";

export const TabBarComponent = (props: any) => {
  const theme = useTheme();
  return (
    <TabBar
      {...props}
      renderLabel={({ route, focused }) => (
        <Text
          fontSize="md"
          bold
          color={focused ? "primary.500" : "coolGray.500"}
          borderWidth={1}
          borderColor="transparent"
        >
          {route.title}
        </Text>
      )}
      indicatorStyle={{ backgroundColor: theme.colors.primary[500] }}
      style={{ backgroundColor: theme.colors.secondary[500] }}
    />
  );
};
