import {ImageData, LeaveApplication, LeaveBalance, LeaveCount, ModalProps, State} from "../state";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import axios from "axios";
import {updateErrorModalStatus, updateLeaveFormModalOpen} from "./actions";
import {extractLeaveApplicationList, extractLeaveBalance} from "./frappeResponse";
import {handleHttpError, handleNoBaseUrlError} from "./actionsAuth";
import moment from "moment";

import {ATTENDANCE_DATE_FORMAT, DATE_DISPLAY_FORMAT_SHORT} from "../lib/dateFormat";
import i18n from "i18next";
import {captureErrorSentry} from "./httpUtils";

export const UPDATE_LEAVE_COUNT = "UPDATE_LEAVE_COUNT";
export const UPDATE_LEAVE_BALANCE = "UPDATE_LEAVE_BALANCE";
export const UPDATE_LEAVE_APPLICATION_LIST = "UPDATE_LEAVE_APPLICATION_LIST";

export const UPDATE_FETCHING_LEAVE_APPLICATIONS_ATTRIBUTE = "UPDATE_FETCHING_LEAVE_APPLICATIONS_ATTRIBUTE";
export const UPDATE_CREATING_LEAVE_APPLICATION_ATTRIBUTE = "UPDATE_CREATING_LEAVE_APPLICATION_ATTRIBUTE";
export const UPDATE_FETCHING_LEAVE_COUNT_STATUS = "UPDATE_FETCHING_LEAVE_COUNT_STATUS";

export const ADD_LEAVE_APP_IMAGE_URL = "ADD_LEAVE_APP_IMAGE_URL";
export const UPDATE_LEAVE_IMAGE_DATA = "UPDATE_LEAVE_IMAGE_DATA";
export const UPDATE_UPLOADING_LEAVE_IMAGE_STATUS = "UPDATE_UPLOADING_LEAVE_IMAGE_STATUS";

export const addLeaveAppImageUrl = (imageUrl: string, imageBase64: string) => {
  return {
    type: ADD_LEAVE_APP_IMAGE_URL,
    payload: {
      imageUrl: imageUrl,
      imageBase64: imageBase64,
    },
  };
};

export const updateLeaveImageData = (imageData?: ImageData) => {
  return {type: UPDATE_LEAVE_IMAGE_DATA, payload: imageData};
};

export const updateUploadingLeaveImageStatus = (isUploading: boolean) => {
  return {type: UPDATE_UPLOADING_LEAVE_IMAGE_STATUS, payload: isUploading};
};

export const uploadLeaveAppImage = (LeaveApplicationName: string, imageData: ImageData) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State): Promise<any> => {


    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.ImageUploadErrorTitle'),
      content: "",
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }
    const url = baseUrl + "/api/method/run_custom_method";

    const fileName = LeaveApplicationName.concat("-").concat("o").concat(".jpg");

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("is_private", encodeURIComponent("0"));
    urlSearchParams.append("doctype", encodeURIComponent("Leave Application"));
    urlSearchParams.append("docname", encodeURIComponent(LeaveApplicationName));
    urlSearchParams.append("filedata", encodeURIComponent(imageData.base64));
    urlSearchParams.append("from_form", encodeURIComponent("0"));
    urlSearchParams.append("cmd", encodeURIComponent("uploadfile"));
    urlSearchParams.append("file_url", encodeURIComponent("/files/".concat(fileName)));
    urlSearchParams.append("filename", encodeURIComponent(fileName));

    dispatch(updateUploadingLeaveImageStatus(true));

    return axios({
      method: "put",
      url: encodeURI(url),
      headers: {
        Accept: "application/json",
      },
      data: urlSearchParams,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
      validateStatus: () => true,
    }).then((response) => {
      if (response.status === 200) {
        dispatch(updateUploadingLeaveImageStatus(false));
        return Promise.resolve()
      } else {
        dispatch(updateUploadingLeaveImageStatus(false));
        handleHttpError(response.request, errorModalStatus, dispatch);
      }
    })
      .catch(error => {
        dispatch(updateUploadingLeaveImageStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const updateLeaveCount = (leaveCount?: LeaveCount) => {
  return {type: UPDATE_LEAVE_COUNT, payload: leaveCount};
};

export const updateLeaveBalance = (leaveBalance: LeaveBalance) => {
  return { type: UPDATE_LEAVE_BALANCE, payload: leaveBalance };
}

export const updateLeaveApplicationList = (leaveApplicationList: LeaveApplication[]) => {
  return {type: UPDATE_LEAVE_APPLICATION_LIST, payload: leaveApplicationList};
};

export const updateCreatingLeaveAttribute = (status: boolean) => {
  return {
    type: UPDATE_CREATING_LEAVE_APPLICATION_ATTRIBUTE,
    payload: {
      isStoring: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const updateFetchingLeaveApplicationsAttribute = (status: boolean) => {
  return {
    type: UPDATE_FETCHING_LEAVE_APPLICATIONS_ATTRIBUTE,
    payload: {
      isFetching: status,
      lastUpdated: new Date().getTime()
    }
  };
};

export const updateFetchingLeaveCountStatus = (isFetching: boolean) => {
  return {type: UPDATE_FETCHING_LEAVE_COUNT_STATUS, payload: isFetching};
};

export const fetchLeaveCountData = (
  employeeName: string,
  hasCasualLeaveFeature: boolean,
  hasSickLeaveFeature: boolean,
  ) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State) => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchLeaveCountDataErrorTitle')
    };

    const filters = ("data={\"employee\":\"").concat(employeeName).concat("\"}");

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_leave_balance_and_allocation_v2?".concat(filters);

    dispatch(updateFetchingLeaveCountStatus(true));

    axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateLeaveBalance(extractLeaveBalance(
            response.data.message,
            hasCasualLeaveFeature,
            hasSickLeaveFeature,
            )));
          dispatch(updateFetchingLeaveCountStatus(false));
        } else {
          captureErrorSentry("Error when fetching leave count", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingLeaveCountStatus);
        }
      })
      .catch(error => {
        dispatch(updateFetchingLeaveCountStatus(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const fetchLeaveApplicationList = (employeeName: string) => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => State): Promise<any> => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.fetchLeaveApplicationListErrorTitle')
    };

    const filters = ("data={\"employee\":\"").concat(employeeName).concat("\"}");

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.get_leave_application?".concat(filters);

    dispatch(updateFetchingLeaveApplicationsAttribute(true));

    return axios({
      method: 'get',
      url: url,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateLeaveApplicationList(extractLeaveApplicationList(response.data.message)));
          dispatch(updateFetchingLeaveApplicationsAttribute(false));
        } else {
          captureErrorSentry("Error when fetching leave application list", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateFetchingLeaveApplicationsAttribute);
        }
      })
      .catch(error => {
        dispatch(updateFetchingLeaveApplicationsAttribute(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  };
};

export const createLeaveApplication = (employee: string, leaveType: string, startDate: string, endDate: string, reason: string) => {
  return (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State): Promise<any> => {
    let errorModalStatus: ModalProps = {
      isOpen: true,
      title: i18n.t('modal.errorModal.createLeaveApplicationErrorTitle')
    };

    let selectedCompany = getState().control.selectedCompany;
    let baseUrl;

    if (selectedCompany){
      baseUrl = selectedCompany.serverUrl;
    }

    if (!baseUrl) {
      handleNoBaseUrlError(errorModalStatus, dispatch);
    }

    if(!reason){
      reason = "No Reason";
    }

    const url = baseUrl + "/api/method/at_erpnext_tap_extension.at_erpnext_tap_extension.api.create_leave_application";
    const createLeaveData = new URLSearchParams();
    createLeaveData.append("data", JSON.stringify({
      employee: employee,
      leave_type: leaveType,
      from_date: moment(startDate, DATE_DISPLAY_FORMAT_SHORT).format(ATTENDANCE_DATE_FORMAT),
      to_date: moment(endDate, DATE_DISPLAY_FORMAT_SHORT).format(ATTENDANCE_DATE_FORMAT),
      follow_via_email: "0"
    }));

    dispatch(updateCreatingLeaveAttribute(true));

    return axios({
      method: 'post',
      url: url,
      data: createLeaveData,
      validateStatus: () => true,
      timeoutErrorMessage: i18n.t('modal.errorModal.timeoutError'),
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateCreatingLeaveAttribute(false));
          dispatch(updateLeaveFormModalOpen(false));
          return Promise.resolve(response.data.message.leave_application_name);
        } else {
          captureErrorSentry("Error when submitting leave application", response);
          handleHttpError(response.request, errorModalStatus, dispatch, updateCreatingLeaveAttribute);
        }
      })
      .catch(error => {
        dispatch(updateCreatingLeaveAttribute(false));
        errorModalStatus.content = error.message;
        dispatch(updateErrorModalStatus(errorModalStatus));
      });
  }
};
