import React from "react";
import {HStack, Input, Text, VStack} from "native-base";
import {TapInputElement} from "../Components/TapInputElement";

interface DeveloperScreenProps {
  selectedOpeningTime: string;
  selectedClosingTime: string;
  expoPushToken: string;
  isOpeningTimePickerVisible: boolean;
  isClosingTimePickerVisible: boolean;
  showOpeningTimePicker: () => void;
  showClosingTimePicker: () => void;
  hideOpeningTimePicker: () => void;
  hideClosingTimePicker: () => void;
  handleConfirmOpeningTimePicker: (time: Date) => void;
  handleConfirmClosingTimePicker: (time: Date) => void;
}

export const DeveloperScreen = (props: DeveloperScreenProps) => {
  return (
    <VStack
      px={4}
      pt={4}
      bg="secondary.500"
      h="full"
      flex={1}
      space="md"
    >
      <Text fontWeight={"bold"}>Opening and Closing Time</Text>
      <HStack
        justifyContent="space-between"
        space="md"
      >
        <TapInputElement
          mode="time"
          labelName="Opening Time"
          isError={false}
          selectedValue={props.selectedOpeningTime}
          isDateTimePickerOpen={props.isOpeningTimePickerVisible}
          openDateTimePicker={props.showOpeningTimePicker}
          closeDateTimePicker={props.hideOpeningTimePicker}
          handleValueChange={props.handleConfirmOpeningTimePicker}
        />
        <TapInputElement
          mode="time"
          labelName="Closing Time"
          isError={false}
          selectedValue={props.selectedClosingTime}
          isDateTimePickerOpen={props.isClosingTimePickerVisible}
          openDateTimePicker={props.showClosingTimePicker}
          closeDateTimePicker={props.hideClosingTimePicker}
          handleValueChange={props.handleConfirmClosingTimePicker}
        />
      </HStack>
      <Input
        value={props.expoPushToken}
      />
    </VStack>
  );
}
